import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Button, Steps, DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { ArrowLeftOutlined, CheckOutlined, CloudUploadOutlined, DownloadOutlined, SettingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { globalApiService } from '../../services/apiService';
import {
  genSupGstDateFiling,
  genSupStatus,
  genSupStatusLoding
} from '../../utils/constants';
import UploadPRV2 from '../../components/reportsV2/UploadPrV2';
import { IState } from '../../models';
import { nextFilingDate, nextGstGenerate, reSetUploadPr, supplireNextFilingDateAfterUpload } from '../../store/actions/supplierReportActions';

const queryString = require('query-string');

const { Step } = Steps;
const steps = [
  {
    title: 'First',
    content: 'First-content',
  },
  {
    title: 'Second',
    content: 'Second-content',
  },
  {
    title: 'Last',
    content: 'Last-content',
  },
];
interface ICellComponent {
  onResetGenerate?: any;
  onClickView?: any;
  className?: string;
}

const GenerateSupplierReports: React.FunctionComponent<ICellComponent> = (props) => {
  const { onResetGenerate, className, onClickView } = props;

  const storeDispatch = useDispatch();
  useEffect(() => {
    storeDispatch(reSetUploadPr());
    runPageInterval(0);
  }, []);


  const supplireState = useSelector((state: IState) => {
    console.info(state);
    return state.supplierReducer;
  });

  const { pageStep, file_upload_id } = supplireState;

  const history = useHistory();
  const [current, setCurrent] = useState(true);

  const [loading, setLoading] = useState('10%');
  const [loadingPage, setLoadingPage] = useState('100%');
  const [dateChange, setDateChange] = useState<boolean>(false);
  const d = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 11);

  const [date, setDate] = useState(d);
  const [dateString, setDateString] = useState(d.toISOString().split('T')[0]);

  function onChange(dateLo: any, dateStringLo: any) {
    setDate(dateLo);
    setDateString(dateStringLo);
  }
  async function runInterval(b: any) {
    console.info('data', b);
    if (b < 100) {
      const dLo = await getStatus();
      console.info('await data', dLo);
      setTimeout(() => {
        runInterval(dLo);
      }, 5000);
    }
  }
  async function getStatus() {
    const dataLo = await globalApiService.supplierReoprtStatus(file_upload_id);

    setLoading(dataLo.data.data.message);
    return parseInt(dataLo.data.data.message.split('%')[0], 10);
  }


  async function runPageInterval(b: any) {
    console.info('data', b);
    if (b < 100) {
      const dLo = await getPageStatus();
      console.info('data', dLo);
      setTimeout(() => {
        runPageInterval(dLo);
      }, 5000);
    }
  }
  async function getPageStatus() {
    const dataLo = await globalApiService.supplierReoprtPageStatus();

    setLoadingPage(dataLo.data.data.completion_percentage);

    if(dataLo.data.data.page == 'SUPPLIER_REPORT_DUE_DATE_SELECTION'){
      console.info('data', supplireNextFilingDateAfterUpload(dataLo.data.data));
      storeDispatch(supplireNextFilingDateAfterUpload(dataLo.data.data));
    }
    if(dataLo.data.data.tracker_id == ''){
      setLoadingPage('100%');
      console.info('TEst', nextFilingDate());
        
      

      return 100;
    } 
    return parseInt(dataLo.data.data.completion_percentage.split('%')[0], 10);
  }
  const urlParams = queryString.parse(history.location.search) || {};

  function onClickBack() {
    onResetGenerate();
  }

  function onUploadFile() {
    //if(pageStep < 2 ){
      storeDispatch(reSetUploadPr());
      setCurrent(!current);
    //}
  }


  return (
    <>
      {parseInt(loadingPage.split('%')[0], 10) < 100 ?
        <Row >
          <Col sm={24} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 100000, background: '#4532BF1c' }}  >
            <div style={{
              position: 'relative',
              width: '500px',
              height: '385px',
              left: '27.5%',
              top: '151px', backgroundColor: '#fff'
            }}>
              <Row style={{ textAlign: 'center' }}>
                <Col sm={24} style={{ textAlign: 'center', padding: '80px 0px 0px 0px' }}>
                  <div>
                    <img src={genSupStatusLoding} style={{ position: 'relative' }} />
                    <div style={{ position: 'relative', top: '-60px', color: '#FF5F4F' }}>{loadingPage}</div>
                  </div>
                </Col>
                <Col sm={24} style={{ padding: '40px 80px', fontWeight:'bold' }} >  Report generations is already in progress,
                  please try after some time.</Col>

                <Col sm={24}   >
                  <Button onClick={onResetGenerate} style={{ backgroundColor: '#462E90', color: '#fff', borderRadius: '5px', width:'108px' }}> Close </Button>
                </Col>

              </Row>

            </div>
          </Col>
        </Row> : ""
      }

      <Row>
        <Col sm={24}>
          <Row>
            <Col sm={24}>
              <Title level={3}>Report Central</Title>
            </Col>
          </Row>
        </Col>
        <Col sm={24} style={{ padding: '5px' }} className='bxsh'>
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={1}>
              <Button type='primary' icon={<ArrowLeftOutlined style={{color:'#ffffff' }} />}  onClick={onClickBack} style={{color:'#462E90' , cursor: 'pointer'}}/>
            </Col>
            <Col sm={23}>
              <Title level={3} style={{ fontSize: '15px', margin: '5px' }}>Generate Supplier Reports</Title>
            </Col>
          </Row>
        </Col>

        <Col sm={24} style={{ padding: '5px' }} className='bxsh'>
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={10} offset={7}>
              <Steps current={pageStep}>
                <Step icon={ 
                      pageStep ?
                      pageStep == 3?
                      <CloudUploadOutlined  />
                      :
                      <CloudUploadOutlined  onClick={onUploadFile} />
                      :
                      <CloudUploadOutlined  onClick={onUploadFile} />
                      
                      } />
                <Step icon={<SettingOutlined />} />
                <Step icon={<CheckOutlined />}>Test</Step>
              </Steps>
            </Col>
          </Row>
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={10} offset={7}>
              <Row>
                <Col sm={2}>
                  <span style={{ color: pageStep > 0 ? '#462E90' : '#444444' }}>Upload</span>
                </Col>
                <Col sm={4} offset={8}>
                  <span style={{ color: pageStep > 1 ? '#462E90' : '#444444' }}>Filing Date</span>
                </Col>
                <Col sm={2} offset={8}>
                  <span style={{ color: pageStep > 2 ? '#462E90' : '#444444' }}>Generate</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col sm={24} style={{ padding: '5px', display: pageStep > 0 ? 'none' : 'Block' }} className="bxsh">
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={4} offset={20}>
              <Title level={3} style={{ fontSize: '15px', color: '#ff4d4f' }}>
                <DownloadOutlined style={{ fontSize: '20px', margin: '5px',color:'#462E90' }} />
                <a
                  href={'data:text/csv;charset=utf-8,"GSTIN","Full_Name","Email","Phone_Number"'}
                  download="jitfinTemplate.csv"
                >
                  Sample Template
                </a>
              </Title>
            </Col>
          </Row>

          <Row style={{ backgroundColor: '#FFFFFF', display: !pageStep ? 'block' : 'none' }}>
            <Col sm={24}>
              <UploadPRV2 setActiveStep={current} />
            </Col>
          </Row>
        </Col>

        <Col sm={24} style={{ padding: '5px', display: pageStep == 1 ? 'Block' : 'none', height: '432px' }} className="bxsh">
          <Row>
            <Col sm={24} style={{ padding: '30px', textAlign: 'center' }}>
              <img src={genSupGstDateFiling} />
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <p style={{ width: '100%', textAlign: 'center', color:'#000' }}>Select filling Date and Click on Generate to start generating the report</p>
            </Col>
          </Row>

          <Row style={{ backgroundColor: '#F5F4FC' }}>
            <Col  sm={2}/>
            <Col sm={20} >
              <Row>
                <Col sm={3} style={{ backgroundColor: '#F5F4FC' }} />
                <Col sm={1} />
                <Col sm={3} style={{ backgroundColor: '#fff', margin: '20px 0px', border: '1px solid #C4C4C4', borderRadius: '3px' }}>
                  <Row style={{ textAlign: 'center' }}>
                    <Col sm={24}>
                      <span style={{ verticalAlign: "sub", color:'#979797' }} > Last Filing Date</span>
                    </Col>
                  </Row>
                </Col>
                <Col sm={1} />
                <Col sm={6} style={{ backgroundColor: '#fff', margin: '20px 0px', border: '1px solid #C4C4C4', borderRadius: '3px' }}>
                  <Row style={{ textAlign: 'center' }}>
                    <Col sm={12} style={{borderRight:'nome', color: '#000' }}>
                      <span style={{ verticalAlign: "sub" }} > GSTR1 :</span>
                    </Col>

                    <Col sm={12} style={{borderLeft:'none', color: '#000' }}>
                      {dateChange ? <DatePicker onChange={onChange} style={{border:'none'}} /> : <span style={{ verticalAlign: "sub" }} > {dateString}</span>}

                    </Col>
                  </Row>
                </Col>
                
                <Col sm={1} />
                <Col sm={8} style={{ backgroundColor: '#F5F4FC', margin: '20px 0px' }}>
                  <Button onClick={() => { setDate(d); setDateString(d.toISOString().split('T')[0]); setDateChange(!dateChange); }} style={{ border: '1px solid #462E90', color: '#462E90', borderRadius: '5px', width:'220px' }}>
                    {' '}
                    {dateChange ? 'Back to default' : 'Change last filing date'}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col  sm={2}/>
          </Row>

          <Row>
            <Col sm={24}>
              <p style={{ width: '100%', textAlign: 'center' }}>
                <Button
                  onClick={() => {
                    const formData = { 'file_upload_id': file_upload_id, 'last_tax_filing_date': date.toISOString().slice(0, 10) };

                    storeDispatch(nextGstGenerate(formData));
                    runInterval(1);
                  }}
                  style={{ backgroundColor: '#462E90', margin: '20px 0px ', color: '#fff', width: '360px', borderRadius: '5px' }}
                >
                  GENERATE GST REPORT
                </Button>
              </p>
            </Col>
          </Row>
        </Col>


        <Col sm={24} style={{ padding: '5px', display: pageStep > 2 ? 'Block' : 'none', height: '362px' }} className="bxsh">
          <Row style={{ height: '100%' }}>
            <Col sm={24} style={{ textAlign: 'center', padding: '80px 0px 0px 0px' }}>
              <div>
                <img src={genSupStatus} />
                <img src={genSupStatusLoding} style={{ position: 'relative', left: '-130px' }} />
                <div style={{ position: 'relative', left: '-40px', top: '-95px', color: '#FF5F4F' }}>{loading}</div>
              </div>
            </Col>
            <Col sm={24}>
              <p style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                textAlign: 'center',
                letterSpacing: '0.04em',
                color: '#4A4A4A'
              }}
              >
                GST report is generating... it will take around 30 minutes to complete the process.
                You will get an email notification once report is generated.
              </p>

            </Col>
            <Col sm={24}>
              <p style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '22px',
                textAlign: 'center',
                letterSpacing: '0.04em',
                color: '#979797'
              }}
              >
                While we are generating the report feel free to navigate through other important features.
              </p>

            </Col>

          </Row>

        </Col>

        <Col sm={24} style={{ margin: '20px ', display: pageStep > 2 ? 'Block' : 'none', textAlign: 'center' }}>
          <Row>

            <Col sm={6} />
            <Col sm={6}>
              <Button onClick={onResetGenerate} style={{ backgroundColor: '#462E90', color: '#fff', width: '100%', borderRadius: '5px' }}>GO TO DASHBOARD</Button>
            </Col>
            <Col sm={6}>
              <Button onClick={onClickView} style={{ backgroundColor: '#462E90', color: '#fff', width: '100%', borderRadius: '5px' }}>VIEW OTHER GST REPORTS</Button>
            </Col>

            <Col sm={6} />
          </Row>

        </Col>





      </Row>




    </>
  );
};

export default GenerateSupplierReports;
