/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Modal, Radio, Input } from 'antd';
import { Table, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  addInvoice,
  getInvoiceSearch,
  clearInvoice
} from '../store/actions/ReconcilationActions';

interface IReportModal3 {
  setActiveStep?: any;
  isModalVisible: any;
  setIsModalVisible: any;
  matchData: any;
  callback?: Function;
}

interface IRowComponent {
  data?: any;
  id?: any;
  setKey?: any;
  i?: any;
  setInvoice?: any;
  onChangeRadio?: any;
  activeSection?: any;
}

const RowComponent: React.FunctionComponent<IRowComponent> = (props) => {
  const { data, id, i, onChangeRadio, activeSection } = props;

  const section = data.g2a_id ? 'gstr2a' : 'itr';
  const itemId = data.g2a_id || data.itr_id;
  const gstr2aType = data.g2a_type;

  const gstin = data.gstin_2a || data.gstin_itr;
  const taxRate = data.tax_rate_2a || data.tax_rate_itr;
  const stateTax = data.state_tax_2a || data.state_tax_itr;
  const centralTax = data.central_tax_2a || data.central_tax_itr;
  const invoiceDate = data.invoice_date_2a || data.invoice_date_itr;
  const invoiceValue = data.invoice_value_2a || data.invoice_value_itr;
  const taxableValue = data.taxable_value_2a || data.taxable_value_itr;
  const invoiceNumber = data.invoice_number_2a || data.invoice_number_itr;
  const integratedTax = data.integrated_tax_2a || data.integrated_tax_itr;
  const activeId = activeSection && (activeSection.g2a_id || activeSection.itr_id);
  return (
    <>
      <tr
        className="trow"
        onClick={() => {
          // props.setInvoice(data);
          // props.setKey(i);
        }}
      >
        <td>
          <div className="bt bl">
            <div className="flex-y pa--10">
              <Radio
                checked={activeSection && activeId === itemId}
                onChange={() => onChangeRadio(data)}
              />
            </div>
          </div>
        </td>
        <td>
          <div className="bt">
            <div className="flex-y pa--10">
              <label>{gstin || '-'}</label>
            </div>
          </div>
        </td>
        <td>
          <div className="bt ">
            <div className="flex-y pa--10">
              <label>-</label>
            </div>
          </div>
        </td>
        <td>
          <div className="bt ">
            <div className="flex-y pa--10">
              <label>{invoiceNumber || '-'}</label>
            </div>
          </div>
        </td>
        <td>
          <div className="bt ">
            <div className="flex-y pa--10">
              <label>{invoiceDate || '-'}</label>
            </div>
          </div>
        </td>
        <td>
          <div className="bt ">
            <div className="flex-y pa--10">
              <label>{taxRate || 0}</label>
            </div>
          </div>
        </td>
        <td>
          <div className="bt ">
            <div className="flex-y pa--10">
              <label>{taxableValue || 0}</label>
            </div>
          </div>
        </td>
        <td>
          <div className="bt">
            <div className="flex-y pa--10">
              <label>{integratedTax || 0}</label>
            </div>
          </div>
        </td>
        <td>
          <div className="bt">
            <div className="flex-y pa--10">
              <label>{centralTax|| 0}</label>
            </div>
          </div>
        </td>
        <td>
          <div className="bt">
            <div className="flex-y pa--10">
              <label>{stateTax || 0}</label>
            </div>
          </div>
        </td>
        <td>
          <div className="bt">
            <div className="flex-y pa--10">
              <label>{invoiceValue || 0}</label>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

const ReportModal3: React.FunctionComponent<IReportModal3> = ({
  isModalVisible,
  setIsModalVisible,
  matchData,
  callback = () => {}
}) => {
  const [key, setKey] = useState(null);
  const [activeSection, setActiveSection] = useState<any>(null);
  const [gstValue, setGstValue] = useState<string>('');
  const [invoiceValue, setInvoiceValue] = useState<string>('');
  const invoiceData = useSelector(
    (state: any) => state.reconReducer.invoice_data
  );
  const dispatch = useDispatch();
  const [query, setQuery] = useState<any>(null);
  // const params: any = useParams();
  const SearchOnClick = () => {
    if (query && query.gstin && query.invoice_number) {
      dispatch(getInvoiceSearch(query));
    }
  };

  const handleOk = () => {
    resetModal();
  };

  const handleCancel = () => {
    resetModal();
  };

  const getInvoiceResults = () => {
    if(!invoiceData){
      return null;
    }

    return (
      <div className="overflow">
        <Table className="table reconcile-report">
          <thead className="thead">
            <tr>
              <th />
              <th>GSTIN</th>
              <th>Supplier Name</th>
              <th>Invoice Number</th>
              <th>Invoice date</th>
              <th>Tax rate</th>
              <th>Taxable value</th>
              <th>IGST</th>
              <th>CGST</th>
              <th>SGST/UGST</th>
              <th>Total invoice val</th>
              {/* <th>Confidence score</th> */}
              {/* <th>G2A TYPE</th>
          <th>GSTIN 2A</th> */}
            </tr>
          </thead>
          <tbody className="tbody">
            {
          invoiceData?.data &&
          invoiceData.data.map((item: any, i: any) => (
            <>
              <RowComponent
                i={i}
                id={key}
                key={item.g2a_id || item.itr_id}
                setKey={setKey}
                data={item}
                activeSection={activeSection}
                onChangeRadio={onChangeRadio}
              />
            </>
          ))
      }
          </tbody>
        </Table>
        <div className="fill-width">
          <Button
            disabled={!activeSection || (!invoiceValue.length && !gstValue.length)}
            className="flex mlr-auto align-center red-btn border-0 cursor-pointer"
            onClick={() => {
              dispatch(
                addInvoice({
                  section_id: activeSection?.g2a_id || activeSection?.itr_id,
                  section: activeSection?.g2a_id ? 'gstr2a' : 'input_tax_register',
                  gstr2a_type: activeSection?.g2a_type,
                  action: 'add_invoice_data',
                  match_entry_id: matchData.id,
                })
              );
            }}
          >
            save
          </Button>
        </div>
      </div>
    );
  };

  const resetModal = () => {
    setKey(null);
    setIsModalVisible(false);
    setGstValue('');
    setInvoiceValue('');
    setActiveSection(null);
    dispatch(clearInvoice());
  };

  const onChangeRadio = (data: any) => {
    setActiveSection(data);
  };

  return (
    <Modal
      width={1400}
      title={<div className="fs--16 text-center bold">Manual Match</div>}
      visible={isModalVisible}
      onOk={handleOk}
      closeIcon={<div className="fs--16 pr--15" style={{color:'#462E90'}}>close</div>}
      onCancel={handleCancel}
      footer={[]}
    >
      <div className="d-flex align-center">
        <Input
          className="search-input"
          value={gstValue}
          placeholder="GSTIN"
          onChange={(e) => {
            setGstValue(e.target.value);
            setQuery({ ...query, id: matchData.id, gstin: e.target.value });
          }}
        />
        <Input
          className="search-input"
          placeholder="INVOICE NUMBER"
          value={invoiceValue}
          onChange={(e) => {
            setInvoiceValue(e.target.value);
            setQuery({ ...query, id: matchData.id, invoice_number: e.target.value });
          }}
        />
        <div className="fill-width">
          <Button
            className="flex mlr-auto align-center red-btn border-0 cursor-pointer"
            disabled={!gstValue?.length || !invoiceValue.length}
            onClick={() => {
              SearchOnClick();
            }}
            style={{backgroundColor:'#462E90'}}
          >
            Search
          </Button>
        </div>
      </div>
      { getInvoiceResults()}
    </Modal>
  );
};

export default ReportModal3;
