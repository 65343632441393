/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Input } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { gstr2aIcon, infoIcon } from '../utils/constants';
import { generateGstOtp } from '../store/actions/ReconcilationActions';

interface IDownloadGstR2A {
  setActiveStep?: any;
}

const DownloadGstR2A: React.FunctionComponent<IDownloadGstR2A> = () => {
  const dispatch = useDispatch();
  const [gst, setGst] = useState<any>(null);
  return (
    <div className="flex-y align-center download-gst-r2a-wrapper">
      <div className="mtb--80 flex-y align-center">
        <span className="fs--14 lh--21 img-text">
          Downloading GSTR2A from GST Portal
        </span>
        <img src={gstr2aIcon} alt="img" />
      </div>
      <div className="fs--20 lh--30 title">
        Enter Your Username to connect with GST Portal
      </div>
      <div className="fs--16 lh--24 subtitle">
        Same Username as in www.gst.gov.in
      </div>
      <Input
        name="gstin"
        onChange={(e) => {
          setGst(e.target.value);
        }}
        placeholder="GSTN Username"
        size="large"
        value={gst}
        style={{ width: '30%', marginTop: '4.5rem' }}
      />
      <div className="flex-x align-center mt--20">
        <img src={infoIcon} alt="infoIcon" className="mr--10" />
        <span className="">Don’t know what a GST Login ID is?</span>
      </div>
      <div
        className="next-btn flex-x align-center center fs--16 lh--20"
        style={{ opacity: gst ? 1 : 0.5 }}
        onClick={() => {
          dispatch(generateGstOtp(gst));
        }}
      >
        NEXT
      </div>
    </div>
  );
};

export default DownloadGstR2A;
