import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Formik } from 'formik';
import { Input as antdInput, Modal, Spin } from 'antd';
import { Form, Input, Checkbox } from 'formik-antd';
import Row, { RowProps } from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import { useSelector } from 'react-redux';
import Paragraph from 'antd/es/typography/Paragraph';
import { Select } from 'formik-antd/es';
import { WarningTwoTone } from '@ant-design/icons';
import StylizedButton from './stylizedButton';
import { IState } from '../models';
import ValidationService from '../services/validationService';
import { ROUTES } from '../constants/routePaths';
import TermsAndConditionsPage from '../pages/termsAndConditionsPage';

const { Group } = antdInput;

export interface IAnchorFormFields {
  name: string;
  gender: string;
  tradeName: string;
  emailId: string;
  primaryContactNumber: string;
  whatsappOptin: boolean;
  termsAndConditions: boolean;
}

export default function AnchorDetailsForm(props: {
  onSubmitFn: (values: IAnchorFormFields) => any;
  justifyBtn?: RowProps['justify'];
}) {
  const { justifyBtn } = props;
  const authState = useSelector((state: IState) => state.authReducer);

  const nonStringKeys = ['whatsappOptin', 'termsAndConditions'];
  const isSubmitEnabled = (values: IAnchorFormFields): boolean => {
    const disabled = submitControlFields
      .filter((key: string) => !nonStringKeys.includes(key))
      .map((key: string) => (values as any)[key])
      .reduce((acc: boolean, val: string) => acc && val?.length, true);
    return disabled && values.termsAndConditions && values.whatsappOptin;
  };
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [triggerForPrivacyHeadingScroll, setTriggerForPrivacyHeadingScroll] = React.useState(false);
  const submitControlFields = [
    'name',
    'tradeName',
    'emailId',
    'primaryContactNumber',
    'whatsappOptin',
    'termsAndConditions'
  ];
  const initialValues =
    {
      name: '',
      gender: '',
      tradeName: authState.anchorTradeName,
      emailId: '',
      primaryContactNumber: '',
      whatsappOptin: true,
      termsAndConditions: true,
    } as IAnchorFormFields;

    
    const closeModal = () => {
      setIsModalOpen(false)
      setTriggerForPrivacyHeadingScroll(false)

    };
    const captureClickForInModalView =
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, trigger_scroll: boolean) => {
      e.preventDefault();
      setIsModalOpen(true)
      if(trigger_scroll){
        setTriggerForPrivacyHeadingScroll(true)
      }
    };

  return (
    <Formik
      initialValues={initialValues}
      validate={(values: IAnchorFormFields) => {
        const validationFieldToFunctionMap = {
          name: ValidationService.isName,
          emailId: ValidationService.isEmail,
          primaryContactNumber: ValidationService.isIndianPhoneNumber,
          whatsappOptin: () => {
            return true;
          },
          termsAndConditions: () => {
            return true;
          },
        };

        const requiredFeilds = Object.keys(initialValues);

        return ValidationService.validateFields(
          values,
          validationFieldToFunctionMap,
          requiredFeilds
        );
      }}
      onSubmit={(values: IAnchorFormFields, actions) => {
        // TODO: Need to add other to this field
        const transformedGender = values.gender === 'mr' ? 'M' : 'F';
        props.onSubmitFn({ ...values, gender: transformedGender });
        actions.setSubmitting(false);
      }}
      render={({ values, handleSubmit }) => (
        <Form className='signup-form'>
          <Paragraph strong>Trade name of the company</Paragraph>
          <Form.Item name="tradeName" hasFeedback showValidateSuccess>
            <Input name="tradeName" size="large" disabled />
          </Form.Item>

          <Paragraph strong>Name</Paragraph>
          <Group compact>
            <Form.Item
              name="gender"
              hasFeedback
              showValidateSuccess
              style={{ width: '30%' }}
            >
              <Select
                name="gender"
                placeholder="Salutation"
                showSearch
                size="large"
              >
                <Select.Option value="mr">Mr</Select.Option>
                <Select.Option value="miss">Miss</Select.Option>
                <Select.Option value="mrs">Mrs</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="name"
              hasFeedback
              showValidateSuccess
              style={{ width: '70%' }}
            >
              <Input name="name" placeholder="Name" size="large" required />
            </Form.Item>
          </Group>

          <Paragraph strong>Contact Number</Paragraph>
          <Form.Item
            name="primaryContactNumber"
            hasFeedback
            showValidateSuccess
          >
            <Input
              name="primaryContactNumber"
              placeholder="10 digit Contact Number"
              size="large"
              required
            />
          </Form.Item>

          <Paragraph strong>Email</Paragraph>
          <Form.Item name="emailId" hasFeedback showValidateSuccess>
            <Input
              name="emailId"
              placeholder="Email ID"
              size="large"
              required
            />
          </Form.Item>

          <Form.Item name="termsAndConditions" required={false} className='toc-privacy-consent signup-form__consent-declaration'>
            <Checkbox name="termsAndConditions" style={{ fontWeight: 'bold' }}>
              <span className="text">By clicking &apos;Submit&apos; below, you accept our </span>
              <a 
                onClick={(e) => captureClickForInModalView(e, false)}
                href={ROUTES.TERMS_AND_CONDITIONS}
              >
                Terms & Conditions
              </a>
              <span className='text'> & </span>
              <a 
                onClick={(e) => captureClickForInModalView(e, true)}
                href={ROUTES.TERMS_AND_CONDITIONS}
              >
                Privacy Policy
              </a>
            </Checkbox>
            {!values.termsAndConditions && (
              <Paragraph
                style={{
                  color: 'red',
                  marginTop: '8px',
                  verticalAlign: 'baseline',
                }}
              >
                <WarningTwoTone
                  twoToneColor="red"
                  style={{ marginRight: '5px' }}
                />
                Required
              </Paragraph>
            )}
          </Form.Item>

          <Form.Item name="whatsappOptin" required={false} className='whatsapp-consent signup-form__consent-declaration'>
            <Checkbox name="whatsappOptin" style={{ fontWeight: 'bold' }}>
              You consent to receive updates and product related communication via WhatsApp
            </Checkbox>
            {!values.whatsappOptin && (
              <Paragraph style={{ marginTop: '10px', color: 'red' }}>
                <WarningTwoTone
                  twoToneColor="red"
                  style={{ marginRight: '5px' }}
                />
                You will not be able to get important updates and news on
                WhatsApp
              </Paragraph>
            )}
          </Form.Item>

          <Row justify={justifyBtn || 'center'} className='mb--32'>
            <Col>
              <StylizedButton
                textInButton="Submit"
                onClickFn={handleSubmit}
                size="large"
                disabled={!isSubmitEnabled(values)}
              />
            </Col>
          </Row>
          <Modal
              className="terms_privacy_modal"
              title=""
              centered
              visible={isModalOpen}
              onOk={() => closeModal()}
              onCancel={() => closeModal()}
              footer={[
                <StylizedButton
                  key='ok'
                  textInButton="Ok"
                  size="large"
                  customStyles={{margin:"10px 0"}}
                  onClickFn={() => closeModal()}
                />]}
          
            >
            <section>
            <TermsAndConditionsPage scrollToPrivacyPolicySection={triggerForPrivacyHeadingScroll}/>
            </section>
          </Modal>
        </Form>
      )}
    />
  );
}
