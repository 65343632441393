import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { Col, Row } from 'antd/es/grid';
import { NavLink } from 'react-router-dom';
import { RiDashboardLine, RiUserLine } from 'react-icons/ri';
import { HiOutlineUserGroup } from 'react-icons/hi';
// import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { BiCloudUpload } from 'react-icons/bi';
import { AiOutlineThunderbolt } from 'react-icons/ai';
import { BsArrowLeftRight } from 'react-icons/bs';
import {FiFileText} from "react-icons/fi";
import JitFinLogo from './logo';
import { ROUTES } from '../constants/routePaths';
import { THEME } from '../constants/theme';
import {
  reconcilationIcon,
  dashboard,
  manageSupplier,
  inviteCol,
  inviteColNew,
  downloadCenter,
  bulkUpload,
  bulkUploadNew,
  // reportSetting,
  reconcilationIconb,
  dashboardB,
  manageSupplierB,
  downloadCenterB,
  // reportSettingB,
} from '../utils/constants';
import {FiUsers, FiBox, FiSearch} from 'react-icons/fi'

import { FiTruck } from 'react-icons/fi';
import {RocketOutlined} from '@ant-design/icons';

import {FormOutlined} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {IState} from "../models";
import {userPlusLogo} from '../utils/constants';

const NAV_BAR_COLOR = { backgroundColor: '#f0f1ff' };


const NAV_BAR_ELEMENTS = [
  {
    text: 'Dashboard',
    linkTo: ROUTES.DASHBOARD,
    paths: [ROUTES.DASHBOARD],
    keyContent: '1',
    image: dashboard,
    imageDark: dashboardB,
    icon: <RiDashboardLine />,
  },
  {
    text: 'Invite Colleagues',
    linkTo: ROUTES.MANAGE_COLLEAGUES,
    paths: [ROUTES.MANAGE_COLLEAGUES],
    keyContent: '4',
    image: inviteCol,
    imageDark: inviteColNew,
    icon: <img className='user-plus-logo' src={userPlusLogo} alt="userPlusLogo"/>,
  },
  {
    text: 'GSTIN Search',
    linkTo: ROUTES.GSTIN_SEARCH,
    paths: [ROUTES.GSTIN_SEARCH],
    keyContent: '8',
    icon:<FiSearch />,
  },
  {
    text: 'Manage Suppliers',
    linkTo: ROUTES.MANAGE_SUPPLIERS_NEW,
    paths: [ROUTES.MANAGE_SUPPLIERS_NEW],
    keyContent: '10',
    icon:<FiUsers />,
  },
  {
    text: 'Report Central',
    linkTo: ROUTES.DOWNLOAD_CENTER_V2,
    paths: [ROUTES.DOWNLOAD_CENTER_V2],
    keyContent: '5',
    image: downloadCenter,
    imageDark: downloadCenterB,
    icon:<AiOutlineThunderbolt />,
  },
  {
    text: 'Reconcilation',
    linkTo: ROUTES.RECONCILATION,
    paths: [ROUTES.RECONCILATION],
    keyContent: '6',
    image: reconcilationIcon,
    imageDark: reconcilationIconb,
    icon:<BsArrowLeftRight />,
  },
  {
    text: 'Profile',
    linkTo: ROUTES.COMPANY_PROFILE,
    paths: [ROUTES.COMPANY_PROFILE],
    keyContent: '7',
    icon:<RiUserLine />,
  },
  {
    text: 'Manage Customers',
    linkTo: ROUTES.MANAGE_CUSTOMERS,
    paths: [ROUTES.MANAGE_CUSTOMERS],
    keyContent: '9',
    icon:<HiOutlineUserGroup />,
  },
  {
    text: 'Items',
    linkTo: ROUTES.ITEMS,
    paths: [ROUTES.ITEMS],
    keyContent: '11',
    icon:<FiBox />,
  },
  {
    text: 'Transporters',
    linkTo: ROUTES.TRANSPORTERS,
    paths: [ROUTES.TRANSPORTERS],
    keyContent: '12',
    icon:<FiTruck />,
  },
  {
    text: 'Invoicing',
    linkTo: ROUTES.E_INVOICE,
    paths: [ROUTES.E_INVOICE],
    keyContent: '13',
    icon:<FiFileText />,
  },
];

function isInclude(arr: any, pathName: string) {
  for (const val of arr) {
    if (pathName.includes(val)) {
      return true;
    }
  }

  return false;
}

function SideBarNavItem(
  pathName: any,
  linkTo: string,
  linkText: string,
  keyContent: string,
  image?: any,
  imageDark?: any,
  paths?: any,
  icon?:any
) {
  return (
    <Menu.Item key={keyContent}>
      <NavLink
        to={linkTo}
        isActive={() => {
          return !!isInclude(paths, pathName);
        }}
        style={{
          textDecoration: 'none',
          color: '#58585c',
          fontSize: '10px',
        }}
        activeStyle={{
          fontWeight: 'bold',
          color: 'black',
        }}
      >
        <span className={`${keyContent == "6" && "rotate-icon"} menu-item-icon`}>
          {icon || (isInclude(paths, pathName) ? (
            <img src={imageDark} alt="" />
          ) : (
            <img src={image} alt="" />
          ))}
        </span>
        <span className="menu-item-text" style={{marginLeft:'5px'}}>{linkText}</span>
        {keyContent ==='6' && (
          <span className="beta-tag-wrapper">
            <span className="beta-tag">Beta</span>
            <span className="beta-tag-collapsed">B</span>
          </span>
        )}
      </NavLink>
    </Menu.Item>
  );
}

const getRouteKeyFromPath = (pathName: string) => {
  const filteredItems = NAV_BAR_ELEMENTS.filter((el) =>
    isInclude(el.paths, pathName)
  );
  return [filteredItems.length > 0 ? filteredItems[0].keyContent : '1'];
};

export default function SideBar(props: { pathName: string; propCollapse: boolean }) {

  const authState = useSelector((state: IState) => state.authReducer);
  const phoneNumber = authState.phoneNumber;
  const { pathName, propCollapse } = props;
  const [defaultSelectedKey, setDefaultSelectedKey] = useState<string[]>();

  useEffect(() => {
    const routeKeyPath = getRouteKeyFromPath(pathName);
    setDefaultSelectedKey(routeKeyPath);
  }, [pathName]);

  return (
    <div

      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        ...NAV_BAR_COLOR,
      }}
    >
      <Row className="logo-row flex-x center">
        <Col className="logo-wrapper">
          <JitFinLogo
            transparent
            propCollapse={propCollapse}
          />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Menu
            selectedKeys={defaultSelectedKey}
            style={{
              ...NAV_BAR_COLOR,
              borderRight: 0,
            }}
            className={'menu-item-collection'}
          >
            {NAV_BAR_ELEMENTS.map((el, i) => {
              // @ts-ignore
              // if (['8668466983', '9900002554', '9686433443', '9611412486', '8495830644', '7387231588', '9588423961', '9986104646', '9886892456', '8790938790', '9011557778', '9784446995', '9886329796', '9619423667', '9342411303'].indexOf(phoneNumber) > -1) {
              return SideBarNavItem(
                pathName,
                el.linkTo,
                el.text,
                el.keyContent,
                el.image,
                el.imageDark,
                el.paths,
                el.icon
              );
              // }
              // if([7, 8, 9, 10, 11, 12, 13].indexOf(Number(el.keyContent)) == -1) {
              //   return SideBarNavItem(
              //     pathName,
              //     el.linkTo,
              //     el.text,
              //     el.keyContent,
              //     el.image,
              //     el.imageDark,
              //     el.paths,
              //     el.icon
              //   );
              // }

            })}
          </Menu>
        </Col>
      </Row>
    </div>
  );
}
