import React, { useEffect, useState, useReducer } from 'react';
import Table from 'antd/es/table/Table';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'antd/es/modal/Modal';
import { Button } from 'antd/es';
import {
  ConsoleSqlOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons/lib';
import { IProspect, IState } from '../models';
import {
  deleteProspect,
  intitiateWhatsappMessage,
  requestProspectsOfDataSource,
  updateProspect,
} from '../store/actions/dataSourceActions';
import {
  IRequestProspectsOfDataSource,
  IWhatsappRequestBody,
} from '../models/api/dataSourceModels';
import ProspectForm from './prospectForm';
import { whatsappIcon } from '../utils/constants';
import {
  PlusOutlined,
  FilterOutlined,
  ApartmentOutlined,
  EditFilled,
  DeleteFilled,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Radio,
  Tabs,
  DatePicker,
  Upload,
  Dropdown,
  Menu,
  Select,
} from 'antd';
import { THEME } from '../constants/theme';
import { globalApiService } from '../services/apiService';
import { FiMoreHorizontal } from 'react-icons/fi';
import {
  getWatchlistEntries,
  deleteWatchlistEntries,
} from '../store/actions/dataSourceActions';
import OpenNotification from '../utils/notification';

export default function WatchListTable(props: {
  pageSize: number;
  dataSourceName: string;
  searchProspectsQuery: string;
  status: string;
  removeWatchlistTrigger: boolean;
  fetchWatchlistTrigger:boolean
  changeRemoveWatchlistRigger:() => void;
  handleFetchWatchlistTrigger: () => void;
 }) {
  const {
    pageSize,
    dataSourceName,
    searchProspectsQuery,
    status,
    removeWatchlistTrigger,
    changeRemoveWatchlistRigger,
    fetchWatchlistTrigger,
    handleFetchWatchlistTrigger
  } = props;
  const [tableData, setTableData] = useState<IProspect[]>([]);
  const [pagination, setPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize });
  const [loading, setLoading] = useState<boolean>(false);
  const [
    prospectBeingEdited,
    setProspectBeingEdited,
  ] = useState<IProspect | null>(null);
  const storeDispatch = useDispatch();

  const handleMenuClick = (e: any, record: any) => {
    if (e.key == '1') {
      if (record.id) {
        storeDispatch(deleteWatchlistEntries({ ids: [record.id] }));
        // setLoading(true);
        // setTimeout(()=> {
          // storeDispatch(getWatchlistEntries());
        //   setLoading(false);
        // },2000)
      }
    }
  };
  useEffect(() => {
    fetchEntries(
      dataSourceName,
      1,
      pagination.pageSize,
      searchProspectsQuery,
      status
    );
    handleFetchWatchlistTrigger()
  }, [storeDispatch,fetchWatchlistTrigger]);

  ///
  const watchEntryFlag = useSelector(
    (state: IState) => state.dataSourceReducer
  );

  const { deleteWatchlistEntriesFlag } = watchEntryFlag;


  useEffect(() => {
    // if(deleteWatchlistEntriesFlag){
    fetchEntries(
      dataSourceName,
      1,
      pagination.pageSize,
      searchProspectsQuery,
      status
    );
    // }
  }, [deleteWatchlistEntriesFlag]);
  useEffect(() => {
    if (searchProspectsQuery !== undefined && dataSourceName !== undefined && status !== undefined) {
      fetchEntries(
        dataSourceName,
        1,
        pagination.pageSize,
        searchProspectsQuery,
        status
      );
    }
    // eslint-disable-next-line
  }, [searchProspectsQuery,status]);

  function fetchEntries(
    nameOfDataSource: string,
    pageNumber: number,
    sizeOfPage: number,
    search?: string,
    status?: string,
  ) {
    setLoading(true);
    storeDispatch(
      getWatchlistEntries({
        dataSourceName: nameOfDataSource,
        pageSize: sizeOfPage,
        pageNumber,
        search,
        status,
      } as IRequestProspectsOfDataSource)
    );
  }
  const dataSourceState = useSelector(
    (state: IState) => state.dataSourceReducer
  );

  const { watchlistEntries } = dataSourceState;
  useEffect(() => {
    if (Array.isArray(watchlistEntries)){
      setTableData(watchlistEntries);
      setLoading(false)
    }
  }, [watchlistEntries]);
  



  const columns = [
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: 'GSTR Filing Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Email id',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone no',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (text: any, record: Object) => {
        return (
          <div className="iconContainer">
            <Dropdown
              overlay={() => (
                <Menu onClick={(e: any) => handleMenuClick(e, record)}>
                  <Menu.Item key="1">Remove from watchlist</Menu.Item>
                  {/* <Menu.Item key="2">Reconcile</Menu.Item> */}
                </Menu>
              )}
            >
              <FiMoreHorizontal className="iconContainer__item"></FiMoreHorizontal>
            </Dropdown>
          </div>
        );
      },
    },
  ] as any[];
  function handleTableChange(pager: any) {
    pagination.current = pager.current;
    setPagination(pagination);
    // storeDispatch(getWatchlistEntries());
    fetchEntries(dataSourceName, pagination.current, pagination.pageSize);
  }

  const [rowKeys, setRowKeys] = useState<React.Key[]>();
  useEffect(() => {
    if(removeWatchlistTrigger){
    if (Array.isArray(rowKeys) && rowKeys?.length > 0) {
      // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you want to remove these entries?')) {
          storeDispatch(deleteWatchlistEntries({ids: Array.from(rowKeys)}));
      }
    }else {
        OpenNotification('Please select a supplier','error')
    }
    }
    changeRemoveWatchlistRigger()
  }, [removeWatchlistTrigger]);
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setRowKeys(selectedRowKeys);
    },
  };
  return (
    <div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={tableData}
        loading={loading}
        onChange={handleTableChange}
        pagination={pagination}
        scroll={{ x: true }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </div>
  );
}

WatchListTable.defaultProps = {
  searchProspectsQuery: '',
};
