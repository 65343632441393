import { call, put, takeEvery } from 'redux-saga/effects';
import { ReconActions } from '../actions/ReconcilationActions';
import { exceptionLogger } from './utils';
import { IAction } from '../../models';

import { globalApiService } from '../../services/apiService';
import OpenNotification from '../../utils/notification';
import { ROUTES } from '../../constants/routePaths';




function* generateOtp(action: IAction) {
  const payload: any = action.body;
  const response = yield globalApiService.generateGSTOtp(payload.gstUsername);
  if (response.data.success) {
    if(response.data.data.tokenExists) {
      OpenNotification(response.data.data.message, 'success');
      yield put({type: ReconActions.TOKEN_EXISTS});
      const resp = yield globalApiService.downloadGst2Ra();
      if (resp.data.success) {
        OpenNotification('Download Successful!', 'success');
        yield put({
          type: ReconActions.SET_TRACKER_ID,
          body: {
            tracker_id: resp.data.data.tracker_id
          }
        });
        yield put(
          {
            type: ReconActions.SET_PAGE_STATE,
            body: {
              sub_page_id: 2,
              track_id: resp.data.data.tracker_id
            }
          }
        );
      } else {
        OpenNotification('GST data download failed!', 'error');
      }
    } else {
      OpenNotification('Otp sent successfully', 'success');
      yield put({type: ReconActions.GENERATE_OTP_SUCCESS});
    }
  }
}

function* validateOtp(action: IAction) {
  const payload: any = action.body;
  const otpResponse = yield globalApiService.validateGSTOtp(payload.otp);
  if (otpResponse.data.success) {
    OpenNotification('Validate Successful!', 'success');
    yield put({ type: ReconActions.VALIDATE_OTP_SUCCESS });
    const response = yield globalApiService.downloadGst2Ra();
    if (response.data.success) {
      OpenNotification('Download Successful!', 'success');
      yield put({
        type: ReconActions.SET_TRACKER_ID,
        body: {
          tracker_id: response.data.data.tracker_id
        }
      });
      yield put(
        {
          type: ReconActions.SET_PAGE_STATE,
          body: {
            sub_page_id: 2,
            track_id: response.data.data.tracker_id
          }
        }
      );
    } else {
      OpenNotification('GST data download failed!', 'error');
    }
  } else {
    OpenNotification('OTP Validation failed!', 'error');
  }
}

function* uploadPr(action: IAction) {
  try{
    const payload: any = action.body;
    yield globalApiService.uploadPurchaseData(payload);
    yield put({ type: ReconActions.UPLOADPR_SUCCESS });
    OpenNotification('Upload successfully!', 'success');
    yield put({ type: ReconActions.SET_PAGE_STATE, body: { sub_page_id: 3 } });
  }catch(err){
    // OpenNotification('Upload error, please try again', 'error');
    yield put({ type: ReconActions.UPLOADPR_FAILURE });
  }
}

function* addMapping(action: IAction) {
  const payload: any = action.body;
  yield globalApiService.addMappings(payload);
  yield put({ type: ReconActions.ADD_MAPPINGS_SUCCESS });
  OpenNotification('Mapping Saved', 'success');
}

function* getMapping(action: IAction) {
  const payload = yield globalApiService.getMappings();
  yield put({
    type: ReconActions.GET_MAPPINGS_SUCCESS,
    body: payload.data && payload.data.data,
  });
}

function* processItrFileUpload(action: IAction) {
  yield globalApiService.processItrFileUpload();
  yield put({ type: ReconActions.PROCESS_ITR_FILE_UPLOAD_STARTED });
  OpenNotification('Input Tax Register file is being Processed', 'success');
  yield put({ type: ReconActions.SET_PAGE_STATE, body: { sub_page_id: 4 } });
}

function* getItrFileUpload(action: IAction) {
  const payload = yield globalApiService.getItrFileUpload();
  yield put({
    type: ReconActions.GET_ITR_FILE_UPLOAD_SUCCESS,
    body: payload.data && payload.data.data,
  });
}

function* getDropdown(action: IAction) {
  const payload = yield globalApiService.getDropdowns();
  yield put({
    type: ReconActions.GET_DROPDOWNS_SUCCESS,
    body: payload.data.data,
  });
}

function* getPageState(action: IAction) {
  const payload = yield globalApiService.getPageState();
  yield put({
    type: ReconActions.GET_PAGE_STATE_SUCCESS,
    body: payload.data.data,
  });
}

function* setPageState(action: any) {
  const payload: any = action.body;
  yield globalApiService.setPageState(payload);
  yield put({ type: ReconActions.GET_PAGE_STATE });
}

function* getReportList(action: IAction) {
  const payload: any = action.body;
  const response = yield globalApiService.getReportList(payload);
  yield put({
    type: ReconActions.GET_REPORT_LIST_SUCCESS,
    body: response.data.data,
  });
}

function* getReport(action: IAction) {
  const { id, ...payload }: any = action.body;
  const response = yield globalApiService.getReportById(id, payload);
  yield put({
    type: ReconActions.GET_REPORT_SUCCESS,
    body: response.data.data,
  });
}

function* getReportSaga(action: IAction) {
  const { id }: any = action.body;
  const response = yield globalApiService.getSummary(id);
  yield put({
    type: ReconActions.GET_SUMMARY_SUCCESS,
    body: response.data.data,
  });
}

// MAD-2021 ANC-101
function* startReconReport(action: any) {
  const { uploadFileid: id, history } = action.body;
  const response = yield globalApiService.startRecon(id);

  if (response && response.status === 200) {
    yield call(setPageState, { body: { sub_page_id: 1 } });
    history.push(`${ROUTES.DOWNLOAD_CENTER_V2}`,{"setReconReportView":true});
  } else {
    OpenNotification('Recon failed..!', 'error');
  }

}

function* takeAction(action: any) {
  const { ...rest } = action.body;
  const response = yield globalApiService.reportAction(rest);
  yield put({
    type: ReconActions.TAKE_ACTION_SUCCESS,
    body: response.data.data[0],
  });
  // yield put({ type: ReconActions.GET_REPORT, body: { id } });
}

function* getReportHeader(action: any) {
  const { id } = action.body;
  const response = yield globalApiService.getHeader(id);
  yield put({
    type: ReconActions.GET_REPORT_HEADER_SUCCESS,
    body: response.data.data,
  });
}

function* updateMatchEntryObject(action: any) {
  const { recordId } = action.body;
  yield globalApiService.setMatchEntryObject(action.body);
  yield put({ type: ReconActions.GET_REPORT, body: { id: recordId } });
}

function* getInvoiceSearch(action: any) {
  const { id, ...data } = action.body;
  const response = yield globalApiService.getAddInvoiceData(id, data);
  yield put({
    type: ReconActions.GET_INVOICE_SEARCH_SUCCESS,
    body: response.data,
  });
}

function* addInvoiceSaga(action: any) {
  const response = yield globalApiService.addInvoice(action.body);
  yield put({
    type: ReconActions.GET_INVOICE_SEARCH_SUCCESS,
    body: response.data,
  });
}

function* getSupplierListSaga(action: any) {
  const response = yield globalApiService.getSupplierList(action.body.id);
  yield put({ type: ReconActions.GET_SUPPLIER_SUCCESS, body: response.data });
}

function* getDownloadSaga(action: any) {
  const { id, ...payload }: any = action.body;
  const response = yield globalApiService.getReportById(id, payload);
  yield put({
    type: ReconActions.GET_REPORT_DOWNLOAD_SUCCESS,
    body: response.data,
  });
}

export default function* reconeWatcher(): IterableIterator<any> {
  yield takeEvery(ReconActions.GENERATE_OTP, (action) =>
    exceptionLogger(generateOtp(action))
  );
  yield takeEvery(ReconActions.REGENERATE_OTP, (action) =>
    exceptionLogger(generateOtp(action))
  );
  yield takeEvery(ReconActions.VALIDATE_OTP, (action) =>
    exceptionLogger(validateOtp(action))
  );
  yield takeEvery(ReconActions.UPLOADPR, (action) =>
    exceptionLogger(uploadPr(action))
  );
  yield takeEvery(ReconActions.GET_MAPPINGS, (action) =>
    exceptionLogger(getMapping(action))
  );
  yield takeEvery(ReconActions.GET_ITR_FILE_UPLOAD, (action) =>
    exceptionLogger(getItrFileUpload(action))
  );
  yield takeEvery(ReconActions.ADD_MAPPINGS, (action) =>
    exceptionLogger(addMapping(action))
  );
  yield takeEvery(ReconActions.PROCESS_ITR_FILE_UPLOAD, (action) =>
    exceptionLogger(processItrFileUpload(action))
  );
  yield takeEvery(ReconActions.GET_DROPDOWNS, (action) =>
    exceptionLogger(getDropdown(action))
  );
  yield takeEvery(ReconActions.GET_PAGE_STATE, (action) =>
    exceptionLogger(getPageState(action))
  );
  yield takeEvery(ReconActions.SET_PAGE_STATE, (action) =>
    exceptionLogger(setPageState(action))
  );
  yield takeEvery(ReconActions.GET_REPORT_LIST, (action) =>
    exceptionLogger(getReportList(action))
  );
  yield takeEvery(ReconActions.GET_REPORT, (action) =>
    exceptionLogger(getReport(action))
  );
  yield takeEvery(ReconActions.GET_SUMMARY, (action) =>
    exceptionLogger(getReportSaga(action))
  );
  yield takeEvery(ReconActions.START_RECON, (action) =>
    exceptionLogger(startReconReport(action))
  );
  yield takeEvery(ReconActions.TAKE_ACTION, (action) =>
    exceptionLogger(takeAction(action))
  );
  yield takeEvery(ReconActions.GET_REPORT_HEADER, (action) =>
    exceptionLogger(getReportHeader(action))
  );
  yield takeEvery(ReconActions.UPDATE_MATCH_ENTRY, (action) =>
    exceptionLogger(updateMatchEntryObject(action))
  );
  yield takeEvery(ReconActions.GET_INVOICE_SEARCH, (action) =>
    exceptionLogger(getInvoiceSearch(action))
  );
  yield takeEvery(ReconActions.ADD_INVOICE, (action) =>
    exceptionLogger(addInvoiceSaga(action))
  );
  yield takeEvery(ReconActions.GET_SUPPLIER, (action) =>
    exceptionLogger(getSupplierListSaga(action))
  );
  yield takeEvery(ReconActions.GET_REPORT_DOWNLOAD, (action) =>
    exceptionLogger(getDownloadSaga(action))
  );
}
