import React, {useState} from 'react';
import {
  Card,
  Typography,
  Row,
  Spin,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  DatePicker,
} from 'antd';
import {FaCheckCircle, FaExclamationCircle} from 'react-icons/fa';
import {ArrowLeftOutlined, CheckCircleTwoTone} from '@ant-design/icons';
import {AxiosError} from 'axios';
import { useParams, useHistory } from 'react-router';
import { globalApiService } from '../services/apiService';
import { THEME } from '../constants/theme';
import OpenNotification from '../utils/notification';
import {ROUTES} from "../constants/routePaths";
import moment from "moment";


const GSTINDetails = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [yearPicker, setYearPicker] = useState('');
  const [monthPicker, setMonthPicker] = useState('');
  const [taxPayer, setTaxPayer] = React.useState<any>(null);
  const [gstr1Status, setGstr1Status] = React.useState<any>('');
  const [filingOverview, setFilingOverview] = React.useState<any>(null);
  const [filingData, setFilingData] = React.useState<any>(null);
  const [errorMessage,setErrorMessage] = useState('')

  const fetchGstDetails = (gstin:any) => {
    setLoading(true);
    globalApiService.fetchGstDetails(gstin).then((res) => {
      if(res.data.success){
        setTaxPayer(res.data.data.tax_payer);
        if(res.data.data.filing_info_received) {
          setGstr1Status(res.data.data.gstr1_status);
          setFilingOverview(res.data.data.filing_overview);
          setFilingData(res.data.data.filing_data);
        } else{
          setErrorMessage(res.data.data.error_message)
          OpenNotification(res.data.data.error_message, 'error');
        }
      } else {
        history.push('/gstin');
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data?.message;
      setErrorMessage(errorBody)
      setLoading(false);
      // history.push('/gstin');
    });
  };

  const fetchFilingDetails = (gstin:any, monthYear:any) => {
    setLoading(true);
    globalApiService.fetchFilingDetails(gstin, monthYear).then((res) => {
      if(res.data.success){
        setFilingData(res.data.data.filing_data);
      } else {
        OpenNotification(res?.data?.message || 'Error Occured', 'error');
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      setLoading(false);
    });
  };


  const params:any = useParams();
  React.useEffect(()=>{
    if(params.value) {

      fetchGstDetails(params.value);
    }
  }, [params.value]);
  const { Title, Paragraph, Text } = Typography;
  const { TabPane } = Tabs;
  // For Tab
  const onTabChange = (key: any) => {
    // console.log(key);
  };
  // For Table
  const columns = [
    {
      title: 'Last filed',
      dataIndex: 'last_filed',
      key: 'last_filed',
    },
    {
      title: 'Filed for return period',
      dataIndex: 'return_period',
      key: 'return_period',
    },
    {
      title: 'Date of filing',
      dataIndex: 'filing_date',
      key: 'filing_date',
    },
    {
      title: '*Timing',
      dataIndex: 'timing',
      key: 'timing',
    },
  ];

  // For Table in Grid
  const col = [
    {
      title: 'HSN',
      dataIndex: 'hsn',
      key: 'hsn',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  const newdata = [
    {
      hsn: '1111111111111112223355888',
      description: 'highly inflammable item',
    },
  ];
  // Filing Status
  function onDateChange(date: any, dateString: any) {
    if (dateString.indexOf('-') > -1){
      setMonthPicker(dateString);
      setYearPicker('');
    }else {
      setMonthPicker('');
      setYearPicker(dateString);
    }
    if(dateString !== ''){
      fetchFilingDetails(params.value, dateString);
    }else {
      setMonthPicker('');
      setYearPicker('');
      fetchFilingDetails(params.value, 'reset');
    }
  }
  let locale = {
    emptyText: (
      <div className="warning-banner">
        {errorMessage || 'Details not found' }
      </div>
    )
  }
  return (
    <>
      <Row>
        <ArrowLeftOutlined
          className="header__icon"
          onClick={() => history.push(ROUTES.GSTIN_SEARCH)}
        />
        <Title level={3}><span className="header__title">GSTIN search</span></Title>
      </Row>
      <Row>
        <div className="header-details">
          <h3 className="header-details__primary">
            GSTIN: {' '}
            <span className="header-details__primary--bold">
              {params.value}
            </span> {' '}
          </h3>
          <p className="header-details__text">
            {' '}
            {gstr1Status == 'On Time' ? (
              <FaCheckCircle
                style={{'color': 'green'}}
              >
              </FaCheckCircle>
            ) : ( <FaExclamationCircle style={{'color': 'red'}}></FaExclamationCircle> )}
            {' '}{ gstr1Status || 'Delayed' }
          </p>
          <p className="header-details__text">
            {taxPayer && taxPayer.sts.toLowerCase() == 'active' ? (
              <FaCheckCircle
                style={{'color': 'green'}}
              >
              </FaCheckCircle>
            ) : ( <FaExclamationCircle style={{'color': 'red'}}></FaExclamationCircle> )}
            {' '}{ taxPayer && taxPayer.sts || 'Inactive' }
          </p>
        </div>
      </Row>
      <Spin spinning={loading}>
        <Tabs defaultActiveKey="1" onChange={onTabChange} className='custom_tabs'>
          <TabPane tab="Overview" key="1">
            {/* <div className="overview-detail-container">
                      <Paragraph>Trade name of company</Paragraph>
                      <Paragraph><span className="overview-detail-container--bold">jitfin Tech Lab</span></Paragraph>
                  </div>
                  <div className="overview-detail-container">
                      <Paragraph>Location</Paragraph>
                      <Paragraph><span className="overview-detail-container--bold">Bengaluru</span></Paragraph>
                  </div>
                  <div className="overview-detail-container">
                      <Paragraph>Tax payer type</Paragraph>
                      <Paragraph><span className="overview-detail-container--bold">Regular</span></Paragraph>
                  </div> */}

            <div className="overview-details">
              <div className="overview-details__item">
                <Paragraph>Trade name of company</Paragraph>
                <Paragraph>Location</Paragraph>
                <Paragraph>Tax payer type</Paragraph>
              </div>
              <div className="overview-details__value">
                <Paragraph>
                  <span className="overview-details__value--bold">
                    { taxPayer && taxPayer.tradeNam || '' }
                  </span>
                </Paragraph>
                <Paragraph>
                  <span className="overview-details__value--bold">{ taxPayer && taxPayer.pradr && taxPayer.pradr.addr && taxPayer.pradr.addr.stcd || '' }</span>
                </Paragraph>
                <Paragraph>
                  <span className="overview-details__value--bold">{ taxPayer && taxPayer.dty || '' }</span>
                </Paragraph>
              </div>
            </div>
            <p className="overview-note">
              *Delay calculation does not take Government relaxation into account
            </p>
            <div className="overview-table">
              <Table locale={locale} columns={columns} dataSource={filingOverview} pagination={false} />
            </div>
            {/*<div className="overview-table-grid">*/}
            {/*  <div className="">*/}
            {/*    <h3 className="" style={{ color: '#1890ff' }}>*/}
            {/*      Goods*/}
            {/*    </h3>*/}
            {/*    <Table columns={col} dataSource={newdata} pagination={false} scroll={{x:true}} />*/}
            {/*  </div>*/}
            {/*  <div className="">*/}
            {/*    <h3 className="" style={{ color: '#1890ff' }}>*/}
            {/*      Services*/}
            {/*    </h3>*/}
            {/*    <Table columns={col} dataSource={newdata} pagination={false} scroll={{x:true}} />*/}
            {/*  </div>*/}
            {/*</div> */}
          </TabPane>
          <TabPane tab="GSTIN details" key="2">
            <div className="gstin-details-grid">
              <Paragraph>Trade name of company</Paragraph>
              <Paragraph>
                <span className="gstin-details-grid--bold">{ taxPayer && taxPayer.tradeNam || '' }</span>
              </Paragraph>
              <Paragraph>Legal name</Paragraph>
              <Paragraph>
                <span className="gstin-details-grid--bold">{ taxPayer && taxPayer.lgnm || '' }</span>
              </Paragraph>
              <Paragraph>Constitution of business</Paragraph>
              <Paragraph>
                <span className="gstin-details-grid--bold">
                  { taxPayer && taxPayer.ctb || '' }
                </span>
              </Paragraph>
              <Paragraph>Date of registration</Paragraph>

              <Paragraph>
                <span className="gstin-details-grid--bold">{ taxPayer && taxPayer.registration_date || '' }</span>
              </Paragraph>
              <Paragraph>Taxpayer type</Paragraph>
              <Paragraph>
                <span className="gstin-details-grid--bold">{ taxPayer && taxPayer.dty || '' }</span>
              </Paragraph>
              <Paragraph>Nature of business activity</Paragraph>
              <Paragraph>
                <span className="gstin-details-grid--bold">
                  { taxPayer && taxPayer.pradr.ntr || '' }
                </span>
              </Paragraph>
              <Paragraph>State jurisdiction</Paragraph>

              <Paragraph>
                <span className="gstin-details-grid--bold">
                  { taxPayer && taxPayer.stj || '' }
                </span>
              </Paragraph>
              <Paragraph>Center jurisdiction</Paragraph>
              <Paragraph>
                <span className="gstin-details-grid--bold">
                  { taxPayer && taxPayer.ctj || '' }
                </span>
              </Paragraph>
              <Paragraph>Principle place of business</Paragraph>
              <Paragraph>
                <span className="gstin-details-grid--bold">{ taxPayer && taxPayer.primary_address || '' }</span>
              </Paragraph>
            </div>
            <div className="gstin-heading">
              <Text strong style={{fontSize:'16px'}}>Other addresses</Text>
                {taxPayer && taxPayer.other_addresses?.map((oa: any) => (
                  <Card
                    style={{width: '100%', margin: '1rem auto'}}
                  >
                      {oa}
                  </Card>
                ))}
            </div>
          </TabPane>
          <TabPane tab="Filing status" key="3">
            <div className="filter-container">
              <div className="filter-container__subheading">Filter by :</div>
              <div className="filter-container__datepicker">
                <p className="filter-container__datepicker--label">
                  Financial Year
                </p>
                <DatePicker value={yearPicker ? moment(yearPicker, 'YYYY') : undefined} onChange={onDateChange} picker="year" />
              </div>
              <div className="filter-container__orblock">
                <h4>OR</h4>
              </div>
              <div className="filter-container__datepicker">
                <p className="filter-container__datepicker--label">
                  Return Month + Year
                </p>
                <DatePicker value={monthPicker ? moment(monthPicker, 'YYYY-MM') : undefined} onChange={onDateChange} picker="month" />
              </div>
            </div>
            {filingData?.map((tf: any) => (
              <Card
                style={{width: '100%', margin: '2rem auto', padding: '0rem 2rem'}}
              >
                <div className="filing-card-container">
                  <Title level={3} style={{color: THEME.PRIMARY_COLOR}}>
                      {tf.return_period}
                  </Title>
                  <div className="filing-card-wrapper">
                      <div className="filing-card-wrapper__col">
                          <Paragraph>Return</Paragraph>
                          <Paragraph>
                  <span className="filing-card-wrapper__col--bold">
                    Date of filing
                  </span>
                              </Paragraph>
                          </div>
                          <div className="filing-card-wrapper__col">
                              <Paragraph>GSTR1</Paragraph>
                              <Paragraph>
                      <span className="filing-card-wrapper__col--bold">
                          {tf.gstr1}
                      </span>
                              </Paragraph>
                          </div>
                          <div className="filing-card-wrapper__col">
                              <Paragraph>GSTR3B</Paragraph>
                              <Paragraph>
                                <span className="filing-card-wrapper__col--bold">
                                    {tf.gstr3b}
                                </span>
                              </Paragraph>
                          </div>
                      </div>
                  </div>
              </Card>
            ))}
            {filingData == null  &&
            <div className="warning-banner margin-top-warning">
              {errorMessage}
            </div>}
          </TabPane>
          <TabPane tab="Analysis" key="4">
          Coming Soon...
        </TabPane>
        </Tabs>
      </Spin>
    </>
  );
};
export default GSTINDetails;
