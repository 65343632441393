import React from 'react';

export default function JitFinLogo(props: {
  height?: string;
  width?: string;
  customStyles?: object;
  onClickFn?: () => void;
  transparent?: boolean;
  className?:string;
  propCollapse?:boolean;
}) {
  const { customStyles, onClickFn, transparent, propCollapse } = props;

  const addLogo = () => {
    let temp = '';
    if (!propCollapse) {
      temp = transparent ? `${process.env.PUBLIC_URL}/logo.svg` : `${process.env.PUBLIC_URL}/logo.svg`;
    } else {
      temp = `${process.env.PUBLIC_URL}/favicon.ico`;
    }
    return temp;
  };

  let { height, width } = props;
  height = height || '';
  width = width || '';
  return (
    <img
      src={addLogo()}
      alt="JiTFin logo"
      height={height}
      width={width}
      style={customStyles}
      role="presentation"
      onClick={onClickFn}
    />
  );
}
