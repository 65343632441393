import { Card } from 'antd';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { Col, Row } from 'antd/es/grid';
import React from 'react';

import moment from 'moment';
import { THEME } from '../constants/theme';
import { IDocumentUploadStatus, ILatestFilingInfo } from '../models';

function SummaryStatusCard(props: { subtitle: any; title: any }) {
  const { subtitle, title } = props;
  return (
    <Card style={{ boxShadow: THEME.SHADOW, minHeight: '200px' }}>
      <Title style={{ textAlign: 'center' }}>{title}</Title>
      <Paragraph style={{ textAlign: 'center' }} strong>
        {subtitle}
      </Paragraph>
    </Card>
  );
}

function dateFormatConverter(
  dateStr: string,
  fromFormat: string,
  toFormat: string
): string {
  const dateFromFormat = moment(dateStr, fromFormat);

  return dateFromFormat.isValid() ? dateFromFormat.format(toFormat) : '--';
}

function SummaryCardCol(props: { title: string; subtitle: string }) {
  const { title, subtitle } = props;
  return (
    <Col key={1} md={8} xs={24} style={{ padding: '8px' }}>
      <SummaryStatusCard subtitle={subtitle} title={title} />
    </Col>
  );
}

export default function DataSourceSummaryCards(props: {
  latestFileUploadStatusForDataSource: IDocumentUploadStatus;
  latestFilingInfo: ILatestFilingInfo;
}) {
  const { latestFilingInfo } = props;
  const {
    prospectCount,
    validProspectCount,
    prospectCountFiledGST1,
    prospectCountFiledGSTR3B,
    filedGSTR1AsOf,
    filedGSTR3BAsOf,
  } = latestFilingInfo || {
    prospectCount: 0,
    validProspectCount: 0,
    prospectCountFiledGST1: 0,
    prospectCountFiledGSTR3B: 0,
    filedGSTR1AsOf: null,
    filedGSTR3BAsOf: null,
  };
  const prospectsNotFiledGSTR1 = validProspectCount - prospectCountFiledGST1;
  const prospectNotFiledGSTR3B = validProspectCount - prospectCountFiledGSTR3B;
  const isProspectCountZero = prospectCount === 0;

  return (
    <Row style={{ paddingBottom: '30px' }}>
      <SummaryCardCol
        title={isProspectCountZero ? '-' : prospectCount.toString()}
        subtitle="Suppliers in the file"
      />
      <SummaryCardCol
        subtitle={`Suppliers who have not filed GSTR 1 ${`as of ${dateFormatConverter(
          filedGSTR1AsOf,
          'YYYYMM',
          'MMM YYYY'
        )}` || ''
        }`}
        title={isProspectCountZero ? '-' : prospectsNotFiledGSTR1.toString()}
      />
      <SummaryCardCol
        subtitle={`Suppliers who have not filed GSTR 3B ${`as of ${dateFormatConverter(
          filedGSTR3BAsOf,
          'YYYYMM',
          'MMM YYYY'
        )}` || ''
        }`}
        title={isProspectCountZero ? '-' : prospectNotFiledGSTR3B.toString()}
      />
    </Row>
  );
}
