import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  Row,
  Spin,
  Badge,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  DatePicker,
  Modal,
  Upload,
  Dropdown,
  Menu,
  Select,
  Popconfirm,
} from 'antd';
import { THEME } from '../constants/theme';
import {
  PlusOutlined,
  FilterOutlined,
  ApartmentOutlined,
  EditFilled,
  DeleteFilled,
  CheckCircleTwoTone,
  ArrowRightOutlined,
  ContactsFilled,
} from '@ant-design/icons';
import { FiEdit3, RiDeleteBinLine } from 'react-icons/all';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { ROUTES } from '../constants/routePaths';
import { FiArrowRight } from 'react-icons/fi';
import { globalApiService } from '../services/apiService';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { IState } from '../models';
import OpenNotification from '../utils/notification';

const EInvoicing = () => {
  //Initilization
  const history = useHistory();
  const authState = useSelector((state: IState) => state.authReducer);
  const location: any = useLocation();

  React.useEffect(() => {
    if (
      location != undefined &&
      location.state != undefined &&
      location.state.tab
    ) {
      let { tab } = location?.state;
      if (tab == 'error') {
        setMainTabKey('2');
        setInvoiceTabKey('3');
      }
    }
  }, [location]);
  //Destructuring
  const { Title, Paragraph, Text } = Typography;
  const { Search } = Input;
  const { TabPane } = Tabs;
  const { Dragger } = Upload;
  //Search
  const onDraftSearch = (value: string) => {
    getEinvoices(1, 10, value, '', '', 'DRAFT');
  };
  const onIrnSearch = (value: string) => {
    getEinvoices(1, 10, value, '', '', 'INVOICE_GENERATED');
  };
  const onEwaySearch = (value: string) => {
    getEinvoices(1, 10, value, '', '', 'IRN_GENERATED');
  };
  const onErrSearch = (value: string) => {
    getEinvoices(1, 10, value, '', '', 'IRN_FAILED');
  };
  const onEwayDraftSearch = (value: string) => {
    getEwayBill(1, 10, value, '', '', 'DRAFT');
  };
  //Pagination
  const [einvoiceDraftpagination, setEInvoiceDraftPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize: 10 });
  const [irnPagination, setIrnPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize: 10 });
  const [ewayPagination, setEwayPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize: 10 });
  const [errPagination, setErrPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize: 10 });
  const [ewayBillPagination, setEwayBillPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize: 10 });
  //Tab State
  const [mainTabKey, setMainTabKey] = React.useState('1');
  const [draftTabKey, setDraftTabKey] = React.useState('1');
  const [invoiceTabKey, setInvoiceTabKey] = React.useState('1');
  //DatePicker(IRN)
  const dateFormat = 'DD/MM/YYYY';

  //Date Time Function
  function parseDate(str1: any) {
    let dt1 = parseInt(str1.substring(0, 2));
    let mon1 = parseInt(str1.substring(3, 5));
    let yr1 = parseInt(str1.substring(6, 10));
    let date1 = new Date(yr1, mon1 - 1, dt1);
    return date1;
  }

  function getDateDDMMYYYY(date: any) {
    let day = date.getDate().toString();
    if (day.length == 1) {
      day = '0' + day;
    }
    let month = date.getMonth() + 1;
    let month2 = month.toString();

    if (month2.length == 1) {
      month2 = '0' + month2;
    }
    let year = date.getFullYear();
    return day + '/' + month2 + '/' + year;
  }

  //INR Table Config
  const [irnLoading, setIrnLoading] = useState(false);
  const [irnRowKeys, setIrnRowKeys] = useState<React.Key[]>();
  const [ewbRowKeys, setewbRowKeys] = useState<React.Key[]>();
  const rowIRNSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setIrnRowKeys(selectedRowKeys);
    },
  };
  // No pointer events if row selected
  const [isIrnRowSelected, setIsIrnRowSelected] = useState<boolean>(false);
  useEffect(() => {
    if (Array.isArray(irnRowKeys) && irnRowKeys?.length > 0) {
      setIsIrnRowSelected(true);
    } else {
      setIsIrnRowSelected(false);
    }
  }, [irnRowKeys]);

  const [loading, setLoading] = useState<boolean>(false);
  const singleIrnGenerate = (id: number) => {
    generateIrn({ ids: [id] });
  };
  const singleEwbGenerate = (id: number) => {
    generateEwb({ ids: [id] });
  };
  function generateEwb(data: any) {
    setEwayLoading(true);
    globalApiService
      .generateBulkEwb(data?.ids, authState.gstin)
      .then((res) => {
        if (res.data.success) {
          getEinvoices(1, 10, '', '', '', 'INVOICE_GENERATED');
        }
        setEwayLoading(false);
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        setEwayLoading(false);
      });
  }

  function generateIrn(data: any) {
    setIrnLoading(true);
    globalApiService
      .generateBulkIrn(data?.ids, authState.gstin)
      .then((res) => {
        if (res.data.success) {
          if(res.data.data.generatedInvoice.length){
            OpenNotification('IRN Generated for ' + res.data.data.generatedInvoice,'success');
            getEinvoices(1, 10, '', '', '', 'IRN_GENERATED');
          }
          if(res.data.data.failedInvoice.length){
            OpenNotification('IRN Failed for ' + res.data.data.failedInvoice,'error');
            getEinvoices(1, 10, '', '', '', 'IRN_FAILED');
          }
          getEinvoices(1, 10, '', '', '', 'INVOICE_GENERATED');
          

        }
        setIrnLoading(false);
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        getEinvoices(1, 10, '', '', '', 'INVOICE_GENERATED');
        setIrnLoading(false);
      });
  }

  function cancelBulkIrn(data: any) {
    setEwayLoading(true);
    globalApiService
      .cancelBulkIrn(data?.ids, authState.gstin)
      .then((res) => {
        if (res.data.success) {
          getEinvoices(1, 10, '', '', '', 'IRN_GENERATED');
        }
        setEwayLoading(false);
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        setEwayLoading(false);
      });
  }

  const handleBulkIRNClick = () => {
    if (Array.isArray(irnRowKeys)) {
      if (irnRowKeys?.length > 0) {
        // console.log(rowKeys)
        generateIrn({ ids: Array.from(irnRowKeys) });
        setIsIrnRowSelected(false);
      }
    }
  };
  const handleBulkCancelIrnClick = () => {
    if (Array.isArray(ewbRowKeys)) {
      if (ewbRowKeys?.length > 0) {
        // console.log(rowKeys)
        cancelBulkIrn({ ids: Array.from(ewbRowKeys) });
        setIsEwbRowSelected(false);
      }
    }
  };

  const downloaderrorinvoiceList = () => {
    setLoading(true);
    globalApiService.downloaderrorinvoiceList().then((response)=>{
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'error_invoice_list.csv');
      document.body.appendChild(link);
      link.click();
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      setLoading(false);
    });
  };

  const IRNcol = [
    {
      title: 'Invoice no',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width: '20%',
      render: (text: any, record: any) => {
        return (
          <div className="cursor">
            {moment().subtract(2, 'days') <
            moment(record.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSSZ') ? (
              <Badge count="New" offset={[22, 0]}>
                <span
                  onClick={() => {
                    history.push(`/eInvoicing/invoicePreview`, {
                      e_invoice_id: record.id,
                      step: 2,
                    });
                  }}
                >
                  {text}
                </span>
              </Badge>
            ) : (
              <span
                onClick={() => {
                  history.push(`/eInvoicing/invoicePreview`, {
                    e_invoice_id: record.id,
                    step: 2,
                  });
                }}
              >
                {text}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Creation date',
      dataIndex: 'created_on',
      key: 'created_on',
      width: '10%',
      render: (text: any, record: any) => {
        return <div className="cursor">{text}</div>;
      },
    },
    {
      title: 'Invoice date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: '10%',
      render: (text: any, record: any) => {
        return <div className="cursor">{text}</div>;
      },
    },
    {
      title: 'Customer name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '22%',
      render: (text: any, record: any) => {
        return <div className="cursor">{text}</div>;
      },
    },
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
      width: '14%',
      render: (text: any, record: any) => {
        return <div className="cursor">{text}</div>;
      },
    },
    {
      title: 'Total amt',
      dataIndex: 'total_amount',
      key: 'total_amount',
      width: '10%',
      render: (text: any, record: any) => {
        return <div className="cursor">{text}</div>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '12%',
      render: (text: any, record: any) => {
        return (
          <div className='iconContainer iconContainer--column-gap'>
          <a
            href="#"
            onClick={() => singleIrnGenerate(record.id)}
            className="actions-text"
          >
            Generate IRN
          </a>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to delete ?"
          onConfirm={() => handleIndividualDraftRowDelete(record.id,'INVOICE_GENERATED')}
          okText="Yes"
          cancelText="No"
        >
          <RiDeleteBinLine className="iconContainer__item" style={{marginLeft:"10px"}}></RiDeleteBinLine>
        </Popconfirm>
      </div>
        );
      },
    },
  ] as any[];

  const [IRNData, setIRNData] = React.useState<any[]>([]);
  const [irnCount, setIrnCount] = React.useState(0);
  function onIRNChange(pager: any) {
    irnPagination.current = pager.current;
    setIrnPagination(irnPagination);
    getEinvoices(
      irnPagination.current,
      irnPagination.pageSize,
      '',
      '',
      '',
      'INVOICE_GENERATED'
    );
  }

  const [checkCredentials, setCheckCredentials] = useState(false);

  // Get Profile Details
  const fetchProfileDetails = (gstin: any) => {
    setLoading(true);
    globalApiService
      .fetchProfileDetails(gstin)
      .then((res) => {
        if (res.data.success) {
          if (
            res.data.data.general_info.gst_username === '' ||
            res.data.data.general_info.gst_password === ''
          ) {
            setCheckCredentials(true);
          }
        } else {
          OpenNotification('Error Occurred', 'error');
        }
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchProfileDetails(authState.gstin);
  }, [authState.gstin]);

  const [irnDate, setIrnDate] = React.useState({
    from: '',
    to: '',
  });
  const irnFromDateChange = (date: any, dateString: any) => {
    setIrnDate((prevState: any) => {
      return { ...prevState, from: dateString };
    });
  };
  const irnToDateChange = (date: any, dateString: any) => {
    setIrnDate((prevState: any) => {
      return { ...prevState, to: dateString };
    });
  };
  const duplicateInrData = [...IRNData];
  const handleClickOnGo = () => {
    duplicateInrData.filter((ele) => {
      // let replacedstrr: any = ele.creationDate.replace(/\//g, '-');
      // let replacedDate:any = new Date(replacedstrr);
      // let fromDateObj:any = new Date(irnDate.fromDate);
      // console.log(typeof replacedstrr)
      // return new Date(replacedstrr) - new Date(irnDate.fromDate) >= 0 &&
      //   new Date(replacedstrr) - new Date(irnDate.toDate) <= 0
    });
  };
  // E-way bill pending table config
  const rowEwaySelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setewbRowKeys(selectedRowKeys);
    },
  };
  // No pointer events if row selected
  const [isEwbRowSelected, setIsEwbRowSelected] = useState<boolean>(false);
  useEffect(() => {
    if (Array.isArray(ewbRowKeys) && ewbRowKeys?.length > 0) {
      setIsEwbRowSelected(true);
    } else {
      setIsEwbRowSelected(false);
    }
  }, [ewbRowKeys]);
  const Ewaycol = [
    {
      title: 'Invoice no',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width: '20%',
      render: (text: any, record: any) => {
        return (
          <div>
            {moment().subtract(2, 'days') <
            moment(record.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSSZ') ? (
              <Badge count="New" offset={[22, 0]}>
                <span onClick={() => {
            (history.push(`/eInvoicing/invoicePreview`,{'e_invoice_id': record.id, 'step' : 3}))
          }}>{text}</span>
              </Badge>
            ) : (
              <span onClick={() => {
                (history.push(`/eInvoicing/invoicePreview`,{'e_invoice_id': record.id, 'step' : 3}))
              }}>{text}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Creation date',
      dataIndex: 'created_on',
      key: 'created_on',
      width: '10%',
    },
    {
      title: 'Invoice date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: '10%',
    },
    {
      title: 'Customer name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '21%',
    },
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
      width: '14%',
    },
    {
      title: 'IRN',
      dataIndex: 'irn_number',
      key: 'irn_number',
      width: '13%',
      render: (text: any, record: any) => {
        return <span style={{ wordBreak: 'break-all' }}>{text}</span>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '12%',
      render: (text: any, record: any) => {
        console.log('record',record)
        // return <a onClick={() => history.push('/eInvoicing/eWayBill', { 'data' : {'sub_type': 'SUPPLY', 'doc_type': 'INVOICE', 'doc_number':`${record.invoice_number}`}})} className={`actions-text ${isEwbRowSelected && 'no-pointer-events'}`}>Generate E-way bill</a>;
        return (
          
          // <a
          //   onClick={() =>
          //     history.push(`/eInvoicing/eWayBill`, { e_invoice_id: record.id })
          //   }
          //   className="actions-text"
          // >
          //   Generate E-way bill
          // </a>
          <div className='iconContainer iconContainer--column-gap'>
          <a
            href="#"
            onClick={() => history.push(`/eInvoicing/eWayBill`, { e_invoice_id: record.id })}
            className="actions-text"
          >
            Generate E-way Bill
          </a>
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to delete ?"
            onConfirm={() => handleIndividualDraftRowDelete(record.id,'IRN_GENERATED')}
            okText="Yes"
            cancelText="No"
          >
          <RiDeleteBinLine className="iconContainer__item" style={{marginLeft:"10px"}}></RiDeleteBinLine>
        </Popconfirm>
      </div>
          
          
        );
      },
    },
  ] as any[];
  const [ewayDateSearch, setEayDateSearch] = useState<any>({
    from: '',
    to: '',
  });
  const [ewayCount, setEwayCount] = React.useState(0);
  const ewayFromDateChange = (date: any, dateString: any) => {
    setEayDateSearch((prevState: any) => {
      return { ...prevState, from: dateString };
    });
  };
  const ewayToDateChange = (date: any, dateString: any) => {
    setEayDateSearch((prevState: any) => {
      return { ...prevState, to: dateString };
    });
  };
  const [ewayLoading, setEwayLoading] = useState(false);
  const [ewayData, setEwayData] = useState<any[]>([]);
  function onEwayChange(pager: any) {
    ewayPagination.current = pager.current;
    setEwayPagination(ewayPagination);
    getEinvoices(
      ewayPagination.current,
      ewayPagination.pageSize,
      '',
      '',
      '',
      'IRN_GENERATED'
    );
  }
  //Error list config
  const [newerrorlist,setNewerrorlist]=useState<React.Key[]>();
  const rowErrSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log('selected',selectedRows)
      let tempArrayIds:any = []
      selectedRows.map((ele:any) => {
        if(ele !== undefined){
          tempArrayIds.push(ele.id)
        }
      })
      setNewerrorlist(tempArrayIds)

    },
  };
  // const rowErrSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       'selectedRows: ',
  //       selectedRows
  //     );
  //   },
  // };
  const [errDateSearch, setErrDateSearch] = useState<any>({ from: '', to: '' });
  const [errCount, setErrCount] = React.useState(0);
  const [errLoading, setErrLoading] = useState(false);
  const errFromDateChange = (date: any, dateString: any) => {
    setErrDateSearch((prevState: any) => {
      return { ...prevState, from: dateString };
    });
  };
  const errToDateChange = (date: any, dateString: any) => {
    setErrDateSearch((prevState: any) => {
      return { ...prevState, to: dateString };
    });
  };
  const Errcol = [
    {
      title: 'Invoice no',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width: '19%',
      render: (text: any, record: any) => {
        return (
          <div>
            {moment().subtract(2, 'days') <
            moment(record.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSSZ') ? (
              <Badge count="New" offset={[22, 0]}>
                <span>{text}</span>
              </Badge>
            ) : (
              <span>{text}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Invoice date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: '18%',
    },
    {
      title: 'Customer name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '24%',
    },
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
      width: '9%',
    },
    {
      title: 'Reason for failure',
      dataIndex: 'failure_reason',
      key: 'failure_reason',
      width: '25%',
      render: (text: any, record: any) => {
        return (
          <span style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
            {text}
          </span>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '5%',
      render: (text: any, record: any) => {
        
        return (
          
        
          <div className='iconContainer'>
          
          <Popconfirm
            placement="topRight"
            title="Are you sure you want to delete ?"
            onConfirm={() => handleIndividualDraftRowDelete(record.id,'IRN_FAILED')}
            okText="Yes"
            cancelText="No"
          >
          <RiDeleteBinLine className="iconContainer__item" style={{marginLeft:"10px"}}></RiDeleteBinLine>
        </Popconfirm>
      </div>
          
          
        );
      },
    }
  ] as any[];
  const [errData, setErrData] = useState<any[]>([]);
  function onErrChange(pager: any) {
    errPagination.current = pager.current;
    setErrPagination(errPagination);
    getEinvoices(
      errPagination.current,
      errPagination.pageSize,
      '',
      '',
      '',
      'IRN_FAILED'
    );
  }
  //Draft List
  //Drafts table config
  const [draftRowKeys,setDraftRowKeys]=useState<React.Key[]>();
  const rowDraftsSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log('selected',selectedRows)
      let tempArrayIds:any = []
      selectedRows.map((ele:any) => {
        if(ele !== undefined){
          tempArrayIds.push(ele.id)
        }
      })
      setDraftRowKeys(tempArrayIds)

    },
  };
  // const [draftRowKeys, setDraftRowKeys] = useState<React.Key[]>();
  // const rowDraftsSelection = {
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
  //     setDraftRowKeys(selectedRowKeys);
  //   },
  // };
  const [draftEwbRowKeys, setDraftEwbRowKeys] = useState<React.Key[]>();
  const rowEwbDraftsSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setDraftEwbRowKeys(selectedRowKeys);
    },
  };
  
  const [iserrorrowselected, setErrorrowselected]=useState<boolean>(false);
  const [isDraftRowSelected, setIsDraftRowSelected] = useState<boolean>(false);
  const [isEwbDraftRowSelected, setEwbIsDraftRowSelected] = useState<boolean>(
    false
  );

  React.useEffect(() => {
    if (Array.isArray(draftRowKeys) && draftRowKeys?.length > 0) {
      setIsDraftRowSelected(true);
    } else {
      setIsDraftRowSelected(false);
    }
  }, [draftRowKeys]);

  React.useEffect(() => {
    if(Array.isArray(newerrorlist) && newerrorlist?.length > 0){
      setErrorrowselected(true)
    }else {
      setErrorrowselected(false)
    }
  },[newerrorlist])

  React.useEffect(() => {
    if (Array.isArray(draftEwbRowKeys) && draftEwbRowKeys?.length > 0) {
      setEwbIsDraftRowSelected(true);
    } else {
      setEwbIsDraftRowSelected(false);
    }
  }, [draftEwbRowKeys]);

  const handleDraftBulkDelete = () => {
    if (Array.isArray(draftRowKeys) && draftRowKeys?.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete these draft?')) {
        let ids = Array.from(draftRowKeys);
        globalApiService
          .deleteEinvoices(ids)
          .then((res) => {
            if (res.data.success) {
              setDraftRowKeys([])
              getEinvoices(1, 10, '', '', '', 'DRAFT');
            } else {
            }
          })
          .catch((err: AxiosError) => {
            setDraftLoading(false);
            const errorBody = err?.response?.data;
            console.log(errorBody);
          });
        setIsDraftRowSelected(false);
      }
    }
  };
  
  const handleDraftBulkClear = () => {
    if (Array.isArray(newerrorlist) && newerrorlist?.length > 0) {
    // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to clear these records?')) {
        let ids =  Array.from(newerrorlist)
        globalApiService
        .deleteEinvoices(ids)
        .then((res) => {
         if(res.data.success){
          setNewerrorlist([])
          getEinvoices(1,10,'','','','IRN_FAILED')
          OpenNotification('Successfully deleted the e-invoice(s)','success')

         }
        })
        .catch((err: AxiosError) => {
          setDraftLoading(false)
          const errorBody = err?.response?.data;
        });
        setErrorrowselected(false);
      }
    }
  }

  const handleIndividualDraftRowDelete = (id: number, invoice_status='DRAFT') => {
    let ids = [id];
    globalApiService
      .deleteEinvoices(ids)
      .then((res) => {
        if (res.data.success) {
          getEinvoices(1, 10, '', '', '', invoice_status);
        } else {
        }
      })
      .catch((err: AxiosError) => {
        setDraftLoading(false);
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
    setIsDraftRowSelected(false);
  };

  const handleIndividualEwbDraftRowDelete = (id: number) => {
    let ids = [id];
    globalApiService
      .deleteEwayBill(ids)
      .then((res) => {
        if (res.data.success) {
          getEwayBill(1, 10, '', '', '', 'DRAFT');
        } else {
        }
      })
      .catch((err: AxiosError) => {
        setDraftLoading(false);
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
    setEwbIsDraftRowSelected(false);
  };

  const Draftscol = [
    {
      title: 'Invoice no',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      render: (text: any, record: any) => {
        return (
          <div>
            {moment().subtract(2, 'days') <
            moment(record.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSSZ') ? (
              <Badge count="New" offset={[22, 0]}>
                <span>{text}</span>
              </Badge>
            ) : (
              <span>{text}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Creation date',
      dataIndex: 'created_on',
      key: 'created_on',
    },
    {
      title: 'Invoice date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
    },
    {
      title: 'Customer name',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
    },
    {
      title: 'Total amt',
      dataIndex: 'total_amount',
      key: 'total_amount',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, record: any) => {
        return (
          <div className="iconContainer">
            <FiEdit3
              className="iconContainer__item"
              onClick={() =>
                history.push(`/eInvoicing/editInvoice/${record.id}`)
              }
            ></FiEdit3>
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this Draft?"
              onConfirm={() => handleIndividualDraftRowDelete(record.id,'DRAFT')}
              okText="Yes"
              cancelText="No"
            >
              <RiDeleteBinLine className="iconContainer__item"></RiDeleteBinLine>
            </Popconfirm>
          </div>
        );
      },
    },
  ] as any[];

  React.useEffect(() => {
    if (mainTabKey == '3' && draftTabKey == '1') {
      getEinvoices(1, 10, '', '', '', 'DRAFT');
    }
    if (mainTabKey == '3' && draftTabKey == '2') {
      getEwayBill(1, 10, '', '', '', 'DRAFT');
    }
    if (mainTabKey == '2' && invoiceTabKey == '1') {
      getEinvoices(1, 10, '', '', '', 'INVOICE_GENERATED');
    }
    if (mainTabKey == '2' && invoiceTabKey == '2') {
      getEinvoices(1, 10, '', '', '', 'IRN_GENERATED');
    }
    if (mainTabKey == '2' && invoiceTabKey == '3') {
      getEinvoices(1, 10, '', '', '', 'IRN_FAILED');
    }
    if (mainTabKey == '2' && invoiceTabKey == '4') {
      getEinvoices(1, 10, '', '', '', 'IRN_GENERATED_FULL');
    }
  }, [invoiceTabKey, draftTabKey, mainTabKey]);

  React.useEffect(() => {
    //For Tab Count
    getEinvoices(1, 10, '', '', '', 'IRN_GENERATED');
    getEinvoices(1, 10, '', '', '', 'IRN_FAILED');
    getEinvoices(1, 10, '', '', '', 'DRAFT');
    getEwayBill(1, 10, '', '', '', 'DRAFT');
    getEinvoices(1, 10, '', '', '', 'IRN_GENERATED_FULL');

  }, []);

  const [draftsData, setDraftsData] = useState<any[]>([]);
  const [draftDateSearch, setDraftDateSearch] = useState<any>({
    from: '',
    to: '',
  });
  const [draftLoading, setDraftLoading] = useState(false);
  const [einvoiceDraftCount, setEinvoiceDraftCount] = useState(0);

  const draftFromDateChange = (date: any, dateString: any) => {
    setDraftDateSearch((prevState: any) => {
      return { ...prevState, from: dateString };
    });
  };
  const draftToDateChange = (date: any, dateString: any) => {
    setDraftDateSearch((prevState: any) => {
      return { ...prevState, to: dateString };
    });
  };

  const getEinvoices = (
    pageNumber: number = 1,
    pageSize: number = 10,
    search: string = '',
    from: string = '',
    to: string = '',
    status: string
  ) => {
    if (status == 'DRAFT') {
      setDraftLoading(true);
    }
    if (status == 'INVOICE_GENERATED') {
      setIrnLoading(true);
    }
    if (status == 'IRN_GENERATED' || status == 'IRN_GENERATED_FULL') {
      setEwayLoading(true);
    }
    if (status == 'IRN_FAILED') {
      setErrLoading(true);
    }
    globalApiService
      .getEinvoices({
        pageNumber: pageNumber,
        pageSize: pageSize,
        search: search,
        status: status,
        from: from,
        to: to,
      })
      .then((res) => {
        if (res.data.success) {
          if (status == 'DRAFT') {
            einvoiceDraftpagination.total = res.data.data.count;
            setEInvoiceDraftPagination(einvoiceDraftpagination);
            setEinvoiceDraftCount(res.data.data.count);
            setDraftLoading(false);
            setDraftsData([...res.data.data.results]);
          }
          if (status == 'INVOICE_GENERATED') {
            irnPagination.total = res.data.data.count;
            setIrnPagination(irnPagination);
            setIrnLoading(false);
            setIRNData([...res.data.data.results]);
            setIrnCount(res.data.data.count);
          }
          if (status == 'IRN_GENERATED' || status == 'IRN_GENERATED_FULL') {
            ewayPagination.total = res.data.data.count;
            setEwayPagination(ewayPagination);
            setEwayLoading(false);
            setEwayData([...res.data.data.results]);
            if (status == 'IRN_GENERATED') {
              setEwayCount(res.data.data.count);
            }
            if (status == 'IRN_GENERATED_FULL') {
              setHistoryRowCount(res.data.data.count);
            }
          }
          if (status == 'IRN_FAILED') {
            errPagination.total = res.data.data.count;
            setErrPagination(errPagination);
            setErrLoading(false);
            setErrData([...res.data.data.results]);
            setErrCount(res.data.data.count);
          }
        } else {
          setIrnLoading(false);
          setDraftLoading(false);
          setEwayLoading(false);
          setErrLoading(false);
        }
      })
      .catch((err: AxiosError) => {
        setIrnLoading(false);
        setEwayLoading(false);
        setDraftLoading(false);
        setErrLoading(false);
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
  };

  const getEwayBill = (
    pageNumber: number = 1,
    pageSize: number = 10,
    search: string = '',
    from: string = '',
    to: string = '',
    status: string
  ) => {
    setEWayDraftLoading(true);
    globalApiService
      .getEwayBill({
        pageNumber: pageNumber,
        pageSize: pageSize,
        search: search,
        status: status,
        from: from,
        to: to,
      })
      .then((res) => {
        if (res.data.success) {
          ewayBillPagination.total = res.data.data.count;
          setEwayBillPagination(ewayPagination);
          setEWayDraftLoading(false);
          setEwayDraftData([...res.data.data.results]);
          setEWayDraftCount(res.data.data.count);
        } else {
          setEWayDraftLoading(false);
        }
      })
      .catch((err: AxiosError) => {
        setEWayDraftLoading(false);
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
  };

  function onDraftsChange(pager: any) {
    einvoiceDraftpagination.current = pager.current;
    setEInvoiceDraftPagination(einvoiceDraftpagination);
    getEinvoices(
      einvoiceDraftpagination.current,
      einvoiceDraftpagination.pageSize,
      '',
      '',
      '',
      'DRAFT'
    );
  }
  //Bulk Upload Modal
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isFirstStageVisible, setIsFirstStageVisible] = useState(true);
  const [isSuccessScreenVisible, setISSuccessScreenVisible] = useState(false);
  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const handleUploadOk = () => {
    setIsUploadModalVisible(false);
  };

  const handleUploadCancel = () => {
    setIsUploadModalVisible(false);
    setIsFirstStageVisible(true); //To Hide Second stage for bulk upload
  };
  //Bulk Upload Drag and Drop
  const props = {
    name: 'file',
    multiple: true,
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info: any) {
      console.log(info);
      //   toBase64(info.file)
      //   .then((result) =>console.log(result))
      const { status } = info.file;
      console.log(status);
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      console.log(e);
      console.log('Dropped files', e.dataTransfer.files);
    },
    // },
  };
  //Before Upload
  function beforeUpload(file: any, fileList: any) {
    console.log(file);
    console.log(fileList);
    // }
    return true;
  }
  ////// Eway Drafts
  const EwayDraftscol = [
    {
      title: 'Invoice No',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      render: (text: any, record: any) => {
        return (
          <div>
            {moment().subtract(2, 'days') <
            moment(record.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSSZ') ? (
              <Badge count="New" offset={[22, 0]}>
                <span>{text}</span>
              </Badge>
            ) : (
              <span>{text}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Creation date',
      dataIndex: 'created_on',
      key: 'created_on',
    },
    {
      title: 'E way date',
      dataIndex: 'eway_bill_date',
      key: 'eway_bill_date',
    },
    {
      title: 'From Name',
      dataIndex: 'from_name',
      key: 'from_name',
    },
    {
      title: 'To Name',
      dataIndex: 'to_name',
      key: 'to_name',
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, record: any) => {
        
        return (
          <div className="iconContainer">
            <FiEdit3
              className="iconContainer__item"
              onClick={() => history.push(`/eInvoicing/ewayBill/${record.id}`)}
            ></FiEdit3>
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this Draft?"
              onConfirm={() => handleIndividualEwbDraftRowDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <RiDeleteBinLine className="iconContainer__item"></RiDeleteBinLine>
            </Popconfirm>
          </div>
        );
      },
    },
  ] as any[];
  const [ewayDraftData, setEwayDraftData] = useState<any[]>([]);
  const [eWayDraftDateSearch, setEwayDraftDateSearch] = useState<any>({
    from: '',
    to: '',
  });
  const [eWayDraftLoading, setEWayDraftLoading] = useState(false);
  const [eWayDraftCount, setEWayDraftCount] = useState(0);

  const eWayDraftFromDateChange = (date: any, dateString: any) => {
    setEwayDraftDateSearch((prevState: any) => {
      return { ...prevState, from: dateString };
    });
  };
  const eWayDraftToDateChange = (date: any, dateString: any) => {
    setEwayDraftDateSearch((prevState: any) => {
      return { ...prevState, to: dateString };
    });
  };
  function onEwayDraftsChange(pager: any) {
    ewayBillPagination.current = pager.current;
    setEwayBillPagination(ewayBillPagination);
    getEwayBill(
      ewayBillPagination.current,
      ewayBillPagination.pageSize,
      '',
      '',
      '',
      'DRAFT'
    );
  }

  //History Table config

  const [historyRowKeys, setHistoryRowKeys] = useState<React.Key[]>();
  const [historyRowCount,setHistoryRowCount] = useState(0)
  const rowHistorySelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setHistoryRowKeys(selectedRowKeys);
    },
  };
  const [isHistoryRowSelected, setIsHistoryRowSelected] = useState<boolean>(
    false
  );
  useEffect(() => {
    if (Array.isArray(historyRowKeys) && historyRowKeys?.length > 0) {
      setIsHistoryRowSelected(true);
    } else {
      setIsHistoryRowSelected(false);
    }
  }, [historyRowKeys]);
  const [historyDate, setHistoryDate] = React.useState({
    from: '',
    to: '',
  });
  const historyFromDateChange = (date: any, dateString: any) => {
    setHistoryDate((prevState: any) => {
      return { ...prevState, from: dateString };
    });
  };
  const historyToDateChange = (date: any, dateString: any) => {
    setHistoryDate((prevState: any) => {
      return { ...prevState, to: dateString };
    });
  };
  const historyCol = [
    {
      title: 'Invoice no',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      width: '25%',
      render: (text: any, record: any) => {
        return (
          <div>
            {moment().subtract(2, 'days') <
            moment(record.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSSZ') ? (
              <Badge count="New" offset={[22, 0]}>
                <span>{text}</span>
              </Badge>
            ) : (
              <span>{text}</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Creation date',
      dataIndex: 'created_on',
      key: 'created_on',
      width: '9%',
    },
    {
      title: 'Invoice date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      width: '9%',
    },
    {
      title: 'Customer name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: '23%',
    },
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
      width: '12%',
    },
    {
      title: 'IRN',
      dataIndex: 'irn_number',
      key: 'irn_number',
      width: '17%',
      render: (text: any, record: any) => {
        return <span style={{ wordBreak: 'break-all' }}>{text}</span>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (text: any, record: any) => {
        // return <a onClick={() => history.push('/eInvoicing/eWayBill', { 'data' : {'sub_type': 'SUPPLY', 'doc_type': 'INVOICE', 'doc_number':`${record.invoice_number}`}})} className={`actions-text ${isEwbRowSelected && 'no-pointer-events'}`}>Generate E-way bill</a>;
        return (
          <a
            onClick={() =>
              history.push(`/eInvoicing/invoicePreview`, {
                e_invoice_id: record.id,step: 4
              })
            }
            className={`actions-text ${
              isEwbRowSelected && 'no-pointer-events'
            }`}
          >
            View invoice
          </a>
        );
      },
    },
  ] as any[];

  return (
    <main className="einvoicing-main">
      <Row>
        <Title level={3}>Invoicing</Title>
      </Row>
      {checkCredentials && (
        <div className="warning-banner">
          Please update E-Invoicing username and password in the profile tab to
          continue with the e-invoice filing.
        </div>
      )}
      {/* Bulk Upload Modal */}
      <Modal
        className="bulkUploadModal"
        title=""
        centered
        visible={isUploadModalVisible}
        onOk={handleUploadOk}
        onCancel={handleUploadCancel}
        footer={[]}
      >
        {isFirstStageVisible && (
          <section className="uploadSection">
            <h3 className="uploadSection__title">
              You need to download template, add your transporters to it and
              upload the file
            </h3>
            <div className="uploadSection__done">
              <p className="uploadSection__done__text">Already done ?</p>
            </div>
            <div className="uploadSection__download">
              <a href="#" className="uploadSection__download__text">
                Download Template
              </a>
              <Button
                className="uploadSection__download__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                }}
                onClick={() => setIsFirstStageVisible(false)}
              >
                Upload file
              </Button>
            </div>
          </section>
        )}
        {!isFirstStageVisible && !isSuccessScreenVisible && (
          <section className="fileupload-section">
            <Title level={3} className="fileupload-section__title">
              Bulk upload transporters
            </Title>
            <div className="fileupload-section__dragger">
              <Dragger beforeUpload={beforeUpload}>
                <p className="fileupload-section__dragger__text">
                  Drag & drop <br></br>file
                </p>
              </Dragger>
            </div>
            <div className="fileupload-section__browse">
              <Upload beforeUpload={beforeUpload}>
                <Button className="fileupload-section__browse__btn">
                  Browse
                </Button>
              </Upload>
            </div>
          </section>
        )}
        {!isFirstStageVisible && isSuccessScreenVisible && (
          <section className="success-section">
            <div className="success-section__icon">
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: '24px' }}
              />
            </div>
            <h2 className="success-section__text">30 e-invoices added</h2>
            <div className="success-section__btnbox">
              <Button
                className="success-section__btnbox__btn"
                onClick={handleUploadCancel}
              >
                Ok
              </Button>
            </div>
          </section>
        )}
      </Modal>

      {/* endModal */}
      <Tabs
        defaultActiveKey="1"
        onChange={(key) => setMainTabKey(key)}
        activeKey={mainTabKey}
        className="custom_tabs"
      >
        <TabPane tab="Dashboard" key="1">
          <section className="edashboard">
            {/* <div className="edashboard__btnbox">
              <Button
                className="edashboard__btnbox__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                  color: 'white',
                }}
                onClick={() => history.push(ROUTES.NEW_INVOICE)}
              >
                <PlusOutlined /> New invoice
              </Button>
            </div> */}
            <div className="dashboard-threecolgrid">
              <Card
                className="einv_card"
                style={{ boxShadow: THEME.SHADOW }}
                onClick={() => history.push(ROUTES.NEW_INVOICE)}
              >
                <h3 className="einv_card__title">Create new invoice</h3>
                <p
                  className="einv_card__subheading"
                  style={{ visibility: 'hidden' }}
                >
                  <span className="einv_card__subheading--bold">22</span>{' '}
                  invoices in draft
                </p>
                <div className="einv_card__container">
                  <p className="einv_card__container__text">
                    Create a new invoice for your customer
                  </p>
                  <div className="einv_card__container__icon">
                    <FiArrowRight size={28} />
                  </div>
                </div>
              </Card>
              <Card
                className="einv_card"
                style={{ boxShadow: THEME.SHADOW }}
                onClick={() => setMainTabKey('3')}
              >
                <h3 className="einv_card__title">View invoices in draft</h3>
                <p className="einv_card__subheading">
                  <span className="einv_card__subheading--bold">
                    {einvoiceDraftCount}
                  </span>{' '}
                  invoices in draft
                </p>

                <div className="einv_card__container">
                  <p className="einv_card__container__text">
                    View invoices you have saved to drafts
                  </p>
                  <div className="einv_card__container__icon">
                    <FiArrowRight size={28} />
                  </div>
                </div>
              </Card>
              <Card
                className="einv_card"
                style={{ boxShadow: THEME.SHADOW }}
                onClick={() => history.push(ROUTES.E_WAY_BILL)}
              >
                <h3 className="einv_card__title">Create E way bills </h3>
                <p
                  className="einv_card__subheading"
                  style={{ visibility: 'hidden' }}
                >
                  <span className="einv_card__subheading--bold">
                    {eWayDraftCount}
                  </span>{' '}
                  invoices in draft
                </p>
                <div className="einv_card__container">
                  <p className="einv_card__container__text">
                    Create a E way bill for your customer
                  </p>
                  <div className="einv_card__container__icon">
                    <FiArrowRight size={28} />
                  </div>
                </div>
              </Card>
              <Card
                className="einv_card"
                style={{ boxShadow: THEME.SHADOW }}
                onClick={() => {
                  setMainTabKey('3');
                  setDraftTabKey('2');
                }}
              >
                <h3 className="einv_card__title">View E way bills in draft</h3>
                <p className="einv_card__subheading">
                  <span className="einv_card__subheading--bold">
                    {eWayDraftCount}
                  </span>{' '}
                  E way bills in draft
                </p>
                <div className="einv_card__container">
                  <p className="einv_card__container__text">
                    View E way bills you have saved to drafts
                  </p>
                  <div className="einv_card__container__icon">
                    <FiArrowRight size={28} />
                  </div>
                </div>
              </Card>
            </div>
          </section>
        </TabPane>
        <TabPane tab="Invoice list" key="2">
          <section className="invoice-section">
            <div className="invoice-btnbox">
              <Button
                className="invoice-btnbox__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                  color: 'white',
                }}
                onClick={() => history.push(ROUTES.NEW_INVOICE)}
              >
                <PlusOutlined className="btn-icon-padding" /> New invoice
              </Button>
              {/*<Button className="invoice-btnbox__btn invoice-btnbox__btn--bulkbtn" onClick={showUploadModal}>*/}
              {/*  Bulk upload invoices*/}
              {/*</Button>*/}
            </div>
            <Tabs
              defaultActiveKey="1"
              onChange={(key) => setInvoiceTabKey(key)}
              activeKey={invoiceTabKey}
              className="container-tabs irn-bg-changes"
            >
              <TabPane tab={`IRN pending (${irnCount})`} key="1">
                <div className="irn-container">
                  <div className="irn-container__firstele">
                    <div className="irn-container__firstele__search">
                      <Search
                        className="date-picker-border-radius"
                        placeholder="Search by name/GSTIN"
                        onSearch={onIrnSearch}
                      />
                    </div>
                    <div className="irn-container__firstele__datepicker">
                      <p className="irn-container__firstele__datepicker--text">
                        From
                      </p>
                      <DatePicker
                        className="date-picker-border-radius"
                        format={dateFormat}
                        value={
                          irnDate.from
                            ? moment(parseDate(irnDate.from), 'DD/MM/YYYY')
                            : undefined
                        }
                        onChange={irnFromDateChange}
                      />
                    </div>
                    <div className="irn-container__firstele__datepicker">
                      <p className="irn-container__firstele__datepicker--text">
                        To
                      </p>
                      <DatePicker
                        className="date-picker-border-radius"
                        format={dateFormat}
                        value={
                          irnDate.to
                            ? moment(parseDate(irnDate.to), 'DD/MM/YYYY')
                            : undefined
                        }
                        onChange={irnToDateChange}
                      />
                    </div>
                    <Button
                      className="irn-container__firstele__btn"
                      style={{
                        backgroundColor: THEME.PRIMARY_COLOR,
                        color: 'white',
                      }}
                      onClick={() =>
                        getEinvoices(
                          1,
                          10,
                          '',
                          irnDate.from,
                          irnDate.to,
                          'INVOICE_GENERATED'
                        )
                      }
                    >
                      Go
                    </Button>
                  </div>
                  <div className="irn-container__secondele">
                    <Button
                      onClick={() => handleBulkIRNClick()}
                      className="irn-container__secondele__btn"
                      style={{
                        backgroundColor: THEME.PRIMARY_COLOR,
                        color: 'white',
                      }}
                    >
                      Generate IRN
                    </Button>
                  </div>
                </div>
                <Table
                  className="custom_table cursor"
                  loading={irnLoading}
                  rowKey={(record: any) => record.id}
                  // onRow={(r) => ({
                  //   onClick: () => (history.push(`/eInvoicing/invoicePreview`,{'e_invoice_id': r.id, 'step' : 2}))
                  // })}
                  columns={IRNcol}
                  dataSource={IRNData}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowIRNSelection,
                  }}
                  onChange={onIRNChange}
                  scroll={{ x: true }}
                  pagination={irnPagination}
                />
              </TabPane>
              <TabPane tab={`E way bill pending (${ewayCount})`} key="2">
                <div className="irn-container">
                  <div className="irn-container__firstele">
                    <div className="irn-container__firstele__search">
                      <Search
                        className="date-picker-border-radius"
                        placeholder="Search by name/GSTIN"
                        onSearch={onEwaySearch}
                      />
                    </div>
                    <div className="irn-container__firstele__datepicker">
                      <p className="irn-container__firstele__datepicker--text">
                        From
                      </p>
                      <DatePicker
                        className="date-picker-border-radius"
                        format={dateFormat}
                        value={
                          ewayDateSearch.from
                            ? moment(
                                parseDate(ewayDateSearch.from),
                                'DD/MM/YYYY'
                              )
                            : undefined
                        }
                        onChange={ewayFromDateChange}
                      />
                    </div>
                    <div className="irn-container__firstele__datepicker">
                      <p className="irn-container__firstele__datepicker--text">
                        To
                      </p>
                      <DatePicker
                        className="date-picker-border-radius"
                        format={dateFormat}
                        value={
                          ewayDateSearch.to
                            ? moment(parseDate(ewayDateSearch.to), 'DD/MM/YYYY')
                            : undefined
                        }
                        onChange={ewayToDateChange}
                      />
                    </div>
                    <Button
                      className="irn-container__firstele__btn"
                      style={{
                        backgroundColor: THEME.PRIMARY_COLOR,
                        color: 'white',
                      }}
                      onClick={() =>
                        getEinvoices(
                          1,
                          10,
                          '',
                          ewayDateSearch.from,
                          ewayDateSearch.to,
                          'IRN_GENERATED'
                        )
                      }
                    >
                      Go
                    </Button>
                  </div>
                  <div className="irn-container__secondele">
                    <Button
                      className="irn-container__secondele__btn"
                      onClick={() => handleBulkCancelIrnClick()}
                      style={{
                        backgroundColor: THEME.PRIMARY_COLOR,
                        color: 'white',
                      }}
                    >
                      Cancel IRN
                    </Button>
                  </div>
                </div>
                <Table
                  className="custom_table cursor"
                  loading={ewayLoading}
                  rowKey={(record: any) => record.id}
                  // onRow={(r) => ({
                  //   onClick: () =>
                  //     history.push(`/eInvoicing/invoicePreview`, {
                  //       e_invoice_id: r.id,
                  //       step: 3,
                  //     }),
                  // })}
                  columns={Ewaycol}
                  dataSource={ewayData}
                  pagination={ewayPagination}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowEwaySelection,
                  }}
                  onChange={onEwayChange}
                  scroll={{ x: true }}
                />
              </TabPane>
              <TabPane tab={`Error list (${errCount})`} key="3">
                <div className="err-container">
                  <div className="err-container__firstele">
                    <div className="err-container__firstele__search">
                      <Search
                        className="date-picker-border-radius"
                        placeholder="Search by name/GSTIN"
                        onSearch={onErrSearch}
                      />
                    </div>
                    <div className="err-container__firstele__datepicker">
                      <p className="err-container__firstele__datepicker--text">
                        From
                      </p>
                      <DatePicker
                        className="date-picker-border-radius"
                        format={dateFormat}
                        value={
                          errDateSearch.from
                            ? moment(
                                parseDate(errDateSearch.from),
                                'DD/MM/YYYY'
                              )
                            : undefined
                        }
                        onChange={errFromDateChange}
                      />
                    </div>
                    <div className="err-container__firstele__datepicker">
                      <p className="err-container__firstele__datepicker--text">
                        To
                      </p>
                      <DatePicker
                        className="date-picker-border-radius"
                        format={dateFormat}
                        value={
                          errDateSearch.to
                            ? moment(parseDate(errDateSearch.to), 'DD/MM/YYYY')
                            : undefined
                        }
                        onChange={errToDateChange}
                      />
                    </div>
                    <p
                      className="err-container__firstele__text"
                      onClick={() =>
                        getEinvoices(
                          1,
                          10,
                          '',
                          errDateSearch.from,
                          errDateSearch.to,
                          'IRN_FAILED'
                        )
                      }
                    >
                      Go
                    </p>
                  </div>
                  <div className="err-container__secondele">
                    <Button className="err-container__secondele" type='link' 
                      onClick={() => downloaderrorinvoiceList()}
                      style={{'color': '#4532bf'}}
                    >
                    Download list
                    </Button>
                    <Button className="err-container__secondele__btn" onClick={() => handleDraftBulkClear()}>
                    Clear
                    </Button>
                  </div>
                </div>
                <Table
                  className="custom_table"
                  loading={errLoading}
                  columns={Errcol}
                  rowKey={(record) => record.id}
                  dataSource={errData}
                  pagination={errPagination}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowErrSelection,
                  }}
                  onChange={onErrChange}
                  scroll={{ x: true }}
                />
              </TabPane>
              <TabPane tab={`History (${historyRowCount})`} key="4">
                <div className="irn-container">
                  <div className="irn-container__firstele">
                    <div className="irn-container__firstele__search">
                      <Search
                        className="date-picker-border-radius"
                        placeholder="Search by name/GSTIN"
                        onSearch={onEwaySearch}
                      />
                    </div>
                    <div className="irn-container__firstele__datepicker">
                      <p className="irn-container__firstele__datepicker--text">
                        From
                      </p>
                      <DatePicker
                        className="date-picker-border-radius"
                        format={dateFormat}
                        value={
                          historyDate.from
                            ? moment(parseDate(historyDate.from), 'DD/MM/YYYY')
                            : undefined
                        }
                        onChange={historyFromDateChange}
                      />
                    </div>
                    <div className="irn-container__firstele__datepicker">
                      <p className="irn-container__firstele__datepicker--text">
                        To
                      </p>
                      <DatePicker
                        className="date-picker-border-radius"
                        format={dateFormat}
                        value={
                          historyDate.to
                            ? moment(parseDate(historyDate.to), 'DD/MM/YYYY')
                            : undefined
                        }
                        onChange={historyToDateChange}
                      />
                    </div>
                    <Button
                      className="irn-container__firstele__btn"
                      style={{
                        backgroundColor: THEME.PRIMARY_COLOR,
                        color: 'white',
                      }}
                      onClick={() =>
                        getEinvoices(
                          1,
                          10,
                          '',
                          historyDate.from,
                          historyDate.to,
                          'IRN_GENERATED_FULL'
                        )
                      }
                    >
                      Go
                    </Button>
                  </div>
                </div>
                <Table
                  className="custom_table"
                  loading={ewayLoading}
                  rowKey={(record: any) => record.id}
                  columns={historyCol}
                  dataSource={ewayData}
                  pagination={ewayPagination}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowHistorySelection,
                  }}
                  onChange={onEwayChange}
                  scroll={{ x: true }}
                />
              </TabPane>
            </Tabs>
          </section>
        </TabPane>
        <TabPane tab="Drafts" key="3">
          <Tabs
            className="draft-tabs container-tabs irn-bg-changes"
            defaultActiveKey="1"
            onChange={(key) => setDraftTabKey(key)}
            activeKey={draftTabKey}
          >
            <TabPane tab={`E Invoices`} key="1">
              <div className="err-container">
                <div className="err-container__firstele">
                  <div className="err-container__firstele__search">
                    <Search
                      className="date-picker-border-radius"
                      placeholder="Search by name/GSTIN"
                      onSearch={onDraftSearch}
                    />
                  </div>
                  <div className="err-container__firstele__datepicker">
                    <p className="err-container__firstele__datepicker--text">
                      From
                    </p>
                    <DatePicker
                      className="date-picker-border-radius"
                      value={
                        draftDateSearch.from
                          ? moment(
                              parseDate(draftDateSearch.from),
                              'DD/MM/YYYY'
                            )
                          : undefined
                      }
                      format={dateFormat}
                      onChange={draftFromDateChange}
                    />
                  </div>
                  <div className="err-container__firstele__datepicker">
                    <p className="err-container__firstele__datepicker--text">
                      To
                    </p>
                    <DatePicker
                      className="date-picker-border-radius"
                      format={dateFormat}
                      value={
                        draftDateSearch.to
                          ? moment(parseDate(draftDateSearch.to), 'DD/MM/YYYY')
                          : undefined
                      }
                      onChange={draftToDateChange}
                    />
                  </div>
                  <p
                    className="err-container__firstele__text"
                    onClick={() =>
                      getEinvoices(
                        1,
                        10,
                        '',
                        draftDateSearch.from,
                        draftDateSearch.to,
                        'DRAFT'
                      )
                    }
                  >
                    Go
                  </p>
                </div>
                <div className="err-container__secondele">
                  <Button
                    className={`err-container__secondele__btn ${draftRowKeys?.length == 0 && 'no-pointer-event'}`}
                    onClick={() => handleDraftBulkDelete()}
                  >
                    Delete
                  </Button>
                </div>
              </div>
              <Table
                className="custom_table"
                loading={draftLoading}
                columns={Draftscol}
                rowKey={(record) => record.id}
                dataSource={draftsData}
                pagination={einvoiceDraftpagination}
                rowSelection={{
                  type: 'checkbox',
                  ...rowDraftsSelection,
                }}
                onChange={onDraftsChange}
                scroll={{ x: true }}
              />
            </TabPane>
            <TabPane tab={`E Way Bills`} key="2">
              <div className="err-container">
                <div className="err-container__firstele">
                  <div className="err-container__firstele__search">
                    <Search
                      className="date-picker-border-radius"
                      placeholder="Search by name/GSTIN"
                      onSearch={onEwayDraftSearch}
                    />
                  </div>
                  <div className="err-container__firstele__datepicker">
                    <p className="err-container__firstele__datepicker--text">
                      From
                    </p>
                    <DatePicker
                      className="date-picker-border-radius"
                      value={
                        eWayDraftDateSearch.from
                          ? moment(
                              parseDate(eWayDraftDateSearch.from),
                              'DD/MM/YYYY'
                            )
                          : undefined
                      }
                      format={dateFormat}
                      onChange={eWayDraftFromDateChange}
                    />
                  </div>
                  <div className="err-container__firstele__datepicker">
                    <p className="err-container__firstele__datepicker--text">
                      To
                    </p>
                    <DatePicker
                      className="date-picker-border-radius"
                      format={dateFormat}
                      value={
                        eWayDraftDateSearch.to
                          ? moment(
                              parseDate(eWayDraftDateSearch.to),
                              'DD/MM/YYYY'
                            )
                          : undefined
                      }
                      onChange={eWayDraftToDateChange}
                    />
                  </div>
                  <p
                    className="err-container__firstele__text"
                    onClick={() =>
                      getEwayBill(
                        1,
                        10,
                        '',
                        eWayDraftDateSearch.from,
                        eWayDraftDateSearch.to,
                        'DRAFT'
                      )
                    }
                  >
                    Go
                  </p>
                </div>
              </div>
              <Table
                className="custom_table"
                loading={eWayDraftLoading}
                columns={EwayDraftscol}
                rowKey={(record) => record.id}
                dataSource={ewayDraftData}
                rowSelection={{
                  type: 'checkbox',
                  ...rowEwbDraftsSelection,
                }}
                pagination={ewayBillPagination}
                onChange={onEwayDraftsChange}
                scroll={{ x: true }}
              />
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    </main>
  );
};
export default EInvoicing;
