
/* eslint-disable no-console */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/browser';
import appConfig from '../config';
import {
  IRequestAnchorInfo,
  IRequestFindAnchor,
  IRequestGetSubIndustryList,
  IRequestGstAccountCreation,
  IRequestGstLogin,
  IRequestLoginOtp,
  IRequestRegisteredGSTIN,
  IRequestSaveAnchorDetails,
  IRequestSupportNotification,
  IRequestVerifyOtp,
  IRequestWhatsappOptIn,
} from '../models/api/authModels';
import {
  IRequestAddSupplier,
  IRequestCreateDataSource,
  IRequestDocumentUploadData,
  IRequestFieldsForTemplate,
  IRequestProspectLatestFilingInfo,
  IRequestProspectsOfDataSource,
  IRequestUpdateProspect,
  IRequestUploadSuppliersFile,
  IStandardResponse,
  IWhatsappRequestBody,
} from '../models/api/dataSourceModels';
import {
  IRequestAddColleague,
  IRequestColleagueUpdate,
} from '../models/api/colleagueModels';
import { IColleague } from '../models';
import {
  IRequestReportFiles,
  IRequestReportForDataSource,
  IRequestReportHistory,
} from '../models/api/reportsModels';
import OpenNotification from '../utils/notification';
import { OpenErrorModalActionable } from '../utils/modals';

const errorHandler = (error: AxiosError, actionable = false, callbackFunction: Function = () => { }) => {
  const errorResponseA = error.response?.data;
  const errorMessageA = errorResponseA?.message;
  if (actionable && errorMessageA) {
    const message = typeof errorMessageA === 'string' ? errorMessageA : errorMessageA[0];
    OpenErrorModalActionable(message, 'Ok', callbackFunction);
  } else if (error.response?.status === 401) {
    OpenNotification(
      'Your session expired due to inactivity. Please login again',
      'error'
    );
  } else {
    try{
      // console.error('An error has occurrred');
      // console.error(error);
      // console.error(error.response);
      const errorResponse = error?.response?.data;
      const errorMessage = errorResponse?.message;
      Sentry.captureException(error);

      if (errorMessage) {
        if (typeof errorMessage === 'string') {
          OpenNotification(errorMessage, 'error');
        } else if (Array.isArray(errorMessage) && errorMessage.length > 0) {
          const errObj:any = errorMessage[0];
          if ('error_msg' in errObj){
            OpenNotification(errorMessage[0]['error_msg'], 'error');
          }
          else {
            OpenNotification(errorMessage[0], 'error');
          }
        }
      } else {
        OpenNotification('Error occurred', 'error');
        Sentry.captureException(error);
      }
    }catch(exception){
      // console.log('error in error handler');
      // console.log(exception);
      Sentry.captureException(exception);
    }
  }
  return Promise.reject(error);
};

const successHandler = (response: AxiosResponse) => {
  return response;
};

export class ApiService {
  private readonly baseUrl: any;

  private axiosInstance: AxiosInstance;

  constructor() {
    this.baseUrl = appConfig.apiURL;
    this.axiosInstance = axios.create({
      baseURL: appConfig.apiURL
    });
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => successHandler(response),
      (error: AxiosError) => {
        const actionableModalTriggerStrings = ['login', 'register', 'id is non existing'];
        const errorMessage = error?.response?.data?.message;
        const actionable = errorMessage && actionableModalTriggerStrings.some((triggerWord: string) => errorMessage.includes(triggerWord));
        return errorHandler(error, actionable);
      }
    );
  }

  public setUserToken(token: string) {
    //    token='4238e3e8b255452e1d508e4209185c4f8013bfed6650639a85a95bd61395d961';
    this.axiosInstance.defaults.headers.common.Authorization = `Token ${token}`;
  }

  public generateOtp(data: IRequestLoginOtp) {
    return this.axiosInstance.post('/gst-account/generate-otp/kyss', {
      phone_number: data.phoneNumber,
      register: data.register,
    });
  }

  public verifyOtp(data: IRequestVerifyOtp) {
    return this.axiosInstance.post('/gst-account/validate-otp/kyss', {
      phone_number: data.phoneNumber,
      otp: data.otp,
    });
  }

  public async reportAction(data: any) {
    return this.axiosInstance.post('/recon/match-entry-actions', data);
  }

  public getReportList(data: any) {
    return this.axiosInstance.get('/recon/reports');
  }

  public getReportById(id: any, payload: any) {
    return this.axiosInstance.get(`/recon/reports/${id}`, { params: payload });
  }

  public getSupplierList(id: any) {
    return this.axiosInstance.get(`/recon/reports/${id}/suppliers`);
  }

  public validateGSTOtp(otp: string) {
    return this.axiosInstance.post('/wep-gst/validate-otp', { otp });
  }

  public generateGSTOtp(gstUsername: string) {
    return this.axiosInstance.post('/wep-gst/generate-otp', {
      gst_username: gstUsername,
    });
  }

  public downloadGst2Ra() {
    return this.axiosInstance.post('/wep-gst/gstr2a-download', null);
  }

  public getAddInvoiceData(id: any, payload: any) {
    return this.axiosInstance.get(`/recon/add-invoice-search/${id}`, {
      params: payload,
    });
  }

  public addInvoice(data: any) {
    return this.axiosInstance.post('/recon/match-entry-actions', data);
  }

  public getItrFileUpload() {
    return this.axiosInstance.get('/recon/itr-file-upload');
  }

  public getMappings() {
    return this.axiosInstance.get('/recon/itr-file-field-mapping');
  }

  public addMappings(data: any) {
    return this.axiosInstance.post('/recon/itr-file-field-mapping', data);
  }

  public processItrFileUpload() {
    return this.axiosInstance.post('/recon/input-tax-register-entries');
  }

  public getPageState() {
    return this.axiosInstance.get('/recon/page-state');
  }

  public setPageState(pageState: any) {
    return this.axiosInstance.post('/recon/page-state', pageState);
  }

  public uploadPurchaseData(data: any) {
    return this.axiosInstance.post('/recon/itr-file-upload', data);
  }

  public getDropdowns() {
    return this.axiosInstance.get('/recon/dropdown-values');
  }

  public getHeader(id: any) {
    return this.axiosInstance.get(`/recon/reports/${id}/header`);
  }

  public async logOut() {
    return this.axiosInstance.post('/gst-account/logout');
  }

  public async getRegisteredGSTINs(data: IRequestRegisteredGSTIN) {
    return this.axiosInstance.get(
      `/gst-account/registered-gstins/kyss?phone_number=${data.phoneNumber}`
    );
  }

  public gstAccountLogin(data: IRequestGstLogin): Promise<AxiosResponse> {
    return this.axiosInstance.post('/gst-account/login/kyss', {
      gstin: data.gstin,
      phone_number: data.phoneNumber,
    });
  }

  public async requestSupportNotification(data: IRequestSupportNotification) {
    return this.axiosInstance.post('/gst-account/report-login-issue/kyss', {
      phone_number: data.phoneNumber,
      issue: data.issue,
    });
  }

  public async requestAnchorInfo(data: IRequestAnchorInfo) {
    return this.axiosInstance.get(`/anchor/find?gstin=${data.gstin}`);
  }

  public async requestProspectsOfDataSource(
    data: IRequestProspectsOfDataSource
  ) {
    let url = `/prospect/list?paginate=true&page_size=${data.pageSize}&page=${data.pageNumber}&data_source_name=${data.dataSourceName}`;
    if (data.search && data.search !== '') {
      url += `&search=${data.search}`;
    }
    if (data.status && data.status !== '') {
      url += `&status=${data.status}`;
    }
    return this.axiosInstance.get(url);
  }

  public async fetchGstDetails(
    data: any
  ) {
    const url = '/kyss/gstin/search';
    return this.axiosInstance.post(url, {gstin:data});
  }

  public async fetchProfileDetails(
    data: any
  ) {
    const url = `/kyss/profile?gstin=${data}`;
    return this.axiosInstance.get(url);
  }

  public generateProfileOtp(data: any) {
    return this.axiosInstance.post('/kyss/generate-otp/kyss', {
      phone_number: data
    });
  }

  public postProfileData(data: any) {
    return this.axiosInstance.post('/kyss/profile', data);
  }

  public verifyProfileOtp(data: any) {
    return this.axiosInstance.post('/kyss/validate-otp/kyss', {
      phone_number: data.phoneNumber,
      otp: data.otp,
      new_phone: data.new_phone,
    });
  }

  public async fetchFilingDetails(
    gstin: any,
    monthYear: any
  ) {
    const url = `/kyss/gstin/filing?gstin=${gstin}&month_year=${monthYear}`;
    return this.axiosInstance.get(url);
  }

  public async requestColleagueList() {
    return this.axiosInstance.get('/anchor/colleague/');
  }

  public async requestColleagueUpdate(data: IRequestColleagueUpdate) {
    return this.axiosInstance.patch(`/anchor/colleague/${data.id}/`, data);
  }

  public async requestColleagueDelete(data: IColleague) {
    return this.axiosInstance.delete(`/anchor/colleague/${data.id}/`);
  }

  public async addColleague(data: IRequestAddColleague) {
    return this.axiosInstance.post('/anchor/colleague/', data);
  }

  public async requestFindAnchor(data: IRequestFindAnchor) {
    return this.axiosInstance.get(`/anchor/find?gstin=${data.gstin}`);
  }

  public async saveAnchorDetails(data: IRequestSaveAnchorDetails) {
    return this.axiosInstance.post('/anchor/save', {
      primaryContactNumber: data.primaryContactNumber,
      industryType: data.industryType,
      subIndustryType: data.subIndustryType,
      gstin: data.gstin,
      jitfinId: data.jitfinId,
      subIndustryManual: data.otherSubIndustry,
    });
  }

  public async getIndustryList() {
    return this.axiosInstance.get('/anchor/industry-type');
  }

  public async getSubIndustryList(data: IRequestGetSubIndustryList) {
    return this.axiosInstance.get(
      `anchor/sub-industries?industry=${encodeURIComponent(data.industry)}`
    );
  }

  public async createGstAccount(data: IRequestGstAccountCreation) {
    return this.axiosInstance.post('/gst-account/register/kyss', {
      registered_gstins: [
        {
          gstin: data.gstin,
          is_inactive: false,
        },
      ],
      name: data.name,
      phone_number: data.phoneNumber,
      email: data.email,
      gender: data.gender,
      isWhatsappOptIn: data.isWhatsappOptIn,
    });
  }

  public async addSupplier(data: IRequestAddSupplier) {
    return this.axiosInstance.post('/prospect/create-single-prospect', {
      ds_id: data.dataSourceId,
      prospect: {
        gstin: data.gstin,
        email: data.email,
        phone_number: data.phoneNumber,
        company_name: data.companyName,
      },
    });
  }




  public async uploadSuppliersFile(data: IRequestUploadSuppliersFile) {
    const formData = new FormData();
    formData.append('file', data.fileObject);
    formData.append('data_source_name', data.dataSourceName);
    formData.append('description', data.description);
    if (data.shouldGenerateSupplierReport) {
      formData.append(
        'generate_supplier_report',
        data.shouldGenerateSupplierReport.toString()
      );
    }
    if (data.shouldSendNotificationsGSTR1) {
      formData.append('send_notifications_gstr1', 'true');
    }
    if (data.shouldSendNotificationsGSTR3B) {
      formData.append('send_notifications_gstr3b', 'true');
    }
    return this.axiosInstance.post('/files/upload-file', formData);
  }

  public async createDataSource(data: IRequestCreateDataSource) {
    return this.axiosInstance.post('/data-source/create-data-source', {
      name: data.name,
      description: data.description,
      anchor_id: data.anchor_id,
      selected_fields: [
        {
          field_name: 'GSTIN',
          mandatory: true,
        },
        {
          field_name: 'Full_Name',
          mandatory: false,
        },
        {
          field_name: 'Email',
          mandatory: false,
        },
        {
          field_name: 'Phone_Number',
          mandatory: false,
        },
      ],
    });
  }

  public async getFieldsForTemplateForDataSource(
    data: IRequestFieldsForTemplate
  ) {
    return this.axiosInstance.get(
      `/data-source/download-template?ds_id=${data.dataSourceId}`
    );
  }

  public async getGstFilingStatsForFile(
    data: IRequestProspectLatestFilingInfo
  ) {
    return this.axiosInstance.get(
      `/files/get-gst-stats?file_id=${data.fileId}&as_of_month=${data.asOfMonth}&as_of_year=${data.asOfYear}&as_of_date=${data.asOfDate}`
    );
  }

  public async updateProspect(data: IRequestUpdateProspect) {
    return this.axiosInstance.post('/prospect/uploaded-data/edit', {
      prospect_id: data.prospectId,
      email: data.email,
      'phone number': data.phoneNumber,
    });
  }

  public async deleteProspect(ids:number[]) {
    return this.axiosInstance.delete(`/prospect/delete-supplier`,{data:{ids:ids}});
  }

  public async getReportHistory(data: IRequestReportHistory) {
    return this.axiosInstance.get(
      `/reports/report-tracker/?report_name=${data.reportName}`
    );
  }

  public async getReportFiles(data: IRequestReportFiles) {
    return this.axiosInstance.get(
      `/reports/report-tracker/get-report-files/?report_id=${data.reportTrackerId}`
    );
  }

  public async generateReportForDataSource(data: IRequestReportForDataSource) {
    return this.axiosInstance.get(
      `/reports/request-data-source-supplier-report?data_source_id=${data.dataSourceId}`
    );
  }

  public async getSummary(id: any) {
    return this.axiosInstance.get(
      `/recon/reports/${id}/summary`
    );
  }

  //    MAD-2021 -ANC-101 add recon api
  public async startRecon(data: any) {
    return this.axiosInstance.post('/recon/reports', {

      'file_upload_id': data
    });
  }

  public async getDocumentUploadData(data: IRequestDocumentUploadData) {
    return this.axiosInstance.get(
      `/document-upload/?sort_by=-uploaded_at&paginate=true&page_size=1&page=1&ds_name=${data.dataSourceName}`
    );
  }

  public async setWhatsappSubscription(data: IRequestWhatsappOptIn) {
    const url = `/gst-account/${data.profileId}`;

    const params = {
      is_whatsapp_opt_in: data.isWhatsappOptIn,
    };

    return this.axiosInstance.patch(url, params);
  }

  public async setMatchEntryObject(data: any) {
    const { id, ...rest } = data;
    return this.axiosInstance.patch(`/recon/match-entries/${id}`, rest);
  }

  public async initiateWhatsappMessage(data: IWhatsappRequestBody) {
    return this.axiosInstance.post('prospect/gstr-notification', { ds_name: data.dsName, tradename: data.tradeName });
  }

  public supplierReportList(data: any) {

    let url = `/supplier-reports?page_size=${data.pageSize}&page=${data.current}`;
    if (data.search_q && data.search_q.length > 0) {
      url = `${url}&search_q=${data.search_q}`;
    }
    if (data.date && data.date.length > 0) {
      url = `${url}&date=${data.date}`;
    }

    return this.axiosInstance.get(url);
    //   / return this.axiosInstance.post('prospect/gstr-notification', { ds_name: data.dsName, tradename: data.tradeName });
  }

  public supplierReportDetail(key: any, data: any) {
    let url = `/supplier-reports/${key}?page_size=${data.pageSize}&page=${data.current}`;
    if (data.search_q && data.search_q.length > 0) {
      url = `${url}&search_q=${data.search_q}`;
    }
    if (data.date && data.date.length > 0) {
      url = `${url}&tax_filing_date=${data.date}`;
    }

    if (data.status && data.status.length > 0) {
      url = `${url}&gstin_status=${data.status}`;
    }
    return this.axiosInstance.get(url);
  }

  public supplierReportSummary(data: any) {
    const url = `/supplier-reports/${data}/summary`;
    return this.axiosInstance.get(url);
    //   / return this.axiosInstance.post('prospect/gstr-notification', { ds_name: data.dsName, tradename: data.tradeName });
  }


  public  supplierReportSummaryView(data: any) {
    const url = `/supplier-reports/${data.reportid}/summary?page_size=${data.pageSize}&page=${data.current}`;
    return  this.axiosInstance.get(url);
    //   / return this.axiosInstance.post('prospect/gstr-notification', { ds_name: data.dsName, tradename: data.tradeName });
  }



  public supplierUploadPurchaseData(data: any) {
    return this.axiosInstance.post('/files/upload-suppliers/kyss', data);
  }

  public supplierGenerateReoprt(data: any) {
    return this.axiosInstance.post('/files/supplier-update-tax-filing', data);
  }



  public  supplierReportUpdateFilingDate(reportId: any, paylad: any) {
    const url = `/supplier-reports/${reportId}`;
    return this.axiosInstance.patch(url, paylad);
  }

  public supplierReportGstReminders(reportId: any, paylad: any) {
    const url = `/supplier-reports/${reportId}/gst-reminders`;
    return this.axiosInstance.post(url, paylad);
  }

  public  supplierReportRiskData(reportId: any) {
    const url = `/supplier-reports/${reportId}/risk-data`;
    return  this.axiosInstance.get(url);
    //   return this.axiosInstance.post(url, paylad);
  }

  public  supplierReportGstr1(reportId: any) {
    const url = `/supplier-reports/${reportId}/gstr1`;
    return  this.axiosInstance.get(url);
    //   return this.axiosInstance.post(url, paylad);
  }


  public  supplierReportGstr1RiskData(reportId: any) {
    const url = `/supplier-reports/${reportId}/risk-data`;
    return  this.axiosInstance.get(url);
    //   return this.axiosInstance.post(url, paylad);
  }


  public  supplierReportGstr1CompanyType(reportId: any) {
    const url = `/supplier-reports/${reportId}/company-type`;
    return  this.axiosInstance.get(url);
    //   return this.axiosInstance.post(url, paylad);
  }




  public async supplierReportGstr1DelayAnalysis(reportId: any, numberOfDays: any, numberOfTimes: any) {
    let url = `/supplier-reports/${reportId}/delay-analysis?return_type=GSTR1`;

    if (numberOfDays && numberOfDays.length > 0) {
      url = `${url}&days_delayed=${numberOfDays}`;
    }
    if (numberOfTimes && numberOfTimes.length > 0) {
      url = `${url}&times_delayed=${numberOfTimes}`;
    }

    return  this.axiosInstance.get(url);
  }


  public  supplierReportGstr1DropDownValues(reportId: any) {
    const url = `/supplier-reports/${reportId}/drop-down-values`;
    return  this.axiosInstance.get(url);
  }


  public  supplierReportGstr1Download(reportId: any) {
    const url = `/supplier-reports/${reportId}/download`;
    return  this.axiosInstance.post(url);
  }


  public  supplierReportGstr1Watchlist(count: any) {
    const url = `/prospect/watch-list-entries?count=${count}`;
    return  this.axiosInstance.get(url);
    //    return this.axiosInstance.post(url, paylad);
  }





  public supplierReoprtStatus(data: any) {
    return this.axiosInstance.get(`/supplier-report-status?file_upload_id=${data}`);
  }

  public supplierReoprtPageStatus() {
    return this.axiosInstance.get('/supplier-reports/pages/state');
  }

  public supplierReoprtSetPageStatus(data: any) {
    return this.axiosInstance.post('/supplier-reports/pages/state', data);
  }



  public async getGstinStatus() {
    // return this.axiosInstance.get('/prospect/gstin-filter');
  }

  public async getWatchlistEntries(data:any) {
    // return this.axiosInstance.get('/prospect/watch-list-entries');
    {
      let url = `/prospect/watch-list-entries?paginate=true&page_size=${data.pageSize}&page=${data.pageNumber}&data_source_name=${data.dataSourceName}`;
      if (data.search && data.search !== '') {
        url += `&search=${data.search}`;
      }
      if (data.status && data.status !== '') {
        url += `&status=${data.status}`;
      }
      return this.axiosInstance.get(url);
    }
  }
  public async AddToWatchlist(id:any) {
    return this.axiosInstance.post('/prospect/watch-list-entries',{ids:id});
  }
  public async deleteFromWatchlist(ids:number[]) {
    return this.axiosInstance.delete('/prospect/watch-list-entries',{data:{ids:ids}});
  }

  public async getWatchlistGstinStatus() {
    // return this.axiosInstance.get('/prospect/watchlist-gstin-filter');
  }

  public async getInvalidlistEntries(data:any) {
    {
      let url = `/kyss/failed-entries?paginate=true&page_size=${data.pageSize}&page=${data.pageNumber}&data_source_name=${data.dataSourceName}&data_source_id=${data.datasourceID}`;
      if (data.search && data.search !== '') {
        url += `&search=${data.search}`;
      }
      if (data.status && data.status !== '') {
        url += `&status=${data.status}`;
      }
      return this.axiosInstance.get(url);
    }
  }
  public async deleteInvalidlistEntries(data:any) {
    return this.axiosInstance.delete(`/kyss/failed-entries?data_source_id=${data.datasourceID}`,{data:{ids:data.ids}});
  }

  public async exportInvalidlistCsv(dataSourceID: number | string) {
    let url = `/prospect/export-invaildlist?data_source_id=${dataSourceID}`
    // return this.axiosInstance.get('/prospect/export-invaildlist',{data:{dataSourceID:dataSourceID}});
    return this.axiosInstance.get(url);
  }

  public async getTransporter(data:any) {
    {
      let url = `/kyss/transporters?paginate=true&page_size=${data.pageSize}&page=${data.pageNumber}&user_id=${data.user_id}`;
      if (data.search && data.search !== '') {
        url += `&search=${data.search}`;
      }
      // if (data.status && data.status !== '') {
      //   url += `&status=${data.status}`;
      // }
      return this.axiosInstance.get(url);
    }
  }

  public async getItems(data: any) {
    {
      let url = `/kyss/items?paginate=true&page_size=${data.pageSize}&page=${data.pageNumber}`;
      if (data.search && data.search !== '') {
        url += `&search=${data.search}`;
      }
      return this.axiosInstance.get(url);
    }
  }

  public async addTransporter(data:{
    name:string,
    contact_no: string,
  }) {
    return this.axiosInstance.post('/kyss/transporters',{...data});
  }

  public async addItem(data: any) {
    return this.axiosInstance.post('/kyss/items',{...data});
  }

  public async deleteTransporter(ids:number[]) {
    return this.axiosInstance.delete('/kyss/transporters',{data:{ids:ids}});
  }

  public async deleteItem(ids:number[]) {
    return this.axiosInstance.delete('/kyss/items', {data:{ids:ids}});
  }

  public async updateTransporter(data:{
    id: number | string,
    name: string,
    contact_no:string,
  }) {
    return this.axiosInstance.patch(`/kyss/transporters/${data.id}`,{...data});
  }

  public async updateItem(data:any) {
    return this.axiosInstance.patch(`/kyss/items/${data.id}`,{...data});
  }

  public async getExportedCSV(dataSourceID: number | string) {
    let url = `/prospect/export-failed-entires?data_source_id=${dataSourceID}`
    // return this.axiosInstance.get('/prospect/export-invaildlist',{data:{dataSourceID:dataSourceID}});
    return this.axiosInstance.get(url);
  }

  public async addCustomer(data: any) {
    return this.axiosInstance.post('/kyss/customers',
      {...data}
    );
  }

  public async updateCustomer(data: any) {
    return this.axiosInstance.patch(`/kyss/customers/${data.id}`, { ...data });
  }

  public async getCustomers(data: any) {
    {
      let url = `/kyss/customers?paginate=true&page_size=${data.pageSize}&page=${data.pageNumber}`;
      if (data.search && data.search !== '') {
        url += `&search=${data.search}`;
      }
      if (data.tab && data.tab !== '') {
        url += `&tab=${data.tab}`;
      }
      if (data.status && data.status !== '') {
        url += `&status=${data.status}`;
      }
      return this.axiosInstance.get(url);
    }
  }

  public async deleteCustomers(ids:number[]) {
    return this.axiosInstance.delete('/kyss/customers', {data:{ids:ids}});
  }

  public async downloadInvalidCustomerList() {
    return this.axiosInstance.get('/kyss/download-invalid-customers-list');
  }
  public async saveToDraft(data: any) {
    return this.axiosInstance.post('/kyss/e-invoices',{...data});
  }
  public async fetchBankDetails(data: any) {
    return this.axiosInstance.get(`/kyss/bank-details?bank_name=${data}`);
  }

  public async updateEinvoice(
    id: number | string,
    data:any
  ) {
    return this.axiosInstance.patch(`/kyss/e-invoices/${id}`,{...data});
  }

  public async getInvoiceCount() {
    return this.axiosInstance.get('/kyss/get-invoice-count');
  }

  public async getAllCustomers() {
    return this.axiosInstance.get('/kyss/customers?tab=valid&load_page=invoice');
  }

  public async fetchItems() {
    return this.axiosInstance.get('/kyss/items');
  }
  public async getEinvoices(data: any) {
    {
      let url = `/kyss/e-invoices?paginate=true&page_size=${data.pageSize}&page=${data.pageNumber}&status=${data.status}`;
      if (data.search && data.search !== '') {
        url += `&search=${data.search}`;
      }
      if (data.tab && data.tab !== '') {
        url += `&tab=${data.tab}`;
      }
      if (data.from && data.from !== '') {
        url += `&from=${data.from}`;
      }
      if (data.to && data.to !== '') {
        url += `&to=${data.to}`;
      }
      return this.axiosInstance.get(url);
    }
  }

  public async deleteEinvoices(ids:any) {
    return this.axiosInstance.delete('/kyss/e-invoices', {data:{ids:ids}});
  }

  public async deleteEwayBill(ids:any) {
    return this.axiosInstance.delete('/kyss/eway-bill', {data:{ids:ids}});
  }

  public async getSingleEinvoice(
    id: number | string,
  ) {
    return this.axiosInstance.get(`/kyss/e-invoices/${id}`);
  }

  public async fetchEwayBill(gstin:any) {
    return this.axiosInstance.get(`/kyss/eway-bill?gstin=${gstin}`);
  }

  public async submitEwayBill(data:any) {
    return this.axiosInstance.post('/kyss/eway-bill', { ...data });
  }

  public async updateEwayBill(id:any, data:any) {
    return this.axiosInstance.patch(`/kyss/eway-bill/${id}`, { ...data });
  }

  public async generateIrn(id:any, gstin:any) {
    return this.axiosInstance.post('/kyss/generateIrn', { 'einvoice_id': id, 'gstin': gstin });
  }

  public async generateBulkIrn(ids:any, gstin:any) {
    return this.axiosInstance.post('/kyss/generateBulkIrn', { 'ids': ids, 'gstin': gstin });
  }

  public async generateBulkEwb(ids:any, gstin:any) {
    return this.axiosInstance.post('/kyss/generateBulkEwb', { 'ids': ids, 'gstin': gstin });
  }

  public async cancelBulkIrn(ids:any, gstin:any) {
    return this.axiosInstance.post('/kyss/cancelBulkIrn', { 'ids': ids, 'gstin': gstin });
  }

  public async generateEwb(id:any, gstin:any, shareDetails:any, values:any) {
    return this.axiosInstance.post('/kyss/generateEwb', { 'ewb_id': id, 'gstin': gstin, 'share_details': shareDetails, 'form_data':values });
  }

  public async fetchIrnInvoices() {
    return this.axiosInstance.get('/kyss/irn-generated-invoices');
  }

  public async getEwayBill(data: any) {
    {
      let url = `/kyss/get-ewaybills?paginate=true&page_size=${data.pageSize}&page=${data.pageNumber}&status=${data.status}`;
      if (data.search && data.search !== '') {
        url += `&search=${data.search}`;
      }
      if (data.tab && data.tab !== '') {
        url += `&tab=${data.tab}`;
      }
      if (data.from && data.from !== '') {
        url += `&from=${data.from}`;
      }
      if (data.to && data.to !== '') {
        url += `&to=${data.to}`;
      }
      return this.axiosInstance.get(url);
    }
  }
  public async getSingleEwaybill(
    id: number | string,
  ) {
    return this.axiosInstance.get(`/kyss/eway-bill/${id}`);
  }

  public validateBankAccountDelete(id:number) {
    return this.axiosInstance.get(`/kyss/validate-bank-account-delete/${id}`);
  }

  public generateQrCode(doc_number:string) {
    return this.axiosInstance.get(`/kyss/generate-qr-code/${doc_number}`);
  }
  public async downloaderrorinvoiceList() {
    return this.axiosInstance.get('/kyss/download-error-invoice-list');
  }
  public async getEinvoiceStatus(invoice_no:any) {
    return this.axiosInstance.post('/kyss/einvoice-status',{ 'invoice_no': invoice_no});
  }
  public async getInvoiceNumber(data: any) {
    return this.axiosInstance.post('/kyss/filter-invoice-number',{...data});
  }
  public async getLogoUrl() {
    return this.axiosInstance.get('/kyss/upload-logo');
  }



}


export const globalApiService = new ApiService();
export const EerrorHandler = errorHandler;
export const EsuccessHandler = successHandler;