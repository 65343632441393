import { CheckCircleTwoTone, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { THEME_ORANGE } from '../constants/theme';

export enum ModalConfirmationType {
  SUCCESS = 'success',
  ERROR = 'error'
}

const OpenConfirmationModal = (title: string, confirmBtnText: string, content: string = '', confirmationType: ModalConfirmationType = ModalConfirmationType.SUCCESS) => {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText: confirmBtnText,
  });
};

const OpenSuccessModalActionable = (title: string, confirmBtnText: string, callback: Function = () => { }, content?: string) => {
  Modal.confirm({
    title,
    icon: <CheckCircleTwoTone twoToneColor={THEME_ORANGE.COLOR_SUCCESS} />,
    content,
    className: 'popup-success',
    okText: confirmBtnText,
    onOk: () => {callback();},
    cancelButtonProps: { style: { display: 'none' } },
    centered: true,
    okButtonProps: { style: { margin: '0 auto', backgroundColor: THEME_ORANGE.COLOR_CTA, color: '#FFFFFF', border: 'none', boxShadow: THEME_ORANGE.SHADOW }, className: 'ant-btn-round ant-btn-lg' }
  });
};

const OpenErrorModalActionable = (title: string, confirmBtnText: string, callback: Function = () => { }, content?: string) => {
  Modal.error({
    title,
    icon: <CloseCircleOutlined twoToneColor={THEME_ORANGE.COLOR_ERROR} />,
    content,
    className: 'popup-error',
    okText: confirmBtnText,
    onOk: () => {
      callback();
    },
    cancelButtonProps: { style: { display: 'none' } },
    centered: true,
    okButtonProps: { style: { margin: '0 auto', backgroundColor: THEME_ORANGE.COLOR_ERROR, color: '#FFFFFF', border: 'none', boxShadow: THEME_ORANGE.SHADOW }, className: 'ant-btn-round ant-btn-lg' }
  });
};

export { OpenConfirmationModal, OpenSuccessModalActionable, OpenErrorModalActionable };