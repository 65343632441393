/* eslint-disable no-unused-vars */
import { put, takeEvery } from 'redux-saga/effects';
import { IAction } from '../../models';
import {
  AuthActions,
  getIndustryListSuccess,
  getSubIndustryListSuccess,
  requestAnchorInfoSuccess,
  requestFindAnchorSuccess,
  requestGstAccountLoginSuccess,
  requestOtpForLoginError,
  requestOtpForLoginSuccess,
  requestRegisteredGSTINsSuccess,
  saveAnchorDetailsSuccess,
  setAccountCreationFlag,
  setWhatsappOptInSuccess,
} from '../actions/authActions';
import {
  IRequestAnchorInfo,
  IRequestFindAnchor,
  IRequestGetSubIndustryList,
  IRequestGstAccountCreation,
  IRequestGstLogin,
  IRequestLoginOtp,
  IRequestRegisteredGSTIN,
  IRequestSaveAnchorDetails,
  IRequestSupportNotification,
  IRequestVerifyOtp,
  IRequestWhatsappOptIn,
  IResponseAnchorInfo,
  IResponseGstLogin,
  IResponseSaveAnchorDetails,
} from '../../models/api/authModels';
import { exceptionLogger, spinForWorker } from './utils';
import { globalApiService } from '../../services/apiService';
import { createDataSource } from '../actions/dataSourceActions';
import OpenNotification from '../../utils/notification';
import { OpenSuccessModalActionable } from '../../utils/modals';

function* RequestOtpWorker(action: IAction): IterableIterator<any> {
  const payload: IRequestLoginOtp = action.body;
  yield globalApiService.generateOtp(payload);
  action.redirect && action.redirect();
  yield put(requestOtpForLoginSuccess(payload.phoneNumber));
  OpenNotification(
    `Sent a new OTP on the phone number ${payload.phoneNumber}`,
    'success'
  );
}

function* VerifyOtpWorker(action: IAction): IterableIterator<any> {
  try {
    const payload: IRequestVerifyOtp = action.body as IRequestVerifyOtp;
    yield globalApiService.verifyOtp(payload);
    action.redirect && action.redirect();
  } catch (err) {
    yield put(requestOtpForLoginError());
  }
}

function* gstAccountCreationWorker(action: IAction): IterableIterator<any> {
  const payload: IRequestGstAccountCreation = action.body;
  yield globalApiService.createGstAccount(payload);
  // OpenNotification('Account created! Please login.', 'success');
  // OpenSuccessModalActionable('Registered Successfully!', 'Continue');
  yield put(setAccountCreationFlag());
  try {
    const { fbq } = window as any;
    fbq && fbq('track', 'CompleteRegistration', {
      message: 'A new user has just registered successfully!',
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }

  action.redirect && action.redirect();
}

function* gstAccountLoginWorker(action: IAction): IterableIterator<any> {
  const payload: IRequestGstLogin = action.body;
  const response: any = yield globalApiService.gstAccountLogin(payload);
  const dataInResponse = response?.data;
  if (response && dataInResponse) {
    const { gstUser } = dataInResponse;
    yield put(
      requestGstAccountLoginSuccess({
        token: response.data.token,
        userId: gstUser.id,
        email: gstUser.email,
        gstin: response.data.gstin,
        isActive: response.data.isActive,
        userName: gstUser.name,
        isWhatsappOptIn: gstUser.isWhatsappOptIn,
      } as IResponseGstLogin)
    );
    action.redirect && action.redirect();
  }
}

function* logOutWorker(action: IAction): IterableIterator<any> {
  yield globalApiService.logOut();
  OpenNotification('Successfully logged out!', 'success');
}

function* requestAnchorInfoWorker(action: IAction): IterableIterator<any> {
  const payload: IRequestAnchorInfo = action.body;
  const response: any = yield globalApiService.requestAnchorInfo(payload);
  const dataInResponse: any = response?.data;
  if (response && dataInResponse) {
    yield put(
      requestAnchorInfoSuccess({
        anchorId: dataInResponse.anchorId,
        jitFinId: dataInResponse.jitfinId,
        dataSourceId: dataInResponse.data_source_id,
        dataSourceName: dataInResponse.data_source_name,
        industryType: dataInResponse.industryType,
        subIndustryType: dataInResponse.subIndustryType,
      } as IResponseAnchorInfo)
    );
  }
}

function* requestFindAnchorWatcher(action: IAction): IterableIterator<any> {
  const payload: IRequestFindAnchor = action.body;
  const response: any = yield globalApiService.requestFindAnchor(payload);
  const dataInResponse: any = response?.data;
  const { gstin } = payload;
  if (response && dataInResponse) {
    // TODO: If anchor is created, it should return 201
    if (dataInResponse.anchorId !== undefined) {
      OpenNotification(
        'GSTIN already exists in the system, Please login!',
        'error'
      );
    } else {
      yield put(
        requestFindAnchorSuccess({
          success: dataInResponse.success,
          tradeName: dataInResponse.tradeName,
          jitfinId: dataInResponse.jitfinId,
          gstin,
          stateName: dataInResponse.state_name || '',
          Address: dataInResponse.primary_address || ''
        })
      );
      action.redirect && action.redirect();
    }
  }
}

function* saveAnchorDetailsWatcher(action: IAction): IterableIterator<any> {
  const payload: IRequestSaveAnchorDetails = action.body;
  const response: any = yield globalApiService.saveAnchorDetails(payload);
  const dataInResponse: IResponseSaveAnchorDetails = response?.data;
  if (response && dataInResponse) {
    yield put(saveAnchorDetailsSuccess(dataInResponse));
    yield put(
      createDataSource({
        anchor_id: dataInResponse.anchorId,
        name: dataInResponse.anchorId.toString(),
        description: `Datasource of ${dataInResponse.anchorId.toString()}`,
        gstin: payload.gstin,
      })
    );
    OpenNotification('Details have been saved', 'success');
    action.redirect && action.redirect();
  }
}

function* getIndustryListWatcher(_: IAction): IterableIterator<any> {
  const response: any = yield globalApiService.getIndustryList();
  const dataInResponse: any = response?.data;
  if (response && dataInResponse) {
    const industryList: string[] = dataInResponse.result;
    yield put(getIndustryListSuccess({ industryList }));
  }
}

function* getSubIndustryListWatcher(action: IAction): IterableIterator<any> {
  const actionBody: IRequestGetSubIndustryList = action.body;
  const response: any = yield globalApiService.getSubIndustryList(actionBody);
  const dataInResponse = response?.data;
  if (response && dataInResponse) {
    const subIndustryList: string[] = dataInResponse.result;
    yield put(getSubIndustryListSuccess({ subIndustryList }));
  }
}

function* getRegisteredGSTINs(action: IAction): IterableIterator<any> {
  const actionBody: IRequestRegisteredGSTIN = action.body;
  const response: any = yield globalApiService.getRegisteredGSTINs(actionBody);
  const dataInResponse = response ? response.data.data : '';
  if (dataInResponse && dataInResponse.registeredGstins) {
    const registeredGSTINData = dataInResponse.registeredGstins;
    yield put(
      requestRegisteredGSTINsSuccess({ registeredGSTINs: registeredGSTINData })
    );
  }
}

function* requestSupportNotificationWorker(
  action: IAction
): IterableIterator<any> {
  const actionBody: IRequestSupportNotification = action.body;
  yield globalApiService.requestSupportNotification(actionBody);
  OpenNotification('Support notification has been sent!', 'success');
}

function* setWhatsappSubscriptionWorker(
  action: IAction
): IterableIterator<any> {
  const payload: IRequestWhatsappOptIn = action.body;
  const response: any = yield globalApiService.setWhatsappSubscription(payload);
  if (response && response.status === 200) {
    yield put(setWhatsappOptInSuccess());
    OpenNotification(
      'Successfully subscribed to the whatsapp notifications',
      'success'
    );
  } else {
    OpenNotification('Whatsapp subscription failed', 'error');
  }
}

export default function* AuthWatcher(): IterableIterator<any> {
  yield takeEvery(AuthActions.REQUEST_LOGIN_OTP, (action) =>
    exceptionLogger(spinForWorker(RequestOtpWorker(action)))
  );
  yield takeEvery(AuthActions.VERIFY_LOGIN_OTP, (action) =>
    exceptionLogger(spinForWorker(VerifyOtpWorker(action)))
  );
  yield takeEvery(AuthActions.CREATE_GST_ACCOUNT, (action) =>
    exceptionLogger(spinForWorker(gstAccountCreationWorker(action)))
  );
  yield takeEvery(AuthActions.GST_ACCOUNT_LOGIN, (action) =>
    exceptionLogger(spinForWorker(gstAccountLoginWorker(action)))
  );
  yield takeEvery(AuthActions.REQUEST_ANCHOR_INFO, (action) =>
    exceptionLogger(requestAnchorInfoWorker(action))
  );
  yield takeEvery(AuthActions.FIND_ANCHOR, (action) =>
    exceptionLogger(spinForWorker(requestFindAnchorWatcher(action)))
  );
  yield takeEvery(AuthActions.SAVE_ANCHOR_DETAILS, (action) =>
    exceptionLogger(spinForWorker(saveAnchorDetailsWatcher(action)))
  );
  yield takeEvery(AuthActions.GET_INDUSTRY_LIST, (action) =>
    exceptionLogger(getIndustryListWatcher(action))
  );
  yield takeEvery(AuthActions.GET_SUB_INDUSTRY_LIST, (action) =>
    exceptionLogger(getSubIndustryListWatcher(action))
  );
  yield takeEvery(AuthActions.REQUEST_REGISTERED_GSTINS, (action) =>
    exceptionLogger(spinForWorker(getRegisteredGSTINs(action)))
  );
  yield takeEvery(AuthActions.REQUEST_SUPPORT_NOTIFICATION, (action) =>
    exceptionLogger(spinForWorker(requestSupportNotificationWorker(action)))
  );
  yield takeEvery(AuthActions.LOG_OUT, (action) =>
    exceptionLogger(spinForWorker(logOutWorker(action)))
  );
  yield takeEvery(AuthActions.SET_WHATSAPP_OPT_IN, (action) =>
    exceptionLogger(spinForWorker(setWhatsappSubscriptionWorker(action)))
  );
}
