import React from 'react';
import { Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useSelector } from 'react-redux';
import ReconcileReport from '../components/ReconcileReport';


const ReconcileReportPage: React.FunctionComponent = () => {
  const reportHeader = useSelector(
    (state: any) => state.reconReducer.reportHeader
  );
  return (
    <div className='reconcilation-wrapper app__reconciliation-report'>
      <Row>
        <Col sm={24}>
          <Title level={3}> Reconcilation Report</Title>
        </Col>
        <Col sm={24}>
          <div className="flex-x">
            <div className="fs--14 pr--37">
              <span className="text-gray">Date</span>
              &nbsp;:&nbsp;
              <span className="text-black">{reportHeader && reportHeader.created_at}</span>
            </div>
            <div className="fs--14 plr--37">
              <span className="text-gray">Purchase Register</span>
              &nbsp;:&nbsp;
              <span className="text-black">{reportHeader && reportHeader.file_name}</span>
            </div>
            <div className="fs--14 plr--37">
              <span className="text-gray">Month of GSTR2A</span>
              &nbsp;:&nbsp;
              <span className="text-black">{reportHeader && reportHeader.gstr2a_month}</span>
            </div>
          </div>
          <ReconcileReport />
        </Col>
      </Row>
    </div>
  );
};

export default ReconcileReportPage;
