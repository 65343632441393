import moment from 'moment';

const DateUtils = {
  isValidDate: (dateString: string, format: string) => {
    return moment(dateString, format).isValid();
  },
  getMomentDate: (dateString: string) => {
    if(!dateString){
      return null;
    }
    let separator = '-';
    if(dateString.includes('/')){
      separator = '/';
    }
    // const defFormat = 'DD-MM-YYYY';
    const yearFormat = ['YYYY', 'MM', 'DD'].join(separator);
    const dayFormat = ['DD', 'MM', 'YYYY'].join(separator);
    const monthFormat = ['MM', 'DD', 'YYYY'].join(separator);
    let momentDate; let formatted = true;
    const yyFinder = dateString.split(separator);
    if(yyFinder[0].length > 2 && DateUtils.isValidDate(dateString, yearFormat)){
      momentDate = moment(dateString, yearFormat); 
    }else if(moment(dateString, dayFormat).isValid()){
      momentDate = moment(dateString, dayFormat);
    }else if(moment(dateString, monthFormat).isValid()){
      momentDate = moment(dateString, monthFormat);
    }else{
      formatted = false;
    }
    return { dateString: momentDate, formatted } || { dateString, formatted };
  }
};

export default DateUtils;
