import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Row, { RowProps } from 'antd/es/grid/row';
import { Form } from 'formik-antd';
import Col from 'antd/es/grid/col';
import { useDispatch, useSelector } from 'react-redux';
import Paragraph from 'antd/es/typography/Paragraph';
import { Input, Select } from 'formik-antd/es';
import StylizedButton from './stylizedButton';
import ValidationService from '../services/validationService';
import { IState } from '../models';
import {
  getIndustryList,
  getSubIndustryList,
} from '../store/actions/authActions';

export interface IProfileForm {
  industryType: string;
  subIndustryType: string;
  otherSubIndustryType: string;
}

export default function GSTINProfileForm(props: {
  onSubmitFn: (values: IProfileForm) => any;
  justifyBtn?: RowProps['justify'];
}) {
  const { onSubmitFn, justifyBtn } = props;
  const authState = useSelector((state: IState) => state.authReducer);
  const { industryList, subIndustryList } = authState;
  const storeDispatch = useDispatch();
  const [selectedIndustry, setSelectedIndustry] = useState<string>();
  const [shouldShowOtherTextBox, setShouldShowOtherTextBox] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (!authState.industryList) {
      storeDispatch(getIndustryList());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedIndustry) {
      storeDispatch(getSubIndustryList({ industry: selectedIndustry }));
    }
    // eslint-disable-next-line
  }, [selectedIndustry]);

  return (
    <Formik
      initialValues={
        {
          industryType: authState.anchorIndustryType,
          subIndustryType: authState.anchorSubIndustryType,
          otherSubIndustryType: '',
        } as IProfileForm
      }
      validate={(values: IProfileForm) => {
        const validationFieldToFunctionMap = {};
        const requiredFields = ['industryType', 'subIndustryType'];
        if (shouldShowOtherTextBox) {
          requiredFields.push('otherSubIndustryType');
        }
        return ValidationService.validateFields(
          values,
          validationFieldToFunctionMap,
          requiredFields
        );
      }}
      onSubmit={onSubmitFn}
      validateOnChange
      validateOnBlur
      render={({ handleSubmit, setFieldValue }) => {
        return (
          <Form>
            <Paragraph strong>Industry Type</Paragraph>
            <Form.Item
              name="industryType"
              hasFeedback
              showValidateSuccess
              required
            >
              <Select
                name="industryType"
                placeholder="Industry Type"
                showSearch
                size="large"
                showArrow={false}
                onSelect={(val) => {
                  setSelectedIndustry(val);
                  setFieldValue('subIndustryType', null, true);
                  setShouldShowOtherTextBox(false);
                }}
              >
                {industryList &&
                  industryList.map((el) => (
                    <Select.Option value={el} key={el}>
                      {el}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            {subIndustryList && (
              <div>
                <Paragraph strong>Sub Industry Type</Paragraph>
                <Form.Item
                  name="subIndustryType"
                  hasFeedback
                  showValidateSuccess
                  required
                >
                  <Select
                    name="subIndustryType"
                    placeholder="Sub Industry Type"
                    showSearch
                    size="large"
                    disabled={!authState.industryList}
                    showArrow={false}
                    onChange={(val: string) => {
                      if (val.toLowerCase() === 'others') {
                        setShouldShowOtherTextBox(true);
                      } else {
                        setShouldShowOtherTextBox(false);
                      }
                    }}
                  >
                    {subIndustryList.map((el) => (
                      <Select.Option value={el} key={el}>
                        {el}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )}

            {shouldShowOtherTextBox && (
              <div>
                <Paragraph strong>Type of the sub industry</Paragraph>
                <Form.Item
                  name="otherSubIndustryType"
                  hasFeedback
                  showValidateSuccess
                >
                  <Input
                    name="otherSubIndustryType"
                    placeholder="Other Industry Type"
                    size="large"
                  />
                </Form.Item>
              </div>
            )}

            <Row justify={justifyBtn || 'center'}>
              <Col>
                <StylizedButton
                  textInButton="Proceed"
                  onClickFn={handleSubmit}
                  size="middle"
                />
              </Col>
            </Row>
          </Form>
        );
      }}
    />
  );
}
