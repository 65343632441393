/* eslint-disable no-console */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { IAnalyticsZohoAccesToken, IZohoContactDetails } from '../typings';
import { EerrorHandler, EsuccessHandler } from './apiService';
import appConfig from '../config';

export class AnalyticsService {
  private readonly baseUrl: any;

  private axiosInstance: AxiosInstance;

  constructor() {
    this.baseUrl = appConfig.zohoAnalyticsEndpoint;
    this.axiosInstance = axios.create({
      baseURL: this.baseUrl
    });
    // this.axiosInstance.defaults.headers.post.Origin = 'http://kyss.local:3000';
    this.axiosInstance.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => EsuccessHandler(response),
      (error: AxiosError) => {
        const actionableModalTriggerStrings = [
          'login',
          'register',
          'id is non existing',
        ];
        const errorMessage = error?.response?.data?.message;
        const actionable =
          errorMessage &&
          actionableModalTriggerStrings.some((triggerWord: string) =>
            errorMessage.includes(triggerWord)
          );
        return EerrorHandler(error, actionable);
      }
    );
  }

  public setUserToken({ zohoToken }: IAnalyticsZohoAccesToken): boolean {
    return zohoToken === this as any;
    // try {
    //   this.axiosInstance.defaults.headers.post.Origin = 'http://kyss.local:3000';
    //   // this.axiosInstance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    //   this.axiosInstance.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
    //   return true;
    // } catch (err) {
    //   console.log(err);
    //   return false;
    // }
  }

  public setAnalyticsContactZoho(data: IZohoContactDetails) {
    // const config = {
    //   headers:{
    //     Origin: 'http://kyss.local:3000',
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   }
    // };
    return this.axiosInstance.post('/contacts', data)
      .then((resp) => console.log(resp))
      .catch(errorp => {
        console.log('Error in POST ========>', errorp);
      });
  }
}

export const globalAnalyticsService = new AnalyticsService();
