import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { LoadingOutlined } from '@ant-design/icons/lib';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import Spin from 'antd/es/spin';
import Modal from 'antd/es/modal';
import { IReportFileDownloadMetadata, IReportTracker, IState } from '../models';
import {
  requestClearReportFileMetadata,
  requestReportFiles,
  requestReportHistory,
} from '../store/actions/reportsActions';
import { THEME } from '../constants/theme';
import StylizedButton from './stylizedButton';

enum ReportTrackerStatuses {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export default function ReportList() {
  const storeDispatch = useDispatch();
  const reportsState = useSelector((state: IState) => state.reportsReducer);
  const { reportHistory, currentReportDownloadMetadata } = reportsState;
  const handleCancel = () => storeDispatch(requestClearReportFileMetadata());

  const ReportCard = (props: { reportTrackerObj: IReportTracker }) => {
    const { reportTrackerObj } = props;
    const initiatedDate = new Date(
      reportTrackerObj.initiatedAt
    ).toLocaleString();
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
      <Card
        key={reportTrackerObj.id}
        style={{ boxShadow: THEME.SHADOW, marginTop: '20px' }}
        hoverable
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Title level={4} style={{ marginBottom: 0 }}>Supplier Report</Title>
          <Text style={{ marginLeft: 10 }}>{`Generated on ${initiatedDate}`}</Text>
          {reportTrackerObj.status === ReportTrackerStatuses.FAILED && (
            <Text>The report generation has failed.</Text>
          )}
          {reportTrackerObj.status === ReportTrackerStatuses.PENDING && (
            <div>
              <Spin indicator={antIcon} />
              <Text style={{ paddingLeft: '10px' }}>In Progress</Text>
            </div>
          )}
          {reportTrackerObj.status === ReportTrackerStatuses.SUCCESS && (
            <Row justify="end">
              <Col>
                <StylizedButton
                  onClickFn={() => {
                    storeDispatch(
                      requestReportFiles({ reportTrackerId: reportTrackerObj.id })
                    );
                  }}
                  customStyles={{backgroundColor:'#462E90'}}
                  textInButton="Download"
                  size="middle"
                />
              </Col>
            </Row>
          )}
        </div>
      </Card>
    );
  };

  const ReportDownloadModal = (props: {
    filesOfReport: IReportFileDownloadMetadata[];
  }) => {
    const { filesOfReport } = props;
    return (
      <Modal
        title="Files to download"
        visible={!!filesOfReport}
        onCancel={handleCancel}
        footer={null}
      >
        <ul>
          {filesOfReport.map((fileMetadata) => {
            return (
              <li key={fileMetadata.fileName}>
                <a href={fileMetadata.fileUrl}>{fileMetadata.fileName}</a>
              </li>
            );
          })}
        </ul>
      </Modal>
    );
  };

  useEffect(() => {
    storeDispatch(
      requestReportHistory({ reportName: 'ANCHOR_SUPPLIER_REPORT' })
    );
  }, [storeDispatch]);

  return (
    <div>
      {currentReportDownloadMetadata && currentReportDownloadMetadata.files && (
        <ReportDownloadModal
          filesOfReport={currentReportDownloadMetadata.files}
        />
      )}
      {reportHistory &&
        reportHistory.map((el) => {
          return <ReportCard reportTrackerObj={el} key={el.id} />;
        })}
    </div>
  );
}
