import React, { useEffect, useState } from 'react';
import { Button, Col, Layout, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import { IState } from '../models';
import {
  requestOtpForLoginAction,
  verifyOtp,
} from '../store/actions/authActions';
import StylizedButton from './stylizedButton';
import { ROUTES } from '../constants/routePaths';
import { IRequestVerifyOtp } from '../models/api/authModels';

const OTP_RESEND_DISABLE_TIMEOUT_MS = 10000;
const { Content } = Layout;

export default function OtpForm() {
  const storeDispatch = useDispatch();
  const history = useHistory();
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);
  const authState = useSelector((state: IState) => state.authReducer);
  const { phoneNumber, otpError, otp } = authState;

  const [otpp, setotpp] = useState(otp);

  useEffect(() => {
    setTimeout(() => setIsResendDisabled(false), OTP_RESEND_DISABLE_TIMEOUT_MS);
  }, []);

  function resendOtpAndDisableBtn() {
    storeDispatch(
      requestOtpForLoginAction({ phoneNumber, register: false }, () => {})
    );
    setTimeout(() => setIsResendDisabled(true), OTP_RESEND_DISABLE_TIMEOUT_MS);
  }

  const submitOtp = () => {
    if(otpp.length < 4){
      return;
    }
    storeDispatch(
      verifyOtp(
        {
          phoneNumber,
          otp: parseInt(otpp, 10),
        } as IRequestVerifyOtp,
        () => history.push(ROUTES.GET_USER_GSTIN)
      )
    );
  };
  
  return (
    <Layout>
      <Content>
        <Row>
          <div className="flex-y otp-wrapper" onKeyDown={(e) => e?.key === 'Enter' && submitOtp()} role='presentation'>
            <OtpInput
              value={otpp}
              onChange={(val: string) => setotpp(val)}
              numInputs={4}
              className="otp-input mt--10"
              hasErrored={otpError}
              isInputNum
              errorStyle="input--error"
            />
          </div>
        </Row>
        <Row justify="center" align="middle" style={{ padding: '10px' }}>
          <Col>
            <Button
              type="dashed"
              disabled={isResendDisabled}
              shape="round"
              size="small"
              onClick={resendOtpAndDisableBtn}
            >
              Resend OTP
            </Button>
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          style={{ paddingTop: '100px' }}
        >
          <Col>
            <StylizedButton
              disabled={!(otpp && otpp.length === 4)}
              textInButton="Verify OTP"
              onClickFn={submitOtp}
              size="large"
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
