import React from 'react';
import { Button } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ButtonProps } from 'antd/es/button';
import { THEME_ORANGE } from '../constants/theme';

export default function StylizedButton(props: {
  textInButton: string;
  onClickFn?: () => void;
  size: SizeType;
  shape?: ButtonProps['shape'];
  customStyles?: React.CSSProperties;
  type?: ButtonProps['type'];
  disabled?: ButtonProps['disabled'];
}) {
  const {
    textInButton,
    onClickFn,
    size,
    shape,
    customStyles,
    type,
    disabled,
  } = props;
  const defaultStylesForBtn: any = {
    backgroundColor: THEME_ORANGE.PRIMARY_COLOR,
    border: '0px',
    boxShadow: THEME_ORANGE.SHADOW
  };
  if (size === 'large') {
    defaultStylesForBtn.width = '150px';
    defaultStylesForBtn.fontWeight = 'bold';
  }
  return (
    <Button
      type={type || 'primary'}
      htmlType="submit"
      style={{ ...defaultStylesForBtn, ...customStyles }}
      onClick={onClickFn}
      shape={shape || 'round'}
      size={size}
      disabled={disabled}
    >
      {textInButton}
    </Button>
  );
}
