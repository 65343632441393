import React from 'react';
import { Row, Col, Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import RecornReport from '../components/RecornReport';
import ReportHistory from '../components/reportList';

const queryString = require('query-string');

const { TabPane } = Tabs;

const DownloadCenterPage: React.FunctionComponent = () => {
  const history = useHistory();
  const urlParams = queryString.parse(history.location.search) || {};
  return (
    <div className="download-wrapper app_download-center">
      <Row>
        <Col sm={24}>
          <Title level={3}>Download Center</Title>
        </Col>
        <Col sm={24} className='page-subtitle  mb--30'>
          Access your Supplier and Recon Report.
        </Col>
      </Row>
      <Row>
        <Col sm={24}>
          <Tabs defaultActiveKey={urlParams.reconreport ? '2' : '1'} tabBarStyle={{ fontSize: '120px' }}>
            <TabPane tab="GST Report" key="1">
              <ReportHistory />
            </TabPane>
            <TabPane tab="Recon Report" key="2">
              <RecornReport />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default DownloadCenterPage;
