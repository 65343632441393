/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Upload, Button } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useDispatch, useSelector } from 'react-redux';
import OpenNotification from '../../utils/notification';
import { file, loader,  correctfile, genUploadMswordIcon, genSupCheckIcon } from '../../utils/constants';
import { uploadPr, nextFilingDate } from '../../store/actions/supplierReportActions';
import {UploadStatus} from '../../typings';

const { Dragger } = Upload;

interface IUploadPRV2 {
  setActiveStep?: any;
}

const UploadPRV2: React.FunctionComponent<IUploadPRV2> = ({ setActiveStep }) => {
  // const [isUploading, setIsUploading] = useState<boolean>(false);
  const [onUpload, setOnUpload] = useState<boolean>(false);
  const [isValidationError, setIsValidationError] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  useEffect(() => {
    setOnUpload(false);
    setIsValidationError(false);
    setFileList([]);
  }, [setActiveStep]); // <-- empty dependency array
  const dispatch = useDispatch();
  const uploading = useSelector((state: any) => {
    return state.supplierReducer.uploading;
  });
  const uploadStatus = useSelector((state: any) => state.supplierReducer.uploadStatus);
  const dataSourceName = useSelector((state: any) => state.authReducer.dataSourceName);
  const resetRecon = useSelector((state: any) => state.supplierReducer.resetSupplier);

  if (onUpload && !uploading && uploadStatus === UploadStatus.FAIL) {
    setIsValidationError(true);
    setOnUpload(false);
  } else if (isValidationError && uploadStatus !== UploadStatus.FAIL) {
    setIsValidationError(false);
  }

  const removeFile = (f: UploadFile) => {
    setFileList([]);
    setIsValidationError(false);
    return true;
  };

  const isUploadSuccess = () => {
    return uploadStatus === UploadStatus.SUCCESS;
  };

  const UploadDraggerComponent = () => {
    return (
      <Dragger
        style={{ border: '1px solid #ffffff', backgroundColor: '#ffffff' }}
        accept=".csv, text/csv"
        openFileDialogOnClick={!(fileList?.length > 0 && !uploading)}
        className={onUpload ? 'none-file  ' : ''}
        multiple={false}
        disabled={uploading}
        beforeUpload={(ffile) => {
          if (!['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(ffile.type)) {
            OpenNotification('Only csv files are allowed', 'error');
            return false;
          }
          if (fileList.length < 1) {
            setFileList([...fileList, ffile]);
          } else {
            OpenNotification(
              'Only one file can be uploaded. ' +
              'Please remove file previously uploaded from this screen.',
              'error'
            );
          }
          return false;
        }}
        fileList={fileList}
        onRemove={removeFile}
      >
        <p className="pl--50 pr--50 pb--20">
          {isValidationError ? (
            <p style={{ color: 'red' }} className="title fs--20">
              Validation failed. Please recheck and upload the file
            </p>
          ) : (
            <p className="title fs--20">
              Upload your Supplier file in CSV Format
            </p>
          )}
        </p>
        <div className={(fileList && fileList.length > 0 && onUpload) ? 'upload-success image-wrapper' : 'image-wrapper'} style={{ minHeight:'85px' }}>
          {uploading ? (
            <img className="rotate" src={loader} alt="img" />
          ) : fileList && fileList.length > 0 && onUpload && !isValidationError ? (
            <>
              <img
                src={file}
                style={{ fontSize: '50px', height:'68px' }}
                alt=""
              />
              <img
                src={genSupCheckIcon}
                style={{ top: '-20px', left:'-30px', position:'relative' }}
                alt=""
              />
           
            </>
          ) : (
            disableUploadNextButton() ? (
              <img
                src={genUploadMswordIcon}
                style={{ fontSize: '50px' }}
                alt="img"
              />
            ):(
              <>
                <img
                  src={file}
                  style={{ fontSize: '50px' }}
                  alt="img"
                />
                <div>{fileList[0]?.name}</div>
              </>
            )
          )}
        </div>
        {(isUploadSuccess() && !resetRecon) ? (
          <>
            <p>
              {fileList[0]?.name}
            </p>
            <br />
            <br />
          
            <img
              src={correctfile}

              alt="img"
            />
          </>
        ) : onUpload ? (
          <p>
            Your file,
            {fileList[0]?.name}
            {' '}
            is uploading. Please wait.
          </p>
        ) : (fileList?.length && !uploading) ?
          (
            <p className="subtitle fs--16" onKeyDown={() => removeFile(fileList[0])} onClick={() => removeFile(fileList[0])} role="presentation">
              <span style={{ color: '#4532BF' }}>Remove file</span>
            </p>
          ) : (
            <p className="subtitle fs--16" style={{ color: '#4532BF' }}>
              Drag and drop or browse your files, or 
              {' '}
              <span style={{ color:'#462E90' }}>browse</span>
            </p>
          )}
      </Dragger>
    );
  };

  const disableUploadNextButton = (): boolean => {
    return (!(fileList && fileList.length > 0) || isValidationError || uploading);
  };

  return (
    <div className="flex-y align-center download-gst-r2a-wrapper gen_upload" style={{ minHeight: '432px' }}>

      <div style={{ width: '800px', height: '300px' }}>
        <UploadDraggerComponent />
      </div>
      {!(fileList && fileList.length > 0 && onUpload) ? (
        <Button
          disabled={disableUploadNextButton()}
          style={{
            opacity:
              disableUploadNextButton() ? '0.4' : '1',
            margin: '50px 0px ', backgroundColor:'#462E90', color:'#fff', borderRadius:'5px'
          }}
          className="next-btn flex-x align-center center fs--16 lh--20"
          onClick={() => {
            const formData = new FormData();
            formData.append('file', fileList[0]);
            formData.append('description', 'Uploaded file at 22/10/2021');
            // formData.append('data_source_name', "ANCCBGUSZREC2");
            formData.append('data_source_name', dataSourceName);
            
            dispatch(uploadPr(formData));
            // removeFile(fileList[0]);
            setOnUpload(true);
          }}
        >
          {!disableUploadNextButton()?'UPLOAD':'Select File'}
          
        </Button>
      ) : (
        <Button
          className="next-btn flex-x align-center center fs--16 lh--20"
          onClick={() => {
            dispatch(nextFilingDate());
          }}
          style={{ backgroundColor:'#462E90', margin: '50px 0px ', color:'#fff', borderRadius:'5px' }}
        >
          SELECT FILING DATE
        </Button>
      )}
    </div>
  );
};

export default UploadPRV2;
