
import React from 'react';

import { Col, Row, Typography, Modal, Spin } from 'antd';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Banner from '../components/banner';
import JitFinLogo from '../components/logo';
import GSTINForm, { IGSTINFormFields } from '../components/GSTINForm';
import { requestFindAnchor } from '../store/actions/authActions';
import { ROUTES } from '../constants/routePaths';
import SupportComponent from '../components/supportComponent';
import StylizedButton from '../components/stylizedButton';
import { SaveUTMSourceForAnalyticsBegin } from '../store/actions/analytics';
import { Carousel } from 'antd';
import { url } from 'inspector';
import {carouselImg1} from '../utils/constants';
import {carouselImg2} from '../utils/constants';
import {carouselImg3, carouselImg4} from '../utils/constants';
import { KYSSmobileLogo } from '../utils/constants';
import TermsAndConditionsPage from './termsAndConditionsPage';


const contentStyle: React.CSSProperties = {
  height: '100vh',
  color: 'white',
  lineHeight: '30px',
  textAlign: 'center',
  background: '#462e90',
};


const JitfinLogoRow = () => (
    <Row style={{ height: '10vh' }} align="middle" justify="end">
      <Col style={{paddingTop:'-20px',paddingRight:'50px'}}>
        <JitFinLogo height="34.5px" width="140.5px" />
      </Col>
    </Row>
  );

  const JitfinMobileLogo = ()=> (
    <Row className='jitfinMoblogo' style={{paddingTop:'20px'}} align="middle" justify="end">
    <Col style={{paddingTop:'-20px',paddingRight:'20px'}}>
      <img className= "mobile_img_reg" src={KYSSmobileLogo}></img>
    </Col>
  </Row>
  );
const onChange = (currentSlide: number) => {
    console.log(currentSlide);
};
const RegisterRow = (props: {
    onSubmitFn: (body: IGSTINFormFields) => any;
  }) => {
    const { onSubmitFn } = props;
    return (
      <Row align="middle" className='Login-box-row'>
        <Col className='login-box-content' style={{ minHeight: '324px', maxWidth: '501px'}} xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
        <div>
            <h2 className='welcome-title'>Let's get started!</h2>
            <p className='tagline-kyss'>
              Please enter your GSTIN number to register your account.
            </p>
        </div>
        <GSTINForm
            onSubmitFn={onSubmitFn}
            justifyBtn="start"
            fetchRegisteredGSTINs={false}
          />
        </Col>
    </Row>
    );
    
};

export default function SignUpPage() {
    const storeDispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const onSignUp = (body: IGSTINFormFields) => {
      const queryParams = search || '';
      storeDispatch(
        requestFindAnchor(body, () => history.push({
          pathname: ROUTES.GET_ANCHOR_DETAILS,
          search: queryParams
        }))
      );
      if(queryParams){
        storeDispatch(
          SaveUTMSourceForAnalyticsBegin(queryParams)
        );
      }
    };
    const [targetUrl, setTargetUrl] = React.useState('');
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [triggerForPrivacyHeadingScroll, setTriggerForPrivacyHeadingScroll] = React.useState(false);
    const closeModal = () => {
      setIsModalOpen(false)
      setTriggerForPrivacyHeadingScroll(false)

    };
    const captureClickForInModalView =
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, trigger_scroll: boolean) => {
      e.preventDefault();
    
      setIsModalOpen(true)
      if(trigger_scroll){
        setTriggerForPrivacyHeadingScroll(true)
      }
    };
    return(
        <div className='signup_row'>
            <div className='signup_column_1' id='signup_img'>
              <div className='carouseldiv'>
                <JitfinMobileLogo />
                <Carousel afterChange={onChange} className='TextArea' autoplay>
                  <Col span={24}>
                    <img className='carousel_img2' src={carouselImg4} style={{marginLeft: '2rem'}}/>
                    <p className='carousel-text-1'>Create your e-Invoice</p>
                    <br />
                    <p className='carasoul-tag-line-1'>Streamline your billing process with our easy-to-use e-Invoice tool</p>
                  </Col>
                  <Col span={24}>
                    <img className='carousel_img1' src={carouselImg1}/>
                    <p className='carousel-text-1'>Track Supplier GST Filings</p>
                    <br />
                    <p className='carasoul-tag-line-1'>Save time, energy and money by getting real-time GST filing information of your suppliers to keep track of your tax compliance.</p>
                  </Col>
                  <Col span={24}>
                    <img className='carousel_img2' src={carouselImg2}/>
                    <p className='carousel-text-2'>Reconcile GST Transactions</p>
                    <br />
                    <p className='carasoul-tag-line-2'>Speed up the GSTR-2A reconcilation process and improve the accuracy of claims for ITC, reducing mistakes and errors by 32%.</p>
                  </Col>
                  <Col span={24}>
                    <div>
                      <img className='carousel_img3' src={carouselImg3}/>
                    </div>
                    <p className='carousel-text-3'>Claim Full Input Tax Credit</p>
                    <p className='carasoul-tag-line-3'>Seamlessly claim 100% input tax credit on every transaction. Lower your tax liability reduce administrative burden and increase your profitibility.</p>
                  </Col>
                </Carousel>
              </div>
            </div>
            <div className='signup_column_2'>
              <div className='Jitfinlogo_reg'><JitfinLogoRow /></div>
                <div className='signup_box'>
                    <RegisterRow onSubmitFn={onSignUp} />
                    <div className='already-account'>
                        <Col className='already-have-acc' xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
                            <Typography.Text className='already-acc-text'>
                            Already have an account?
                            </Typography.Text>
                            <a href='' className='login-here-link' style={{lineHeight: 'normal',textDecorationLine:'underline'}} onClick={() => history.push(ROUTES.LOGIN)}>Login here</a>
                        </Col>
                    </div>
                    <div className='product_info_reg'>
                    <Row className='' align="middle" >
                        <Col className='need_HELP_text--hide' xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
                            <SupportComponent isLogin />
                        </Col>
                        <Col className='need_HELP_text2 need_HELP_text2--mb' xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:3 }}>
                        <a 
                            onClick={(e) => captureClickForInModalView(e, false)}
                            href={ROUTES.TERMS_AND_CONDITIONS}
                          >
                            Terms & Conditions
                          </a>
                          <span className='text'> & </span>
                          <a 
                              onClick={(e) => captureClickForInModalView(e, true)}
                              href={ROUTES.TERMS_AND_CONDITIONS}
                          >
                            Privacy Policy
                          </a>
                        </Col>
                    </Row>
                        <Row className='help_link_reg' align="middle">
                            <Col className='help_btn_link' xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
                                <SupportComponent isLogin />
                            </Col>
                            <Col className='about_product' xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
                                A Product of JiT Finco
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Modal
              className="terms_privacy_modal"
              title=""
              centered
              visible={isModalOpen}
              onOk={() => closeModal()}
              onCancel={() => closeModal()}
              footer={[
                <StylizedButton
                  key='ok'
                  textInButton="Ok"
                  size="large"
                  customStyles={{margin:"10px 0"}}
                  onClickFn={() => closeModal()}
                />]}
          
            >
            <section>
            <TermsAndConditionsPage scrollToPrivacyPolicySection={triggerForPrivacyHeadingScroll}/>
            </section>
          </Modal>
          </div>

     

    );
}
