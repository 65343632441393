/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

const ZohoEndPoint = () => {

  const getPropertiesFromURL = () => {
    let props = {};
    // let propertyString = window.location.hash || window.location.search;
    // if (propertyString) {
    //   propertyString = typeof propertyString === 'string' ? propertyString.slice(1) : '';
    //   if (propertyString) {
    //     propertyString.split('&').forEach((prop) => {
    //       const key = prop.split('=')[0],
    //       const value = prop.split('=')[1];
    //       props[key] = value;
    //     });
    //   }
    // }
    return props;
  };

  return(
    <>
      <div>zoho endpoint confirmed</div>
    </>
  );
};

export default ZohoEndPoint;