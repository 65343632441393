import * as Sentry from '@sentry/browser';
import AppConfig from '../config';

const SENTRY_DSN = 'https://056b7fb5a1044b2ca567fbf80a4c8dba@o408126.ingest.sentry.io/5283451';
const ENVIRONMENT = AppConfig.environment.toLowerCase();

// eslint-disable-next-line import/prefer-default-export
export function ConfigureSentry() {
  if (ENVIRONMENT === 'qa') {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: 'QA'
    });
  } else if (ENVIRONMENT === 'staging') {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: 'STAGING'
    });
  } else if (ENVIRONMENT === 'prod') {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: 'PROD'
    });
  }
}
