import { call, put, takeEvery } from 'redux-saga/effects';
import { ReconActions } from '../actions/ReconcilationActions';
import { exceptionLogger } from './utils';
import { IAction } from '../../models';

import { globalApiService } from '../../services/apiService';
import OpenNotification from '../../utils/notification';
import { ROUTES } from '../../constants/routePaths';
import { SupplierReportActions } from '../actions/supplierReportActions';


function* supplireUpload(action: IAction): any {
  try {
    const payload: any = action.body;
    const result: any = yield globalApiService.supplierUploadPurchaseData(payload);
    yield put({ type: SupplierReportActions.SUPPLIRE_SUCCESS, body: result });
    OpenNotification('Upload successfully!', 'success');
    yield put({ type: SupplierReportActions.SUPPLIRE_SET_PAGE_STATE, body: { sub_page_id: 3 } });
    const payloadPs={
      page: 'SUPPLIER_REPORT_DUE_DATE_SELECTION'
    }
    //const data: any = yield globalApiService.supplierReoprtSetPageStatus(payloadPs);
  } catch (err) {
    // OpenNotification('Upload error, please try again', 'error');
    yield put({ type: SupplierReportActions.SUPPLIRE_FAILURE });
  }
}

function* supplierGenerateReoprt(action: IAction): any {
  try {
    const payload: any = action.body;
    const result: any = yield globalApiService.supplierGenerateReoprt(payload);
    const payloadPs={
      page: 'SUPPLIER_FILE_UPLOAD'
    }
   // const data: any = yield globalApiService.supplierReoprtSetPageStatus(payloadPs);
    
    OpenNotification('Request to generate report registered!', 'success');
  } catch (err) {
    // OpenNotification('Upload error, please try again', 'error');
    yield put({ type: SupplierReportActions.SUPPLIRE_FAILURE });
  }
}

export default function* supplierWatcher(): IterableIterator<any> {

  yield takeEvery(SupplierReportActions.SUPPLIRE_UPLOAD, (action) =>
    exceptionLogger(supplireUpload(action))
  );

  yield takeEvery(SupplierReportActions.SUPPLIRE_GST_GENERATE, (action) =>
    exceptionLogger(supplierGenerateReoprt(action))
  );
}
