import { IAction } from '../../models';
import { IZohoContactDetails } from '../../typings';

export enum AnalyticsActions {
  INIT_ANALYTICS_ZOHO_PAGESENSE = 'INIT_ANALYTICS_ZOHO_PAGESENSE',
  ZOHO_CRM_CONTACT_BEGIN = 'ZOHO_CRM_CONTACT_BEGIN',
  ZOHO_CRM_CONTACT_SUCCESS = 'ZOHO_CRM_CONTACT_SUCCESS',
  ZOHO_CRM_CONTACT_FAILURE = 'ZOHO_CRM_CONTACT_FAILURE',
  ZOHO_PAGESENSE_SET_TOKEN_BEGIN = 'ZOHO_PAGESENSE_SET_TOKEN_BEGIN',
  ZOHO_PAGESENSE_SET_TOKEN_SUCCESS = 'ZOHO_PAGESENSE_SET_TOKEN_SUCCESS',
  ZOHO_PAGESENSE_SET_TOKEN_FAILURE = 'ZOHO_PAGESENSE_SET_TOKEN_FAILURE',
  ZOHO_CRM_SAVE_UTM_BEGIN = 'ZOHO_CRM_SAVE_UTM_BEGIN',
  ZOHO_CRM_SAVE_UTM_SUCCESS = 'ZOHO_CRM_SAVE_UTM_SUCCESS',
  ZOHO_CRM_SAVE_UTM_FAILURE = 'ZOHO_CRM_SAVE_UTM_FAILURE',
}

export function InitAnalyticsZoho(): IAction {
  return {
    type: AnalyticsActions.INIT_ANALYTICS_ZOHO_PAGESENSE
  };
}

export function ZohoAnalyticsSignupGstBegin(data: IZohoContactDetails): IAction {
  return {
    type: AnalyticsActions.ZOHO_CRM_CONTACT_BEGIN,
    body: data,
  };
}

export function ZohoAnalyticsSignupGstSuccess(data: {payload: IZohoContactDetails}): IAction {
  return {
    type: AnalyticsActions.ZOHO_CRM_CONTACT_SUCCESS,
    body: data,
  };
}

// eslint-disable-next-line max-len
export function ZohoAnalyticsSignupGstFailure(data: {payload: IZohoContactDetails, error: any}): IAction {
  return {
    type: AnalyticsActions.ZOHO_CRM_CONTACT_FAILURE,
    body: data,
  };
}

export function SaveUTMSourceForAnalyticsBegin(payload:any): IAction{
  return {
    type: AnalyticsActions.ZOHO_CRM_SAVE_UTM_BEGIN,
    body: payload
  };
}

// eslint-disable-next-line max-len
export function SaveUTMSourceForAnalyticsSuccess(): IAction {
  return {
    type: AnalyticsActions.ZOHO_CRM_SAVE_UTM_SUCCESS,
  };
}

export function SaveUTMSourceForAnalyticsError(payload: {status: boolean, error: any}): IAction {
  return {
    type: AnalyticsActions.ZOHO_CRM_SAVE_UTM_FAILURE,
    body: payload
  };
}