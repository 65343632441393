import React from 'react';

import { Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { useSelector } from 'react-redux';
import OtpForm from '../components/otpForm';
import BackToLoginBtn from '../components/backToLogin';
import AppConfig from '../config';
import { IState } from '../models';

const { enableAnalytics } = AppConfig;

export default function VerifyOtpPage() {

  const authState = useSelector((state: IState) => state.authReducer);
  const zohoIdentity = (phoneNumber: string) => {
    (window as any).pagesense = (window as any).pagesense || [];
    (window as any).pagesense.push(['identifyUser', phoneNumber]);
  };
  (enableAnalytics && !!authState?.phoneNumber) && zohoIdentity(authState.phoneNumber);
  return (
    <div>
      <Row
        style={{ height: '5vh', paddingRight: '10px' }}
        justify="end"
        align="middle"
      >
        <BackToLoginBtn />
      </Row>
      <Row justify="center" style={{ height: '40vh', width: '100vw' }}>
        <Col style={{ paddingTop: '10%' }}>
          <Title level={1} style={{ textAlign: 'center' }}>
            OTP Verification
          </Title>
          <Paragraph style={{ textAlign: 'center' }}>
            Please enter the OTP (One Time Password) sent to your Phone and
            Email registered with KYSS.
          </Paragraph>
        </Col>
      </Row>
      <Row justify="center" style={{ paddingTop: '1vh' }}>
        <Col>
          <OtpForm />
        </Col>
      </Row>
    </div>
  );
}
