/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import Row from 'antd/es/grid/row';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import { downArr } from '../utils/constants';
import {
  next,
  getMappings,
  addMappings,
  getItrFileUpload,
  processItrFileUpload,
  getDropdowns
} from '../store/actions/ReconcilationActions';

interface IMappingPR {
  setActiveStep?: any;
}

const MappingPR: React.FunctionComponent<IMappingPR> = () => {
  const [mappingsState, setMappingState] = useState<any>({} as {[key:string]:string});
  const [data, setData] = useState({} as any);
  const dispatch = useDispatch();
  const { mappings, mappingsFixed } = useSelector((state: any) => ({ mappings: state.reconReducer.mappings, mappingsFixed: state.reconReducer.mappingsFixed }));
  const dropdowns = useSelector((state: any) => state.reconReducer?.itrFileUpload?.file_columns);
  let periodDropdowns: {[str:string]: number}[] = useSelector((state: any) => state.reconReducer?.dropdowns?.report_periods);
  const fallBackPeriods: any[] = [
    {
      'Current month': 1
    }
  ];

  periodDropdowns = periodDropdowns?.length ? periodDropdowns : [...fallBackPeriods];

  useEffect(() => {
    if(!periodDropdowns?.length){
      dispatch(getDropdowns());
    }
    setMappingState(mappings);
  }, [mappings]);

  useEffect(() => {
    dispatch(getMappings());
    dispatch(getItrFileUpload());
  }, [dispatch]);

  useEffect(() => {
    if(mappings && mappingsFixed){
      dispatch(processItrFileUpload());
      dispatch(next());
    }
  });

  const fields = ['central_tax_mapping',
    'cess_mapping',
    'gstin_mapping',
    'integrated_tax_mapping',
    'invoice_date_mapping',
    'invoice_number_mapping',
    'invoice_value_mapping',
    'name_mapping',
    'state_tax_mapping',
    'tax_rate_mapping',
    'taxable_value_mapping'];
  const prFields = [...fields, 'period'];


  const returnIfPresent = (pMappings: { [key: string]: string }, property: string, options: string) => {
    if (pMappings && options) {
      return options.split(',').includes(pMappings[property]) ? pMappings[property] : undefined;
    }
    return undefined;
  };
  const unSelectedFieldNames:string[] = dropdowns?.split(',');

  const isNextEnabled = () => {
    if(!mappingsState || !unSelectedFieldNames || !fields.every(key => unSelectedFieldNames.includes(mappingsState[key]))){
      return false;
    }
    const combined = { ...mappingsState, ...data };
    return prFields.every(key => Object.keys(combined).includes(key));
  };


  const onSelectFieldName = (name: string) => {
    const idx = unSelectedFieldNames.findIndex(selected => selected === name );
    unSelectedFieldNames.splice(idx, 1);
  };

  const onUnselectFieldName = (name: string) => {
    unSelectedFieldNames.push(name);
  };

  return (
    <div className="flex-y align-center mapping-gst-r2a-wrapper">
      <div className="flex-x align-center pa--10 border-bottom ptb--25 pa--15">
        <div className="pr--25">
          <div className="title fs--16">
            {' '}
            Select return period for which Recon has to be performed
          </div>
        </div>
        <div className="white-selector">
          <Select
            id="recon-period"
            key="recon-period"
            onChange={(e) => {
              setData({ ...data, period: e });
              setMappingState({
                ...mappingsState,
                period: e
              });
            }}
          >
            {
              periodDropdowns.map(dropdown => 
                (
                  <Select.Option key={Object.values(dropdown)[0]} value={Object.values(dropdown)[0]}>
                    {Object.keys(dropdown)[0]}
                  </Select.Option>
                )
              )
            }
            <Select.Option value={3}>3 month</Select.Option>
            <Select.Option value={6}>6 month</Select.Option>
            <Select.Option value={9}>9 month</Select.Option>
            <Select.Option value={12}>12 month</Select.Option>
          </Select>
        </div>
      </div>
      <hr />
      <div className="flex-y">
        <div className="title flex-x center fs--20 pb--5">
          Map your PR Columns with KYSS Columns
        </div>
        <br />
        <div className="subtitle flex-x center fs--16  pa--15">
          Previous mapping which you did on is shown below.
          <br />
          You can modify if the PR template has changed.
        </div>
      </div>

      <div style={{ maxWidth: '900px', width: '100%' }}>
        <Row className="pa--10  border-radius--5 flex align-center row">
          <div className="subtitle">
            GSTIN of the Supplier is in the column named.
          </div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="supplier-gstin"
              key="supplier-gstin"
              onChange={(e) => {
                setData({ ...data, gstin_mapping: e });
                setMappingState({
                  ...mappingsState,
                  gstin_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'gstin_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row-gray">
          <div className="subtitle">
            Name of the Supplier is in the column named
          </div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="supplier-name"
              key="supplier-name"
              onChange={(e) => {
                setData({ ...data, name_mapping: e });
                setMappingState({
                  ...mappingsState,
                  name_mapping: e
                });
              }}

              value={returnIfPresent(mappingsState, 'name_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row">
          <div className="subtitle">Date of Invoice is in the column named</div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="invoice-date"
              key="invoice-date"
              onChange={(e) => {
                setData({ ...data, invoice_date_mapping: e });
                setMappingState({
                  ...mappingsState,
                  invoice_date_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'invoice_date_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row-gray">
          <div className="subtitle">Invoice number is in the column named</div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="invoice-number"
              key="invoice-number"
              onChange={(e) => {
                setData({ ...data, invoice_number_mapping: e });
                setMappingState({
                  ...mappingsState,
                  invoice_number_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'invoice_number_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i} style={{ minWidth:200 }}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row">
          <div className="subtitle">Invoice Value is in the column named</div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="invoice-value"
              key="invoice-value"
              onChange={(e) => {
                setData({ ...data, invoice_value_mapping: e });
                setMappingState({
                  ...mappingsState,
                  invoice_value_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'invoice_value_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row-gray">
          <div className="subtitle">Tax Rate is in the column named</div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="tax-rate"
              key="tax-rate"
              onChange={(e) => {
                setData({ ...data, tax_rate_mapping: e });
                setMappingState({
                  ...mappingsState,
                  tax_rate_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'tax_rate_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row">
          <div className="subtitle">
            Total Taxable Value is in the column named
          </div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="taxable-value"
              key="taxable-value"
              onChange={(e) => {
                setData({ ...data, taxable_value_mapping: e });
                setMappingState({
                  ...mappingsState,
                  taxable_value_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'taxable_value_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row-gray">
          <div className="subtitle">IGST Value is in the column named</div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="igst-value"
              key="igst-value"
              onChange={(e) => {
                setData({ ...data, integrated_tax_mapping: e });
                setMappingState({
                  ...mappingsState,
                  integrated_tax_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'integrated_tax_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row">
          <div className="subtitle">CGST Value is in the column named</div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="cgst-value"
              key="cgst-value"
              onChange={(e) => {
                setData({ ...data, central_tax_mapping: e });
                setMappingState({
                  ...mappingsState,
                  central_tax_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'central_tax_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row-gray">
          <div className="subtitle">SGST/UGST Value is in the column named</div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="sgst-igst-value"
              key="sgst-igst-value"
              onChange={(e) => {
                setData({ ...data, state_tax_mapping: e });
                setMappingState({
                  ...mappingsState,
                  state_tax_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'state_tax_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
        <Row className="pa--10 border-radius--5 flex align-center row">
          <div className="subtitle">Cess Value is in the column named</div>
          <div className="flex-x center ml-auto gray-selector mr--10">
            <Select
              id="cess-value"
              key="cess-value"
              onChange={(e) => {
                setData({ ...data, cess_mapping: e });
                setMappingState({
                  ...mappingsState,
                  cess_mapping: e
                });
              }}
              value={returnIfPresent(mappingsState, 'cess_mapping', dropdowns)}
            >
              {
                dropdowns && dropdowns.split(',').map((d: any, i: any) => {
                  return (
                    <Select.Option value={d} key={i}>
                      {d}
                    </Select.Option>
                  );
                })
              }
            </Select>
          </div>
        </Row>
      </div>
      <div
        className="next-btn flex-x center align-center center fs--16 lh--20"
        style={{ opacity: isNextEnabled() ? 1 : 0.5 }}
        onClick={() => {
          if (!isNextEnabled()) {
            return;
          }
          const param: any = {
            'generation_period': (data as any)?.period,
            'gstin_mapping': mappingsState?.gstin_mapping,
            'name_mapping': mappingsState?.name_mapping,
            'invoice_number_mapping': mappingsState?.invoice_number_mapping,
            'invoice_date_mapping': mappingsState?.invoice_date_mapping,
            'invoice_value_mapping': mappingsState?.invoice_value_mapping,
            'tax_rate_mapping': mappingsState?.tax_rate_mapping,
            'taxable_value_mapping': mappingsState?.taxable_value_mapping,
            'integrated_tax_mapping': mappingsState?.integrated_tax_mapping,
            'central_tax_mapping': mappingsState?.central_tax_mapping,
            'state_tax_mapping': mappingsState?.state_tax_mapping,
            'cess_mapping': mappingsState?.cess_mapping
          };
          dispatch(addMappings(param));
        }}
      >
        NEXT
      </div>
    </div>
  );
};

export default MappingPR;
