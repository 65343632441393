/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Upload, Button } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { useDispatch, useSelector } from 'react-redux';
import OpenNotification from '../utils/notification';
import { desk, file, loader, correct } from '../utils/constants';
import { uploadPr, next } from '../store/actions/ReconcilationActions';
import { UploadStatus } from '../typings';

const { Dragger } = Upload;

interface IUploadPR {
  setActiveStep?: any;
}

const UploadPR: React.FunctionComponent<IUploadPR> = ({ setActiveStep }) => {
  // const [isUploading, setIsUploading] = useState<boolean>(false);
  const [onUpload, setOnUpload] = useState<boolean>(false);
  const [isValidationError, setIsValidationError] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const dispatch = useDispatch();
  const uploading = useSelector((state: any) => state.reconReducer.uploading);
  const uploadStatus = useSelector((state: any) => state.reconReducer.uploadStatus);  
  const resetRecon = useSelector((state:any) => state.reconReducer.resetRecon);

  if(onUpload && !uploading && uploadStatus === UploadStatus.FAIL){
    setIsValidationError(true);
    setOnUpload(false);
  } else if(isValidationError && uploadStatus !== UploadStatus.FAIL) {
    setIsValidationError(false);
  }

  const removeFile = (f:UploadFile) => {
    setFileList([]);
    setIsValidationError(false);
    return true;
  };

  const isUploadSuccess = () => {
    return uploadStatus === UploadStatus.SUCCESS;
  };

  const UploadDraggerComponent = () => {
    return (
      <Dragger
        accept=".csv, text/csv"
        openFileDialogOnClick={!(fileList?.length > 0 && !uploading)}
        className={onUpload ? 'none-file' : ''}
        multiple={false}
        disabled={uploading}
        beforeUpload={(ffile) => {
          if (!['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(ffile.type)) {
            OpenNotification('Only csv files are allowed', 'error');
            return false;
          }
          if (fileList.length < 1) {
            setFileList([...fileList, ffile]);
          } else {
            OpenNotification(
              'Only one file can be uploaded. ' +
                'Please remove file previously uploaded from this screen.',
              'error'
            );
          }
          return false;
        }}
        fileList={fileList}
        onRemove={removeFile}
      >
        <p className="pl--50 pr--50 ptb--10">
          {isValidationError ? (
            <p style={{ color: 'red' }} className="title fs--20">
              Validation failed. Please recheck and upload the file
            </p>
          ) : (
            <p className="title fs--20">
              Upload your Purchase Register Data in csv format
            </p>
          )}
        </p>
        <div className={(fileList && fileList.length > 0 && onUpload) ? 'upload-success image-wrapper' : 'image-wrapper'}>
          {uploading ? (
            <img className="rotate" src={loader} alt="img" />
          ) : fileList && fileList.length > 0 && onUpload && !isValidationError? (
            <img
              src={correct}
              style={{ fontSize: '50px' }}
              alt="img"
            />
          ) : (
            <img
              src={file}
              style={{ fontSize: '50px' }}
              alt="img"
            />
          )}
        </div>
        {(isUploadSuccess() && !resetRecon)? (
          <p>
            Your file,
            {fileList[0]?.name}
            {' '}
            is successfully uploaded!
          </p>
        ) : onUpload ? (
          <p>
            Your file,
            {fileList[0]?.name}
            {' '}
            is uploading. Please wait.
          </p>
        ) : (fileList?.length && !uploading) ? 
          (
            <p className="subtitle fs--16" onKeyDown={() => removeFile(fileList[0])} onClick={() => removeFile(fileList[0])} role="presentation">
              <span style={{ color: '#4532BF' }}>Remove file</span>
            </p>
          ) : (
            <p className="subtitle fs--16" style={{ color: '#4532BF' }}>
              Drop your file here, or browse
            </p>
          )}
      </Dragger>
    );
  };

  const disableUploadNextButton = ():boolean => {
    return (!(fileList && fileList.length > 0) || isValidationError || uploading);
  };

  return (
    <div className="flex-y align-center download-gst-r2a-wrapper">
      <div className="mtb--80 flex-y align-center">
        <span className="fs--14 lh--21 img-text">
          Uploading PR Data to KYSS Portal
        </span>
        <img src={desk} alt="img" />
      </div>
      <div style={{ width: '800px', height: '258px' }}>
        <UploadDraggerComponent />
      </div>
      {!(fileList && fileList.length > 0 && onUpload) ? (
        <Button
          disabled={disableUploadNextButton()}
          style={{
            opacity:
            disableUploadNextButton() ? '0.4' : '1',
          }}
          className="next-btn flex-x align-center center fs--16 lh--20"
          onClick={() => {
            const formData = new FormData();
            formData.append('file', fileList[0]);
            dispatch(uploadPr(formData));
            setOnUpload(true);
          }}
        >
          UPLOAD
        </Button>
      ) : (
        <Button
          className="next-btn flex-x align-center center fs--16 lh--20"
          onClick={() => {
            dispatch(next());
          }}
        >
          NEXT
        </Button>
      )}
    </div>
  );
};

export default UploadPR;
