import React, { ReactElement, useState } from 'react';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import Title from 'antd/es/typography/Title';
import { Button } from 'antd';
import Modal from 'antd/es/modal';
import Paragraph from 'antd/es/typography/Paragraph';
import { UserAddOutlined } from '@ant-design/icons/lib';
import { useDispatch, useSelector } from 'react-redux';
import ColleagueForm from '../components/colleagueForm';
import ColleagueTable from '../components/colleagueTable';
import { THEME } from '../constants/theme';
import { addColleague } from '../store/actions/colleagueActions';
import { IState } from '../models';

const ManageColleaguesPage = (): ReactElement => {
  const [showAddModal, setShowAddModal] = useState(false);
  const storeDispatch = useDispatch();
  const authState = useSelector((state: IState) => state.authReducer);

  const handleCancel = () => {
    setShowAddModal(false);
  };

  const AddColleagueModal = () => {
    return (
      <Modal
        title="Add a Colleague"
        visible={showAddModal}
        onCancel={handleCancel}
        footer={null}
      >
        <ColleagueForm
          onSubmitFn={(values) => {
            storeDispatch(addColleague({ ...values, gstin: authState.gstin }));
            handleCancel();
          }}
          justifyBtn="end"
        />
      </Modal>
    );
  };

  return (
    <div className='app__invite-colleagues'>
      <AddColleagueModal />

      <Row>
        <Col flex="auto">
          <Title level={3}>
            Invite Your Colleagues to KYSS
          </Title>
        </Col>
      </Row>

      <Row>
        <Col flex="auto" style={{ padding: '10px 10px 10px 0' }}>
          <Paragraph strong>
            All your colleagues added to KYSS will receive latest reports
            directly over email
          </Paragraph>
        </Col>
      </Row>

      <Row align="middle" justify="end">
        <Col>
          <Button
            type="primary"
            onClick={() => setShowAddModal(true)}
            style={{ backgroundColor: THEME.PRIMARY_COLOR }}
          >
            <UserAddOutlined />
          </Button>
        </Col>
      </Row>

      <Row>
        <Col flex="auto">
          <ColleagueTable />
        </Col>
      </Row>
    </div>
  );
};

export default ManageColleaguesPage;
