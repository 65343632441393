/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { LeftOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import CountDown from 'ant-design-pro/lib/CountDown';
import {
  generateGstOtp,
  validateGstOtp,
  prev
} from '../store/actions/ReconcilationActions';


interface IGenerateOTP {
  setActiveStep?: any;
}

const GenerateOTP: React.FunctionComponent<IGenerateOTP> = () => {
  const dispatch = useDispatch();
  const [otp, setOpt] = useState<string>('');
  const [checkbox, setCheckbox] = useState<boolean>(false);
  const gstUsername = useSelector(
    (state: any) => state.reconReducer.gstUsername
  );
  return (
    <>
      <div
        className="flex-x align-center cursor-pointer text"
      >
        <LeftOutlined className="mr--10" />
        <div
          onClick={() => {
            dispatch(prev());
          }}
        >
          Back to previous step
        </div>
      </div>
      <div className="flex-y center generate-opt-wrapper mt--50">
        <span className="fs--16 lh--24">
          As a first time activity, the Government requires you to allow API
          Access to KYSS
          {' '}
          <br />
        </span>
        <span className="fs--16 lh--24">
          from your GST Portal and authenticate with an OTP.
        </span>
        <span id="link1" className="fs--14 lh--20 text mt--20">
          <a href="#link1"><span className="link">Click here</span></a>
          {' '}
          to allow API Access
        </span>
        <Checkbox
          className="fs--14 lh--20 enable-opt-checkbox mt--20"
          onChange={(e) => setCheckbox(e.target.checked)}
        >
          I have enabled OTP Access on GST Portal
        </Checkbox>
        <div className="divider mtb--50" />
        <span className="fs--20 lh--30 generate-title">Generate your OTP</span>
        <span className="fs--16 lh-24 generate-subtitle mt--10">
          OTP will be sent to the number linked with your GST Portal
        </span>
        <span id="link2" className="fs--14 lh--20 text mt--10">
          <a href="#link2"><span className="link">Click here</span></a>
          {' '}
          to see the registered number
          in the GST portal
        </span>

        <div className="flex-y otp-wrapper">
          <OtpInput
            isDisabled={!checkbox}
            disabledStyle={{ opacity: 0.5 }}
            value={otp}
            onChange={(val: string) => setOpt(val)}
            numInputs={6}
            className="otp-input mt--40"
          />
          <span>
            {checkbox && (
              <CountDown
                style={{ fontSize: 20 }}
                target={new Date().getTime() + 180000}
              />
            )}
          </span>
        </div>
        {otp && otp.length === 6 ? (
          <Button
            className="mt--50 orange-outline-btn"
            onClick={() => {
              dispatch(generateGstOtp(gstUsername));
            }}
          >
            RE-GENERATE OTP
          </Button>
        ) : (
          <Button
            className="mt--50 orange-btn"
            disabled={!checkbox}
            onClick={() => {
              dispatch(generateGstOtp(gstUsername));
            }}
          >
            GENERATE OTP
          </Button>
        )}
        <Button
          className="mt--10 orange-btn"
          disabled={!checkbox || !(otp.length === 6)}
          onClick={() => {
            dispatch(validateGstOtp(otp));
          }}
        >
          VALIDATE
        </Button>
      </div>
    </>
  );
};

export default GenerateOTP;
