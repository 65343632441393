import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, Radio, Space, message } from 'antd';
import { ArrowLeftOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';
import { ROUTES } from '../constants/routePaths';
import { THEME } from '../constants/theme';
import { globalApiService } from '../services/apiService';
import OpenNotification from '../utils/notification';


export interface editForm {
  address: any;
  company_name: string;
  has_gstin: any;
  gstin: any;
  point_of_contact: string;
  description: string;
  phone_number: string;
  email: string;
  id?: number;
}
const AddCustomer = (props: any) => {
  // Destructing
  const { TextArea } = Input;
  // Initialization
  const history = useHistory();
  const [form] = Form.useForm();
  // Form
  const [customerInput, setCustomerInput] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      has_gstin: true,
      gstin: '',
      company_name: '',
      point_of_contact: '',
      phone_number: '',
      email: '',
      address: '',
    }
  );
  // console.log(customerInput)
  const onCustomerChange = (evt: any) => {
    const { name } = evt.target;
    const newValue = evt.target.value;
    setCustomerInput({ [name]: newValue });
  };

  const onAddCustomerFinish = (values: any) => {
    if (status === 'add_customer') {
      if(customerInput.has_gstin &&
        !(/(^[0-9]{2})([A-Za-z]{5}[0-9]{4}[A-Za-z]{1})[1-9A-Za-z]{1}[Zz]{1}[0-9A-Za-z]{1}$/.test(customerInput.gstin))){
            OpenNotification('Please Enter a Valid GSTIN', 'error');
            return;
        }
      globalApiService
        .addCustomer(values)
        .then((res) => {
          if (res.data.success) {
            message.success('Customer Added Successfully!');
          }
          //history.push(ROUTES.MANAGE_CUSTOMERS);
          history.goBack()
        })
        .catch((err: AxiosError) => {
        history.push(ROUTES.MANAGE_CUSTOMERS);
          const errorBody = err?.response?.data;
          console.log(errorBody);
        });
    } else {
      globalApiService
        .updateCustomer(customerInput)
        .then((res) => {
          if (res.data.success) {
            message.success('Customer Updated Successfully!');
          }
          history.push(ROUTES.MANAGE_CUSTOMERS);
        })
        .catch((err: AxiosError) => {
        history.push(ROUTES.MANAGE_CUSTOMERS);
          const errorBody = err?.response?.data;
          console.log(errorBody);
        });
    }
  };

  const onAddCustomerFailed = (errorInfo: any) => {
    message.error('Submit failed!');
  };
  const handleCancel = () => {
    history.goBack()
    // history.push(ROUTES.MANAGE_CUSTOMERS);
  };
  const [customerData, setcustomerData] = useState<editForm>();
  const [status, setStatus] = useState('');

  const [ regEx, setRegEx ] = useState([
      {
          required: true,
          pattern: new RegExp(
            /(^[0-9]{2})([A-Za-z]{5}[0-9]{4}[A-Za-z]{1})[1-9A-Za-z]{1}[Zz]{1}[0-9A-Za-z]{1}$/
          ),
          message: 'Please Enter Valid GSTIN Number',
      }
  ]);

  const {has_gstin} = customerInput

  useEffect(() => {
    if (status === 'edit_customer' || !customerInput?.has_gstin) {
        setRegEx([
        {
            required: false,
            pattern: new RegExp(/^[a-zA-Z0-9#]{1,15}$/),
            message: 'Please Enter Valid GSTIN Number',
        }
        ])
    }
    if(!customerInput?.has_gstin){
      setCustomerInput({...customerInput, 'gstin': ''});
    }
    }, [status,has_gstin])


  React.useEffect(() => {
    if (props.location.state) {
      setCustomerInput({ ...props.location.state.record });
      setStatus(props.location.state.status);
    }
  }, [props]);

  form.setFieldsValue({
    ...customerInput,
  });
  return (
    <main className="">
      <header className="header">
        <ArrowLeftOutlined
          className="header__icon"
          onClick={() => history.goBack()}
        />
        <h3 className="header__title">
          {status === 'edit_customer' ? 'Edit Customer' : 'Add Customer'}
        </h3>
      </header>
      <section className="customer">
        <h4 className="customer__title">Enter details</h4>

        <Form
          name="basic"
          layout="horizontal"
          initialValues={{ remember: true }}
          onFinish={onAddCustomerFinish}
          onFinishFailed={onAddCustomerFailed}
          autoComplete="off"
          className="customer__card"
          form={form}
        >
          <Card>
            <div className="customer__card__grid">
              <Form.Item label="Customer has GSTIN" name="has_gstin">
                <Radio.Group
                  name="has_gstin"
                  value={customerInput.has_gstin}
                  onChange={onCustomerChange}
                  disabled={status === 'edit_customer'}
                >
                  <Space direction="vertical" size="small">
                    <Radio value>
                      <span className="radiotext">Yes</span>
                    </Radio>
                    <Radio value={false}>
                      <span className="radiotext">No</span>
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Customer GSTIN"
                name="gstin"
                rules={regEx}
              >
                <Input
                  name="gstin"
                  disabled={
                    !customerInput.has_gstin || status === 'edit_customer'
                  }
                  value={customerInput.gstin}
                  onChange={onCustomerChange}
                  placeholder={customerData ? customerData.gstin : 'Enter customer GSTIN'}
                  className='input-class'
                />
              </Form.Item>
              <Form.Item
                label="Company Name"
                name="company_name"
                rules={[
                  {
                    required: !customerInput.has_gstin && status === 'add_customer',
                    message: 'Please enter your Company Name!',
                  },
                  {
                    type: 'string',
                    min: 3,
                  },
                ]}
              >
                <Input
                  name="company_name"
                  disabled={
                    (customerInput.has_gstin && status === 'add_customer')  || status === 'edit_customer'
                  }
                  value={customerInput.company_name}
                  onChange={onCustomerChange}
                  placeholder={customerData ? customerData.company_name : 'Enter company name'}
                  className='input-class'
                />
              </Form.Item>
              <Form.Item
                label="Person Concerned"
                name="point_of_contact"
                rules={[
                  {
                    required: status === 'add_customer',
                    message: 'Please enter your Person Concerned!',
                  },
                  {
                    type: 'string',
                    min: 3,
                  },
                ]}
              >
                <Input
                  name="point_of_contact"
                  value={customerInput.point_of_contact}
                  onChange={onCustomerChange}
                  placeholder={
                    customerData ? customerData.point_of_contact : 'Concerned person in company'
                  }
                  className='input-class'
                />
              </Form.Item>
              <Form.Item
                label="Contact No"
                name="phone_number"
                rules={[
                  {
                    required: status === 'add_customer',
                    message: 'Please enter your Contact No!',
                  },
                  {
                    pattern: new RegExp(/^[6-9]{1}[0-9]{9}$/),
                    message: 'Please Enter Valid Mobile Number',
                  },
                ]}
              >
                <Input
                  name="phone_number"
                  value={customerInput.phone_number}
                  onChange={onCustomerChange}
                  placeholder={customerData ? customerData.phone_number : 'Enter contact number'}
                  className='input-class'
                />
              </Form.Item>
              <Form.Item
                label="Customer Email"
                name="email"
                rules={[
                  {
                    required: status === 'add_customer',
                    message: 'Please enter your Email!',
                  },
                  {
                    type: 'email',
                  },
                ]}
              >
                <Input
                  name="email"
                  value={customerInput.email}
                  onChange={onCustomerChange}
                  placeholder={customerData ? customerData.email : 'Enter customer email'}
                  className='input-class'
                />
              </Form.Item>
              <Form.Item
                label="Customer Address"
                name="address"
                rules={[
                  {
                    required: status === 'add_customer',
                    message: 'Please enter your Address!',
                  },
                  {
                    type: 'string',
                    min: 5,
                  },
                ]}
              >
                <TextArea
                  name="address"
                  value={customerInput.address}
                  onChange={onCustomerChange}
                  rows={3}
                  placeholder={customerData ? customerData.address : 'Enter customer address'}
                  className='input-class'
                />
              </Form.Item>
            </div>
          </Card>
          <div className="formbtnbox">
            <Form.Item>
              <p
                className="formbtnbox__btn formbtnbox__btn--paragraph"
                style={{
                  color: THEME.PRIMARY_COLOR,
                }}
                onClick={handleCancel}
              >
                Cancel
              </p>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="formbtnbox__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                }}
              >
                {status === 'edit_customer' ? 'Update' : 'Submit'}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </section>
    </main>
  );
};

export default AddCustomer;
