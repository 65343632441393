import React from 'react';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import Title from 'antd/es/typography/Title';
import UploadFileComponent from '../components/uploadFileComponent';

const UploadSuppliersToDataSourcePage = () => {
  return (
    <div>
      <Row>
        <Col flex="auto">
          <Title level={3}>Add Your Suppliers to KYSS</Title>
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <UploadFileComponent />
        </Col>
      </Row>
    </div>
  );
};

export default UploadSuppliersToDataSourcePage;
