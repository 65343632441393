import { IAction } from '../../models';
import {
  IRequestAnchorInfo,
  IRequestFindAnchor,
  IRequestGetSubIndustryList,
  IRequestGstAccountCreation,
  IRequestGstLogin,
  IRequestLoginOtp,
  IRequestRegisteredGSTIN,
  IRequestSaveAnchorDetails,
  IRequestSupportNotification,
  IRequestVerifyOtp,
  IRequestWhatsappOptIn,
  IResponseAnchorInfo,
  IResponseFindAnchor,
  IResponseGetIndustryList,
  IResponseGstLogin,
  IResponseRegisteredGSTIN,
  IResponseSaveAnchorDetails,
  IResponseSubIndustryList,
} from '../../models/api/authModels';

export enum AuthActions {
  REQUEST_LOGIN_OTP = 'REQUEST_LOGIN_OTP',
  REQUEST_LOGIN_OTP_SUCCESS = 'REQUEST_LOGIN_OTP_SUCCESS',
  VERIFY_LOGIN_OTP_ERROR = 'VERIFY_LOGIN_OTP_ERROR',

  SET_WHATSAPP_OPT_IN = 'SET_WHATSAPP_OPT_IN',
  SET_WHATSAPP_OPT_IN_SUCCESS = 'SET_WHATSAPP_OPT_IN_SUCCESS',

  VERIFY_LOGIN_OTP = 'VERIFY_LOGIN_OTP',

  GST_ACCOUNT_LOGIN = 'GST_ACCOUNT_LOGIN',
  GST_ACCOUNT_LOGIN_SUCCESS = 'GST_ACCOUNT_LOGIN_SUCCESS',

  REQUEST_ANCHOR_INFO = 'REQUEST_ANCHOR_INFO',
  REQUEST_ANCHOR_INFO_SUCCESS = 'REQUEST_ANCHOR_INFO_SUCCESS',

  FIND_ANCHOR = 'FIND_ANCHOR',
  FIND_ANCHOR_SUCCESS = 'FIND_ANCHOR_SUCCESS',

  GET_INDUSTRY_LIST = 'GET_INDUSTRY_LIST',
  GET_INDUSTRY_LIST_SUCCESS = 'GET_INDUSTRY_LIST_SUCCESS',

  GET_SUB_INDUSTRY_LIST = 'GET_SUB_INDUSTRY_LIST',
  GET_SUB_INDUSTRY_LIST_SUCCESS = 'GET_SUB_INDUSTRY_LIST_SUCCESS',

  SAVE_ANCHOR_DETAILS = 'SAVE_ANCHOR_DETAILS',
  SAVE_ANCHOR_DETAILS_SUCCESS = 'SAVE_ANCHOR_DETAILS_SUCCESS',

  CREATE_GST_ACCOUNT = 'CREATE_GST_ACCOUNT',
  SET_CREATE_GST_ACCOUNT_SUCCESS_FLAG = 'SET_CREATE_GST_ACCOUNT_SUCCESS_FLAG',
  CLEAR_CREATE_GST_ACCOUNT_SUCCESS_FLAG = 'CLEAR_CREATE_GST_ACCOUNT_SUCCESS_FLAG',

  REQUEST_REGISTERED_GSTINS = 'REQUEST_REGISTERED_GSTINS',
  REQUEST_REGISTERED_GSTINS_SUCCESS = 'REQUEST_REGISTERED_GSTINS_SUCCESS',

  REQUEST_SUPPORT_NOTIFICATION = 'REQUEST_SUPPORT_NOTIFICATION',

  SPIN_START = 'SPIN_START',
  SPIN_END = 'SPIN_END',

  LOG_OUT = 'LOG_OUT',

  DEFULT = 'DEFULT',

  TRANSPARENT = 'TRANSPARENT',
}

export function requestOtpForLoginAction(
  data: IRequestLoginOtp,
  redirect: () => void
): IAction {
  return {
    type: AuthActions.REQUEST_LOGIN_OTP,
    body: data,
    redirect,
  };
}

export function requestOtpForLoginSuccess(phoneNumber: string): IAction {
  return {
    type: AuthActions.REQUEST_LOGIN_OTP_SUCCESS,
    body: {
      phoneNumber,
    },
  };
}

export function requestOtpForLoginError(): IAction {
  return {
    type: AuthActions.VERIFY_LOGIN_OTP_ERROR
  };
}

export function verifyOtp(
  body: IRequestVerifyOtp,
  redirect: () => void
): IAction {
  return {
    type: AuthActions.VERIFY_LOGIN_OTP,
    body,
    redirect,
  };
}

export function requestGstAccountLogin(
  body: IRequestGstLogin,
  redirect: () => void
): IAction {
  return {
    type: AuthActions.GST_ACCOUNT_LOGIN,
    body,
    redirect,
  };
}

export function setAccountCreationFlag(): IAction{
  return {
    type: AuthActions.SET_CREATE_GST_ACCOUNT_SUCCESS_FLAG
  };
}

export function clearAccountCreationFlag(): IAction{
  return {
    type: AuthActions.CLEAR_CREATE_GST_ACCOUNT_SUCCESS_FLAG
  };
}

export function requestGstAccountLoginSuccess(
  body: IResponseGstLogin
): IAction {
  return {
    type: AuthActions.GST_ACCOUNT_LOGIN_SUCCESS,
    body,
  };
}

export function requestAnchorInfo(body: IRequestAnchorInfo): IAction {
  return {
    type: AuthActions.REQUEST_ANCHOR_INFO,
    body,
  };
}

export function requestAnchorInfoSuccess(body: IResponseAnchorInfo): IAction {
  return {
    type: AuthActions.REQUEST_ANCHOR_INFO_SUCCESS,
    body,
  };
}

export function requestFindAnchor(
  body: IRequestFindAnchor,
  redirect: () => void
): IAction {
  return {
    type: AuthActions.FIND_ANCHOR,
    body,
    redirect,
  };
}

export function requestFindAnchorSuccess(body: IResponseFindAnchor): IAction {
  return {
    type: AuthActions.FIND_ANCHOR_SUCCESS,
    body,
  };
}

export function saveAnchorDetails(
  body: IRequestSaveAnchorDetails,
  redirect: () => void
): IAction {
  return {
    type: AuthActions.SAVE_ANCHOR_DETAILS,
    body,
    redirect,
  };
}

export function saveAnchorDetailsSuccess(
  body: IResponseSaveAnchorDetails
): IAction {
  return {
    type: AuthActions.SAVE_ANCHOR_DETAILS_SUCCESS,
    body,
  };
}

export function requestGstAccountCreation(
  body: IRequestGstAccountCreation,
  redirect: () => void
): IAction {
  return {
    type: AuthActions.CREATE_GST_ACCOUNT,
    body,
    redirect,
  };
}

export function getIndustryList(): IAction {
  return {
    type: AuthActions.GET_INDUSTRY_LIST,
  };
}

export function getIndustryListSuccess(body: IResponseGetIndustryList) {
  return {
    type: AuthActions.GET_INDUSTRY_LIST_SUCCESS,
    body,
  };
}

export function getSubIndustryList(body: IRequestGetSubIndustryList) {
  return {
    type: AuthActions.GET_SUB_INDUSTRY_LIST,
    body,
  };
}

export function getSubIndustryListSuccess(body: IResponseSubIndustryList) {
  return {
    type: AuthActions.GET_SUB_INDUSTRY_LIST_SUCCESS,
    body,
  };
}

export function spinStart(): IAction {
  return {
    type: AuthActions.SPIN_START,
  };
}

export function spinEnd(): IAction {
  return {
    type: AuthActions.SPIN_END,
  };
}

export function logOut(): IAction {
  return {
    type: AuthActions.LOG_OUT,
  };
}

export function requestRegisteredGSTINs(
  body: IRequestRegisteredGSTIN
): IAction {
  return {
    type: AuthActions.REQUEST_REGISTERED_GSTINS,
    body,
  };
}

export function requestRegisteredGSTINsSuccess(
  body: IResponseRegisteredGSTIN
): IAction {
  return {
    type: AuthActions.REQUEST_REGISTERED_GSTINS_SUCCESS,
    body,
  };
}

export function requestSupportNotification(
  body: IRequestSupportNotification
): IAction {
  return {
    type: AuthActions.REQUEST_SUPPORT_NOTIFICATION,
    body,
  };
}

export function setWhatsappOptIn(
  body: IRequestWhatsappOptIn
): IAction {
  return {
    type: AuthActions.SET_WHATSAPP_OPT_IN,
    body,
  };
}

export function setWhatsappOptInSuccess(
): IAction {
  return {
    type: AuthActions.SET_WHATSAPP_OPT_IN_SUCCESS,
  };
}


export function setDefault(
): IAction {
  return {
    type: AuthActions.DEFULT,
  };
}


export function setTransparent(
): IAction {
  return {
    type: AuthActions.TRANSPARENT,
  };
}
