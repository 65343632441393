import { IAction, IAuthState } from '../../models';
import { AuthActions } from '../actions/authActions';
import {
  IResponseAnchorInfo,
  IResponseFindAnchor,
  IResponseGetIndustryList,
  IResponseGstLogin,
  IResponseRegisteredGSTIN,
  IResponseSaveAnchorDetails,
  IResponseSubIndustryList,
} from '../../models/api/authModels';

const defaultState = {} as IAuthState;

export default function authReducer(
  authState = defaultState,
  action: IAction
): IAuthState {
  switch (action.type) {

    case AuthActions.REQUEST_LOGIN_OTP: {
      return {
        ...authState,
        otpError: false,
        otp:''
      };
    }

    case AuthActions.REQUEST_LOGIN_OTP_SUCCESS: {
      return {
        ...authState,
        phoneNumber: action.body.phoneNumber,
        otpError: false,
        loadingIndicator: false,
        otp: ''
      };
    }

    case AuthActions.VERIFY_LOGIN_OTP_ERROR: {
      return {
        ...authState,
        otpError: true,
        loadingIndicator: false,
      };
    }

    case AuthActions.VERIFY_LOGIN_OTP: {
      return {
        ...authState,
        otpError: false,
        loadingIndicator: true,
        otp: action.body.otp
      };
    }

    case AuthActions.GST_ACCOUNT_LOGIN_SUCCESS: {
      const actionBody: IResponseGstLogin = action.body;
      return {
        ...authState,
        ...actionBody,
      };
    }

    case AuthActions.FIND_ANCHOR_SUCCESS: {
      const response: IResponseFindAnchor = action.body;
      return {
        ...authState,
        anchorTradeName: response.tradeName,
        jitFinId: response.jitfinId,
        gstin: response.gstin,
        stateName: response.stateName,
        Address: response.Address
      };
    }

    case AuthActions.REQUEST_ANCHOR_INFO_SUCCESS: {
      const actionBody: IResponseAnchorInfo = action.body;
      return {
        ...authState,
        anchorId: actionBody.anchorId ? actionBody.anchorId : null,
        anchorTradeName: actionBody.tradeName,
        jitFinId: actionBody.jitFinId,
        dataSourceName: actionBody.dataSourceName,
        dataSourceId: actionBody.dataSourceId,
        anchorIndustryType: actionBody.industryType,
        anchorSubIndustryType: actionBody.subIndustryType,
      };
    }

    case AuthActions.SAVE_ANCHOR_DETAILS_SUCCESS: {
      const actionBody: IResponseSaveAnchorDetails = action.body;
      return {
        ...authState,
        anchorId: actionBody.anchorId,
        jitFinId: actionBody.jitfinId,
        phoneNumber: actionBody.primaryContactNumber,
        anchorTradeName: actionBody.tradeName,
      };
    }

    case AuthActions.GET_INDUSTRY_LIST_SUCCESS: {
      const actionBody: IResponseGetIndustryList = action.body;
      return {
        ...authState,
        industryList: actionBody.industryList,
      };
    }

    case AuthActions.GET_SUB_INDUSTRY_LIST_SUCCESS: {
      const actionBody: IResponseSubIndustryList = action.body;
      return {
        ...authState,
        subIndustryList: actionBody.subIndustryList,
      };
    }

    case AuthActions.REQUEST_REGISTERED_GSTINS_SUCCESS: {
      const actionBody: IResponseRegisteredGSTIN = action.body;
      return {
        ...authState,
        registeredGSTINs: actionBody.registeredGSTINs,
      };
    }

    case AuthActions.SET_WHATSAPP_OPT_IN_SUCCESS: {
      const yup = {
        ...authState,
        isWhatsappOptIn: !authState.isWhatsappOptIn,
      };
      return yup;
    }

    case AuthActions.LOG_OUT: {
      return {} as IAuthState;
    }

    case AuthActions.SPIN_START: {
      return {
        ...authState,
        loadingIndicator: true,
      };
    }

    case AuthActions.SPIN_END: {
      return {
        ...authState,
        loadingIndicator: false,
      };
    }

    case AuthActions.DEFULT: {
      return {
        ...authState,
        backGround: '#FFF',
      };
    }

    case AuthActions.TRANSPARENT: {
      return {
        ...authState,
        backGround: '#E5E5E5',
      };
    }

    case AuthActions.SET_CREATE_GST_ACCOUNT_SUCCESS_FLAG:
      return {
        ...authState,
        signupSuccessFlag: true
      };

    case AuthActions.CLEAR_CREATE_GST_ACCOUNT_SUCCESS_FLAG:
      return {
        ...authState,
        signupSuccessFlag: false
      };

    default: {
      return {
        ...authState,
      };
    }
  }
}
