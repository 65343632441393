/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Modal from 'antd/es/modal/Modal';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Input, Radio } from 'formik-antd/es';
import Col from 'antd/es/grid/col';
import Row from 'antd/es/grid/row';
import { useDispatch } from 'react-redux';
import Paragraph from 'antd/es/typography/Paragraph';
import StylizedButton from './stylizedButton';
import { requestSupportNotification } from '../store/actions/authActions';
import ValidationService from '../services/validationService';

import '../styles/supportModal.css';

export interface ISupportRequestPayload {
  issueType: string;
  phoneNumber: string;
}

export default function SupportComponent(props: { isLogin: boolean }) {
  // const { isLogin } = props;

  const storeDispatch = useDispatch();

  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);

  const issueTypes = [
    {
      value: 'OTP_NOT_RECEIVED',
      label: 'Mobile number and Email are correct, but no OTP recieved',
    },
    {
      value: 'OTP_NOT_ACCEPTED',
      label: 'OTP correctly entered, but not being accepted',
    },
    {
      value: 'OPTOUT_WHATSAPP',
      label: 'Want to opt out of WhatsApp messages',
    },
  ];

  const formikInitialValues = {
    issueType: issueTypes[0].value,
    phoneNumber: '',
  };

  const handleCancel = () => {
    setShowSupportModal(false);
  };

  return (
    <div>
      <Modal
        title="Help and Support"
        visible={showSupportModal}
        onCancel={handleCancel}
        footer={false}
        wrapClassName="supportModal"
      >
        <Formik
          initialValues={formikInitialValues}
          onSubmit={(values: ISupportRequestPayload, actions) => {
            storeDispatch(
              requestSupportNotification({
                phoneNumber: values.phoneNumber,
                issue: values.issueType,
              })
            );
            actions.setSubmitting(false);
          }}
          validate={(values) => {
            const validationFieldToFunctionMap = {
              phoneNumber: ValidationService.isIndianPhoneNumber,
            };
            return ValidationService.validateFields(
              values,
              validationFieldToFunctionMap
            );
          }}
          validateOnBlur
          validateOnChange
        >
          {({ handleSubmit }) => (
            <Form>
              <Paragraph strong>Phone number</Paragraph>
              <Form.Item name="phoneNumber" hasFeedback showValidateSuccess>
                <Input
                  name="phoneNumber"
                  placeholder="Phone Number"
                  size="large"
                />
              </Form.Item>
              <Paragraph strong>I am facing issue with...</Paragraph>
              <Form.Item name="issueType" hasFeedback>
                <Radio.Group
                  name="issueType"
                  options={issueTypes}
                  size="large"
                />
              </Form.Item>
              <Row justify="start">
                <Col>
                  <StylizedButton
                    textInButton="Submit"
                    onClickFn={handleSubmit}
                    size="middle"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal>
      <a href="" onClick={(e) => {e.preventDefault(); setShowSupportModal(true);}}>
        Need help?
      </a>
    </div>
  );
}
