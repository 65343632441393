import React, { useState, useEffect } from 'react';
import {
  Card,
  message,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Popconfirm,
  Tabs,
  Table,
  DatePicker,
  Modal,
  Upload,
  Dropdown,
  Menu,
  Select,
  Pagination,
} from 'antd';
import { THEME } from '../constants/theme';
import {
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import {FiEdit3, RiDeleteBinLine} from "react-icons/all";
import { ROUTES } from '../constants/routePaths';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {IState, IItem} from "../models";
import {
  getItems, deleteItem, deleteTransporter
} from '../store/actions/dataSourceActions';
import OpenNotification from '../utils/notification';
import { file } from '../utils/constants';
import { info } from 'console';
import { data } from 'jquery';

const Items = () => {
  // Initialization
  const history = useHistory();
  const [table, setTable] = useState<IItem[]>([])
  const [pagination, setPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize:10});
  // Store Init
  const storeDispatch = useDispatch();
  // Fetching AuthState and Itemstate
  const authState = useSelector((state: IState) => state.authReducer);
  let user = authState.userId
  const itemState = useSelector((state: IState) => state.dataSourceReducer);
  const dataSourceState = useSelector(
    (state: IState) => state.dataSourceReducer
  );
  const { ItemFlag } = dataSourceState
  const {itemsData} = itemState;
  const items = itemsData?.items;
  const itemCount = itemsData?.count;
  // Destructuring
  const { Title, Paragraph, Text } = Typography;
  const { Search } = Input;
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { Dragger } = Upload;
  const { Column, ColumnGroup } = Table;
  // Base 64  Function
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  // Search
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const onSearch = (key: string) => {
    setSearchQuery(key);
  };

  //Table
  const onDelete = (id: number) => {
    storeDispatch(deleteItem({ids:[id]}));
  };
  const [rowKeys, setRowKeys] = useState<React.Key[]>();
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      let tempRowIds:any = []
      selectedRows.map((ele:any) => {
        if(ele != undefined){
          tempRowIds.push(ele.id)
        }
      })
      setRowKeys(tempRowIds);
    },
  };
  const handleBulkDeleteClick = () => {
    if (Array.isArray(rowKeys) && rowKeys?.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete these items?')) {
        // console.log(rowKeys)
        storeDispatch(deleteItem({ids: Array.from(rowKeys)}));
        setIsRowSelected(false);
        pagination.current = 1 
        setRowKeys([]);
      }
    }
    if (!isRowSelected || rowKeys?.length === 0) {
      OpenNotification('Please select item to delete', 'error');
    }
  }
  function onChange(pager:any) {
    pagination.current = pager.current;
    pagination.pageSize = pager.pageSize;
    setPagination(pagination);
    // storeDispatch(getWatchlistEntries());
    fetchItems(authState.userId, pagination.current, pagination.pageSize);
  }
  useEffect(()=>{
    if(items != undefined && itemCount != undefined){
      // @ts-ignore
      pagination.total = itemCount;
      setPagination(pagination);
      setTable(itemsData.items);
      setLoading(false);
    }
  }, [items]);
  useEffect(() => {
    fetchItems(
      authState.userId,
      1,
      pagination.pageSize,
      searchQuery
    );

  }, [ItemFlag]);

  useEffect(()=>{
    if(searchQuery !=undefined){
      fetchItems(
        authState.userId,
        1,
        pagination.pageSize,
        searchQuery
      );
    }
  }, [storeDispatch, searchQuery]);
  function fetchItems(
    userId:number, page_number:number,
    // eslint-disable-next-line @typescript-eslint/camelcase
    page_size:number, search?:string) {
    setLoading(true);
    storeDispatch(getItems({
      userId,
      pageSize:page_size,
      pageNumber:page_number,
      search
    } as any)
    );
    setLoading(false);
  }

  const getFileContentsForTemplate = () => {
    // const quotedFieldsOfTransporters= ['name', 'type', 'item_code', 'hsn_code', 'description', 'unit', 'price', 'cgst', 'sgst', 'igst', 'cess'].map((el) => `"${el}"`);
    // return quotedFieldsOfTransporters.join(',');
    const rows = [
      ['name', 'type', 'item_code', 'hsn_code', 'description', 'unit', 'price', 'cgst', 'sgst', 'igst', 'cess'],
      ['Sample1', 'Product', '1', '1002', 'bullet rice', 'BAGS', '100', 2.5, 2.5, 5, 0],
      ['Sample2', 'Service', '2', '1003', 'bullet rice', 'BAGS', '1000', 9, 9, 18, 0]
    ];
  
    let csvContent = 'data:text/csv;charset=utf-8,';
  
    // eslint-disable-next-line func-names
    rows.forEach(function (rowArray) {
      let row = rowArray.join(',');
      csvContent += row + '\r\n';
    });
    return csvContent;
  };
  // No pointer events if row selected
  const [isRowSelected,setIsRowSelected] = useState<boolean>(false)
  useEffect(() => {
    if(Array.isArray(rowKeys) && rowKeys?.length > 0){
      setIsRowSelected(true)
    }else {
      setIsRowSelected(false)
    }
  },[rowKeys])
  const col = [
    {
      title: 'Item Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '11%',
      render: (text : any) => (
        <span style={{textTransform:'capitalize'}}>
          {text}
        </span>
      )
    },
    {
      title: 'Item Code',
      dataIndex: 'item_code',
      key: 'item_code',
      width: '11%',
    },
    {
      title: 'HSN Code',
      dataIndex: 'hsn_code',
      key: 'hsn_code',
      width: '11%',
    },
    {
      title: 'Item description',
      dataIndex: 'description',
      key: 'description',
      width: '17%',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: '5%',
      render: (text : any) => (
        <span>
          {parseFloat(text).toFixed(2)}
          ₹
        </span>
      )
    },
    {
      title: 'CGST',
      dataIndex: 'cgst',
      key: 'cgst',
      width: '5%',
      render: (text : any) => (
        <span>
          {text}
          %
        </span>
      )
    },
    {
      title: 'SGST',
      dataIndex: 'sgst',
      key: 'sgst',
      width: '5%',
      render: (text : any) => (
        <span>
          {text}
          %
        </span>
      )
    },
    {
      title: 'IGST',
      dataIndex: 'igst',
      key: 'igst',
      width: '5%',
      render: (text : any) => (
        <span>
          {text}
          %
        </span>
      )
    },
    {
      title: 'CESS',
      dataIndex: 'cess',
      key: 'cess',
      width: '5%',
      render: (text : any) => (
        <span>
          {text}
          %
        </span>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions2',
      key: 'actions2',
      width: '10%',
      render: (text: any, record: any) => {
        return (
          <div className="iconContainer">
            <FiEdit3
              // style={{'pointerEvents': rowKeys.length > 0 ? 'none' : 'auto'}}
              className="iconContainer__item"
              onClick={() => history.push(`/items/editItem/${record.id}`)}
            />
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this item?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <RiDeleteBinLine
                className="iconContainer__item"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  // Bulk Upload Modal
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isFirstStageVisible, setIsFirstStageVisible] = useState(true);
  const [isSuccessScreenVisible, setIsSuccessScreenVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [errorListData, setErrorListData] =  useState([])
  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const handleUploadOk = () => {
    setIsUploadModalVisible(false);
  };

  const handleUploadCancel = () => {
    setIsUploadModalVisible(false);
    setIsFirstStageVisible(true); // To Hide Second stage for bulk upload
  };
  const showErrorModal = () =>{
    setModalVisible(false);
  }
  // Bulk Upload Drag and Drop
  const props = {
    name: 'file',
    multiple: false,
    headers: {
      authorization: `Token ${authState.token}`,
    },
    accept: '.csv, text/csv',
    action: `${process.env.REACT_APP_API_URL  }/kyss/files/items`,
    beforeUpload(i: any) {
      const isCSV = i.type === 'text/csv';
      if (!isCSV) {
        message.error(`${i.name} is not a csv file...`);
        OpenNotification(`${i.name} is not a csv file`, 'error');
      }
      return isCSV;
    },
    onChange(info: any) {
      console.log(info);
      const { status } = info.file;
      // console.log(status);
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        // console.log(`${info.file.name} file uploaded successfully.`);
        //OpenNotification(`${info.file.name} File Uploaded Successfully.`, 'success');
        if(info.file.response.data.created_item_count){
          OpenNotification(`${info.file.response.data.created_item_count} items added`, 'success');
        }
        if(info.file.response.data.error_list.length){
          setErrorListData(info.file.response.data.error_list);
          setModalVisible(true);
          // info.file.response.data.error_list.map((ele:any) => {
          //   OpenNotification(ele, 'error');
          // })
        }
        handleUploadCancel();
        fetchItems(
          authState.userId,
          1,
          pagination.pageSize,
          searchQuery
        );
      } else if (status === 'error') {
        // console.log(`${info.file.name} file upload failed.`);
        OpenNotification(`${info.file.name} File Upload Failed.`, 'error');
      }
    },
    onDrop(e: any) {
      // console.log(e);
      // console.log('Dropped files', e.dataTransfer.files);
    },
    // progress: {
    //   strokeColor: {
    //     '0%': '#4532bf',
    //     '100%': '#87d068',
    //   },
    //   strokeWidth: 3,
    //   format: (percent:any) => `${parseFloat(percent.toFixed(2))}%`,
    // },
  };
  const downloadFileContents = () => {
    const rows = [
      ['name', 'type', 'item_code', 'hsn_code', 'description', 'unit', 'price', 'cgst', 'sgst', 'igst', 'cess'],
      ['Sample1', 'Product', '1', '1002', 'bullet rice', 'BAGS', '100', 2.5, 2.5, 5, 0],
      ['Sample2', 'Service', '2', '1003', 'bullet rice', 'BAGS', '1000', 9, 9, 18, 0]
    ];
  
    let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "item_template.csv");
    document.body.appendChild(link); // Required for FF
  
    link.click();
  };

  return (
    <main className="">
      {/* Bulk Upload Modal */}
      <Modal
        className="bulkUploadModal"
        title=""
        centered
        visible={isUploadModalVisible}
        onOk={handleUploadOk}
        onCancel={handleUploadCancel}
        footer={[]}
      >
        {isFirstStageVisible && (
          <section className="uploadSection">
            <h3 className="uploadSection__title">
              You need to download template, add your items to it and upload the
              file
            </h3>
            <div className="uploadSection__done">
              <p className="uploadSection__done__text">Already done ?</p>
            </div>
            <div className="uploadSection__download">
              {/* <a
                href={`${getFileContentsForTemplate()}`}
                download="item_template.csv"
                className="uploadSection__download__text"
              >
                Download Template
              </a> */}
              <p
                // type='link'
                onClick={() => downloadFileContents()}
                className="uploadSection__download__text--btn"
                
                >
                Download Template
              </p>
              <Button
                className="uploadSection__download__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                }}
                onClick={() => setIsFirstStageVisible(false)}
              >
                Upload file
              </Button>
            </div>
          </section>
        )}
        {!isFirstStageVisible && !isSuccessScreenVisible && (
          <section className="fileupload-section">
            <Title level={3} className="fileupload-section__title">
              Bulk upload items
            </Title>
            <div className="fileupload-section__dragger">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Dragger {...props}>
                <p className="fileupload-section__dragger__text">
                  Drag & drop <br></br>file
                </p>
              </Dragger>
            </div>
            <div className="fileupload-section__browse">
              <Upload {...props}>
                <Button className="fileupload-section__browse__btn">
                  Browse
                </Button>
              </Upload>
            </div>
          </section>
        )}
        {!isFirstStageVisible && isSuccessScreenVisible && (
          <section className="success-section">
            <div className="success-section__icon">
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: '24px' }}
              />
            </div>
            <h2 className="success-section__text">40 items added</h2>
            <div className="success-section__btnbox">
              <Button
                className="success-section__btnbox__btn"
                onClick={handleUploadCancel}
              >
                Ok
              </Button>
            </div>
          </section>
        )}
      </Modal>

      {/* endModal */}
      <Modal 
      width={1000}
      centered
      className='itemErrorlistModal'
      visible={isModalVisible} 
      title='Errors in uploading file' 
      footer={[]} 
      onCancel={showErrorModal}>
        <p>
          {errorListData.map(errors => (
            <h4>
              {errors}
            </h4>
          ))}
        </p>

      </Modal>
      <Row>
        <Title level={3}>Item List</Title>
      </Row>

      <section className="items">
        <div className="items__add">
          <Button
            className="items__add__btn"
            // onClick={showModal}
            onClick={() => history.push(ROUTES.ADD_ITEM)}
          >
            <PlusOutlined className='btn-icon-padding'/>
            Add item
          </Button>
          <Button
            className="items__add__btn items__add__btn--bg"
            onClick={showUploadModal}
          >
            Bulk upload items
          </Button>
        </div>
        <div className="items__search">
          <div className="items__search__bar">
            <Search placeholder="Search by item name/HSN" className='date-picker-border-radius' onSearch={onSearch} />
          </div>
          <div className="items__search__delete">
            <Button className="items__search__delete--btn" onClick={() => handleBulkDeleteClick()}>
              Delete item/s
            </Button>
          </div>
        </div>
      </section>
      <div className="items__table custom_table">
        <div className="items__table__subtitlebox">
          <p className="items__table__subtitlebox--text">Tax rates</p>
        </div>
        <Table
          columns={col}
          rowKey={(record) => record.id}
          dataSource={table}
          loading={loading}
          pagination={pagination}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          onChange={onChange}
         />
      </div>
    </main>
  );
};
export default Items;
