const EMAIL_REGEX = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
const IN_PHONE_NUMBER_REGEX = /^(?:(?:\+|0{0,2})91(\s*[ -]\s*)?|[0]?)?[56789]\d{9}|(\d[ -]?){10}\d$/;//
const IN_PHONE_NUMBER_10 = /[1-9]{1}[0-9]{9}/;
const GSTIN_REGEX = /([0-9]{2})([A-Z]{5}[0-9]{4}[A-Z])[1-9A-Z][A-Z][0-9A-Z]/;
const NAME_REGEX = /^[a-zA-Z ]{2,30}$/;

export default class ValidationService {
  public static verifyRequiredFields(
    values: object,
    requiredFields?: string[]
  ) {
    const errors: any = {};
    Object.entries(values).map(
      // eslint-disable-next-line array-callback-return
      (entry: [string, string]) => {
        const fieldName = entry[0];
        const fieldValue = entry[1];
        if (requiredFields) {
          if (requiredFields.includes(fieldName) && !fieldValue) {
            errors[fieldName] = 'Required';
          }
        } else if (!fieldValue) {
          errors[fieldName] = 'Required';
        }
      }
    );
    return errors;
  }

  // TODO: Eventually required field needs to be mandatory
  public static validateFields(
    values: object,
    fieldToFnMap: any,
    requiredFields?: string[]
  ) {
    const reqErrors = ValidationService.verifyRequiredFields(
      values,
      requiredFields
    );
    const validationErrors: any = {};
    Object.entries(values).map(
      // eslint-disable-next-line array-callback-return
      (entry: [string, string]) => {
        const fieldName = entry[0];
        const fieldValue = entry[1];
        const validatorFnForField = fieldToFnMap[fieldName];
        if (
          fieldName &&
          fieldValue &&
          validatorFnForField &&
          !validatorFnForField(fieldValue)
        ) {
          validationErrors[fieldName] = 'Please check and enter again!';
        }
      }
    );
    return {
      ...validationErrors,
      ...reqErrors,
    };
  }

  public static isEmail(value: string): boolean {
    return EMAIL_REGEX.test(value);
  }

  public static isIndianPhoneNumber(value: string): boolean {
    return value.length ===10 && IN_PHONE_NUMBER_REGEX.test(value);
  }

  public static isGstin(value: string) {
    return GSTIN_REGEX.test(value);
  }

  public static isName(value: string) {
    return NAME_REGEX.test(value);
  }
}
