import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../constants/routePaths';
import StylizedButton from './stylizedButton';

export default function BackToLoginBtn() {
  const history = useHistory();
  return (
    <StylizedButton
      size="middle"
      onClickFn={() => {
        history.push(ROUTES.LOGIN);
      }}
      textInButton="Go back to login"
    />
  );
}
