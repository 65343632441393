import React, { useEffect, useState } from 'react';
import {
  Card,
  Form,
  Input,
  Button,
  Select,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from '../constants/routePaths';
import { THEME } from '../constants/theme';
import {
  addItem,
  updateItem
} from '../store/actions/dataSourceActions';
import {IState} from '../models';

export interface IItemForm {
  name: string;
  type: string;
  item_code: string;
  hsn_code: string;
  description: string;
  unit: string;
  price: any;
  cgst: any;
  sgst: any;
  igst: any;
  cess: any;
  user_id? : number;
  id?:number
}

const AddItem = () => {
  // Destructing
  const { TextArea } = Input;
  const { Option } = Select;
  // Initialization
  const history = useHistory();
  const params:any = useParams();

  // Form
  const [item_form] = Form.useForm();
  const [itemInput, setItemInput] = React.useState<IItemForm>({
    name: '',
    type: '',
    item_code: '',
    hsn_code: '',
    description: '',
    unit:'',
    price: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    cess: 0,
  });
  const itemState = useSelector((state: IState) => state.dataSourceReducer);
  const {itemsData} = itemState;
  const items = itemsData?.items;


  const [isItemEdited,setIsItemEdited] = useState(false)
  React.useEffect(()=>{
    if (params.id) {
      setIsItemEdited(true)
      let temp = items.filter(function (i) {
        return i.id == params.id;
      });
      if (temp.length > 0) {
        // itemInput = temp[0];
        // setItemInput(prevState => [...prevState, temp[0]])
        setItemInput({...temp[0]});
      }
    }else {
      setIsItemEdited(false)
    }
  }, [params.id]);




  const onItemChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setItemInput((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSelectChange = (value: string) => {
    if(value !== undefined){
      setItemInput((prevState) => {
        return { ...prevState, 'type': value };

      });
    }
  };
  const handleSelectUnitChange = (value: string) => {
    if(value !== undefined){
      setItemInput((prevState) => {
        return { ...prevState, 'unit': value };

      });
    }
  };
  // eslint-disable-next-line @typescript-eslint/camelcase
  const unit_options: any = [
    { 'label': 'BAGS', 'value': 'BAG' },
    { 'label': 'BALE', 'value': 'BAL' },
    { 'label': 'BUNDLES', 'value': 'BDL' },
    { 'label': 'BUCKLES', 'value': 'BKL' },
    { 'label': 'BILLIONS OF UNITS', 'value': 'BOU' },
    { 'label': 'BOX', 'value': 'BOX' },
    { 'label': 'BOTTLES', 'value': 'BTL' },
    { 'label': 'BUNCHES', 'value': 'BUN' },
    { 'label': 'CANS', 'value': 'CAN' },
    { 'label': 'CUBIC METER', 'value': 'CBM' },
    { 'label': 'CUBIC CENTIMETER', 'value': 'CCM' },
    { 'label': 'CENTIMETER', 'value': 'CMS' },
    { 'label': 'CARTONS', 'value': 'CTN' },
    { 'label': 'DOZEN', 'value': 'DOZ' },
    { 'label': 'DRUM', 'value': 'DRM' },
    { 'label': 'GREAT GROSS', 'value': 'GGR' },
    { 'label': 'GRAMS', 'value': 'GMS' },
    { 'label': 'GROSS', 'value': 'GRS' },
    { 'label': 'GROSS YARDS', 'value': 'GYD' },
    { 'label': 'KILOGRAMS', 'value': 'KGS' },
    { 'label': 'KILOLITER', 'value': 'KLR' },
    { 'label': 'KILOMETRE', 'value': 'KME' },
    { 'label': 'MILLILITRE', 'value': 'MLT' },
    { 'label': 'METERS', 'value': 'MTR' },
    { 'label': 'METRIC TONS', 'value': 'MTS' },
    { 'label': 'NUMBERS', 'value': 'NOS' },
    { 'label': 'PACKS', 'value': 'PAC' },
    { 'label': 'PIECES', 'value': 'PCS' },
    { 'label': 'PAIRS', 'value': 'PRS' },
    { 'label': 'QUINTAL', 'value': 'QTL' },
    { 'label': 'ROLLS', 'value': 'ROL' },
    { 'label': 'SETS', 'value': 'SET' },
    { 'label': 'SQUARE FEET', 'value': 'SQF' },
    { 'label': 'SQUARE METERS', 'value': 'SQM' },
    { 'label': 'SQUARE YARDS', 'value': 'SQY' },
    { 'label': 'TABLETS', 'value': 'TBS' },
    { 'label': 'TEN GROSS', 'value': 'TGM' },
    { 'label': 'THOUSANDS', 'value': 'THD' },
    { 'label': 'TONNES', 'value': 'TON' },
    { 'label': 'TUBES', 'value': 'TUB' },
    { 'label': 'US GALLONS', 'value': 'UGS' },
    { 'label': 'UNITS', 'value': 'UNT' },
    { 'label': 'YARDS', 'value': 'YDS' },
    { 'label': 'OTHERS', 'value': 'OTH' },
  ];
  const unit_options_display:any = [];
  for(let i =0; i<unit_options.length; i++){
    unit_options_display.push(<Option key={unit_options[i].value} value={unit_options[i].value}>{unit_options[i].label}</Option>);
  }

  // Store Init
  const storeDispatch = useDispatch();
  // Fetching AuthState
  const authState = useSelector((state: IState) => state.authReducer);
  const onAddItemFinish = (values: any) => {
    if(values.price == ""){
      values["price"] = "0"
    }
    if(values.cess == ""){
      values["cess"] = "0"
    }
    if(params.id){
    storeDispatch(
      updateItem({
        ...values,
        id:params.id
      })
    );
  }else{
    storeDispatch(
      addItem({
        ...values
      })
    );
  }
    item_form.resetFields();
    history.goBack()
  };

  const onAddItemFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const SGST_CGST_CHOICES = [
    "0",
    "2.5",
    "6",
    "9",
    "14"
  ]
  const IGST_CHOICES = [
    "0",
    "5",
    "12",
    "18",
    "28"
  ]
  const onSelectChange = (name: any,value:any) => {
    setItemInput((prevState) => {
      const igstValue = value * 2;
      item_form.setFieldsValue({'sgst': value, 'igst': igstValue});
      return { ...prevState, [name]: value, 'sgst': value, 'igst': (igstValue.toString()) };
    });
  };

  React.useEffect (() => {
    if(!params.id){
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    let result = ""
    for(let i=0;i<6;i++){
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if(result){
      setItemInput(
        (prevState:any) => {
        return {
          ...prevState,
          'item_code': result
        }
      }
      )
    }

   }
  },[]);
  // const {price,cess} = itemInput
  // useEffect(() => {
  //   if(itemInput.price == ""){
  //     setItemInput(
  //       (prevState:any) => {
  //       return {
  //         ...prevState,
  //         'price': "0"
  //       }
  //     }
  //     )
  //   }

  //   if(itemInput.cess == ""){
  //     setItemInput(
  //       (prevState:any) => {
  //       return {
  //         ...prevState,
  //         'cess': "0"
  //       }
  //     }
  //     )
  //   }
  // },[cess,price])
  item_form.setFieldsValue({...itemInput});
  return (
    <main className="">
      <header className="header">
        <ArrowLeftOutlined 
          style={{marginTop:'0px'}}
          className="header__icon"
          onClick={() => history.goBack()}
        />
        <h3 className="header__title">{params.id ? 'Update Item' : 'Add Item'}</h3>
      </header>
      <section className="item">
        <h4 className="item__title">Enter details</h4>

        <Form
          form={item_form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onAddItemFinish}
          onFinishFailed={onAddItemFailed}
          autoComplete="off"
          className="item__card"
        >
          <Card>
            <div className="item__card__grid">
              <Form.Item label="Item name" name="name" rules={[{ required: isItemEdited ? false : true, message: 'Item name is a manadatory field'}]}>
                <Input
                  name="name"
                  // value={itemInput.name}
                  onChange={onItemChange}
                  placeholder={itemInput.name ? itemInput.name : "Enter item name"}
                  className='input-class'
                />
              </Form.Item>
              <Form.Item label="Type" name="type" rules={[{ required: isItemEdited ? false : true, message: 'Type is a manadatory field'}]}>
                <Select
                  className='select-border-radius'
                  size='large'
                  placeholder={itemInput.type ? itemInput.type : "Product/Service"}
                  onChange={handleSelectChange}
                >
                  <Option value="Product">Product</Option>
                  <Option value="Service">Service</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Item code" name="item_code" rules={[{ required: isItemEdited ? false : true, message: 'Item Code is a manadatory field'},{ pattern: new RegExp(/^[A-Za-z0-9_@./#&+-]{6,6}$/),
                message: 'Enter a 6 digit item code'}]}>
                <Input
                  name="item_code"
                  className='input-class'
                  //onclick={autoGenerateItemCode}
                  // value={itemInput.item_code}
                  onChange={onItemChange}
                  placeholder={itemInput.item_code ? itemInput.item_code :"Enter item code"}
                  
                />
              </Form.Item>
              <Form.Item label="HSN Code" name="hsn_code" rules={[{ required: isItemEdited ? false : true, message: 'HSN Code is a manadatory field'},
              { pattern: new RegExp("^[0-9]*$"),message:"Please enter digit values"},
              ({ getFieldValue }) => ({
                  validator() {
                  if(isItemEdited && getFieldValue('hsn_code').length == 0) {
                    return Promise.reject(new Error("HSN code can not be empty"))
                  }
                  return Promise.resolve();
                }
                }),]}>
                <Input
                  name="hsn_code"
                  className='input-class'
                  // value={itemInput.hsn_code}
                  onChange={onItemChange}
                  placeholder={itemInput.hsn_code ? itemInput.hsn_code : "Enter HSN code"}
                />
              </Form.Item>
              <Form.Item label="Item Description" name="description" rules={[{ required: isItemEdited ? false : true, message: 'Item Description is a manadatory field'}]}>
                <Input
                  name="description"
                  className='input-class'
                  // value={itemInput.description}
                  onChange={onItemChange}
                  placeholder={itemInput.description ? itemInput.description :"Enter Item Description"}
                />
              </Form.Item>
              <Form.Item label="Unit" name="unit" rules={[{ required: isItemEdited ? false : true, message: 'Unit is a manadatory field'}]}>
                <Select
                  className='select-border-radius'
                  size='large'
                  showSearch
                  optionFilterProp="children"
                  placeholder={itemInput.unit ? itemInput.unit : 'Select Unit'}
                  onChange={handleSelectUnitChange}
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                 >
                  {unit_options_display}
                </Select>
              </Form.Item>
              <Form.Item label="Price per Unit" name="price" rules={[{ required: isItemEdited ? false : true, message: 'Price is a manadatory field'}]}>
                <Input
                  className='input-class'
                  name="price"
                  type="number"
                  onChange={onItemChange}
                  min="0"
                  step="0.01"
                  placeholder={itemInput.price ? itemInput.price : "Enter Price"}
                />
              </Form.Item>
            </div>
            <div className="item__card__taxgrid">
              <h4 className="item__card__taxgrid--text">Tax rates for</h4>
              <Form.Item label="CGST" name="cgst" rules={[{ required: isItemEdited ? false : true, message: 'cgst is a manadatory field'}, {pattern: new RegExp(/^[+-]?\d+(\.\d+)?$/), message:'Enter a cgst'}]}>
                <Select
                  className='select-border-radius'
                  size='large'
                  // value={itemInput.cgst}
                  onChange={(value) => onSelectChange("cgst",value)}
                  placeholder={itemInput.cgst  ? itemInput.cgst :"Enter % rate"}
                >
                  {
                    SGST_CGST_CHOICES.map((ele) =><Option value={ele}>{ele}</Option>)
                  }
               </Select>
              </Form.Item>
              <Form.Item label="SGST" name="sgst" rules={[{ required: isItemEdited ? false : true, message: 'sgst is a manadatory field'}, {pattern: new RegExp(/^[+-]?\d+(\.\d+)?$/), message:'Enter a sgst'}]}>
                <Select
                  className='select-border-radius'
                  size='large'
                  // value={itemInput.sgst}
                  // onChange={(value) => onSelectChange("sgst",value)}
                  placeholder={itemInput.sgst  ? itemInput.sgst :"Enter % rate"}
                  disabled={true}
                >
                {
                    SGST_CGST_CHOICES.map((ele) =><Option value={ele}>{ele}</Option>)
                  }
                </Select>
              </Form.Item>
              <Form.Item label="IGST" name="igst" rules={[{ required: isItemEdited ? false : true, message: 'Igst is a manadatory field'}, {pattern: new RegExp(/^[+-]?\d+(\.\d+)?$/), message:'Enter a Igst'}]}>
                <Select
                  className='select-border-radius'
                  size='large'
                  // value={itemInput.igst}
                  // onChange={(value) => onSelectChange("igst",value)}
                  placeholder={itemInput.igst  ? itemInput.igst :"Enter % rate"}
                  disabled={true}
                >
                  {
                    IGST_CHOICES.map((ele) =><Option value={ele}>{ele}</Option>)
                  }
                   </Select>
              </Form.Item>
              <Form.Item label="CESS" name="cess" rules={[{ required: false , message: 'Cess is a manadatory field'}, {pattern: new RegExp(/^[+-]?\d+(\.\d+)?$/), message:'Enter a Cess'}]}>
                <Input
                  className='input-class'
                  name="cess"
                  type="number"
                  value={itemInput.cess}
                  onChange={onItemChange}
                  min="0"
                  step="0.01"
                  placeholder={itemInput.cess ? itemInput.cess :"Enter % rate"}
                />
              </Form.Item>
            </div>
          </Card>
          <div className="formbtnbox" style={{  marginBottom: '4rem'}}>
            <Form.Item>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <p
                className="formbtnbox__btn formbtnbox__btn--paragraph"
                style={{
                  color: THEME.PRIMARY_COLOR,
                }}
                onClick={() => history.goBack()}
              >
                Cancel
              </p>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="formbtnbox__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR
                }}
              >
                {params.id ? 'Update' : 'Add'}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </section>
    </main>
  );
};

export default AddItem;
