import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Button, Card } from 'antd';
import {useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import {
  genSupRepIcon1,
  genSupRepIcon2,
} from '../../utils/constants';
import   './v2.css';
import { useDispatch } from 'react-redux';
import { setTransparent } from '../../store/actions/authActions';
import GenerateSupplierReports from './GenerateSupplierReports';
import SupplierReportView from './SupplierReportView';
import ReconCenterReportView from './ReconCenterReportView';
import ReconCenterReport from './ReconCenterReport';
import SupplierReports from './SupplierReports';
import { ROUTES } from '../../constants/routePaths';
import OlderSupplierReportView from './OlderSupplierReport';

const queryString = require('query-string');

const { TabPane } = Tabs;

function  Main(props:any){
  const history = useHistory();
  const { onClickView, onClickGenerate, toggleSupplierReconReport, reconReportView}=props;

  
  function generateReconReoprt(){
    history.push(ROUTES.RECONCILATION);
  }

  return (
    <>
      <Row>
        <Col sm={24}>
          <Title level={3}>Report Central</Title>
        </Col>
      </Row>
   
      <Row>
        <Col sm={5} style={{ textAlign: 'center' }} />
        <Col sm={7} style={{ background: '#ffffff', textAlign: 'center', marginRight: '5px' }}>
          <Card bordered>
            <img
              style={{ marginTop: '30px', marginLeft: '10px' }}
              src={
                  genSupRepIcon1
                }
            />
            <Title level={4} style={{ fontSize: '15px', marginTop: '5%' }}>Generate Supplier Report</Title>
            <Button type="primary" danger style={{ width: '18vw', borderRadius:'5px',backgroundColor:'#462E90',borderColor:'#462E90'}} onClick={onClickGenerate}>
              Generate
            </Button>
            <br/>
            <br/>
            <span style={{color:'#462E90', textDecoration: 'underline',  cursor: 'pointer'}}  onClick={onClickView}> View all GST Reports</span>
            
          </Card>

        </Col>

        <Col sm={7} style={{ background: '#ffffff', textAlign: 'center', marginLeft: '5px' }}>
          <Card bordered>
            <img
              style={{ marginTop: '30px', marginLeft: '10px' }}
              src={
                  genSupRepIcon2
                }
            />
              
            <Title level={4} style={{ fontSize: '15px', marginTop: '5%' }}>Generate Recon Report</Title>
            <Button type="primary" danger style={{ width: '18vw', borderRadius:'5px',backgroundColor:'#462E90',borderColor:'#462E90'}} onClick={generateReconReoprt}>
              Generate
            </Button>
            <br/>
            <br/>
            <span style={{color:'#462E90', textDecoration: 'underline',  cursor: 'pointer'}}  onClick={reconReportView}> View all Recon Reports</span>
            
          </Card>

        </Col>

        <Col sm={5} style={{ textAlign: 'center' }} />
      </Row>
    </>
  );
}



const ReportCenter: React.FunctionComponent = (props:any) => {
  const history = useHistory();
  const [supplireReport, setSupplireReport] = useState(0);
  const [reportId, setReportId] = useState(10);

  React.useEffect(() => {
    if(props && props.location && props.location.state !== null && props.location.state.setReconReportView == true){
      setSupplireReport(4)
    }
  },[props])

  const storeDispatch = useDispatch();
  useEffect(() => { 
    storeDispatch(setTransparent());
  }, []);
  const urlParams = queryString.parse(history.location.search) || {};


  function onClickGenerate(){
    // history.push(ROUTES.DOWNLOAD_CENTER_V2_UPLOAD)
    setSupplireReport(1);
  }
  function onResetGenerate(){
    // history.push(ROUTES.DOWNLOAD_CENTER_V2_UPLOAD)
    setSupplireReport(0);
  }
  function onViewReport(id:any){
    // history.push(ROUTES.DOWNLOAD_CENTER_V2_UPLOAD)
    setSupplireReport(3);
    setReportId(id);
  }
  function onViewReconReport(id:any){
    // history.push(ROUTES.DOWNLOAD_CENTER_V2_UPLOAD)
    setSupplireReport(5);
    setReportId(id);
  }
  function toggleSupplierReconReport(tab:number){
    // if(supplireReport != 2 && supplireReport != 6){
    //   setSupplireReport(4);
    // }
    // if(supplireReport != 4 && supplireReport != 6){
    //   setSupplireReport(2);
    // }
    // if(supplireReport != 2 && supplireReport != 4){
    //   setSupplireReport(6);
    // }
    setSupplireReport(tab)
    
  }
  function reconReportView(){
    setSupplireReport(4);
  }
  function onClickView(){
    // history.push(ROUTES.DOWNLOAD_CENTER_V2_VIEW)
    setSupplireReport(2);
  }
  
  if(supplireReport==1){
    return (
    
      <GenerateSupplierReports onResetGenerate={onResetGenerate} onClickView={onClickView} />
    );
  }

  if(supplireReport==2){
    return (
    
      <SupplierReports data={onResetGenerate} viewReport={onViewReport} onClickGenerate={onClickGenerate} tab={toggleSupplierReconReport} />
    );
  }

  if(supplireReport==3){
    return (
    
      <SupplierReportView data={onClickView} reportid={reportId} />
    );
  }

  

  if(supplireReport==4){
    return (
    
      <ReconCenterReportView data={onResetGenerate} viewReport={onViewReconReport} tab={toggleSupplierReconReport} />
    );
  }
  

  if(supplireReport==5){
    return (
    
      <ReconCenterReport data={onClickView} reportid={reportId} />
    );
  }

  if(supplireReport==6){
    return (
    
      <OlderSupplierReportView data={onResetGenerate} viewReport={onViewReconReport} tab={toggleSupplierReconReport}/>
    );
  }
  

  
  return (
    <Main 
        onClickView={onClickView} 
        reconReportView={reconReportView}
        onClickGenerate={onClickGenerate}  
        toggleSupplierReconReport={toggleSupplierReconReport}/>
  );
};

export default ReportCenter;
