import { put, takeEvery } from 'redux-saga/effects';
import { IAction, IColleague } from '../../models';
import {
  addColleagueSuccess,
  ColleagueActions,
  requestColleagueDeleteSuccess,
  requestColleagueList,
  requestColleagueUpdateSuccess,
  requestColleagureListSuccess,
} from '../actions/colleagueActions';
import {
  IRequestAddColleague,
  IRequestColleagueUpdate,
  IResponseAddColleague,
  IResponseColleagueList,
  IResponseColleagueUpdate,
} from '../../models/api/colleagueModels';
import { globalApiService } from '../../services/apiService';
import { exceptionLogger } from './utils';

function* requestColleagueWorker(_: IAction) {
  const response = yield globalApiService.requestColleagueList();
  const responseBody = response.data;
  if (response && responseBody) {
    yield put(
      requestColleagureListSuccess(responseBody as IResponseColleagueList)
    );
  }
}

function* requestColleagueUpdateWorker(action: IAction) {
  const actionBody: IRequestColleagueUpdate = action.body;
  const response = yield globalApiService.requestColleagueUpdate(actionBody);
  const responseBody: IResponseColleagueUpdate = response.data;
  if (response && responseBody) {
    yield put(requestColleagueUpdateSuccess(responseBody));
    yield put(requestColleagueList());
  }
}

function* requestColleagueDeleteWorker(action: IAction) {
  const actionBody: IColleague = action.body;
  const colleagueId: number = actionBody.id;
  yield globalApiService.requestColleagueDelete(actionBody);
  yield put(requestColleagueDeleteSuccess({ id: colleagueId }));
}

function* addColleagueWorker(action: IAction) {
  const actionBody: IRequestAddColleague = action.body;
  const response = yield globalApiService.addColleague(actionBody);
  const responseBody: IResponseAddColleague = response.data;
  if (response && responseBody) {
    yield put(addColleagueSuccess(responseBody));
  }
}

export default function* ColleagueWatcher(): IterableIterator<any> {
  yield takeEvery(ColleagueActions.REQUEST_COLLEAGUE_LIST, (action) =>
    exceptionLogger(requestColleagueWorker(action))
  );
  yield takeEvery(ColleagueActions.REQUEST_COLLEAGUE_UPDATE, (action) =>
    exceptionLogger(requestColleagueUpdateWorker(action))
  );
  yield takeEvery(ColleagueActions.REQUEST_COLLEAGUE_DELETE, (action) =>
    exceptionLogger(requestColleagueDeleteWorker(action))
  );
  yield takeEvery(ColleagueActions.ADD_COLLEAGUE, (action) =>
    exceptionLogger(addColleagueWorker(action))
  );
}
