import ColorPicker from "rc-color-picker";
import React from 'react'
const ImportedColorPicker = ({onColorChange,index, color}) => {
    return (
        <ColorPicker
        color={color}
        // alpha={30}
        onChange={(colors) =>onColorChange(colors.color,index)}
        onClose={() => {}}
        placement="topLeft"
        className="some-class"
        enableAlpha={false}
      >
        <div className="color-picker"></div>
      </ColorPicker>
    )
}
export default ImportedColorPicker;