import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons/lib';
import { Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import Checkbox from 'antd/es/checkbox';
import { Row } from 'antd/es/grid';
import Col from 'antd/es/grid/col';
import { IState } from '../models';
import { globalApiService } from '../services/apiService';
import StylizedButton from './stylizedButton';
import { requestFieldsOfDataSource } from '../store/actions/dataSourceActions';
import OpenNotification from '../utils/notification';

const { Dragger } = Upload;

const UploadFileComponent = () => {
  const authState = useSelector((state: IState) => state.authReducer);
  const { dataSourceName, dataSourceId } = authState;
  const dataSourceState = useSelector(
    (state: IState) => state.dataSourceReducer
  );
  const { fieldsOfDataSource } = dataSourceState;
  const storeDispatch = useDispatch();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isValidationError, setIsValidationError] = useState<boolean>(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [
    shouldGenerateSupplierReport,
    setShouldGenerateSupplierReport,
  ] = useState<boolean>(true);
  const [
    shouldSendNotificationsGSTR1,
    setShouldSendNotificationsGSTR1,
  ] = useState<boolean>(true);
  const [
    shouldSendNotificationsGSTR3B,
    setShouldSendNotificationsGSTR3B,
  ] = useState<boolean>(true);

  useEffect(() => {
    if (!fieldsOfDataSource) {
      storeDispatch(requestFieldsOfDataSource({ dataSourceId }));
    }
  }, [storeDispatch, fieldsOfDataSource, dataSourceId]);

  const onUpload = () => {
    setIsUploading(true);
    globalApiService
      .uploadSuppliersFile({
        fileObject: fileList[0],
        dataSourceName,
        description: `Uploaded file at ${new Date().toLocaleDateString()}`,
        shouldGenerateSupplierReport,
        shouldSendNotificationsGSTR1,
        shouldSendNotificationsGSTR3B,
      })
      .then((_) => {
        OpenNotification(
          'File uploaded successfully. The system is capturing the data. The report will be shared on your email ID!',
          'success'
        );
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        if (errorBody) {
          const { success, message: errorMessage } = errorBody;
          if(
            success === false &&
            errorMessage &&
            errorMessage === "Empty file can't be uploaded" 
          ){
            setIsValidationError(true);
          } else if (
            success === false &&
            errorMessage &&
            (errorMessage as string)?.includes('Extra Columns present in file')
          ) {
            setIsValidationError(true);
          }
        }
      });
    setIsUploading(false);
  };

  const UploadDraggerComponent = () => {
    return (
      <Dragger
        accept=".csv"
        multiple={false}
        disabled={isUploading}
        beforeUpload={(file) => {
          if (!['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type)) {
            OpenNotification('Only csv files are allowed', 'error');
            return false;
          }
          if (fileList.length < 1) {
            setFileList([...fileList, file]);
          } else {
            OpenNotification(
              'Only one file can be uploaded. ' +
                'Please remove file previously uploaded from this screen.',
              'error'
            );
          }
          return false;
        }}
        fileList={fileList}
        onRemove={(file: UploadFile) => {
          const updatedFileList: RcFile[] = fileList;
          const deletedFileIndex: number = updatedFileList.findIndex(
            (el) => el.name === file.name
          );
          if (deletedFileIndex > -1) {
            updatedFileList.splice(deletedFileIndex, 1);
          }
          setFileList([...updatedFileList]);
          setIsValidationError(false);
        }}
      >
        <p>
          {isValidationError ? (
            <p style={{ color: 'red' }}>
              Validation failed. Please recheck and upload the file
            </p>
          ) : (
            <p>
              Adding your Suppliers to KYSS is easy. Just upload your Supplier
              list as per the template and you are done
            </p>
          )}
        </p>
        <UploadOutlined style={{ fontSize: '50px', padding: '50px' }} />
        <p>Drag and drop or browse your files</p>
      </Dragger>
    );
  };

  const getFileContentsForTemplate = () => {
    const quotedFieldsOfDataSource = fieldsOfDataSource.map((el) => `"${el}"`);
    return quotedFieldsOfDataSource.join(',');
  };

  return (
    <div>
      <Row>
        <Col flex="auto" style={{ paddingBottom: '30px', paddingTop: '30px' }}>
          <UploadDraggerComponent />
        </Col>
      </Row>

      <Row justify="end">
        {fieldsOfDataSource && (
          <a
            href={`data:text/csv;charset=utf-8,${getFileContentsForTemplate()}`}
            download="jitfinTemplate.csv"
          >
            Download Template
          </a>
        )}
      </Row>

      <Row>
        <Col>
          <Checkbox
            onChange={(e) => {
              setShouldGenerateSupplierReport(e.target.checked);
            }}
            defaultChecked
          >
            Generate supplier report after a successful file upload
          </Checkbox>
        </Col>
      </Row>

      <Row>
        <Col>
          <Col>
            <Checkbox
              onChange={(e) => {
                setShouldSendNotificationsGSTR1(e.target.checked);
              }}
              defaultChecked
            >
              Send email notifications to Suppliers who have not filed latest
              GSTR1
            </Checkbox>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col>
          <Checkbox
            onChange={(e) => {
              setShouldSendNotificationsGSTR3B(e.target.checked);
            }}
            defaultChecked
          >
            Send email notifications to Suppliers who have not filed latest
            GSTR3B
          </Checkbox>
        </Col>
      </Row>

      <Row justify="center">
        <Col style={{ paddingTop: '30px' }}>
          <StylizedButton
            onClickFn={onUpload}
            textInButton="Upload"
            size="large"
            disabled={fileList.length < 1}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UploadFileComponent;
