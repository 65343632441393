// eslint-disable-next-line import/prefer-default-export
export const ROUTES = {
  LOGIN: '/login',
  SIGN_UP: '/signup',
  TERMS_AND_CONDITIONS: '/termsAndConditions',
  VERIFY_OTP: '/verifyOtp',
  GET_USER_GSTIN: '/getUserGstin',
  GET_ANCHOR_DETAILS: '/getAnchorDetails',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  COMPANY_PROFILE: '/companyProfile',
  MANAGE_SUPPLIERS: '/manageSuppliers',
  MANAGE_COLLEAGUES: '/manageColleagues',
  UPLOAD_SUPPLIERS_TO_DATA_SOURCE: '/uploadSuppliersToDataSource',
  DOWNLOAD_CENTER: '/downloadCenter',
  DOWNLOAD_CENTER_V2: '/downloadCenterv2',
  
  RECONCILATION: '/reconcilation',
  RECONCIL_SUMMARY: '/reconcil-summary/:id',
  RECONCIL_REPORT: '/reconcil-report/:id',
  SIGN_UP_SUCCESS: '/signup-success',
  ZOHO_CRM: '/zoho-crm',
  MANAGE_CUSTOMERS:'/customers',
  ADD_CUSTOMER: '/customers/addCustomer',
  EDIT_CUSTOMER: '/customers/editCustomer/:id',
  CUSTOMER_DETAILS: '/customers_details',
  MANAGE_SUPPLIERS_NEW:'/suppliers',
  ITEMS: '/items',
  ADD_ITEM: '/items/addItem',
  EDIT_ITEM: '/items/editItem/:id',
  TRANSPORTERS: '/transporters',
  GSTIN_SEARCH:'/gstin',
  GSTIN_DETAILS:'/gstin/:value',
  E_INVOICE: '/eInvoicing',
  NEW_INVOICE:'/eInvoicing/newInvoice',
  E_WAY_BILL:'/eInvoicing/eWayBill',
  INVOICE_PREVIEW:'/eInvoicing/invoicePreview',
  EWAYBILL_PREVIEW:'/eInvoicing/eWayBillPreview',
  EDIT_INVOICE:'/eInvoicing/editInvoice/:id',
  EDIT_EWB:'/eInvoicing/ewayBill/:id',
};
