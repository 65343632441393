import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Table, Tag, Input, Select, DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { ArrowLeftOutlined, CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined, DownloadOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Pie } from 'react-chartjs-2';
import { globalApiService } from '../../services/apiService';
import OpenNotification from '../../utils/notification';

const queryString = require('query-string');

const { Option } = Select;


function Card(props: any) {
  return (
    <>
      <div style={{ width: '97%', height: '100%', backgroundColor: props.bgcolor, borderBottom: `4px solid ${props.brcolor}`, padding: '5px', borderRadius: '5px' }}>
        <p>{props.title}</p>
        <br />
        <div style={{ color: props.color, position: 'absolute', bottom: '5px' }}>{props.value}</div>
      </div>
    </>
  );
}


function Workspace(props: any) {

  const { sendGstReminders, summaryData, reportid, dropDownValues } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [arrowStatus, setArrowStatus] = useState(true);
  const [name, setName] = useState();
  const [status, setStatus] = useState('');
  const [date, setDate] = useState();
  const [dateString, setDateString] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  function updateLastTaxFilingDate(dateLo: any, dateStringLo: any) {
    console.log('TEST DATE ', dateLo);
    setDate(dateLo);
    setDateString(dateStringLo);
    setPagination({ ...pagination, date: dateStringLo });

  }

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
    search_q: '',
    date: '',
    status: ''
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function onSelectChange(selectedRowKeysLo: any) {
    setSelectedRowKeys(selectedRowKeysLo);
  }

  const columns = [
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      width: '20%',
    },
    {
      title: 'Name of the Company',
      dataIndex: 'company',
      width: '20%',
    },
    {
      title: 'GSTIN Status',
      dataIndex: 'gstin_status',
      render: (gstinStatus: any) => (
        <>
          {gstinStatus === 'Active' ? (
            <Button icon={<CheckOutlined />} style={{ backgroundColor: '#3dd6ab', borderRadius: '5px', color: '#fff', cursor:'default' }}>
              {gstinStatus}
            </Button>
          )
            : (
              <Button icon={<CloseOutlined />} style={{ backgroundColor: '#ff4d4f', borderRadius: '5px', color: '#fff', cursor:'default' }}>
                {gstinStatus}
              </Button>
            )}
        </>
      ),
      width: '10%',
    },
    {
      title: 'GSTRI filed for Current Month ?',
      dataIndex: 'gstr1_filing_status',
      render: (gstinStatus: any) => (
        <>
          {gstinStatus === 'NOT FILED' ? (
            <span style={{ color: '#ff4d4f' }}>
              {gstinStatus}
            </span>
          )
            : (
              <span style={{ color: '#3dd6ab' }}>
                {gstinStatus}
              </span>
            )}
        </>
      ),
      width: '20%',
    },
    {
      title: 'Filing Date',
      dataIndex: 'gstr1_filing_date',
      width: '10%',

    },
    {
      title: 'Action',
      dataIndex: 'key',
      render: (key: any) => (
        <>
          <span
            onClick={(e) => {
              const payload = {
                'gstr_types_to_notify': ['GSTR1'],
                'supplier_ids': [key]
              };
              sendGstReminders(payload);
            }}
            style={{ color: '#462E90', cursor: 'pointer' }}
          >
            <u>
              Send Reminder
            </u>
          </span>
        </>
      ),
    },
  ];


  function sendSelectedEmail() {
    const payload = {
      'gstr_types_to_notify': ['GSTR1'],
      'supplier_ids': rowSelection.selectedRowKeys
    };
    sendGstReminders(payload);

  }

  useEffect(() => {
    // do stuff here...

    componentDidMount();
  }, []); // <-- empty dependency array


  useEffect(() => {
    // do stuff here...

    componentDidMount();
  }, [name, dateString, summaryData, status]); // <-- empty dependency array

  function componentDidMount() {
    fetch(pagination);
  }
  function handleTableChange(pagin: any, filters: any, sorter: any) {
    fetch(pagin);

  }

  async function fetch(params: any) {
    setLoading(true);
    const data = await globalApiService.supplierReportDetail(reportid, params);
    const dataRes: any = [];
    for (let i = 0; i < data.data.data.results.length; i++) {
      const re = data.data.data.results[i];
      if (re.gst_data_downloaded_at === null) {
        re.gst_data_downloaded_at = '';
      }
      if (re.is_gst_data_download_requested === null) {
        re.is_gst_data_download_requested = '';
      }
      dataRes.push({
        key: re.id,
        company: re.company,
        gstin: re.gstin,
        gstin_status: re.gstin_status,
        gstr1_filing_status: re.gstr1_filing_status,
        gstr1_filing_date: re.gstr1_filing_date,

      });
    }

    setPagination({ ...pagination, current: params.current, total: data.data.data.count });
    setTableData(dataRes);
    setLoading(false);

  }


  function searchByName(e: any) {
    console.log(e.currentTarget.value);
    setPagination({ ...pagination, search_q: e.currentTarget.value });
    setName(e.currentTarget.value);
  }


  function searchByStatus(e: any) {
    console.log(e);
    setPagination({ ...pagination, status: e });
    setStatus(e);
  }

  function arrowClick() {
    if (arrowStatus) {
      setArrowStatus(false);
    } else {
      setArrowStatus(true);
    }
  }

  return (
    <>
      <Col sm={24} style={{ padding: '5px' }} className='bxsh'>
        <Row style={{ backgroundColor: '#FFFFFF', margin: '10px 0px ' }}>
          <Col sm={24}>
            {
              arrowStatus ?
                <UpOutlined style={{ fontSize: '21px', backgroundColor: '#462E90', color: '#ffffff', borderRadius: '30px', width: '25px', height: '25px' }} onClick={arrowClick} />
                :
                <DownOutlined style={{ fontSize: '21px', backgroundColor: '#462E90', color: '#ffffff', borderRadius: '30px', width: '25px', height: '25px' }} onClick={arrowClick} />
            }
            <span style={{ fontSize: '22px', fontWeight: 'bold' }}> Supplier Reports</span>
          </Col>
        </Row>
        {
          arrowStatus ?
            <>
              <Row style={{ backgroundColor: '#FFFFFF', margin: '10px 0px ' }}>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Total uploaded"
                    value={summaryData.total_uploaded_suppliers_count}
                    bgcolor="#F5F4FC"
                    brcolor="#B3A7FF"
                    color="#4532BF"
                  />
                </Col>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Report generated"
                    value={summaryData.report_generated_for_count}
                    bgcolor="#F5F4FC"
                    brcolor="#B3A7FF"
                    color="#4532BF"
                  />
                </Col>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Filed gstin "
                    value={summaryData.total_filed_gstin_count}
                    bgcolor="#C5FFEE"
                    brcolor="#3DD6AB"
                    color="#3DD6AB"
                  />
                </Col>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Filed on time"
                    value={summaryData.total_filed_on_or_before_time}
                    bgcolor="#C5FFEE"
                    brcolor="#3DD6AB"
                    color="#3DD6AB"
                  />
                </Col>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Invalid gstins"
                    value={summaryData.total_invalid_gstins}
                    bgcolor="#FFF3F2"
                    brcolor="#FF5F4F"
                    color="#FF6555"
                  />
                </Col>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Canceled gstins"
                    value={summaryData.canceled_gstin_count}
                    bgcolor="#FFF3F2"
                    brcolor="#FF5F4F"
                    color="#FF6555"
                  />
                </Col>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Inactive gstins"
                    value={summaryData.inactive_gstin_count}
                    bgcolor="#FFF3F2"
                    brcolor="#FF5F4F"
                    color="#FF6555"
                  />
                </Col>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Active gstins"
                    value={summaryData.active_gstin_count}
                    bgcolor="#FFF3F2"
                    brcolor="#FF5F4F"
                    color="#FF6555"
                  />
                </Col>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Delayed"
                    value={summaryData.total_delayed}
                    bgcolor="#FFF3F2"
                    brcolor="#FF5F4F"
                    color="#FF6555"
                  />
                </Col>
                <Col style={{ width: '10%' }}>
                  <Card
                    title="Not filed"
                    value={summaryData.total_not_filed}
                    bgcolor="#FFF3F2"
                    brcolor="#FF5F4F"
                    color="#FF6555"
                  />
                </Col>
              </Row>
            </>
            :
            ''
        }
      </Col>

      <Col sm={24} style={{ padding: '5px' }} className='bxsh'>

        <Row style={{ backgroundColor: '#FFFFFF', margin: '10px 0px ' }}>
          <Col sm={4}>
            <Input placeholder="Search by GSTIN/Name" style={{ width: '190px' }} onChange={(e) => { searchByName(e); }} value={name} />
          </Col>
          <Col sm={4}>
            <Select
              placeholder="Filter by GSTIN Status "
              allowClear
              onChange={(e) => { searchByStatus(e); }}
            >
              {dropDownValues && dropDownValues.gstin_statuses ?
                dropDownValues.gstin_statuses.map((item: any) => (
                  <Option key={`Select${item.key}`} value={item.key}>
                    {' '}
                    {item.value}
                    {' '}
                  </Option>
                ))
                : ''}
            </Select>
          </Col>
          <Col sm={4}>
            {date ?
              <DatePicker placeholder="Filter by Date" defaultValue={moment(date, 'YYYY-MM-DD')} onChange={updateLastTaxFilingDate} />
              :
              <DatePicker placeholder="Filter by Date" defaultValue={dateString} onChange={updateLastTaxFilingDate} />}

          </Col>
          <Col sm={8} />
          <Col sm={4}>

            {
              rowSelection.selectedRowKeys.length > 0 ?
<Button type="primary" style={{ float: 'right' }} danger onClick={sendSelectedEmail}>
              Send email to all Selected
            </Button>
              :
<Button disabled type="primary" style={{ float: 'right' }} danger onClick={sendSelectedEmail}>
              Send email to all Selected
            </Button>
            }
            
          </Col>
        </Row>
      </Col>






      <Col sm={24} style={{ padding: '5px' }} className='bxsh'>

        <Row style={{ backgroundColor: '#FFFFFF' }}>
          <Col sm={24}>
            <Table

              rowSelection={rowSelection}
              columns={columns}
              dataSource={tableData}
              pagination={pagination}
              loading={loading}
              onChange={handleTableChange}
              size="small"
            />
          </Col>
        </Row>
      </Col>

    </>
  );
}





function Watchlist(props: any) {
  const { reportid } = props;
  const columnsWatchlist = [

    {
      title: 'Company',
      dataIndex: 'company_name'
    },
    {
      title: 'Gstin',
      dataIndex: 'gstin'
    },
    {
      title: 'Not Filed For',
      dataIndex: 'not_filed_for'
    },
  ];

  const [watchlist, setWatchlist] = useState<any>(null);

  useEffect(() => {
    supplierReportGstr1Watchlist();
  }, []); // <-- empty dependency array

  async function supplierReportGstr1Watchlist() {

    const result = await globalApiService.supplierReportGstr1Watchlist(10);

    console.log('Watch List = ', result.data.data.results);

    setWatchlist(result.data.data.results);
  }
  return (
    <>
      <Col sm={24} style={{ padding: '5px' }} className='bxsh'>
        <Row style={{ margin: '25px 0px 0px' }}>
          <Col sm={24} />
        </Row>
        <Row>
          <Col sm={24}>
            {watchlist ? (
              <Table
                columns={columnsWatchlist}
                dataSource={watchlist}
                pagination={false}
                size="small"
              />
            ) : ''}
          </Col>
        </Row>
      </Col>
    </>
  );
}




function Overview(props: any) {
  const { reportid } = props;
  const pirchart = useRef();
  const [loadingCompany, setLoadingCompany] = useState<any>(false);
  const [loadingWatchlist, setLoadingWatchlist] = useState<any>(false);
  const [loadingGSTIN, setLoadingGSTIN] = useState<any>(false);

  const [dataValue, setDataValue] = useState<any>(null);
  const [riskData, setRiskData] = useState<any>(null);

  const [companyType, setCompanyType] = useState<any>([]);
  const [watchlist, setWatchlist] = useState<any>([]);
  const [tableDataStatus, setTableDataStatus] = useState([]);


  const columnsType = [
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Count',
      dataIndex: 'count'
    },
  ];


  const columnsStatus = [
    {
      title: 'Status',
      dataIndex: 'type',
      render: (type: any) => (
        <>
          {
          type == 'Active' ?
            <div  style={{ color: '#258921'}}>
              {type}
            </div>
            :type == 'Cancelled' ?
            <div style={{ color: '#A72626'}}>
              {type}
            </div>
            
            :type == 'Inactive' ?
            <div  style={{ color: '#808080'}}>
              {type}
            </div>
             :type == 'Suspended' ?
             <div  style={{ color: '#808080'}}>
               {type}
             </div>
             
          : 
          <div  style={{ color: '#76829D'}}>
            {type}
          </div>
            }



        </>
      ),
      width: '80%',
    },
    {
      title: 'Count',
      dataIndex: '',
      render: (data: any) => (
        <>
                  {
          data.type == 'Active' ?
            <div  style={{color: '#258921', textAlign:'center' }}>
              {data.count}
            </div>
            :data.type == 'Cancelled' ?
            <div style={{ color: '#A72626', textAlign:'center' }}>
              {data.count}
            </div>
            
            :data.type == 'Inactive' ?
            <div  style={{ color: '#808080', textAlign:'center' }}>
              {data.count}
            </div>
             :data.type == 'Suspended' ?
             <div  style={{ color: '#808080', textAlign:'center' }}>
               {data.count}
             </div>
             
          : 
          <div  style={{ color: '#76829D', textAlign:'center'  }}>
            {data.count}
          </div>
            }

        </>),
        width: '20%',
    },
  ];
  const columnsWatchlist = [

    {
      title: 'Company',
      dataIndex: 'company_name'
    },
    {
      title: 'Gstin',
      dataIndex: 'gstin'
    },
    {
      title: 'Not Filed For',
      dataIndex: 'not_filed_for'
    },
    {
      title: 'Current Month',
      dataIndex: 'current_month'
    },
  ];

  async function supplierReportGstr1() {
    const result = await globalApiService.supplierReportGstr1(reportid);
    const dataTemp = {
      labels: ['Not filed', 'Delayed Filing', 'Filed'],
      datasets: [
        {
          label: 'Current Month GSTR 1',
          data: [result.data.data.total_not_filed, result.data.data.total_delayed, result.data.data.total_filed_gstin_count],
          backgroundColor: ['#FF7272', '#FFB23E', '#3DD6AB'],
        }
      ]
    };
    setDataValue(dataTemp);

  }
  async function supplierReportGstr1RiskEntries() {
    const result = await globalApiService.supplierReportGstr1RiskData(reportid);

    console.log(result.data.data);

    setRiskData(result.data.data);

  }

  async function supplierReportGstr1CompanyType() {
    setLoadingCompany(true);
    const result = await globalApiService.supplierReportGstr1CompanyType(reportid);

    console.log('CompanyType = ', result.data.data.company_type_data);

    setCompanyType(result.data.data.company_type_data);
    setLoadingCompany(false);
  }

  async function supplierReportGstr1Watchlist() {
    setLoadingWatchlist(true);
    const result = await globalApiService.supplierReportGstr1Watchlist(5);

    console.log('Watch List = ', result.data.data.results);

    setWatchlist(result.data.data.results);
    setLoadingWatchlist(false);
  }


  async function sumarry() {
    setLoadingGSTIN(true);
    const data = await globalApiService.supplierReportSummary(reportid);

    const ds: any = [];
    const d = data.data.data.gstin_status_count;
    Object.keys(d).forEach(function (key) {
      ds.push({ 'type': key, 'count': d[key] });
    });

    setTableDataStatus(ds);
    console.info('GstinStatus', ds);
    setLoadingGSTIN(false);
  }


  const updatePlot = () => { };
  useEffect(() => {

    supplierReportGstr1();
    supplierReportGstr1RiskEntries();
    supplierReportGstr1CompanyType();
    supplierReportGstr1Watchlist();
    sumarry();
  }, []); // <-- empty dependency array



  return (
    <>
      <Col sm={24} style={{ padding: '5px' }} className='bxsh'>
        <Row>
          <Col sm={8} className='bxsh'>
            {dataValue ? (
              <Pie
                data={dataValue}
                width={100}
                options={{ 
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'bottom',
                    },
                    title: {
                      display: true,
                      text: 'Current Month GSTR 1'
                    }
                  }
                }}
                style={{ margin: '10px' }}
                contentEditable
              />
            ) : ''}
          </Col>
          <Col sm={16} className='bxsh'>
            <Row>
              <Col sm={24} style={{ margin: '10px' }}>RAG Suppliers</Col>
              <Col sm={8}>
                <div style={{ width: '95%', height: '200px', backgroundColor: '#FF7272', margin: 'auto', borderBottom: '15px solid #EE3636', textAlign: 'center', paddingTop: '30%', color: '#fff', fontSize: '30px' }}>
                  {' '}
                  {riskData ? riskData.high_risk.length : 0}
                  {' '}
                </div>
              </Col>
              <Col sm={8}>
                <div style={{ width: '95%', height: '200px', backgroundColor: '#FFCB7E', margin: 'auto', borderBottom: '15px solid #FFB23E', textAlign: 'center', paddingTop: '30%', color: '#fff', fontSize: '30px' }}>
                  {riskData ? riskData.medium_risk.length : 0}
                  {' '}
                </div>
              </Col>
              <Col sm={8}>
                <div style={{ width: '95%', height: '200px', backgroundColor: '#5AF3C8', margin: 'auto', borderBottom: '15px solid #3DD6AB', textAlign: 'center', paddingTop: '30%', color: '#fff', fontSize: '30px' }}>
                  {riskData ? riskData.no_risk.length : 0}
                  {' '}
                </div>
              </Col>
            </Row>
          </Col>

        </Row>
      </Col>
      <Col sm={24}>
        <Row style={{ margin: '25px 0px 0px' }}>
          <Col sm={6}>Company Type</Col>
          <Col sm={6}>GSTIN Status</Col>
          <Col sm={12}>Top 5 on Watchlist (Last 6 Filings)</Col>
        </Row>
        <Row>
          <Col sm={5}>
            {companyType ? (
              <Table
                loading={loadingCompany}
                columns={columnsType}
                dataSource={companyType}
                pagination={false}
                size="small"
              />
            ) : ''}


          </Col>
          <Col sm={1} />
          <Col sm={5}>
            <Table
              loading={loadingGSTIN}
              columns={columnsStatus}
              dataSource={tableDataStatus}
              pagination={false}
              size="small"
            />
          </Col>
          <Col sm={1} />
          <Col sm={12}>
            {watchlist ? (
              <Table
                loading={loadingWatchlist}
                columns={columnsWatchlist}
                dataSource={watchlist}
                pagination={false}
                size="small"
              />
            ) : ''}
          </Col>
        </Row>
      </Col>
    </>
  );
}



function DelayAnalysis(props: any) {
  const { reportid, dropDownValues } = props;
  const [count, setCount] = useState<any>(0);

  const [numberOfDays, setNumberOfDays] = useState<any>('');

  const [numberOfTimes, setNumberOfTimes] = useState<any>('');


  useEffect(() => {
    supplierReportGstr1DelayAnalysis();
  }, []);
  useEffect(() => {
    supplierReportGstr1DelayAnalysis();
  }, [numberOfDays, numberOfTimes]);
  const columnsAnalysis = [
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      width: '20%',
    },
    {
      title: 'Trade Name',
      dataIndex: 'company'
    },

  ];
  const [tableAnalysis, setTableAnalysis] = useState([
  ]);

  async function supplierReportGstr1DelayAnalysis() {

    const result = await globalApiService.supplierReportGstr1DelayAnalysis(reportid, numberOfDays, numberOfTimes);
    setTableAnalysis(result.data.data.results);
    setCount(result.data.data.count);

    /*
    let dataRes: any = [];
    for (let i = 0; i < result.data.data.results.length; i++) {
      let re = result.data.data.results[i];
      if (re.gst_data_downloaded_at === null) {
        re.gst_data_downloaded_at = "";
      }
      if (re.is_gst_data_download_requested === null) {
        re.is_gst_data_download_requested = "";
      }
      dataRes.push({
        key: re.id,
        company: re.company,
        gstin: re.gstin,
        gstin_status: re.gstin_status,
        gstr1_filing_status: re.gstr1_filing_status,
        gstr1_filing_date: re.gstr1_filing_date,

      });
    }

    setTableData(dataRes);
    setLoading(false);

    console.log("Watch List = ", result.data.data.results)

    setWatchlist(result.data.data.results);
    */
  }


  function searchByNumberOfDays(e: any) {
    console.log(e);
    // setPagination({ ...pagination, status: e });
    setNumberOfDays(e);
  }


  function searchByNumberOfTimes(e: any) {
    console.log(e);
    // setPagination({ ...pagination, status: e });
    setNumberOfTimes(e);
  }

  return (
    <>
      <Col sm={24} style={{ padding: '5px' }} className='bxsh'>
        <Row style={{ margin: '25px 0px 0px' }}>
          <Col sm={7}>Companies identified based on Delay criteria</Col>
          <Col sm={1}>
            <Tag color="#4532BF" style={{ width: '100%', borderRadius: '20px', textAlign: 'center' }}>{count}</Tag>
          </Col>
          <Col sm={8} />
          <Col sm={4}>
            <Select
              style={{ width: '100%' }}
              placeholder="Filter by number of times "
              allowClear
              onChange={(e) => { searchByNumberOfTimes(e); }}
            >
              {dropDownValues && dropDownValues.da_number_of_times ?
                dropDownValues.da_number_of_times.map((item: any) => (
                  <Option key={`Select${item.key}`} value={item.key}>
                    {' '}
                    {item.value}
                    {' '}
                  </Option>
                ))
                : ''}
            </Select>

          </Col>


          <Col sm={4}>

            <Select
              style={{ width: '100%' }}
              placeholder="Filter by number of days "
              allowClear
              onChange={(e) => { searchByNumberOfDays(e); }}
            >
              {dropDownValues && dropDownValues.da_number_of_days ?
                dropDownValues.da_number_of_days.map((item: any) => (
                  <Option key={`Select${item.key}`} value={item.key}>
                    {' '}
                    {item.value}
                    {' '}
                  </Option>
                ))
                : ''}
            </Select>
          </Col>

        </Row>
        <Row>
          <Col sm={24}>
            <Table

              columns={columnsAnalysis}
              dataSource={tableAnalysis}
              size="small"
            />
          </Col>
        </Row>
      </Col>
    </>
  );
}




const SupplierReportView: React.FunctionComponent<any> = (props) => {
  const { reportid } = props;
  const [dropDownValues, setDropDownValues] = useState([]);
  const [htmlReport, setHtmlReport] = useState<any>(null); 
  const [tabname, setTabname] = useState(0);
  const history = useHistory();
  const [summaryData, setSummaryData] = useState({
    'total_uploaded_suppliers_count': 0,
    'report_generated_for_count': 0,
    'total_filed_gstin_count': 0,
    'total_filed_on_or_before_time': 0,
    'total_invalid_gstins': 0,
    'total_canceled_gstins': 0,
    'total_inactive_gstins': 0,
    'total_active_gstins': 0,
    'total_delayed': 0,
    'total_not_filed': 0,
    'canceled_gstin_count': 0,
    'inactive_gstin_count': 0,
    'active_gstin_count': 0,
    'gstr1_due_date': "2022-04-18"
  });

  const [dateString, setDateString] = useState();


  function updateLastTaxFilingDate(dateLo: any, dateStringLo: any) {
    //setDate(dateLo);
    console.info('dateStringLo',dateStringLo);
    if(dateStringLo)
    setDateString(dateStringLo);
  }

  useEffect(() => {
    // do stuff here...

    sumarry();
  }, []); // <-- empty dependency array



  function onClickBack() {
    // history.push(ROUTES.DOWNLOAD_CENTER_V2)
    const payload = {
      'last_date_of_filing': '2022-01-08'
    };
    props.data();
  }




  async function sendGstReminders(payload: any) {
    try {

      const data = await globalApiService.supplierReportGstReminders(reportid, payload);
      OpenNotification(data.data.data.message, 'success');
    } catch (e) {
      console.log(e);
    }
  }


  function onClickSubmit() {
    // history.push(ROUTES.DOWNLOAD_CENTER_V2)
    const payload = {
      'last_date_of_filing': dateString
    };
    updateFilingDate(payload);
    // props.data();
  }
  async function updateFilingDate(payload: any) {
    const data = await globalApiService.supplierReportUpdateFilingDate(reportid, payload);
    console.log(data);
    OpenNotification(data.data.data.message, 'success');
    sumarry();
  }

  const urlParams = queryString.parse(history.location.search) || {};

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });



  async function sumarry() {
    const data = await globalApiService.supplierReportSummary(reportid);
    setSummaryData(data.data.data);
    setDateString(data.data.data.gstr1_due_date);
    console.info('Summary', data.data.data);

    console.info(data);
    console.info(summaryData);

    const result = await globalApiService.supplierReportGstr1DropDownValues(reportid);
    setDropDownValues(result.data.data);

    
  }
  function onClickDownload(){
    onClickDownloadReport();
  }
  async function onClickDownloadReport(){
    const data = await globalApiService.supplierReportGstr1Download(reportid);
    if(data){
      if(data.data.data.message){
        OpenNotification(data.data.data.message, 'success');
      }else{
          setHtmlReport(data.data.data.report_files);
      }
     ;
    }
  }

  return (
    <>
{htmlReport ?
        <Row >
          <Col sm={24} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 100000, background: '#4532BF1c' }}  >
            <div style={{
              position: 'relative',
              width: '442px',
              margin:'10px',
              left: '340px',
              top: '151px', backgroundColor: '#fff'
            }}>
              <Row style={{ textAlign: 'center' }}>
                {htmlReport.map((item: any) => (
                  <Col sm={24}   style={{margin:'10px 0px 0px 0px'}}  > <a href={item.file_url}> {item.file_name} </a>  </ Col>
                ))
                
                }
                

                <Col sm={24}  style={{margin:'20px 0px'}} >
                  <Button onClick={()=>{setHtmlReport(null)}} style={{ backgroundColor: '#FF5F4F', color: '#fff', borderRadius: '5px' }}> Close </Button>
                </Col>

              </Row>

            </div>
          </Col>
        </Row> : ""
      }


      <Row>
        <Col sm={24}>
          <Row>
            <Col sm={21}>
              <Title level={3}>Report Central</Title>
            </Col>
            <Col sm={3} onClick={onClickDownload} >
              <Title  level={4} style={{ cursor: 'pointer', color: '#462E90', float: 'right' }}>
                <DownloadOutlined   />
                Download
              </Title>
            </Col>
          </Row>
        </Col>
        <Col sm={24} className='bxsh' style={{padding: '2px 0px'}}>
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={1}>
              <Button type="primary" style={{backgroundColor:'#462E90',borderColor:'#462E90'}} icon={<ArrowLeftOutlined style={{backgroundColor:'#462E90'}}/>} danger onClick={onClickBack} />
            </Col>
            <Col sm={3}>
              <Title level={3} style={{ padding: '5px 0px 0px', fontSize: '15px', marginLeft: '10px' }} className="sp_right">Supplier Reports</Title>
            </Col>

            <Col sm={3}>
              <Title level={3} style={{ padding: '5px 0px 0px', fontSize: '15px', marginLeft: '10px' }} className="sp_right">Reports View</Title>
            </Col>

            <Col sm={6}>
              <Title level={3} style={{ padding: '5px 0px 0px', fontSize: '15px', marginLeft: '10px' }}>
                Reports ID
                <span style={{ color: '#462E90' }}>
                  {' '}
                  {reportid}
                </span>
              </Title>
            </Col>

            <Col sm={1} />

            <Col sm={3}>
              <Input placeholder="Last filling date" disabled style={{ backgroundColor: '#fff', cursor: 'default' }} />
            </Col>
            <Col sm={2}>
              <Input value="GSTR1 : " disabled style={{ backgroundColor: '#fff',color:'#000', cursor: 'default' ,borderRight: 'none' }} />
            </Col>
            <Col sm={3}>
              {
                dateString?
                <DatePicker placeholder="Select date" value={moment(dateString, 'YYYY-MM-DD')} onChange={updateLastTaxFilingDate}  style={{borderLeft: 'none',color:'#000' }}/>
                :
                <DatePicker placeholder="Select date"  onChange={updateLastTaxFilingDate}  style={{borderLeft: 'none',color:'#000' }}/>
              }
            </Col>
            <Col sm={2}>
              <Button type="primary" style={{ float: 'right', backgroundColor: '#462E90', borderColor: '#462E90' }} danger onClick={onClickSubmit}>
                SUBMIT
              </Button>
            </Col>
          </Row>
        </Col>



        <Col sm={24} style={{ padding: '5px' }} className='bxsh'>
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={6}>
              <Button
                style={{
                  color: tabname === 0 ? '#ffff' : '',
                  backgroundColor: tabname === 0 ? '#4532BF' : '',
                  borderColor: tabname === 0 ? '#4532BF' : '',
                  width: '100%'
                }}
                onClick={() => { setTabname(0); }}
              >
                {' '}
                Workspace
                {' '}
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                style={{
                  color: tabname === 1 ? '#ffff' : '',
                  backgroundColor: tabname === 1 ? '#4532BF' : '',
                  borderColor: tabname === 1 ? '#4532BF' : '',
                  width: '100%'
                }}
                onClick={() => { setTabname(1); }}
              >
                {' '}
                Overview
                {' '}
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                style={{
                  color: tabname === 2 ? '#ffff' : '',
                  backgroundColor: tabname === 2 ? '#4532BF' : '',
                  borderColor: tabname === 2 ? '#4532BF' : '',
                  width: '100%'
                }}
                onClick={() => { setTabname(2); }}
              >
                {' '}
                Watchlist
                {' '}
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                style={{
                  color: tabname === 3 ? '#ffff' : '',
                  backgroundColor: tabname === 3 ? '#4532BF' : '',
                  borderColor: tabname === 3 ? '#4532BF' : '',
                  width: '100%'
                }}
                onClick={() => { setTabname(3); }}
              >
                {' '}
                Delay Analysis
                {' '}
              </Button>
            </Col>
          </Row>
        </Col>

        {tabname === 0 ? <Workspace sendGstReminders={sendGstReminders} summaryData={summaryData} reportid={reportid} dropDownValues={dropDownValues} /> : ''}
        {tabname === 1 ? <Overview reportid={reportid} dropDownValues={dropDownValues} /> : ''}
        {tabname === 2 ? <Watchlist reportid={reportid} dropDownValues={dropDownValues} /> : ''}
        {tabname === 3 ? <DelayAnalysis reportid={reportid} dropDownValues={dropDownValues} /> : ''}






      </Row>




    </>
  );
};

export default SupplierReportView;
