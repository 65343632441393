/* eslint-disable @typescript-eslint/camelcase */
import { IAction } from '../../models';

export enum SupplierReportActions {
  
  SUPPLIRE_UPLOAD = 'SUPPLIRE_UPLOAD',
  SUPPLIRE_UPLOAD_RESET = 'SUPPLIRE_UPLOAD_RESET',
  SUPPLIRE_SUCCESS = 'SUPPLIRE_SUCCESS',
  SUPPLIRE_FAILURE = 'SUPPLIRE_FAILURE',
  SUPPLIRE_NEXT_FILING_DATE = 'SUPPLIRE_NEXT_FILING_DATE',
  SUPPLIRE_NEXT_FILING_DATE_AFTER_UPLOAD='SUPPLIRE_NEXT_FILING_DATE_AFTER_UPLOAD',
  SUPPLIRE_GST_GENERATE = 'SUPPLIRE_GST_GENERATE',
  SUPPLIRE_GST_GENERATE_FAIL='SUPPLIRE_GST_GENERATE_FAIL',
  SUPPLIRE_SET_PAGE_STATE='SUPPLIRE_SET_PAGE_STATE',

  

 
}

export function nextFilingDate(): IAction {
  
  return {
    type: SupplierReportActions.SUPPLIRE_NEXT_FILING_DATE
  };
}

export function supplireNextFilingDateAfterUpload(body: any): IAction {
  
  return {
    type: SupplierReportActions.SUPPLIRE_NEXT_FILING_DATE_AFTER_UPLOAD,
    body
  };
}

export function nextGstGenerate(body: any): IAction {
  
  return {
    type: SupplierReportActions.SUPPLIRE_GST_GENERATE,
    body
  };
}



export function uploadPr(body: any): IAction {
  return {
    type: SupplierReportActions.SUPPLIRE_UPLOAD,
    body,
  };
}

export function reSetUploadPr(): IAction {
  return {
    type: SupplierReportActions.SUPPLIRE_UPLOAD_RESET,
  };
}
