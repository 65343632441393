import React, { useState } from 'react';
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  DatePicker,
  Modal,
  Upload,
  Dropdown,
  Menu,
  Select,
  Divider
} from 'antd';

import Paragraph from 'antd/es/typography/Paragraph';
import Text from 'antd/es/typography/Text';
import { ArrowLeftOutlined, FilterOutlined,
  ApartmentOutlined, } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { ROUTES } from '../constants/routePaths';
import { THEME } from '../constants/theme';


const CustomerDetails = () => {
  // Destructing
  const { Search } = Input;
  // Initialization
  const history = useHistory();


  // Search
  const [searchQuery, setSearchQuery] = useState('');
  const onSearch = (key: string) => {
    setSearchQuery(key);
  };

  // Handle Menu Click
  function handleMenuClick(e: any) {
    console.log('Click on menu item.');
    console.log('click', e);
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Option 1</Menu.Item>
      <Menu.Item key="2">Option 2</Menu.Item>
      <Menu.Item key="3">Option 3</Menu.Item>
    </Menu>
  );

  // Table Data
  const dataSource = [
    {
      key: '1',
      inv_number: '0123231',
      created_on: '01/02/2022',
      due_on: '01/04/2022',
      inv_amount:'10,00,00,000',
      amt_paid:'10,00,000',
      action:''
    },
    {
      key: '2',
      inv_number: '0123231',
      created_on: '01/02/2022',
      due_on: '01/04/2022',
      inv_amount:'10,00,00,000',
      amt_paid:'10,00,000',
    },
    {
      key: '3',
      inv_number: '0123231',
      created_on: '01/02/2022',
      due_on: '01/04/2022',
      inv_amount:'10,00,00,000',
      amt_paid:'10,00,000',
    },
    {
      key: '4',
      inv_number: '0123231',
      created_on: '01/02/2022',
      due_on: '01/04/2022',
      inv_amount:'10,00,00,000',
      amt_paid:'10,00,000',
    },
  ];

  const columns = [
    {
      title: 'Invoice No.',
      dataIndex: 'inv_number',
      key: 'inv_number',
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
    },
    {
      title: 'Due On',
      dataIndex: 'due_on',
      key: 'due_on',
    },
    {
      title: 'Invoice Amt',
      dataIndex: 'inv_amount',
      key: 'inv_amount',
    },
    {
      title: 'Amt Paid',
      dataIndex: 'amt_paid',
      key: 'amt_paid',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: any, record: Object) => {
        return (
          <a href='#' style={{textDecoration:'underline', color:'#4532bf'}}>View Invoice</a>
        );
      }
    },
  ];
  return (
    <main className="">
      <header className="header">
        <ArrowLeftOutlined
          className="header__icon"
          onClick={() => history.push(ROUTES.MANAGE_CUSTOMERS)}
        />
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <h3 className="header__title">Shashi's Bar</h3>
      </header>
      <section className="customer">
        <h4 className="customer__title">Customer Details</h4>
        <Card>
          <Row gutter={36}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Paragraph strong style={{color:'#80808091'}}>GSTIN</Paragraph>
              <Text strong>29AAHJ33J98H4</Text>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Paragraph strong style={{color:'#80808091'}}>Company Name</Paragraph>
              <Text strong>Milky Mist Private Limited</Text>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Paragraph strong style={{color:'#80808091'}}>Person Concerned</Paragraph>
              <Text strong>Ranjan Choudhari</Text>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Paragraph strong style={{color:'#80808091'}}>Contact No.</Paragraph>
              <Text strong>9329522353</Text>
            </Col>
          </Row>
          <Row gutter={36} style={{paddingTop:'2rem'}}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Paragraph strong style={{color:'#80808091'}}>Customer Email</Paragraph>
              <Text strong>milky.mist@gmail.com</Text>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Paragraph strong style={{color:'#80808091'}}>Company Address</Paragraph>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <Text strong>Shashi's bar, 100 ft Road, Indira Nagar, plot no.078, Bangalore 500034</Text>
            </Col>

          </Row>
        </Card>
      </section>
      <section style={{marginTop:'24px'}}>
        <h4 className="customer__title">Invoice Details</h4>
        <Divider style={{margin:'0px'}} />
        <div className="customers__search" style={{marginTop:'1rem'}}>
          <div className="customers__search__bar">
            <Search
              placeholder="Search by company name/GSTIN"
              onSearch={onSearch}
            />
            <Dropdown overlay={menu}>
              <Button>
                GSTIN filters
                {' '}
                <FilterOutlined />
              </Button>
            </Dropdown>
            <Dropdown overlay={menu}>
              <Button>
                Sort
                {' '}
                <ApartmentOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className="customers__table">
          <Table
            columns={columns}
            dataSource={dataSource}
          />
        </div>
      </section>
    </main>
  );
};

export default CustomerDetails;
