import React from 'react';
import Col from 'antd/es/grid/col';
import Row from 'antd/es/grid/row';
import { useDispatch, useSelector } from 'react-redux';
import Title from 'antd/es/typography/Title';
import { Redirect, Route, useHistory } from 'react-router-dom';
import GSTINProfileForm from '../components/GSTINProfileForm';
import { IState } from '../models';
import { saveAnchorDetails } from '../store/actions/authActions';
import { THEME } from '../constants/theme';
import { ROUTES } from '../constants/routePaths';

export default function ProfilePage() {
  const authState = useSelector((state: IState) => state.authReducer);
  const storeDispatch = useDispatch();
  const history = useHistory();
  if (authState.anchorId !== null) {
    // Redirect to dashboard if anchorid is present
    return (
      <div>
        <Redirect to={ROUTES.DASHBOARD} />
      </div>
    );
  }
  return (
    <Row style={{ height: '100vh' }} justify="center" align="top">
      <Col md={8} xs={20}>
        <Title
          style={{
            color: THEME.PRIMARY_COLOR,
            paddingTop: '100px',
            paddingBottom: '40px',
          }}
        >
          Please fill in some more details to proceed!
        </Title>
        <GSTINProfileForm
          onSubmitFn={(values) => {
            storeDispatch(
              saveAnchorDetails(
                {
                  primaryContactNumber: authState.phoneNumber,
                  industryType: values.industryType,
                  subIndustryType: values.otherSubIndustryType
                    ? values.otherSubIndustryType
                    : values.subIndustryType,
                  gstin: authState.gstin,
                  jitfinId: authState.jitFinId,
                  otherSubIndustry: !!values.otherSubIndustryType,
                },
                () => {
                  history.push(ROUTES.DASHBOARD);
                }
              )
            );
          }}
          justifyBtn="start"
        />
      </Col>
    </Row>
  );
}
