import React from 'react';
import { Formik } from 'formik';
import Row, { RowProps } from 'antd/es/grid/row';
import { Form, Input } from 'formik-antd';
import Col from 'antd/es/grid/col';
import StylizedButton from './stylizedButton';
import ValidationService from '../services/validationService';
import { IColleague } from '../models';

export interface IColleagueForm {
  email: string;
  name: string;
  designation: string;
}

export default function ColleagueForm(props: {
  onSubmitFn: (values: IColleagueForm) => any;
  justifyBtn?: RowProps['justify'];
  existingColleague?: IColleague;
}) {
  const { onSubmitFn, justifyBtn, existingColleague } = props;
  const formikInitialValues: IColleagueForm = existingColleague || {
    email: '',
    name: '',
    designation: '',
  };
  return (
    <Formik
      initialValues={formikInitialValues}
      validate={(values: IColleagueForm) => {
        const validationFieldToFunctionMap = {
          name: ValidationService.isName,
          email: ValidationService.isEmail,
        };
        return ValidationService.validateFields(
          values,
          validationFieldToFunctionMap
        );
      }}
      onSubmit={onSubmitFn}
      render={({ handleSubmit }) => {
        return (
          <Form>
            <Form.Item name="name" hasFeedback showValidateSuccess>
              <Input name="name" placeholder="Name" size="large" />
            </Form.Item>

            <Form.Item name="email" hasFeedback showValidateSuccess>
              <Input name="email" placeholder="Email" size="large" />
            </Form.Item>

            <Form.Item name="designation" hasFeedback showValidateSuccess>
              <Input
                name="designation"
                placeholder="Designation"
                size="large"
              />
            </Form.Item>

            <Row justify={justifyBtn || 'center'}>
              <Col>
                <StylizedButton
                  textInButton="Add"
                  onClickFn={handleSubmit}
                  size="middle"
                />
              </Col>
            </Row>
          </Form>
        );
      }}
    />
  );
}
