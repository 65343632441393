import React, { useEffect, useState, useReducer } from 'react';
import Table from 'antd/es/table/Table';
import { useDispatch, useSelector } from 'react-redux';
import {FiEdit3, RiDeleteBinLine} from 'react-icons/all';
import { Button } from 'antd/es';
import {
  ConsoleSqlOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons/lib';
import { IProspect, IState } from '../models';
import {
  deleteProspect,
  intitiateWhatsappMessage,
  requestProspectsOfDataSource,
  updateProspect,
  getInvalidlistEntries,
} from '../store/actions/dataSourceActions';
import {
  IRequestProspectsOfDataSource,
  IWhatsappRequestBody,
} from '../models/api/dataSourceModels';
import ProspectForm from './prospectForm';
import { whatsappIcon } from '../utils/constants';
import {
  PlusOutlined,
  FilterOutlined,
  ApartmentOutlined,
  EditFilled,
  DeleteFilled,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import {
  Popconfirm,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Radio,
  Tabs,
  DatePicker,
  Upload,
  Dropdown,
  Menu,
  Select,
} from 'antd';
import { THEME } from '../constants/theme';
import { globalApiService } from '../services/apiService';
import { FiMoreHorizontal } from 'react-icons/fi';
import {
  getWatchlistEntries,
  deleteWatchlistEntries,
  deleteInvalidlistEntries,
} from '../store/actions/dataSourceActions';
import OpenNotification from '../utils/notification';

function InvalidListTable(props: {
  pageSize: number;
  dataSourceName: string;
  invalidSearchQuery: string;
  status: string;
  dataSourceId: string | number;
  clearState: boolean;
  changeClearState: () => void;
}) {
  const {
    pageSize,
    dataSourceName,
    invalidSearchQuery,
    status,
    dataSourceId,
    clearState,
    changeClearState,
  } = props;
  //Destructuring
  const { Title, Paragraph, Text } = Typography;
  const [tableData, setTableData] = useState<IProspect[]>([]);
  const [pagination, setPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize });
  const [loading, setLoading] = useState<boolean>(false);
  const [
    prospectBeingEdited,
    setProspectBeingEdited,
  ] = useState<IProspect | null>(null);
  const storeDispatch = useDispatch();

  const handleMenuClick = (e: any, record: any) => {
    if (e.key == '1') {
      if (record.id) {
        storeDispatch(deleteWatchlistEntries({ ids: [record.id] }));
      }
    }
  };
  useEffect(() => {
    fetchEntries(
      dataSourceName,
      1,
      pagination.pageSize,
      invalidSearchQuery,
      status
    );
  }, [storeDispatch]);

  useEffect(() => {
    if (invalidSearchQuery !== undefined && dataSourceName !== undefined) {
      fetchEntries(
        dataSourceName,
        1,
        pagination.pageSize,
        invalidSearchQuery,
        status
      );
    }
    // eslint-disable-next-line
  }, [invalidSearchQuery]);




  function fetchEntries(
    nameOfDataSource: string,
    pageNumber: number,
    sizeOfPage: number,
    search?: string,
    status?: string
  ) {
    setLoading(true);
    storeDispatch(
      getInvalidlistEntries({
        dataSourceName: nameOfDataSource,
        pageSize: sizeOfPage,
        pageNumber,
        search,
        status,
        datasourceID: dataSourceId,
      } as any)
    );
  }
  const dataSourceState = useSelector(
    (state: IState) => state.dataSourceReducer
  );

  const { invalidlistEntries,deleteInvalidListFlag } = dataSourceState;
  
  // if(invalidlistEntries !== undefined) {
  // const {results}  = invalidlistEntries
  // }
  useEffect(() => {
    if (invalidlistEntries !== undefined) {
      setTableData(invalidlistEntries);
      setLoading(false);
    }
  }, [invalidlistEntries]);
  useEffect(() => {
    fetchEntries(
      dataSourceName,
      1,
      pagination.pageSize,
      invalidSearchQuery,
      status
    );
    
  }, [deleteInvalidListFlag])

  // interface InvalidRecordObject {
  //   created_at: string;
  //   data_source: number;
  //   gstin: string;
  //   prospect_type: string;
  //   reason: string;
  //   updated_at: '2021-11-19T12:00:20.516136Z';
  // }

  const onDelete = (id: any) => {
    if(id){
    storeDispatch(deleteInvalidlistEntries({ ids : [id], datasourceID: dataSourceId}));
    }
  };

  //Config for the Edit Modal
  const [form] = Form.useForm();
  const EditRecordFunction = (state: any, action: any) => {
    if (action.type === 'NEW') {
      return action.record;
    }
    return defaultRecord;
  };
  const defaultRecord = {
    gstin: '',
    email: '',
    // companyName: '',
    phoneNumber: '',
    id: 0,
  };
  const handleEditClick = (record: any) => {
    setIsEditModalVisible(true);
    // setEditRecord(record)
    dispatchEditRecord({ type: 'NEW', record: record });
  };

  const [editRecord, dispatchEditRecord] = useReducer(
    EditRecordFunction,
    defaultRecord
  );
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const handleEditOk = () => {
    setIsEditModalVisible(false);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };
  const onEditSupplierFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onEditSupplierFinish = (values: any) => {
    console.log('Success:', values);
    storeDispatch(
      updateProspect({
        prospectId: editRecord.id,
        email: values.email,
        dataSourceName,
        phoneNumber: values.phoneNumber,
      })
    );
    // setEditRecord({
    //   gstin: '',
    //   email: '',
    //   companyName: '',
    //   phoneNumber: '',
    //   id: 0,
    // });
    form.resetFields();

    handleEditOk();
  };

  const columns = [
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
    },
    // {
    //   title: 'Company Name',
    //   dataIndex: 'compName',
    //   key: 'compName',
    // },
    // {
    //   title: 'Email id',
    //   dataIndex: 'email',
    //   key: 'email',
    // },
    // {
    //   title: 'Phone no',
    //   dataIndex: 'phone',
    //   key: 'phone',
    // },
    {
      title: 'Reason For Failure',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (text: any, record: any) => {
        return (
          <div className="iconContainer">
            {/*<EditFilled*/}
            {/*  className="iconContainer__item"*/}
            {/*  // onClick={() => handleEditClick(record)}*/}
            {/*></EditFilled>*/}
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this record?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
            <RiDeleteBinLine
              className="iconContainer__item"
            ></RiDeleteBinLine>
            </Popconfirm>
          </div>
        );
      },
    },
  ] as any[];
  function handleTableChange(pager: any) {
    pagination.current = pager.current;
    setPagination(pagination);
    // storeDispatch(getWatchlistEntries());
    fetchEntries(dataSourceName, pagination.current, pagination.pageSize);
  }

  const [selectedRowKeys, setselectedRowKeys] = useState<React.Key[]>([]);
  // useEffect(() => {
  //   if (Array.isArray(rowKeys)) {
  //     if (rowKeys?.length > 0) {

  //       storeDispatch(deleteWatchlistEntries({ ids: Array.from(rowKeys)}));
  //     }
  //   }
  // }, [removeWatchlistTrigger]);
  useEffect(() => {
    if(clearState){
    if (Array.isArray(selectedRowKeys) && selectedRowKeys.length > 0) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you want to clear these records?')) {
            storeDispatch(deleteInvalidlistEntries({ids: [...selectedRowKeys], datasourceID: dataSourceId}));
            setselectedRowKeys([])
        }
    }else {
      OpenNotification('Please select atleast one supplier to clear from the list','error')
    }
  }
  changeClearState()
  }, [clearState]);

  const onSelectChange = (selectedRowKeys: any[],selectedRows: any[]) => {
    setselectedRowKeys([...selectedRowKeys]);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    //   setRowKeys(selectedRowKeys);
    //   console.log(
    //     `selectedRowKeys: ${selectedRowKeys}`,
    //     'selectedRows: ',
    //     selectedRows
    //   );
    // },
  };

  return (
    <div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={tableData}
        loading={loading}
        onChange={handleTableChange}
        pagination={pagination}
        scroll={{ x: true }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </div>
  );
}

InvalidListTable.defaultProps = {
  invalidSearchQuery: '',
};
export default InvalidListTable;
