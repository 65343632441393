import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  DatePicker,
  Modal,
  Upload,
  Dropdown,
  Menu,
  Select,
  Space,
  Switch,
  Tooltip
} from 'antd';
import { RiEditLine } from 'react-icons/ri';
import {
  ArrowLeftOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  ConsoleSqlOutlined,
} from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory, useLocation, useParams } from 'react-router';
import moment from 'moment';
import { AxiosError } from 'axios';
import { THEME } from '../constants/theme';
import { ROUTES } from '../constants/routePaths';
import { globalApiService } from '../services/apiService';
import { IState } from '../models';
import { useDispatch, useSelector } from 'react-redux';
import OpenNotification from '../utils/notification';
import { param } from 'jquery';

const NewInvoice = () => {
  // Initilization
  const history = useHistory();
  const location: any = useLocation();
  const params: any = useParams();
  const [form] = Form.useForm();
  const [invoiceform] = Form.useForm();
  // Destructuring
  const { id } = params;
  const { Title, Paragraph, Text } = Typography;
  const { Search } = Input;
  const { Option,OptGroup } = Select;
  const [routeToPreview, setRouteToPreview] = useState(true);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState<any>({});
  const authState = useSelector((state: IState) => state.authReducer);
  const [bankOption,setBankOption] = useState<any[]>([]);
  const [duplicateInvoiceNumber,setDuplicateInvoiceNumber] = useState(invoiceNumber);
  const [isSameAddressChecked, setIsSameAddressChecked] = useState(false);
  

const [isInputVisible, setIsInputVisible] = useState(false);
const showInput = () => {
  if(invoiceNumber != ''){
  setIsInputVisible(!isInputVisible);
  }
};

// useEffect(() => {
//   if(invoiceNumber !== undefined){
//     globalApiService.getEinvoiceStatus(invoiceNumber)
//         .then((res) => {
//           if(res.data.success){
//             if(res.data.data.isInvoiceExist){
//             OpenNotification(res.data.data.message,'error')
//             history.push(ROUTES.E_INVOICE)
//             }
//           }
//         })
//         .catch((err: AxiosError) => {
//           const errorBody = err?.response?.data;
//         });
//       }
// },[invoiceNumber,id])

const setValue = (e:any) =>{
  let reg = /^([a-zA-Z1-9]{1}[a-zA-Z0-9\/-]{0,15})$/
  if(reg.test(e.target.value) && e.target.value.length<=16){
    setInvoiceNumber(e.target.value);
  }
  else{
    setInvoiceNumber(duplicateInvoiceNumber)
  }

};
const validate = () =>{
  let reg = /^([a-zA-Z1-9]{1}[a-zA-Z0-9\/-]{0,15})$/
  if(!reg.test(invoiceNumber) || invoiceNumber.length>16 ){
    OpenNotification('Enter valid invoice number','error')
  }
};

 
const [formData, setFormData] = useState({});

  function getDateDDMMYYYY(date: any) {
    let day = date.getDate().toString();
    if (day.length == 1) {
      day = '0' + day;
    }
    let month = date.getMonth() + 1;
    let month2 = month.toString();

    if (month2.length == 1) {
      month2 = '0' + month2;
    }
    let year = date.getFullYear();
    return day + '/' + month2 + '/' + year;
  }

  // React.useEffect(() => {
  //   if(!params.id){
  //   globalApiService
  //       .getInvoiceCount()
  //       .then((res) => {
  //        if(res.data.success){
  //          let count = res.data.data.count + 1
  //         // setInvoiceNumber(count)
  //        }
  //       })
  //       .catch((err: AxiosError) => {
  //         const errorBody = err?.response?.data;
  //         console.log(errorBody);
  //       });
  //     }
  // },[])
  // Handle Form
  const onEinvoiceFormFinish = (values: any) => {
    values['discount'] = 0;
    const reg = new RegExp(/^([a-zA-Z1-9]{1}[a-zA-Z0-9\/-]{0,15})$/);
    let customer_info = {
      company_name: selectedCustomer.company_name,
      point_of_contact: selectedCustomer.point_of_contact,
      phone_number: selectedCustomer.phone_number,
      email: selectedCustomer.email,
      gstin: selectedCustomer.gstin,
    };
    const data = {
      ...values,
      due_date: invoiceState.due_date,
      status: 'DRAFT',
      invoice_number: invoiceNumber,
      invoice_date: invoiceState.invoice_date,
    };
    const previewData = {
      formValues: {
        ...data,
        customerName: selectedCustomer.company_name,
        customer: { ...customer_info },
      },
      items: [...colData],
    };
    const eInvoiceItemPayload: any[] = [];
    colData.map((ele: any) => {
      eInvoiceItemPayload.push({
        quantity: parseFloat(ele.quantity) || 1,
        price_per_unit: parseFloat(ele.price_per_unit),
        tax_percent: parseFloat(ele.tax_percent),
        is_tax_included: ele.is_tax_included == 'included',
        item_id: parseFloat(ele.id),
        discount_percent: parseFloat(ele.discount_percent) || 0,
        item_description: ele.item_description
      });
    });
    let bank_account: any = {};
    bankOption.map((ele: any) => {
      if (ele.id === values.bank_account_id) {
        bank_account = { ...ele };
      }
    });

    if(params.id || eInvoiceId !== 0){
      let invoice_id = params.id ? params.id : eInvoiceId;
      if(eInvoiceItemPayload.length == 0 ||  !eInvoiceItemPayload[0].item_id){
        OpenNotification('Please Select an Item ','error');
        return;
      }
      if(invoiceNumber!='' && reg.test(invoiceNumber) && invoiceNumber.length<=16){
      globalApiService
        .updateEinvoice(invoice_id, {
          ...data,
          items: [...eInvoiceItemPayload],
          bank_account: { ...bank_account },
          customer: { ...customer_info },
        })
        .then((res) => {
          if (res.data.success) {
            if (routeToPreview) {
              history.push(`${ROUTES.INVOICE_PREVIEW}`, {
                formData: {
                  ...previewData,
                  eInvoice_id: res.data.data.eInvoice_id,
                  bank_account: { ...bank_account },
                },
              });
            } else {
              history.push(ROUTES.E_INVOICE);
            }
          }
        })
        .catch((err: AxiosError) => {
          const errorBody = err?.response?.data;
          console.log(errorBody);
        });
      }else{
      OpenNotification('Please Enter a Valid Invoice Number','error');
    }
    } else {
      const draftData = {
        due_date: invoiceState.due_date,
        status: 'DRAFT',
        invoice_number: invoiceNumber,
        invoice_date: invoiceState.invoice_date,
        invoice_type: values.invoice_type,
        payment_type: values.payment_type,
        reverse_chargable: values.reverse_chargable,
        bill_phone: values.bill_phone,
        ship_phone: values.ship_phone,
        bill_address: values.bill_address,
        ship_address: values.ship_address,
        bank_account_id: parseInt(values.bank_account_id),
        discount: parseFloat(values.discount),
        tds: parseFloat(values.tds || 0) || 0,
        shipping_charges: parseFloat(values.shipping_charges || 0) || 0,
        received: parseFloat(values.received || 0) || 0,
        pending: parseFloat(values.pending),
        gst_reverse_charge: parseFloat(values.gst_reverse_charge),
        cess: parseFloat(values.cess || 0) || 0,
        customer_id: selectedCustomer.id,
        items: [...eInvoiceItemPayload],
        bank_account: {...bank_account},
        customer: {...customer_info},
        reimbursements: parseFloat(values.reimbursements) || 0
      };
      if (draftData) {
        if(eInvoiceItemPayload.length == 0 ||  !eInvoiceItemPayload[0].item_id){
          OpenNotification('Please Select an Item ','error');
          return;
        }
        if (invoiceNumber != '' && reg.test(invoiceNumber) && invoiceNumber.length <= 16) {
          globalApiService
              .saveToDraft(draftData)
              .then((res) => {
                if (res.data.success) {
                  if (routeToPreview) {
                    history.push(`${ROUTES.INVOICE_PREVIEW}`, {
                      formData: {
                        ...previewData,
                        eInvoice_id: res.data.data.eInvoice_id,
                        bank_account: {...bank_account},
                      },
                    });
                  } else {
                    history.push(ROUTES.E_INVOICE);
                  }
                }
              })
              .catch((err: AxiosError) => {
                const errorBody = err?.response?.data;
                console.log(errorBody);
              });
        } else {
          OpenNotification('Please Enter a Valid Invoice Number', 'error')
        }
      }
      setFormData(previewData);
    }
  };

  const refForCustomer:any = useRef(null)
  const refForShippingAddress:any = useRef(null)
  const refForBankAccount:any = useRef(null)


  const onEinvoiceFormFailed = (errorInfo: any) => {
    let isBroken = false;
    errorInfo.errorFields.map((ele:any) => {
      if(isBroken){
        return;
      }
      let fieldName = ele.name[0]
      if(fieldName == 'customer_id'){
        refForCustomer.current.focus();
        isBroken = true;
        return;
      }
      if(fieldName == 'ship_address'){
        refForShippingAddress.current.focus();
        isBroken = true;
        return;
      }
      if(fieldName == 'bank_account_id'){
        refForBankAccount.current.focus();
        return;
      }
    })
  };

  const today = getDateDDMMYYYY(new Date());
  const [itemTotal, setItemTotal] = useState(0);
  function withDecimal(n: any) {
    n = parseFloat(n).toFixed(2);
    const nums = n.toString().split('.');
    const whole = convertNumberToWords(nums[0]);
    if (nums.length === 2) {
      const fraction = convertNumberToWords(nums[1]);
      if (fraction.trim() !== '') {
        return `${whole} and ${fraction} Paise Only`;
      }
      return `${whole} Only`;
    } else {
      return whole;
    }
  }
  function convertNumberToWords(amount: any) {
    let words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';

    amount = amount.toString();
    let atemp: any = amount.split('.');
    let number: any = atemp[0].split(',').join('');
    let nLength: any = number.length;
    let wordsString = '';
    if (nLength <= 9) {
      let nArray: any = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      let receivedNArray = new Array();
      for (let i = 0; i < nLength; i++) {
        receivedNArray[i] = number.substr(i, 1);
      }
      for (let i = 9 - nLength, j = 0; i < 9; i++, j++) {
        nArray[i] = receivedNArray[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (nArray[i] == 1) {
            nArray[j] = 10 + parseInt(nArray[j]);
            nArray[i] = 0;
          }
        }
      }
      let value: any = '';
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = nArray[i] * 10;
        } else {
          value = nArray[i];
        }
        if (value != 0) {
          wordsString += words[value] + ' ';
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && nArray[i + 1] == 0)
        ) {
          wordsString += 'Crores ';
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && nArray[i + 1] == 0)
        ) {
          wordsString += 'Lakhs ';
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && nArray[i + 1] == 0)
        ) {
          wordsString += 'Thousand ';
        }
        if (i == 6 && value != 0 && nArray[i + 1] != 0 && nArray[i + 2] != 0) {
          wordsString += 'Hundred ';
        } else if (i == 6 && value != 0) {
          wordsString += 'Hundred ';
        }
      }
      wordsString = wordsString.split('  ').join(' ');
    }
    return wordsString;
  }

  // State Declaration
  const [invoiceState, setInvoiceState] = useState({
    invoice_type: 'B2B',
    payment_type: 'CASH',
    reverse_chargable: false,
    due_date: getDateDDMMYYYY(new Date()),
    customer_id: '',
    gstin: '',
    email: '',
    phone: '',
    point_of_contact: '',
    bill_phone: '',
    ship_phone: '',
    bill_address: '',
    ship_address: '',
    taxInvoiceTotalAmt: '',
    bank_account_id: '',
    phone_number: '',
    invoice_date: getDateDDMMYYYY(new Date()),
    round_off: true,
  }) as any[];

  const [colData, setColData] = React.useState([
    {
      index: 0,
      name: '',
      type: '',
      item_code: '',
      price_per_unit: 0,
      unit: '',
      description: '',
      gstin: '',
      hsn_code: '',
      is_deleted: false,
      cgst: '',
      sgst: '',
      igst: '',
      cess: '',
      deleted_at: '',
      tax_percent: 0,
      is_tax_included: 'included',
      discount_percent: 0,
      quantity: 1,
      id: '',
      item_description : '',
    },
  ]) as any[];
  const handleAddRow = () => {
    setColData([
      ...colData,
      {
        index: colData.length,
        name: '',
        itemCode: '',
        hsnCode: '',
        quantity: 1,
        unit: '',
        tax_percentage: 0,
        price: 0,
        tax: 0,
        taxAmt: 0,
        totalAmt: 0,
        tax_percent: 0,
        is_tax_included: 'included',
        discount_percent: 0,
        item_description: '',
      },
    ]);
  };
  const [billState, setBillState] = useState({
    item_total: 0,
    discount: 0,
    tds: 0,
    shipping_charges: 0,
    received: 0,
    pending: 0,
    gst_reverse_charge: 0,
    tax_invoice_total_amt: '',

    //New fields
    total_amount_before_tax: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    cess: 0,
    total_amount_after_tax: 0,
    reimbursements: 0
  }) as any[];

  const onInvoiceChange = (e: any) => {
    const { name } = e.target;
    const { value } = e.target;
    setInvoiceState((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };
  const onBillChange = (e: any) => {
    const { name } = e.target;
    const { value } = e.target;
    setBillState((prevState: any) => {
      return { ...prevState, [name]: value };
    });

  };

  // const onSwitchChange = (checked: boolean, event: Event) => {
  //   setInvoiceState((prevState: any) => {
  //     return { ...prevState, 'reverse_chargable': checked };
  //   });
  // };
  const onSwitchChange = (checked: boolean) => {
    setInvoiceState((prevState: any) => {
      return { ...prevState, reverse_chargable: checked };
    });
  };
  const onRoundOffChange = (checked: boolean) => {
    setInvoiceState((prevState: any) => {
      return { ...prevState, round_off: checked };
    });
  };
  const dueDateChange = (date: any, dateString: any) => {
    setInvoiceState((prevState: any) => {
      return { ...prevState, due_date: dateString };
    });
  };
  const dueInvoiceDateChange = (date: any, dateString: any) => {
    setInvoiceState((prevState: any) => {
      return { ...prevState, invoice_date: dateString };
    });
  };

  const handleSelectChange = (value: string, name: string) => {
    setInvoiceState((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleCustomerSelectChange = (value: string, name: string) => {
    customerData
      .filter((temp) => {
        if (temp.id === value) {
          setSelectedCustomer({ ...temp });
        }
        return temp.id === value;
      })
      .map((filterData: any) => {
        return setInvoiceState((prevState: any) => {
          return {
            ...prevState,
            ...filterData,
            bill_phone: filterData.phone_number,
            ship_phone: filterData.phone_number,
          };
        });
      });
    setInvoiceState((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const [addedItemsName,setAddedItemsName] = useState<any[]>([])
  const onItemChange = (value: string, index: number) => {
    itemData
      .filter((temp) => {
        return temp.name === value
      })
      .map((filterData: any) => {
        const tempColData = [...colData];
        tempColData[index] = { ...filterData, ['index']: index };
        tempColData[index].item_description = filterData.description
        return setColData([...tempColData]);
      });
    // setColData((prevState: any) => {
    //   return { ...prevState, name: value };
    // });
  };
  // Table config

  const onTableInputChange = (e: any, index: number) => {
    let name: any = e.target.name;
    let value: any = e.target.value;
    if(!(value < 0) || value != ''){
    const duplicateColData = [...colData];
    duplicateColData[index][name] = value;
    setColData([...duplicateColData]);
    }else {
    const duplicateColData = [...colData];
    duplicateColData[index][name] = 0;
    setColData([...duplicateColData]);
    }
  };
  const onQuantityInputChange = (e: any, index: number) => {
    let name: any = e.target.name;
    let value: any = e.target.value;
      if(parseFloat(value) === 0){
        value=1
      }
      const duplicateColData = [...colData];
      duplicateColData[index]['quantity'] = value;
      setColData([...duplicateColData]);
      // }
  };

  const handleUnitsSelectChange = (value: string, index: any) => {
    const duplicateColData = [...colData];
    duplicateColData[index].units = value;
    setColData([...duplicateColData]);
  };
  const handleis_tax_includedChange = (value: any, index: any) => {
    const duplicateColData = [...colData];
    duplicateColData[index].is_tax_included = value;
    setColData([...duplicateColData]);
  };
  const handleTaxSelectChange = (value: any, index: any) => {
    const duplicateColData = [...colData];
    duplicateColData[index].tax_percent = value;
    setColData([...duplicateColData]);
  };
  const sameAsBillingChecked = (e: any) => {
    if (e.target.checked) {
      // invoiceState.ship_address = invoiceState.address;
      setInvoiceState({
        ...invoiceState,
        ship_address: invoiceState.bill_address,
      });
      setIsSameAddressChecked(true)
    } else {
      setIsSameAddressChecked(false)
      setInvoiceState({ ...invoiceState, ship_address: '' });
    }
  };
  const onItemDescriptionChange = (e: any, index: number) => {
    let name: any = e.target.name;
    let value: any = e.target.value;
    const duplicateColData = [...colData];
    duplicateColData[index]['item_description'] = value;
    setColData([...duplicateColData]);
  };
  function onTableChange(pagination: any, filters: any, sorter: any) {
    console.log('params', pagination, filters, sorter);
  }

  const [itemData, setItemData] = useState<any[]>([]);
 
  useEffect(() => {
    let tempArray:any = []
    colData.map((ele:any) => {
      tempArray.push(ele.id)
    })
    setAddedItemsName([...tempArray])
  },[JSON.stringify(colData)])

  const col = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: '2.5%',
      render: (text: any, record: any) => {
        return record.index + 1;
      },
    },
    {
      title: 'Item/service',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      render: (text: any, record: any) => {
        return (
          <div>
          <Tooltip title={record.name}>
          <Select
            className={`search-item ${
              Object.keys(selectedCustomer).length == 0 &&
              'table-add-row__text--disabled'
            }`}
            showSearch
            style={{ width: '12rem'}}
            defaultValue={'Search Item'}
            value={record.name}
            placeholder="abc"
            optionFilterProp="children"
            // onChange={(e) => onTableInputChange(e, record.index)}
            onChange={(value: any) => onItemChange(value, record.index)}
            filterOption={(input: any, option: any) =>
              typeof option.children == "string"  && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onBlur={() => handleSaveToDraft(false)}
          >
            
            {itemData.map((item, idx) => {
              if(!(addedItemsName.includes(item.id))){
              return <Option key={idx} value={item.name} >
                {item.name}
              </Option>
              }
              
            })}
            <div className="add-new__subcontainer add-new__subcontainer--small-font">
              <PlusOutlined />
              <p
                className="add-new__subcontainer__text"
                onClick={() => history.push({ pathname: ROUTES.ADD_ITEM })}
              >
                Add Item
              </p>
            </div>
          </Select>
          </Tooltip>
            <p className='add-new__subcontainer__description'>
            <Tooltip title={record.item_description}>
            <Input.TextArea
              placeholder='Item description'
              rows={2}
              className='add-new__subcontainer__descriptextarea'
              name="item_description"
              value={record.item_description}
              onChange={(e) => onItemDescriptionChange(e, record.index)}
              onBlur={() => handleSaveToDraft(false)}
            />
            </Tooltip>
            </p>
          </div>
        );
      },
    },
    {
      title: 'Item code/HSN code',
      dataIndex: '',
      key: '',
      width: '11%',
      render: (text: any, record: any) => {
        return (
          <div className="item-hsn-code">
            <p className="item-hsn-code__text">Item Code</p>
            <Tooltip title={record.item_code}>
            <Input
              disabled
              name="item_code"
              value={record.item_code}
              onChange={(e) => onTableInputChange(e, record.index)}
              onBlur={() => handleSaveToDraft(false)}
              // placeholder="Enter customer GSTIN"
            />
            </Tooltip>
            <p className="item-hsn-code__text item-hsn-code__text--mt">
              HSN Code
            </p>
            <Tooltip title={record.hsn_code}>
            <Input
              disabled
              name="hsn_code"
              value={record.hsn_code}
              onChange={(e) => onTableInputChange(e, record.index)}
              onBlur={() => handleSaveToDraft(false)}
              // placeholder="Enter customer GSTIN"
            />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10.5%',
      render: (text: any, record: any) => {
        return (
          <Form>
          <Tooltip title={record.quantity}>
          <Input
            name="quantity"
            type="number"
            min="1"
            value={record.quantity}
            onChange={(e) => onQuantityInputChange(e, record.index)}
            onBlur={() => handleSaveToDraft(false)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
          </Form>
        );
      },
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: '10%',
      render: (text: any, record: any) => {
        return (
          // <Select
          //   className="search-unit"
          //   showSearch
          //   style={{width: '100%'}}
          //   defaultValue="Search Unit"
          //   placeholder="Search Unit"
          //   optionFilterProp="children"
          //   // onChange={(e) => onTableInputChange(e, record.index)}
          //   onChange={(value: any) =>
          //     handleUnitsSelectChange(value, record.index)
          //   }
          //   filterOption={(input: any, option: any) =>
          //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          //   }
          // >
          //   {unitOptions.map((item: any, idx: number) => (
          //     <Option key={idx} value={item}>
          //       {item}
          //     </Option>
          //   ))}
          // </Select>
          <Tooltip title={record.unit}>
          <Input
            disabled
            name="unit"
            value={record.unit}
            onChange={(e) => onTableInputChange(e, record.index)}
            onBlur={() => handleSaveToDraft(false)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
        );
      },
    },
    {
      title: 'Price/unit',
      dataIndex: 'price',
      key: 'price',
      width: '8%',
      render: (text: any, record: any) => {
        return (
          <Tooltip title={record.price_per_unit}>
          <Input
            name="price_per_unit"
            type="number"
            min="0"
            value={record.price_per_unit}
            onChange={(e) => onTableInputChange(e, record.index)}
            onBlur={() => handleSaveToDraft(false)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
        );
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discount_percent',
      key: 'discount_percent',
      width: '10%',
      render: (text: any, record: any) => {
        return (
          <Tooltip title={record.discount_percent}>

          <Input
            suffix="%"
            type="number"
            min="0"
            name="discount_percent"
            value={record.discount_percent}
            onChange={(e) => onTableInputChange(e, record.index)}
            onBlur={() => handleSaveToDraft(false)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
        );
      },
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      key: 'tax',
      width: '11.5%',
      render: (text: any, record: any) => {
        return (
          <div className="tax">
            <Tooltip title={record.is_tax_included}>

            <Select
              value={record.is_tax_included}
              className="tax__select"
              onChange={(value: any) =>
                handleis_tax_includedChange(value, record.index)
              }
              defaultValue={colData[record.index].is_tax_included}
              onBlur={() => handleSaveToDraft(false)}
            >
              <Option value="included">Included</Option>
              <Option value="excluded">Excluded</Option>
            </Select>
            </Tooltip>
            <p className="tax__text">Tax %</p>
            <Tooltip title={record.tax_percent}>

            <Select
              value={record.tax_percent}
              className="tax__percentage"
              onChange={(value: any) =>
                handleTaxSelectChange(value, record.index)
              }
              defaultValue={0}
              onBlur={() => handleSaveToDraft(false)}
            >
              <Option value={0}>None</Option>
              <Option value={5}>5</Option>
              <Option value={12}>12</Option>
              <Option value={18}>18</Option>
              <Option value={28}>28</Option>
            </Select>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Taxable Amt',
      dataIndex: 'totalAmt',
      key: 'totalAmt',
      width: '10%',
      render: (text: any, record: any) => {
        const total =
          colData[record.index].quantity * colData[record.index].price_per_unit;
        let discount = (
          (total * parseFloat(record.discount_percent)) /
          100
        ).toFixed(2);
        if (record.is_tax_included === 'included') {
          colData[record.index].totalAmt = parseFloat(
            (
              (total - parseFloat(discount)) /
              (1 + record.tax_percent / 100)
            ).toFixed(2)
          );
        } else if (record.is_tax_included === 'excluded') {
          colData[record.index].totalAmt = parseFloat(
            (total - parseFloat(discount)).toFixed(2)
          );
        }
        // if(record.discount_percent > 0){
        //   colData[record.index].totalAmt = parseFloat((colData[record.index].totalAmt - total * record.discount_percent/100).toFixed(2)) ;
        // }
        return (
          <Tooltip title={!isNaN(colData[record.index].totalAmt)
            ? colData[record.index].totalAmt
            : ''}>

          <Input
            name="totalAmt"
            value={
              !isNaN(colData[record.index].totalAmt)
                ? colData[record.index].totalAmt
                : ''
            }
            onChange={(e) => onTableInputChange(e, record.index)}
            onBlur={() => handleSaveToDraft(false)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
        );
      },
    },
    {
      title: 'Tax Amt',
      dataIndex: 'taxAmt',
      key: 'taxAmt',
      width: '10%',
      render: (text: any, record: any) => {
        let total =
          colData[record.index].quantity * colData[record.index].price_per_unit;
        let discount = (
          (total * parseFloat(record.discount_percent)) /
          100
        ).toFixed(2);
        if (record.is_tax_included == 'included') {
          colData[record.index].taxAmt = (
            total -
            parseFloat(discount) -
            colData[record.index].totalAmt
          ).toFixed(2);
        }
        if (record.is_tax_included == 'excluded') {
          colData[record.index].taxAmt = (
            (colData[record.index].totalAmt *
              colData[record.index].tax_percent) /
            100
          ).toFixed(2);
        }
        // if (record.discount_percent > 0) {
        //   total -= (total * record.discount_percent / 100);
        // }
        // colData[record.index].taxAmt = (record.tax_percent / 100) * total;
        // colData[record.index].taxAmt = parseFloat((colData[record.index].taxAmt || 0).toFixed(2));
        return (
          <Tooltip title={!isNaN(colData[record.index].taxAmt)
            ? colData[record.index].taxAmt
            : ''}>
          <Input
            name="taxAmt"
            value={
              !isNaN(colData[record.index].taxAmt)
                ? colData[record.index].taxAmt
                : ''
            }
            onBlur={() => handleSaveToDraft(false)}
            // onChange={(e) => onTableInputChange(e, record.index)}
            // placeholder="Enter customer GSTIN"
          />
          </Tooltip>
        );
      },
    },

    {
      title: '',
      dataIndex: '',
      key: '',
      width: '1.5%',
      render: (text: any, record: any) => {
        return (
          <div className="remove-row">
            {colData.length > 1 && (
              <CloseCircleOutlined
                className="remove-row__icon"
                onClick={() => handleRemoveRow(record.index,record.name)}
              ></CloseCircleOutlined>
            )}
          </div>
        );
      },
    },
  ];
  const handleRemoveRow = (index: number,name:string) => {
    const duplicateColData = [...colData];
    let noIndexChange = duplicateColData.slice(0, index);
    let indexChange = duplicateColData.slice(index + 1);
    let manipulatedIndex: any[] = [];
    indexChange.map((ele) => {
      ele['index'] = ele.index - 1;
      manipulatedIndex.push({ ...ele });
    });
    setColData([...noIndexChange, ...manipulatedIndex]);

    // let tempColData:any = []
    // duplicateItemData.map((ele:any) => {
    //   colData.map((ele2:any) => {
    //       if(ele.id == ele2.id){
    //         tempColData.push(ele)
    //       }
    //   })
    // })
    // console.log('tempColData',tempColData)
    // setItemData([...tempColData])
    
    // let indexOfName = addedItemsName.indexOf(name)
    // if(indexOfName > -1){
    //   let dummyAddedItemName = [...addedItemsName]
    //   dummyAddedItemName.splice(indexOfName,1)
    //   setAddedItemsName([...dummyAddedItemName])
    // }

  };
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [customerDuplicateData, setCustomerDuplicateData] = useState<any[]>([]);
  const [totalAmountAfterTaxCounter, setTotalAmountAfterTaxCounter] = useState(
    0
  ); //Trigger UseEffect
  const [pendingCounter, setPendingCounter] = useState(0); //Trigger UseEffect

  const {
    discount,
    tds,
    shipping_charges,
    received,
    item_total,
    gst_reverse_charge,
    total_amount_before_tax,
    cgst,
    sgst,
    igst,
    cess,
    total_amount_after_tax,
    pending,
    reimbursements
  } = billState;
  let { reverse_chargable,round_off } = invoiceState;
  React.useEffect(() => {
    // Calculation for total_amount_after_tax and gst_reverse_charge
    if (
      total_amount_before_tax !== undefined &&
      cess !== undefined &&
      tds !== undefined &&
      shipping_charges !== undefined &&
      reimbursements !== undefined
    ) {
      let temp_cess = 0;
      let temp_tds = 0;
      let temp_cgst = 0;
      let temp_igst = 0;
      let temp_sgst = 0;
      let temp_reimbursements = 0
      if (cess != '' && total_amount_before_tax != '') {
        temp_cess =
          parseFloat(total_amount_before_tax) * (parseFloat(cess) / 100);
      }
      if (tds != '') {
        console.log('this is tds',tds)
        temp_tds = parseFloat(tds);
      }
      if (cgst != undefined) {
        temp_cgst = parseFloat(cgst);
      }
      if (igst != undefined) {
        temp_igst = parseFloat(igst);
      }
      if (sgst != undefined) {
        temp_sgst = parseFloat(sgst);
      }
      if (reimbursements != undefined) {
        temp_reimbursements = parseFloat(reimbursements);
      }
      let temp_total_amount_after_tax =
        total_amount_before_tax +
        temp_cgst +
        temp_igst +
        temp_sgst +
        temp_reimbursements +
        temp_cess -
        temp_tds;
        setBillState((prevState: any) => {
          return {
            ...prevState,
            total_amount_after_tax: isNaN(
              parseFloat(temp_total_amount_after_tax.toFixed(2))
            )
              ? 0
              : invoiceState.round_off ? Math.round(parseFloat(temp_total_amount_after_tax.toFixed(2))) : parseFloat(temp_total_amount_after_tax.toFixed(2)),
          };
        });

      if (invoiceState.reverse_chargable) {
        const total_reverse_charge_gst = temp_cgst + temp_igst + temp_sgst + temp_cess;
        setBillState((prevState: any) => {
          return {
            ...prevState,
            gst_reverse_charge: parseFloat(total_reverse_charge_gst.toFixed(2))
          };
        });
      } else {
        setBillState((prevState: any) => {
          return { ...prevState, gst_reverse_charge: 0 };
        });
      }
      setPendingCounter((prevState: any) => prevState + 1);
    }
  }, [
    total_amount_before_tax,
    cgst,
    igst,
    cess,
    tds,
    sgst,
    reverse_chargable,
    shipping_charges,
    totalAmountAfterTaxCounter,
    round_off,
    reimbursements
  ]);
  React.useEffect(() => {
    // Calculation for pending and tax_invoice_total_amt

    if (received !== undefined && total_amount_after_tax !== undefined) {
      let temp_received = 0;
      if (received != '') {
        temp_received = parseFloat(received);
      }
      let temp_reverse_charge = 0;
      if (reverse_chargable != '' && invoiceState.reverse_chargable) {
        temp_reverse_charge = gst_reverse_charge;
      }
      let temp_pending = total_amount_after_tax - temp_received - temp_reverse_charge;

      setBillState((prevState: any) => {
        return {
          ...prevState,
          pending: isNaN(parseFloat(temp_pending.toFixed(2)))
            ? 0
            : parseFloat((total_amount_after_tax - temp_received - temp_reverse_charge).toFixed(2)),
          // 'tax_invoice_total_amt':
          // !isNaN(temp_pending + temp_reverse_charge) && (temp_pending + temp_reverse_charge) !== undefined && typeof (temp_pending + temp_reverse_charge) === 'number'
          //   ? withDecimal(parseFloat((temp_pending + temp_reverse_charge).toFixed(2)) || 0)
          //   : '',
        };
      });
    }
  }, [received, total_amount_after_tax, pendingCounter, gst_reverse_charge,reverse_chargable]);

  React.useEffect(() => {
    if (total_amount_after_tax !== undefined) {
      let temp_total_amount_after_tax = 0
      if(total_amount_after_tax){
        temp_total_amount_after_tax = total_amount_after_tax
      } 
      setBillState((prevState: any) => {
        return {
          ...prevState,
          tax_invoice_total_amt:
          total_amount_after_tax
              ? withDecimal(
                  parseFloat((total_amount_after_tax).toFixed(2)) ||
                    0
                )
              : '',
        };
      });
    }
  }, [total_amount_after_tax]);

  useEffect(() => {
    if (colData) {
      let total = 0;
      let total_tax_amt = 0;
      let highest_tax_bracket = 0;
      let total_amount_for_all_items = 0;
      colData.map((ele: any) => {
        let temp_total = ele.price_per_unit * parseInt(ele.quantity);
        let discount = (
          (total * parseFloat(ele.discount_percent)) /
          100
        ).toFixed(2);
        if (ele.is_tax_included == 'included') {
          total += parseFloat(
            (
              (temp_total - parseFloat(discount)) /
              (1 + ele.tax_percent / 100)
            ).toFixed(2)
          );
        }
        if (ele.is_tax_included == 'excluded') {
          // total += parseFloat((temp_total * (ele.tax_percent / 100)).toFixed(2))
          total += temp_total - parseFloat(discount);
        }
        total_tax_amt += parseFloat(ele.taxAmt);
        total_amount_for_all_items += ele.totalAmt;
        if (ele.tax_percent > highest_tax_bracket) {
          highest_tax_bracket = ele.tax_percent;
        }
        return total;
      });
      let discount_amt = total - total_amount_for_all_items;
      if (discount_amt) {
        setBillState((prevState: any) => {
          return {
            ...prevState,
            discount: isNaN(discount_amt)
              ? 0
              : parseFloat(discount_amt.toFixed(2)),
          };
        });
      }
      setBillState({
        ...billState,
        item_total: !isNaN(total - discount_amt)
          ? invoiceState.round_off ? Math.round(parseFloat((total - discount_amt).toFixed(2))) : parseFloat((total - discount_amt).toFixed(2))
          : 0,
      });
      if (selectedCustomer.gstin && !isNaN(shipping_charges)) {
        let temp_total_tax_amt = parseFloat(
          (
            total_tax_amt +
            (parseFloat(shipping_charges) * highest_tax_bracket) / 100
          ).toFixed(2)
        );
        if (selectedCustomer.gstin.slice(0, 2) == authState.gstin.slice(0, 2)) {
          //Checking whether both of gstin's belong to same state or not for sgst,cgst and igst breakup
          setBillState((prevState: any) => {
            return {
              ...prevState,
              cgst: invoiceState.round_off ? (Math.round(temp_total_tax_amt / 2) || 0) : (temp_total_tax_amt / 2 || 0),
              sgst: invoiceState.round_off ? (Math.round(temp_total_tax_amt / 2) || 0) : (temp_total_tax_amt / 2 || 0),
            };
          });

        } else {

          setBillState((prevState: any) => {
            return { ...prevState, igst: invoiceState.round_off ? (Math.round(temp_total_tax_amt) || 0) : (temp_total_tax_amt || 0)};
          });
        }
      }else if(!selectedCustomer.gstin  && !isNaN(shipping_charges)) {
        let temp_total_tax_amt = parseFloat(
          (
            total_tax_amt +
            (parseFloat(shipping_charges) * highest_tax_bracket) / 100
          ).toFixed(2)
        );
       setBillState((prevState: any) => {
          return { ...prevState, igst: invoiceState.round_off ? (Math.round(temp_total_tax_amt) || 0) : (temp_total_tax_amt || 0)};
        });
      }
      if (!isNaN(shipping_charges) && !isNaN(discount_amt)) {
        let temp_shipping_charges = 0;
        if (shipping_charges != '') {
          temp_shipping_charges = parseFloat(shipping_charges);
        }

          setBillState((prevState: any) => {
            return {
              ...prevState,
              total_amount_before_tax: isNaN(
                parseFloat(
                  (total - discount_amt + temp_shipping_charges).toFixed(2)
                )
              )
                ? 0
                : invoiceState.round_off ? Math.round(
                    parseFloat(
                      (total - discount_amt + temp_shipping_charges).toFixed(2)
                    )
                  ): parseFloat(
                    (total - discount_amt + temp_shipping_charges).toFixed(2)
                  )
            };
          });
      }
      setTotalAmountAfterTaxCounter((prevState: any) => prevState + 1);
    }
  }, [JSON.stringify(colData), shipping_charges,round_off]);

  React.useEffect(() => {
    if (params.id) {

      if (location !== undefined && location.state !== undefined) {
        const { data } = location?.state;
        setBillState({
          item_total: parseFloat(data.formValues.item_total.toFixed(2)),
          discount: parseFloat(data.formValues.discount),
          tds: parseFloat(data.formValues.tds),
          shipping_charges: parseFloat(data.formValues.shipping_charges),
          received: parseFloat(data.formValues.received),
          pending: parseFloat(data.formValues.pending),
          gst_reverse_charge: parseFloat(data.formValues.gst_reverse_charge),
          tax_invoice_total_amt: data.formValues.tax_invoice_total_amt,
          cess: parseFloat(data.formValues.cess),
          reimbursements: parseFloat(data.formValues.reimbursements),
        });
        setInvoiceState({
          ...data.formValues,
          bill_address: data.formValues.bill_address,
        });
        setColData([...data.items]);
        setInvoiceNumber(data.formValues.invoice_number);
        setSelectedCustomer({ ...data.formValues.customer });
        // form.setFieldsValue({
        //   'bill_address':data.formValues.bill_address,
        // });
      } else {
        globalApiService
          .getSingleEinvoice(params.id)
          .then((res: any) => {
            if (res.data.success) {
              setInvoiceNumber(res.data.data.e_invoice.invoice_number);
              setBillState({
                item_total: 0,
                discount: res.data.data.e_invoice.discount
                  ? res.data.data.e_invoice.discount
                  : 0,
                tds: res.data.data.e_invoice.tds
                  ? parseFloat(res.data.data.e_invoice.tds)
                  : 0,
                shipping_charges: res.data.data.e_invoice.shipping_charges
                  ? parseFloat(res.data.data.e_invoice.shipping_charges)
                  : 0,
                received: res.data.data.e_invoice.received
                  ? parseFloat(res.data.data.e_invoice.received)
                  : 0,
                pending: res.data.data.e_invoice.pending
                  ? parseFloat(res.data.data.e_invoice.pending)
                  : 0,
                gst_reverse_charge: res.data.data.e_invoice.gst_reverse_charge
                  ? parseFloat(res.data.data.e_invoice.gst_reverse_charge)
                  : 0,
                tax_invoice_total_amt: '',
                cess: res.data.data.e_invoice.cess
                  ? parseFloat(res.data.data.e_invoice.cess)
                  : 0,
                reimbursements: res.data.data.e_invoice.reimbursements
                  ? parseFloat(res.data.data.e_invoice.reimbursements) 
                  : 0

              });
              let tempInvoiceState: any = {
                bill_address: res.data.data.e_invoice.bill_address
                  ? res.data.data.e_invoice.bill_address
                  : '',
                bill_phone: res.data.data.e_invoice.bill_phone
                  ? res.data.data.e_invoice.bill_phone
                  : '',
                ship_address: res.data.data.e_invoice.ship_address
                  ? res.data.data.e_invoice.ship_address
                  : '',
                ship_phone: res.data.data.e_invoice.ship_phone
                  ? res.data.data.e_invoice.ship_phone
                  : '',
                due_date: res.data.data.e_invoice.due_date
                  ? res.data.data.e_invoice.due_date
                  : '',
                // 'id': res.data.data.e_invoice.id,
                invoice_date: res.data.data.e_invoice.invoice_date
                  ? res.data.data.e_invoice.invoice_date
                  : '',
                invoice_number: res.data.data.e_invoice.invoice_number
                  ? res.data.data.e_invoice.invoice_number
                  : '',
                invoice_type: res.data.data.e_invoice.invoice_type
                  ? res.data.data.e_invoice.invoice_type
                  : '',
                payment_type: res.data.data.e_invoice.payment_type
                  ? res.data.data.e_invoice.payment_type
                  : '',
                reverse_chargable: res.data.data.e_invoice.reverse_chargable
                  ? res.data.data.e_invoice.reverse_chargable
                  : false,
                round_off: res.data.data.e_invoice.round_off
                  ? res.data.data.e_invoice.round_off
                  : false
              };
              if (res.data.data.e_invoice.customer != null) {
                [
                  'customer_id',
                  'point_of_contact',
                  'gstin',
                  'email',
                  'phone_number',
                ].map((ele: any) => {
                  if (
                    res.data.data.e_invoice.customer[ele] &&
                    ele !== 'customer_id'
                  ) {
                    tempInvoiceState[ele] =
                      res.data.data.e_invoice.customer[ele];
                  }
                  if (ele == 'customer_id') {
                    tempInvoiceState['customer_id'] =
                      res.data.data.e_invoice.customer['id'];
                  }
                });
              }
              if (res.data.data.e_invoice.bank_account != null) {
                tempInvoiceState['bank_account_id'] =
                  res.data.data.e_invoice.bank_account.id;
              }
              setInvoiceState({ ...tempInvoiceState });
              if (res.data.data.e_invoice.customer != null) {
                setSelectedCustomer({ ...res.data.data.e_invoice.customer });
              }
              let tempItemArray: any[] = [];
              if (res.data.data.items.length) {
                res.data.data.items.map((ele: any, idx: any) => {
                  tempItemArray.push({
                    quantity: ele.quantity,
                    item_description: ele.item_description,
                    price_per_unit: ele.price_per_unit,
                    tax_percent: ele.tax_percent,
                    is_tax_included: ele.is_tax_included
                      ? 'included'
                      : 'excluded',
                    discount_percent: ele.discount_percent,
                    ...ele.item,
                    index: idx,
                  });
                });
              }
              if (tempItemArray.length) {
                setColData([...tempItemArray]);
              }
            }
          })
          .catch((err: AxiosError) => {
            const errorBody = err?.response?.data;
          });
      }
    }
  }, [id]);
  React.useEffect(() => {
    globalApiService
      .getAllCustomers()
      .then((res) => {
        if (res.data.success) {
          let tempArray: any[] = [];
          res.data.data.results.map((ele: any) => {
            if (ele.has_gstin) {
              tempArray.push({ ...ele });
            }
            setCustomerData([...tempArray]);
          });
          setCustomerDuplicateData(res.data.data.results);
        }
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
    globalApiService
      .fetchItems()
      .then((res) => {
        if (res.data.success) {
          res.data.data.results.map((ele: any, idx: number) => {
            setItemData((prevState) => [
              ...prevState,
              {
                ...ele,
                index: idx,
                tax_percent: 0,
                is_tax_included: 'included',
                price_per_unit: ele.price,
                discount_percent: 0,
                quantity: 1,
                item_description: ''
              },
            ]);
          });
        }
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
  }, []);
  // const [randomInvNumber, setRandomInvNumber] = useState(Date.now());
  const Random = (length:any) => {
    return (
      Math.floor(
        Math.pow(10, length - 1) +
          Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
      )
    );
  };
  // const [dummyCount, setDummyCount] = useState(0);
  // React.useEffect(() => {
  //   if (!params.id) {
  //     let count = 0;
  //     globalApiService
  //       .getInvoiceCount()
  //       .then((res) => {
  //         if (res.data.success) {
  //           count = res.data.data.count + 1;
  //           setDummyCount(count);
  //         }
  //       })
  //       .catch((err: AxiosError) => {
  //         const errorBody = err?.response?.data;
  //         console.log(errorBody);
  //       });
  //   }
  // }, [invoiceState.invoice_type]);

  React.useEffect(() => {
    if (!params.id) {
      let prefix: any = {};
      globalApiService
        .fetchProfileDetails(authState.gstin)
        .then((res) => {
          if (res.data.success) {
            setBankOption([...res.data.data.general_info.bankDetails]);
            prefix = { ...res.data.data.inv_settings };
            // if (invoiceState.invoice_type == 'B2B') {
            //   let countStr =
            //     prefix.b2b_prefix !== undefined ? prefix.b2b_prefix : '';
            //   countStr += Random(16-countStr.length);
              
            //   setInvoiceNumber(countStr.slice(0,16));
            // }
            // if (invoiceState.invoice_type == 'B2C') {
            //   let countStr =
            //     prefix.b2c_prefix !== undefined ? prefix.b2c_prefix : '';
            //   countStr += Random(16-countStr.length);
              
            //   setInvoiceNumber(countStr.slice(0,16));
              
            // }
          }
        })
        .catch((err: AxiosError) => {
          const errorBody = err?.response?.data;
          console.log(errorBody);
        });
    } else {
      globalApiService
        .fetchProfileDetails(authState.gstin)
        .then((res) => {
          if (res.data.success) {
            setBankOption([...res.data.data.general_info.bankDetails]);
          }
        })
        .catch((err: AxiosError) => {
          const errorBody = err?.response?.data;
          console.log(errorBody);
        });
    }
  }, [invoiceState.invoice_type]);

  // React.useEffect(() => {
  //   form.setFieldsValue({
  //     'bill_phone':invoiceState.phone_number,
  //     'ship_phone':invoiceState.phone_number
  //   });
  // },[invoiceState.phone_number])

  React.useEffect(() => {
    // if(!params.id){
    if (invoiceState.address != '' && invoiceState.address != undefined) {
      form.setFieldsValue({
        bill_address: invoiceState.address,
      });
      setInvoiceState((prevState: any) => {
        return { ...prevState, bill_address: invoiceState.address };
      });
    }
  }, [invoiceState.address]);


  // React.useEffect(() => {
  //   if(params.id){
  //   form.setFieldsValue({
  //     'bill_address':invoiceState.bill_address,
  //   });
  //   setInvoiceState((prevState: any) => {
  //     return { ...prevState, 'bill_address': invoiceState.bill_address };
  //   });
  // }
  // },[invoiceState.bill_address])

  React.useEffect(() => {
    let tempArray: any[] = [];
    if (invoiceState.invoice_type == 'B2B') {
      customerDuplicateData.map((ele) => {
        if (ele.has_gstin) {
          tempArray.push({ ...ele });
        }
        setCustomerData([...tempArray]);
      });
    }

    if (invoiceState.invoice_type == 'B2C') {
      customerDuplicateData.map((ele) => {
        if (!ele.has_gstin) {
          tempArray.push({ ...ele });
        }
        setCustomerData([...tempArray]);
      });
    }
  }, [invoiceState.invoice_type]);

  function parseDate(str1: any) {
    let dt1 = parseInt(str1.substring(0, 2));
    let mon1 = parseInt(str1.substring(3, 5));
    let yr1 = parseInt(str1.substring(6, 10));
    let date1 = new Date(yr1, mon1 - 1, dt1);
    return date1;
  }
  const [eInvoiceId, setEInvoiceID] = useState(0);

  const handleSaveToDraft = (isNotificationVisible: boolean) => {
    let customer_info: any = {};
    const reg = new RegExp(/^([a-zA-Z1-9]{1}[a-zA-Z0-9\/-]{0,15})$/);
    if (Object.keys(selectedCustomer).length !== 0) {
      customer_info = {
        company_name: selectedCustomer.company_name,
        point_of_contact: selectedCustomer.point_of_contact,
        phone_number: selectedCustomer.phone_number,
        email: selectedCustomer.email,
        gstin: selectedCustomer.gstin,
      };
    }
    let bank_account: any = {};
    if (invoiceState.bank_account_id) {
      bankOption.map((ele: any) => {
        if (ele.id === invoiceState.bank_account_id) {
          bank_account = { ...ele };
        }
      });
    }
    let eInvoiceItemPayload: any[] = [];
    if (colData.length) {
      colData.map((ele: any) => {
        if (ele.id !== null && ele.id !== '' && ele.id !== undefined) {
          eInvoiceItemPayload.push({
            quantity: ele.quantity ? parseInt(ele.quantity) : 1,
            price_per_unit: ele.price_per_unit ? parseFloat(ele.price_per_unit) : 0,
            tax_percent: parseFloat(ele.tax_percent),
            is_tax_included: ele.is_tax_included == 'included' ? true : false,
            item_id: parseFloat(ele.id),
            discount_percent: parseFloat(ele.discount_percent) || 0,
            item_description: ele.item_description
          });
        }
      });
    }
    //check for params id or invoice id if either of them is present call patch API
    if (params.id || eInvoiceId !== 0) {
      if(invoiceNumber!='' && reg.test(invoiceNumber) && invoiceNumber.length<=16){
      billState.tds =  parseFloat(billState.tds || 0) || 0;
      billState.shipping_charges= parseFloat(billState.shipping_charges || 0) || 0;
      billState.received= parseFloat(billState.received || 0) || 0;
      billState.cess= parseFloat(billState.cess || 0) || 0;
      billState.cess= parseFloat(billState.cess || 0) || 0;
      billState.reimbursements= parseFloat(billState.reimbursements || 0) || 0;
      let invoice_id = params.id ? params.id : eInvoiceId;
      globalApiService
        .updateEinvoice(invoice_id, {
          ...invoiceState,
          ...billState,
          status: 'DRAFT',
          invoice_number: invoiceNumber,
          items: eInvoiceItemPayload.length ? [...eInvoiceItemPayload] : [],
          bank_account: { ...bank_account },
          customer: { ...customer_info },
        })
        .then((res) => {

          if(res.data.data.isInvoiceExist){
            OpenNotification(res.data.data.message,'error')
            history.push(ROUTES.E_INVOICE)
            return;
            }

          if (res.data.success && isNotificationVisible) {
            OpenNotification(res.data.data.message, 'success');
          } else {
            if (isNotificationVisible) {
              OpenNotification(res.data.data.message, 'error');
            }
          }
        })
        .catch((err: AxiosError) => {
          const errorBody = err?.response?.data;
          console.log(errorBody);
        });
      }
  else{
    OpenNotification('Enter a valid Invoice Number','error');
  }
    } else {
      const draftData: any = {
        due_date: invoiceState.due_date,
        status: 'DRAFT',
        invoice_number: invoiceNumber,
        invoice_date: invoiceState.invoice_date,
        invoice_type: invoiceState.invoice_type,
        payment_type: invoiceState.payment_type,
        reverse_chargable: invoiceState.reverse_chargable,
        items: eInvoiceItemPayload.length ? [...eInvoiceItemPayload] : [],
      };
      [
        'bill_phone',
        'ship_phone',
        'bill_address',
        'ship_address',
        'bank_account_id',
        'discount',
        'tds',
        'shipping_charges',
        'received',
        'pending',
        'customer_id',
        'gst_reverse_charge',
        'cess',
        'round_off',
        'reimbursements'
      ].map((ele: any) => {
        if (ele in invoiceState) {
          draftData[ele] = invoiceState[ele];
        }
        if (ele in billState) {
          draftData[ele] = billState[ele];
        }
      });
      if (invoiceState.bank_account_id) {
        draftData['bank_account'] = { ...bank_account };
      }
      if (invoiceState.customer_id) {
        draftData['customer'] = { ...customer_info };
      }

      if (draftData) {
          if(draftData['invoice_number'] && reg.test(invoiceNumber) && invoiceNumber.length<=16){
        globalApiService
          .saveToDraft(draftData)
          .then((res) => {
            if (res.data.success) {
              setEInvoiceID(res.data.data.eInvoice_id);
              if (isNotificationVisible) {
                OpenNotification(res.data.data.message, 'success');
              }
              // if(!res.data.data.eInvoice_id){
              // history.goBack()
              // }
            } else {
              if (isNotificationVisible) {
                OpenNotification(res.data.data.message, 'error');
              }
            }
          })
          .catch((err: AxiosError) => {
            const errorBody = err?.response?.data;
            console.log(errorBody);
          });
        }
else{
  OpenNotification('Enter valid invoice number','error');
}
      }
    }
  };
  form.setFieldsValue({
    ...invoiceState,
    ...colData,
    ...billState,
  });

  useEffect(() =>{
    if(invoiceNumber != ''){
      invoiceform.setFieldsValue({'invoiceNumber':invoiceNumber})
    }
  },[invoiceNumber])


  const {
    ship_address,
    invoice_type,
    payment_type,
    reverse_chargable: triggerForPatchReverseChargable,
  } = invoiceState;
  let colDataLength = colData.length;

  useEffect(() => {
    if (
      ship_address != '' ||
      colDataLength > 1 ||
      payment_type in ['B2B', 'B2C'] ||
      invoice_type in ['CASH', 'CREDIT', 'PARTIAL_CREDIT'] ||
      triggerForPatchReverseChargable
    ) {
      handleSaveToDraft(false);
    }
  }, [
    ship_address,
    colDataLength,
    payment_type,
    invoice_type,
    triggerForPatchReverseChargable
  ]);

  useEffect(() => {
    if((invoiceState.bill_address != '' && invoiceState.ship_address != '') && (invoiceState.bill_address == invoiceState.ship_address)){
      setIsSameAddressChecked(true)
    }else{
      setIsSameAddressChecked(false)
    }
  },[invoiceState.bill_address,ship_address])

  // const {id:customer_id} = selectedCustomer
  // const {bank_account_id,bill_address,ship_address} = invoiceState
  // console.log(bank_account_id)
  // useEffect(() => {
  //   if((customer_id !== undefined || bank_account_id) && (bill_address || ship_address)){
  //     handleSaveToDraft(false)
  //   }
  // },[customer_id,bank_account_id,bill_address,ship_address])

  // const handleBackClick = () => {
  //   let confirm = window.confirm('Do you want to save this as draft before leaving?')
  //   if(confirm){
  //     handleSaveToDraft(true)
  //     history.push(ROUTES.E_INVOICE)
  //   }else{
  //     history.push(ROUTES.E_INVOICE)
  //   }
    
  // }
  //////////For invoice number filter//////////
  useEffect(() => {
    if (!params.id) {
    if(invoiceState.invoice_type == 'B2B'){
      globalApiService
      .getInvoiceNumber({invoice_type:"B2B"})
      .then((res) => {
        setInvoiceNumber(res.data.data.invoice_number)
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
      return;
    }
    if(invoiceState.invoice_type == 'B2C'){
      globalApiService
      .getInvoiceNumber({invoice_type:"B2C"})
      .then((res) => {
        console.log(res)
        setInvoiceNumber(res.data.data.invoice_number)
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
      return;
    }
  }
  },[invoiceState.invoice_type])

  return (
    <main>
      <Row>
        <Title level={3}>E invoicing</Title>
      </Row>
      <div className="header-container">
        <div className="header-container__firstele">
          <ArrowLeftOutlined
            className="header-container__firstele__icon"
            //onClick={handleBackClick}
            onClick={() => history.push(ROUTES.E_INVOICE)}
          />
          <h4 className="header-container__firstele__text">Invoice no. {isInputVisible ? <div>
           <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={(value:any)=>{console.log(value)}}
              onFinishFailed={(errorInfo:any)=>{console.log(errorInfo)}}
              autoComplete="on"
              className="einvoice-form"
              form={invoiceform}
            >
            <Form.Item label=" " name="invoiceNumber"   rules={[{ required:true, message: 'Invoice number required'},{pattern:/^([a-zA-Z1-9]{1}[a-zA-Z0-9\/-]{0,15})$/, message:"Invalid invoice number"}]}>
            <input className="input-class"
            value={invoiceNumber}
            onChange={setValue}

             />
            </Form.Item>
          </Form>
      </div> : invoiceNumber}</h4><RiEditLine   onClick={showInput}/>

        </div>
        <div onClick={showInput} ></div>
        <div className="header-container__secondele">
          <p className="header-container__secondele__text">Date</p>
          <DatePicker
            size="large"
            format={'DD/MM/YYYY'}
            value={
              invoiceState.invoice_date
                ? moment(parseDate(invoiceState.invoice_date), 'DD/MM/YYYY')
                : undefined
            }
            className="invdate__datepicker"
            onChange={dueInvoiceDateChange}
          />
          {/* <Input disabled className="header-container__secondele__input" value={today} /> */}
        </div>
      </div>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onEinvoiceFormFinish}
        onFinishFailed={onEinvoiceFormFailed}
        autoComplete="on"
        className="einvoice-form"
        form={form}
      >
        <div className="invoicetype-fourcolgrid">
          <Form.Item
            label="Invoice Type"
            name="invoice_type"
            rules={[
              { required: true, message: 'Invoice Type is a mandatory field' },
            ]}
          >
            <Radio.Group
              value={invoiceState.invoice_type}
              name="invoice_type"
              onChange={onInvoiceChange}
            >
              <Space direction="vertical" size="small">
                <Radio value="B2B">
                  <span className="radiotext">B2B</span>
                  (If the customer has a GSTIN)
                </Radio>
                <Radio value="B2C">
                  <span className="radiotext">B2C</span>
                  (If the customer does not have a GSTIN)
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Type of invoice payment"
            name="payment_type"
            rules={[
              {
                required: true,
                message: 'Type of invoice payment is a mandatory field',
              },
            ]}
          >
            <Radio.Group
              value={invoiceState.payment_type}
              name="payment_type"
              onChange={onInvoiceChange}
            >
              <Space direction="vertical" size="small">
                <Radio value="CASH">
                  <span className="radiotext">Cash</span>
                </Radio>
                <Radio value="CREDIT">
                  <span className="radiotext">Credit</span>
                </Radio>
                <Radio value="PARTIAL_CREDIT">
                  <span className="radiotext">Partial credit</span>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Reverse chargeable"
            name="reverse_chargable"
            className="reverse-chargeable"
            rules={[
              {
                required: true,
                message: 'Reverse Chargeable is a mandatory field',
              },
            ]}
          >
            {/* <Switch
              className="reverse-chargeable__switch"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={invoiceState.reverse_chargable}
              onChange={onSwitchChange}
            /> */}
            <div className="reverse-chargeble-switch">
              <div
                onClick={() => onSwitchChange(true)}
                className={
                  invoiceState.reverse_chargable
                    ? 'reverse-chargeble-switch__checked'
                    : 'reverse-chargeble-switch__unchecked'
                }
              >
                Yes
              </div>
              <div
                onClick={() => onSwitchChange(false)}
                className={
                  invoiceState.reverse_chargable
                    ? 'reverse-chargeble-switch__unchecked'
                    : 'reverse-chargeble-switch__checked'
                }
              >
                No
              </div>
            </div>
          </Form.Item>
          <Form.Item
            label="Round-off total amount"
            name="round_off"
            className="reverse-chargeable"
            rules={[
              { required: true, message: 'Round off is a manadatory field' },
            ]}
          >
            <div className="reverse-chargeble-switch">
              <div
                onClick={() => onRoundOffChange(true)}
                className={
                  invoiceState.round_off
                    ? 'reverse-chargeble-switch__checked'
                    : 'reverse-chargeble-switch__unchecked'
                }
              >
                Yes
              </div>
              <div
                onClick={() => onRoundOffChange(false)}
                className={
                  invoiceState.round_off
                    ? 'reverse-chargeble-switch__unchecked'
                    : 'reverse-chargeble-switch__checked'
                }
              >
                No
              </div>
            </div>
          </Form.Item>
          <div>
            {/* <Form.Item
            label="Due Date"
            name="due_date"
            className="due-date-picker"
            // rules={[{ required:true, message: 'Due Date is a mandatory field'}]}
            
          > */}
            <p className="dueDate_label">Due Date</p>

            <DatePicker
              size="large"
              format={'DD/MM/YYYY'}
              value={
                invoiceState.due_date
                  ? moment(parseDate(invoiceState.due_date), 'DD/MM/YYYY')
                  : undefined
              }
              className="dueDate__datepicker"
              onChange={dueDateChange}
              onBlur={() => handleSaveToDraft(false)}
            />
            {/* </Form.Item> */}
          </div>
        </div>
        <div className="invoicetype-threecolgrid">
          <div className="customer-search">
            <Form.Item
              label="Customer Name"
              name="customer_id"
              className="customer-name"
              rules={[
                {
                  required: true,
                  message: 'Customer name is a mandatory field',
                },
              ]}
            >
              <Select
                ref={refForCustomer}
                className="select-border-radius"
                size="large"
                showSearch
                style={{ width: '100%' }}
                defaultValue="Enter Existing Customer"
                placeholder="abc"
                optionFilterProp="children"
                onChange={(value: any) =>
                  handleCustomerSelectChange(value, 'customer_id')
                }
                filterOption={(input: any, option: any) =>
                  typeof option.children == "string" && option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onBlur={() => handleSaveToDraft(false)}
              >
                {customerData.map((item, idx) => {
                  if(invoiceState.invoice_type == 'B2B' ? item.gstin_status.toLowerCase() == 'active'.toLowerCase() : true)
                  return (
                  <Option key={idx} value={item.id}>
                    {item.company_name}
                  </Option>
                  )
                })}
                <OptGroup label="Inactive GSTIN Status">
                {customerData.map((item, idx) => {
                  if(invoiceState.invoice_type == 'B2B' ? item.gstin_status.toLowerCase() != 'active'.toLowerCase() : false)
                  return (
                  <Option key={idx} value={item.id} disabled>
                    {item.company_name}
                  </Option>
                  )
                })}
                </OptGroup>
              </Select>
            </Form.Item>
          </div>
          <div className="add-new">
            <p className="add-new__text">or</p>
            <div className="add-new__subcontainer">
              <PlusOutlined />
              <p
                className="add-new__subcontainer__text"
                onClick={() =>
                  history.push({
                    pathname: ROUTES.ADD_CUSTOMER,
                    state: { status: 'add_customer' },
                  })
                }
              >
                Add new
              </p>
            </div>
          </div>
          {/* {customerData
            .filter((name) => name.company_name === invoiceState.name)
            .map((filterData) => (
              <> */}
          <Form.Item
            label="Customer GSTIN"
            name="gstin"
            rules={[
              {
                required: invoiceState.invoice_type == 'B2B' ? true : false,
                message: 'gstin is a mandatory field',
              },
            ]}
          >
            <Input
              disabled
              name="gstin"
              value={invoiceState.gstin}
              onChange={onInvoiceChange}
              placeholder="Enter customer GSTIN"
              className="input-class"
              onBlur={() => handleSaveToDraft(false)}
            />
          </Form.Item>
          <Form.Item
            label="Customer email"
            name="email"
            rules={[
              { required: true, message: 'email is a mandatory field' },
              {
                pattern: new RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/),
                message: 'Enter a valid email',
              },
            ]}
          >
            <Input
              disabled
              name="email"
              value={invoiceState.email}
              onChange={onInvoiceChange}
              placeholder="Enter customer email"
              className="input-class"
              onBlur={() => handleSaveToDraft(false)}
            />
          </Form.Item>
          <Form.Item
            label="Contact no"
            name="phone_number"
            rules={[
              { required: true, message: 'Phone Number is a mandatory field' },
              {
                pattern: new RegExp(/^([+]\d{2})?\d{10}$/),
                message: 'Enter a valid phone number',
              },
            ]}
          >
            <Input
              disabled
              name="phone_number"
              value={invoiceState.phone_number}
              onChange={onInvoiceChange}
              placeholder="Enter contact no"
              className="input-class"
              onBlur={() => handleSaveToDraft(false)}
            />
          </Form.Item>
          <Form.Item
            label="Person concerned"
            name="point_of_contact"
            rules={[{ required: true, message: 'email is a mandatory field' }]}
          >
            <Input
              disabled
              name="point_of_contact"
              value={invoiceState.point_of_contact}
              onChange={onInvoiceChange}
              placeholder="Concerned person in company"
              className="input-class"
              onBlur={() => handleSaveToDraft(false)}
            />
          </Form.Item>
          {/* </>
            ))} */}
        </div>
        <div className="invoicetype-twocolgrid">
          <Form.Item
            label="Bill to phone number"
            name="bill_phone"
            className="bill-phone"
            rules={[
              { required: true, message: 'Phone Number is a mandatory field' },
              {
                pattern: new RegExp(/^([+]\d{2})?\d{10}$/),
                message: 'Enter a valid phone number',
              },
            ]}
          >
            <Input
              className="bill-phone__input input-class"
              name="bill_phone"
              value={invoiceState.bill_phone}
              onChange={onInvoiceChange}
              placeholder="Enter billing phone number"
              onBlur={() => handleSaveToDraft(false)}
            />
          </Form.Item>
          <Form.Item
            label="Ship to phone number"
            name="ship_phone"
            className="bill-phone"
            rules={[
              { required: true, message: 'Phone Number is a mandatory field' },
              {
                pattern: new RegExp(/^([+]\d{2})?\d{10}$/),
                message: 'Enter a valid phone number',
              },
            ]}
          >
            <Input
              className="bill-phone__input input-class"
              name="ship_phone"
              value={invoiceState.ship_phone}
              onChange={onInvoiceChange}
              placeholder="Enter shipping phone number"
              onBlur={() => handleSaveToDraft(false)}
            />
          </Form.Item>
          <Form.Item
            label="Billing address"
            name="bill_address"
            rules={[
              {
                required: true,
                message: 'Billing address is a mandatory field',
              },
            ]}
          >
            <Input.TextArea
              rows={3}
              className="input-class"
              name="bill_address"
              value={invoiceState.bill_address}
              onChange={onInvoiceChange}
              placeholder="Enter billing address"
              onBlur={() => handleSaveToDraft(false)}
            />
          </Form.Item>
          <Form.Item
            label=""
            name="ship_address"
            rules={[
              {
                required: true,
                message: 'Shipping address is a mandatory field',
              },
            ]}
          >
            <div className="ship-address">
              <p className="ship-address__text">
                Shipping address
                <span className="ship-address__checkbox">
                  <Checkbox checked={isSameAddressChecked} onChange={(e) => sameAsBillingChecked(e)} />
                </span>
                same as billing address
              </p>
            </div>
            <Input.TextArea
              ref={refForShippingAddress}
              rows={3}
              className="input-class"
              name="ship_address"
              value={invoiceState.ship_address}
              onChange={onInvoiceChange}
              placeholder="Enter shipping address"
              onBlur={() => handleSaveToDraft(false)}
            />
          </Form.Item>
        </div>
        <Table
          columns={col}
          dataSource={colData}
          onChange={onTableChange}
          scroll={{ x: true }}
          pagination={false}
          rowClassName="table-row-bg"
        />
        <div className="table-add-row">
          <p
            className={`table-add-row__text ${
              Object.keys(selectedCustomer).length == 0 &&
              'table-add-row__text--disabled'
            }`}
            onClick={handleAddRow}
          >
            {' '}
            <PlusOutlined />
            Add row
          </p>
        </div>
        <div className="footer-twocolgrid">
          <div className="footer-twocolgrid__firstele">
            <Form.Item
              label="Tax invoice total amount"
              name="tax_invoice_total_amt"
            >
              <Input.TextArea
                name="tax_invoice_total_amt"
                className="footer-twocolgrid__firstele--Bmargin input-class"
                value={billState.tax_invoice_total_amt}
                disabled
                placeholder="Enter total amount in words"
                onBlur={() => handleSaveToDraft(false)}
              />
            </Form.Item>
            <Form.Item
              label="Bank account for payment"
              name="bank_account_id"
              rules={[
                { required: true, message: 'Bank is a mandatory field' },
              ]}
            >
              <Select
                ref={refForBankAccount}
                className="select-border-radius"
                size="large"
                // defaultValue="Bank account for payment"
                placeholder="Select bank account"
                onChange={(value: any) =>
                  handleSelectChange(value, 'bank_account_id')
                }
                onBlur={() => handleSaveToDraft(false)}
              >
                {bankOption.map((ele) => (
                  <Option value={ele.id}>{ele.bank_name}</Option>
                ))}
                <div className="add-new__subcontainer add-new__subcontainer--small-font">
                <PlusOutlined />
                <p
                  className="add-new__subcontainer__text"
                  onClick={() => history.push({ pathname: ROUTES.COMPANY_PROFILE })}
                >
                  Add Bank Account
                </p>
              </div>
              </Select>
            </Form.Item>
          </div>
          <div className="footer-twocolgrid__secondele">
            <div className="total-twocolgrid">
              <p className="total-label">Total</p>
              <Form.Item
                name="item_total"
                rules={[
                  { required: true, message: 'Total is a mandatory field' },
                ]}
              >
                <Input
                  disabled
                  className="total-input input-class"
                  placeholder="Item Total"
                  value={
                    !isNaN(billState.item_total)
                      ? (billState.item_total || 0).toFixed(2)
                      : ''
                  }
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              {/* <p className="total-label">Discount</p>
              <Form.Item name="discount" 
              rules={[{ required:true, message: 'discount is a mandatory field'}]}
              >
                <Input
                  disabled
                  name="discount"
                  className="total-input input-class"
                  placeholder="Enter Discount"
                  value={billState.discount}
                  onChange={(value: any) => onBillChange(value)}
                />
              </Form.Item> */}
              <p className="total-label">Shipping Charges </p>
              <Form.Item
                name="shipping_charges"
                rules={[
                  {
                    required: true,
                    message: 'Shipping Charges is a mandatory field',
                  },
                  {pattern:/^([0-9]+)(.[[0-9]{1,4}]?)?$/, message:"Invalid shipping amount"},
                ]}
              >
                <Input
                  type='number'
                  className="total-input input-class"
                  name="shipping_charges"
                  placeholder="Enter Shipping Charges"
                  value={billState.shipping_charges}
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">Reimbursements </p>
              <Form.Item
                name="reimbursements"
                rules={[
                  {
                    required: true,
                    message: 'Reimbursements is a mandatory field',
                  },
                  {pattern:/^([0-9]+)(.[[0-9]{1,4}]?)?$/, message:"Invalid Reimbursements"},
                ]}
              >
                <Input
                  type='number'
                  className="total-input input-class"
                  name="reimbursements"
                  placeholder="Enter Reimbursements"
                  value={billState.reimbursements}
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">Total amount before tax</p>
              <Form.Item
                name="total_amount_before_tax"
                rules={[
                  {
                    required: true,
                    message: 'Total amount before tax is a mandatory field',
                  },
                ]}
              >
                <Input
                  disabled
                  className="total-input input-class"
                  name="total_amount_before_tax"
                  placeholder="Enter total amount before tax"
                  value={billState.total_amount_before_tax}
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="footer-twocolgrid__secondele">
            <div className="total-twocolgrid">
              <p className="total-label">CGST</p>
              <Form.Item
                name="cgst"
                rules={[
                  { required: true, message: 'cgst is a mandatory field' },
                ]}
              >
                <Input
                  disabled
                  className="total-input input-class"
                  placeholder="cgst"
                  value={!isNaN(billState.cgst) ? billState.cgst : ''}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">SGST/UGST</p>
              <Form.Item
                name="sgst"
                rules={[
                  {
                    required: true,
                    message: 'SGST/UGST is a mandatory field',
                  },
                ]}
              >
                <Input
                  disabled
                  name="sgst"
                  className="total-input input-class"
                  placeholder="Enter sgst"
                  value={!isNaN(billState.sgst) ? billState.sgst : ''}
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">IGST</p>
              <Form.Item
                name="igst"
                rules={[
                  { required: true, message: 'IGST is a mandatory field' },
                ]}
              >
                <Input
                  disabled
                  className="total-input input-class"
                  placeholder="igst"
                  value={!isNaN(billState.igst) ? billState.igst : ''}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">CESS</p>
              <Form.Item
                name="cess"
                rules={[
                  { required: true, message: 'CESS is a mandatory field'},
                  {pattern:/^([0-9]+)(.[[0-9]{1,2}]?)?$/, message:"Invalid cess percentage"},
                ]}
              >
                <Input
                  suffix="%"
                  type="number"
                  className="total-input input-class"
                  placeholder="cess"
                  name="cess"
                  value={billState.cess}
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">TDS/Other Tax Deductions </p>
              <Form.Item
                name="tds"
                rules={[
                  { required: true, message: 'Invalid TDS amount' },
                  {pattern:/^([0-9]+)(.[[0-9]{1,4}]?)?$/, message:"Invalid TDS amount"},
                ]}
              >
                <Input
                  type="number"
                  className="total-input input-class"
                  name="tds"
                  placeholder="Enter TDS/Other Tax Deductions"
                  value={billState.tds}
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">Total amount after tax</p>
              <Form.Item
                name="total_amount_after_tax"
                rules={[
                  {
                    required: true,
                    message: 'Total amount after tax is a mandatory field',
                  },
                ]}
              >
                <Input
                  disabled
                  className="total-input input-class"
                  name="total_amount_after_tax input-class"
                  placeholder="Enter total amount after tax"
                  value={
                    billState.total_amount_after_tax > 0
                      ? billState.total_amount_after_tax
                      : 0
                  }
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">Received</p>
              <Form.Item
                name="received"
                rules={[
                  {pattern:/^([0-9]+)(.[[0-9]{1,4}]?)?$/, message:"Invalid received amount"},
                  { required: true, message: 'Invalid received amount' },
                ]}
              >
                <Input
                  type="number"
                  className="total-input input-class"
                  name="received"
                  placeholder="Enter Received"
                  value={billState.received}
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">Pending</p>
              <Form.Item
                name="pending"
                rules={[
                  { required: true, message: 'Pending is a mandatory field' },
                ]}
              >
                <Input
                  disabled
                  className="total-input input-class"
                  name="pending"
                  placeholder="Enter Pending"
                  value={billState.pending ? billState.pending : ''}
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
              <p className="total-label">GST payable on reverse charge</p>
              <Form.Item
                name="gst_reverse_charge"
                rules={[
                  {
                    required: true,
                    message: 'GST Reverse Charge is a mandatory field',
                  },
                ]}
              >
                <Input
                  disabled={!invoiceState.reverse_chargable}
                  className="total-input input-class"
                  placeholder="Enter GST payable on reverse charge"
                  name="gst_reverse_charge"
                  value={billState.gst_reverse_charge}
                  onChange={(value: any) => onBillChange(value)}
                  onBlur={() => handleSaveToDraft(false)}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="previewbtnbox">
          <Form.Item>
            <p
              onClick={() => handleSaveToDraft(true)}
              // onClick={() => {
              //   setRouteToPreview(false)
              //   document.getElementById('submit-button')?.click()
              // }}
              className="previewbtnbox__btn previewbtnbox__btn--paragraph"
              style={{
                color: THEME.PRIMARY_COLOR,
              }}
            >
              Save to drafts
            </p>
          </Form.Item>
          <Form.Item>
            <Button
              id="submit-button"
              type="primary"
              htmlType="submit"
              className="previewbtnbox__btn"
              style={{
                backgroundColor: THEME.PRIMARY_COLOR,
              }}
              // onClick={() => history.push(ROUTES.INVOICE_PREVIEW)}
            >
              Preview & generate
            </Button>
          </Form.Item>
        </div>
      </Form>
    </main>
  );
};
export default NewInvoice;
