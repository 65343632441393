import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  Popconfirm,
  Modal,
  Upload,
  Dropdown,
  Menu,
  Select, message,
  Badge
} from 'antd';
import {
  PlusOutlined,
  FilterOutlined,
  ApartmentOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import {FiEdit3, RiDeleteBinLine} from 'react-icons/all';
import { useHistory } from 'react-router';
import {AxiosError} from 'axios';
import { globalApiService } from '../services/apiService';
import { THEME } from '../constants/theme';
import { ROUTES } from '../constants/routePaths';
import OpenNotification from '../utils/notification';
import {IState} from '../models';
import moment from 'moment';

const ManageCustomers = () => {
  const authState = useSelector((state: IState) => state.authReducer);
  const [uniqueStatus, setUniqueStatusArray] = useState([]);

  // Initialization
  const history = useHistory();
  // Destructuring
  const { Title, Paragraph, Text } = Typography;
  const { Option } = Select;
  const { Search } = Input;
  const { TabPane } = Tabs;
  const { Dragger } = Upload;
  // Base 64  Function
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  // Search
  const [tab, setTab] = useState('valid');
  const [searchQuery, setSearchQuery] = useState('');
  const onSearch = (key: string) => {
    setSearchQuery(key);
  };
  // Table Data
  const [customers, setCustomers] = useState([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [pagination, setPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize:10});
  const [loading, setLoading] = useState<boolean>(false);

  function deleteCustomers(data:any) {
    setLoading(true);
    globalApiService.deleteCustomers(data?.ids).then((res) => {
      if(res.data.success){
        getCustomers(
          {
            'pageNumber': 1,
            'pageSize': pagination.pageSize,
            'search': searchQuery,
            'tab': tab
          }
        );
        OpenNotification('Customers deleted successfully!', 'success');
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      setLoading(false);
    });
  };

  const handleBulkDeleteClick = () => {
    if (Array.isArray(rowKeys) && rowKeys?.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete these customers?')) {
        // console.log(rowKeys)
        deleteCustomers({ ids: Array.from(rowKeys)});
        setIsRowSelected(false);
        setRowKeys([]);
      }
    }
    if (!isRowSelected || rowKeys?.length === 0) {
      OpenNotification('Please select a customer to delete', 'error');
    }
  };

  const downloadInvalidCustomerList = () => {
    setLoading(true);
    globalApiService.downloadInvalidCustomerList().then((response)=>{
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invalid_customers_list.csv');
      document.body.appendChild(link);
      link.click();
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      setLoading(false);
    });
  };

  function getCustomers(data:any) {
    setLoading(true);
    globalApiService.getCustomers(data).then((res) => {
      if(res.data.success){
        setCustomers(res.data?.data.results);
        setCustomersCount(res.data?.data.count);
        setUniqueStatusArray(res.data?.data.unique_status);
      }
      setLoading(false);
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      setLoading(false);
    });
  };

  const unit_options: any = uniqueStatus;
  const unit_options_display:any = [];
  for(let i =0; i<unit_options.length; i++){
    unit_options_display.push(<Option key={unit_options[i]} value={unit_options[i]}>{unit_options[i]}</Option>);
  }

  useEffect(()=>{
    if(searchQuery != undefined){
      getCustomers(
        {
          'pageNumber': 1,
          'pageSize': pagination.pageSize,
          'search': searchQuery,
          'tab': tab
        }
      );
    }
  }, [searchQuery, tab]);

  useEffect(()=>{
    if(customers != undefined && customersCount != undefined){
      // @ts-ignore
      pagination.total = customersCount;
      setPagination(pagination);
      setCustomers(customers);
      setLoading(false);
    }
  }, [customers]);

  // Tab Change
  const onTabChange = (key: any) => {
    setTab(key);
  };
  // Add Customer
  // Handle Filter Value Change
    const handleFilterOptionChange = (value: string) => {
      if(value == undefined){
          value = '';
      }
        if (value != undefined) {
            getCustomers(
                {
                    'pageNumber': 1,
                    'pageSize': pagination.pageSize,
                    'status': value,
                    'tab': tab,
                }
            );
        }
    };

  // Handle Menu Click
  function handleMenuClick(e: any) {
    console.log('Click on menu item.');
    console.log('click', e);
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Option 1</Menu.Item>
      <Menu.Item key="2">Option 2</Menu.Item>
      <Menu.Item key="3">Option 3</Menu.Item>
    </Menu>
  );
  const [rowKeys, setRowKeys] = useState<React.Key[]>();
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setRowKeys(selectedRowKeys);
    },
    // getCheckboxProps: (record: any) => ({
    //   disabled: record.transporter === 'Disabled User', // Column configuration not to be checked
    //   name: record.transporter,
    // }),
  };
  // No pointer events if row selected
  const [isRowSelected, setIsRowSelected] = useState<boolean>(false)
  useEffect(() => {
    if(Array.isArray(rowKeys) && rowKeys?.length > 0){
      setIsRowSelected(true);
    }else {
      setIsRowSelected(false);
    }
  },[rowKeys]);
  // Table
  const onDelete = (id: number) => {
    deleteCustomers({ ids:[id] });
  };
  const onEdit = (record: any) => {
    history.push({
      pathname: `customers/editCustomer/${record.id}`,
      state: { record, status: 'edit_customer' },
    });
  };
  function onChange(pager: any) {
    pagination.current = pager.current;
    setPagination(pagination);
    getCustomers(
      {
        'pageNumber': pagination.current,
        'pageSize': pagination.pageSize,
        'search': searchQuery,
        'tab': tab
      }
    );
  }
  const col = [
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
      render: (text: any,record: any) => {
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        return (
          <div>
          {(moment().subtract(2, 'days') < moment(record.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')) ? (
            <Badge count="New" offset={[22, 0]}>
              <span>{text !== null ? text : 'N/A'}</span>
            </Badge>
          ) : <span>{text !== null ? text : 'N/A'}</span>}
        </div>
        );
      },
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text: any) => {
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        return (
          <span
            style={{ cursor: 'pointer' }}
            // onClick={() => history.push(ROUTES.CUSTOMER_DETAILS)}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: 'GSTIN Status',
      dataIndex: 'gstin_status',
      key: 'gstin_status',
      render: (text: any) => {
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        return (
          <span>
            {text || 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'Email id',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone no',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (text: any, record: any) => {
        return (
          <div className="iconContainer">
            <FiEdit3
              className="iconContainer__item"
              onClick={() => onEdit(record)}
            />
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this customer?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <RiDeleteBinLine
                className="iconContainer__item"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const cusromerColData = [
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
      render: (text: any) => {
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        return (
          <span>
            {text || 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      render: (text: any) => {
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        return (
          <span>
            {text || 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'Email id',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone no',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Reason For Failure',
      dataIndex: 'failure_reason',
      key: 'failure_reason',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (text: any, record: any) => {
        return (
          <div className="iconContainer">
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this customer?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <RiDeleteBinLine
                className="iconContainer__item"
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const data = [
    {
      id: '1',
      gstin: '10AABCU9603R1Z2',
      company_name: 'Maple India Private Limited',
      email: 'mapleindialtd@gmail.com',
      phone_number: '985985475',
      status: 'Active',
      reason: 'GSTIN Invalid',
      actions: '',
    },
    {
      id: '2',
      gstin: '10AABCU9603R1Z2',
      company_name: 'Maple India Private Limited',
      email: 'mapleindialtd@gmail.com',
      phone_number: '985985475',
      status: 'Active',
      reason: 'GSTIN Invalid',
      actions: '',
    },
    {
      id: '3',
      gstin: '10AABCU9603R1Z2',
      company_name: 'Maple India Private Limited',
      email: 'mapleindialtd@gmail.com',
      phone_number: '985985475',
      status: 'Active',
      reason: 'GSTIN Invalid',
      actions: '',
    },
    {
      id: '4',
      gstin: '10AABCU9603R1Z2',
      company_name: 'Maple India Private Limited',
      email: 'mapleindialtd@gmail.com',
      phone_number: '985985475',
      status: 'Active',
      reason: 'GSTIN Invalid',
      actions: '',
    },
  ];
  // Bulk Upload Modal
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isFirstStageVisible, setIsFirstStageVisible] = useState(true);
  const [isSuccessScreenVisible, setISSuccessScreenVisible] = useState(false);
  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const handleUploadOk = () => {
    setIsUploadModalVisible(false);
  };

  const handleUploadCancel = () => {
    setIsUploadModalVisible(false);
    setIsFirstStageVisible(true); // To Hide Second stage for bulk upload
  };
  // Bulk Upload Drag and Drop
  const props = {
    name: 'file',
    multiple: false,
    headers: {
      authorization: `Token ${authState.token}`,
    },
    action: `${process.env.REACT_APP_API_URL  }/kyss/upload-customer-list`,
    beforeUpload(i: any) {
      const isCSV = i.type === 'text/csv';
      if (!isCSV) {
        message.error(`${i.name} is not a csv file...`);
        OpenNotification(`${i.name} is not a csv file`, 'error');
      }
      return isCSV;
    },
    onChange(info: any) {
      // console.log(info);
      //   toBase64(info.file)
      //   .then((result) =>console.log(result))
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        // console.log(`${info.file.name} file uploaded successfully.`);
        OpenNotification(`${info.file.name} File Uploaded Successfully.`, 'success');
        getCustomers(
          {
            'pageNumber': 1,
            'pageSize': pagination.pageSize,
            'search': searchQuery,
            'tab': tab
          }
        );
      } else if (status === 'error') {
        OpenNotification(`${info.file.name} File Upload Failed.`, 'error');
        // console.log(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      // console.log(e);
      // console.log('Dropped files', e.dataTransfer.files);
    },
    // progress: {
    //   strokeColor: {
    //     '0%': '#4532bf',
    //     '100%': '#87d068',
    //   },
    //   strokeWidth: 3,
    //   format: (percent:any) => `${parseFloat(percent.toFixed(2))}%`,
    // },
  };
  // Before Upload
  function beforeUpload(file: any, fileList: any) {
    // console.log(file);
    // console.log(fileList);
    toBase64(file).then((result) => console.log(result));
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //   console.error('You can only upload JPG/PNG file!');
    // }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   console.error('Image must smaller than 2MB!');
    // }
    return true;
  }

  return (
    <main className="">
      {/* Bulk Upload Modal */}
      <Modal
        className="bulkUploadModal"
        title=""
        centered
        visible={isUploadModalVisible}
        onOk={handleUploadOk}
        onCancel={handleUploadCancel}
        footer={[]}
      >
        {isFirstStageVisible && (
          <section className="uploadSection">
            <h3 className="uploadSection__title">
              You need to download template, add your customers to it and upload
              the file
            </h3>
            <div className="uploadSection__done">
              <p className="uploadSection__done__text">Already done ?</p>
            </div>
            <div className="uploadSection__download">
              <a href={`${process.env.PUBLIC_URL}/excel-templates/customer_template.csv`} rel="noopener noreferrer" target="_blank" className="uploadSection__download__text">
                Download Template
              </a>
              <Button
                className="uploadSection__download__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                }}
                // onClick={() => setIsFirstStageVisible(false)}
              >
                Upload file
              </Button>
            </div>
          </section>
        )}
        {!isFirstStageVisible && !isSuccessScreenVisible && (
          <section className="fileupload-section">
            <Title level={3} className="fileupload-section__title">
              Bulk upload customers
            </Title>
            <div className="fileupload-section__dragger">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Dragger {...props}>
                <p className="fileupload-section__dragger__text">
                  Drag & drop <br></br>file
                </p>
              </Dragger>
            </div>
            <div className="fileupload-section__browse">
              <Upload {...props}>
                <Button className="fileupload-section__browse__btn">
                  Browse
                </Button>
              </Upload>
            </div>
          </section>
        )}
        {!isFirstStageVisible && isSuccessScreenVisible && (
          <section className="success-section">
            <div className="success-section__icon">
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: '24px' }}
              />
            </div>
            <h2 className="success-section__text">40 Customers added</h2>
            <div className="success-section__btnbox">
              <Button
                className="success-section__btnbox__btn"
                onClick={handleUploadCancel}
              >
                Ok
              </Button>
            </div>
          </section>
        )}
      </Modal>

      {/* endModal */}
      <Row>
        <Title level={3}>Manage Customers</Title>
      </Row>
      <Tabs defaultActiveKey="valid" className='custom_tabs' onChange={onTabChange}>
        <TabPane tab="Customer List" key="valid">
          <section className="customers">
            <div className="customers__add">
              <Button
                className="customers__add__btn"
                // onClick={showModal}
                onClick={() =>
                  history.push({
                    pathname: ROUTES.ADD_CUSTOMER,
                    state: { status: 'add_customer' },
                  })}
              >
                <PlusOutlined className='btn-icon-padding' />
                Add customers
              </Button>
              {/*<Button*/}
              {/*  className="customers__add__btn customers__add__btn--bg"*/}
              {/*  onClick={showUploadModal}*/}
              {/*>*/}
              {/*  Bulk upload customers*/}
              {/*</Button>*/}
            </div>
            <div className="customers__search">
              <div className="customers__search__bar">
                <Search
                  className='date-picker-border-radius'
                  placeholder="Search by company name/GSTIN"
                  onSearch={onSearch}
                />
                <Select
                  className="gstin-select select-border-radius"
                  placeholder={'GSTIN Filters'}
                  onChange={handleFilterOptionChange}
                  suffixIcon={<FilterOutlined color="#ccc" />}
                  // size="large"
                  allowClear
                >
                    {unit_options_display}
                </Select>
                {/* <Select
                  defaultValue={`GSTIN Filters ${<FilterOutlined/>}`}
                  onChange={handleFilterOptionChange}
                >
                  <Option value="1">Option 1</Option>
                  <Option value="2">Option 2</Option>

                </Select> */}
              </div>
              <div className="customers__search__delete">
                <Button className="customers__search__delete--btn" onClick={() => handleBulkDeleteClick()}>
                  Delete Customer/s
                </Button>
              </div>
            </div>
          </section>
          <div className="customers__table custom_table">
            <Table
              columns={col}
              rowKey={(record:any) => record.id}
              loading={loading}
              dataSource={customers}
              pagination={pagination}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              onChange={onChange}
            />
          </div>
        </TabPane>
        <TabPane tab="Invalid List" key="invalid">
          <section className="invalidcustomers">
            <div className="invalidcustomers__search">
              <div className="invalidcustomers__search__bar">
                <Search
                  className='date-picker-border-radius'
                  placeholder="Search by company name/GSTIN"
                  onSearch={onSearch}
                />
              </div>
              <div className="invalidcustomers__search__clear">
                <Button
                  type='link'
                  onClick={() => downloadInvalidCustomerList()}
                  style={{'color': '#4532bf'}}
                >
                  Download list
                </Button>
                <Button className="invalidcustomers__search__clear--btn" onClick={() => handleBulkDeleteClick()}>
                  Clear
                </Button>
              </div>
            </div>
          </section>
          <div className="invalidcustomers__table custom_table">
            <Table
              columns={cusromerColData}
              rowKey={(record:any) => record.id}
              dataSource={customers}
              pagination={pagination}
              loading={loading}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              onChange={onChange}
            />
          </div>
        </TabPane>
      </Tabs>
    </main>
  );
};
export default ManageCustomers;
