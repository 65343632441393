import { IAction, IColleague, IColleagueState } from '../../models';
import { ColleagueActions } from '../actions/colleagueActions';
import { IResponseAddColleague } from '../../models/api/colleagueModels';

const defaultState = {} as IColleagueState;

export default function ColleagueReducer(
  colleagueState: IColleagueState = defaultState,
  action: IAction
): IColleagueState {
  switch (action.type) {
    case ColleagueActions.RESET_DATA: {
      
      return {
        colleagues:[]
      };
    }
    case ColleagueActions.REQUEST_COLLEAGUE_LIST_SUCCESS: {
      const payload = action.body;
      return {
        ...colleagueState,
        colleagues: payload,
      };
    }
    case ColleagueActions.REQUEST_COLLEAGUE_UPDATE_SUCCESS: {
      const payload: IColleague = action.body;
      const colleaguesOriginalList: IColleague[] = colleagueState.colleagues;
      const colleagueToReplaceIndex: number = colleaguesOriginalList.findIndex(
        (r) => r.id === payload.id
      );
      colleaguesOriginalList.splice(colleagueToReplaceIndex, 1, payload);
      return {
        ...colleagueState,
        colleagues: colleaguesOriginalList,
      };
    }
    case ColleagueActions.ADD_COLLEAGUE_SUCCESS: {
      const actionBody: IResponseAddColleague = action.body;
      return {
        ...colleagueState,
        colleagues: [...colleagueState.colleagues, actionBody],
      };
    }
    case ColleagueActions.REQUEST_COLLEAGUE_DELETE_SUCCESS: {
      const actionBody: IColleague = action.body;
      const colleagueId: number = actionBody.id;
      const colleaguesList: IColleague[] = colleagueState.colleagues;
      const modifiedColleaguesList: IColleague[] = colleaguesList.filter(
        (item) => {
          return item.id !== colleagueId;
        }
      );
      return {
        ...colleagueState,
        colleagues: modifiedColleaguesList,
      };
    }
    default: {
      return colleagueState;
    }
  }
}
