/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Row from 'antd/es/grid/row';
import { Col } from 'antd/es/grid';
import { useDispatch, useSelector } from 'react-redux';
import StylizedButton from './stylizedButton';
import { THEME } from '../constants/theme';
import { logOut, setWhatsappOptIn } from '../store/actions/authActions';
import { IState } from '../models';
import { resetColleagueData } from '../store/actions/colleagueActions';
import { dataSourceResetData } from '../store/actions/dataSourceActions';

export default function TopBar() {
  const storeDispatch = useDispatch();
  const authState = useSelector((state: IState) => state.authReducer);

  const btnText = `Logout ${authState.userName || ''}?`;
  const showSubscribeBtn = !authState.isWhatsappOptIn;

  const disableWhatsappButton = () => {
    let waBtnArray = document.querySelectorAll('#WAButton .floating-wpp-button');
    waBtnArray?.forEach(node => node?.remove());
    waBtnArray = document.querySelectorAll('#WAButton .floating-wpp-popup');
    waBtnArray?.forEach(node => node?.remove());
  };

  function signOutBtnAction() {
    disableWhatsappButton();
    storeDispatch(logOut());
    storeDispatch(resetColleagueData());
    storeDispatch(dataSourceResetData());
    document.body.className = document.body.className.replace('logged-in', '');
    
  }

  function whatsappBtnAction() {
    storeDispatch(
      setWhatsappOptIn({
        profileId: authState.userId,
        isWhatsappOptIn: true,
      })
    );
  }

  return (
    <Row
      align="middle"
      justify="end"
      style={{ boxShadow: THEME.SHADOW, height: '50px' }}
    >
      {showSubscribeBtn ? (
        <Col>
          <a
            href="#"
            onClick={() => whatsappBtnAction()}
            style={{
              margin: 0,
              paddingRight: '2rem',
              fontWeight: 'bold',
              color: 'rgba(0, 0, 0, 0.65)',
            }}
          >
            <span>Subscribe to </span>
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/whatsapp.svg`}
                alt="JiTFin logo"
                height="30px"
              />
            </span>
          </a>
        </Col>
      ) : (
        <span />
      )}

      <Col>
        <p style={{ margin: 0, paddingRight: '10px', fontWeight: 'bold' }}>
          GSTIN:
          {authState.gstin}
        </p>
      </Col>
      <Col style={{ padding: '10px 32px 10px' }}>
        <StylizedButton
          textInButton={btnText}
          size="small"
          customStyles={{
            backgroundColor: THEME.PRIMARY_COLOR,
          }}
          onClickFn={() => signOutBtnAction()}
        />
      </Col>
    </Row>
  );
}
