/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Modal, Button, Input, DatePicker } from 'antd';
import { Table } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// import moment from 'moment';
import { updateMatchEntry } from '../store/actions/ReconcilationActions';
import DateUtils from '../utils/date';

interface IReportModal2 {
  setActiveStep?: any;
  isModalVisible: any;
  setIsModalVisible: any;
  data?: any;
  callback?: Function;
}

const ReportModal2: React.FunctionComponent<IReportModal2> = ({
  isModalVisible,
  setIsModalVisible,
  data,
  callback = () => {}
}) => {
  const [curData, setCurData] = useState({});
  const dispatch = useDispatch();
  const params: any = useParams();
  const dateFormat = 'DD-MM-YYYY';

  const onEdit = (payload: any) => {
    dispatch(updateMatchEntry({ recordId: params.id, id: data.id, ...payload }));
    callback();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getFormattedDate = (dateString:string) => {
    const result = DateUtils.getMomentDate(dateString);
    return result?.dateString || undefined;
  };

  return (
    <Modal
      width={800}
      title={(
        <div className="fs--16 text-center bold">
          Edit the fields manually to match
        </div>
      )}
      visible={isModalVisible}
      onOk={handleOk}
      closeIcon={<div className="fs--16 pr--15" style={{color:'#462E90'}}>close</div>}
      onCancel={handleCancel}
      footer={[
        <div className="fill-width flex-x center pa-20" key={0}>
          <Button
            className="ant-btn align-center"
            onClick={() => {
              onEdit(curData);
            }}
            style={{backgroundColor:'#462E90',color: 'white'}}
          >
            save
          </Button>
        </div>,
      ]}
    >
      <Table className="table reconcile-report">
        <thead className="thead">
          <tr>
            <th className="cell" />
            <th className="cell">My Data</th>
            <th className="cell">Supplier Data</th>
          </tr>
        </thead>
        <tbody className="tbody">
          <tr className="trow">
            <td className="cell">
              <div> GSTIN </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.gstin_itr}
                  onChange={(e) => {
                    setCurData({ ...curData, gstin_itr: e.target.value });
                  }}
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.gstin_2a}
                  onChange={(e) => {
                    setCurData({ ...curData, gstin_2a: e.target.value });
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className="trow">
            <td className="cell">
              <div> Supplier Name </div>
            </td>
            <td className="cell">
              <div>
                {' '}
                <Input
                  defaultValue={data.supplier_name_itr}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      supplier_name_itr: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.supplier_name_2a}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      supplier_name_2a: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className="trow">
            <td className="cell">
              <div> Invoice No </div>
            </td>
            <td className="cell">
              <div>
                {' '}
                <Input
                  defaultValue={data.invoice_number_itr}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      invoice_number_itr: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.invoice_number_2a}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      invoice_number_2a: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className="trow">
            <td className="cell">
              <div> Invoice Date </div>
            </td>
            <td className="cell">
              <div>
                {' '}
                <DatePicker 
                  defaultValue={getFormattedDate(data.invoice_date_itr)} 
                  format={dateFormat} 
                  onChange={(date, dateString) => {
                    setCurData({
                      ...curData,
                      invoice_date_itr: dateString,
                    });
                  }} 
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <DatePicker 
                  defaultValue={getFormattedDate(data.invoice_date_itr)} 
                  format={dateFormat} 
                  onChange={(date, dateString) => {
                    setCurData({
                      ...curData,
                      invoice_date_2a: dateString,
                    });
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className="trow">
            <td className="cell">
              <div> Tax Rate </div>
            </td>
            <td className="cell">
              <div>
                {' '}
                <Input
                  defaultValue={data.tax_rate_itr}
                  onChange={(e) => {
                    setCurData({ ...curData, tax_rate_itr: e.target.value });
                  }}
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.tax_rate_2a}
                  onChange={(e) => {
                    setCurData({ ...curData, tax_rate_2a: e.target.value });
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className="trow">
            <td className="cell">
              <div> Taxable Value </div>
            </td>
            <td className="cell">
              <div>
                {' '}
                <Input
                  defaultValue={data.taxable_value_itr}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      taxable_value_itr: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.taxable_value_2a}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      taxable_value_2a: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className="trow">
            <td className="cell">
              <div> IGST </div>
            </td>
            <td className="cell">
              <div>
                {' '}
                <Input
                  defaultValue={data.integrated_tax_itr}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      integrated_tax_itr: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.integrated_tax_2a}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      integrated_tax_2a: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className="trow">
            <td className="cell">
              <div> CGST </div>
            </td>
            <td className="cell">
              <div>
                {' '}
                <Input
                  defaultValue={data.central_tax_itr}
                  onChange={(e) => {
                    setCurData({ ...curData, central_tax_itr: e.target.value });
                  }}
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.central_tax_2a}
                  onChange={(e) => {
                    setCurData({ ...curData, central_tax_2a: e.target.value });
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className="trow">
            <td className="cell">
              <div> SGST </div>
            </td>
            <td className="cell">
              <div>
                {' '}
                <Input
                  defaultValue={data.state_tax_itr}
                  onChange={(e) => {
                    setCurData({ ...curData, state_tax_itr: e.target.value });
                  }}
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.state_tax_2a}
                  onChange={(e) => {
                    setCurData({ ...curData, state_tax_2a: e.target.value });
                  }}
                />
              </div>
            </td>
          </tr>
          <tr className="trow">
            <td className="cell">
              <div> Total Invoice Data </div>
            </td>
            <td className="cell">
              <div>
                {' '}
                <Input
                  defaultValue={data.invoice_value_itr}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      invoice_value_itr: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
            <td className="cell">
              <div>
                <Input
                  defaultValue={data.invoice_value_2a}
                  onChange={(e) => {
                    setCurData({
                      ...curData,
                      invoice_value_2a: e.target.value,
                    });
                  }}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </Modal>
  );
};

export default ReportModal2;
