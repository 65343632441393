import React from 'react';
import { Card } from 'antd';
import Title from 'antd/es/typography/Title';
import Row from 'antd/es/grid/row';
import Button from 'antd/es/button';
import { THEME } from '../constants/theme';

export default function RequestReportCards(props: {
  title: string;
  buttonText: string;
  onClickFn: () => void;
}) {
  const { title, buttonText, onClickFn } = props;
  return (
    <Card style={{ boxShadow: THEME.SHADOW, minHeight: '256px' }}>
      <Row align="middle" justify="center" style={{ padding: '30px' }}>
        <Title level={3}>
          {title}
        </Title>
      </Row>
      <Row align="middle" justify="center" style={{ paddingBottom: '30px' }}>

        <Button
          size="large"
          shape="round"
          style={{
            color: THEME.PRIMARY_COLOR
          }}
          onClick={onClickFn}
        >
          {buttonText}
        </Button>
      </Row>
    </Card>
  );
}
