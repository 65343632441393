import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import Row, { RowProps } from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Select } from 'formik-antd/es';
import StylizedButton from './stylizedButton';
import ValidationService from '../services/validationService';
import { IState } from '../models';
import { requestRegisteredGSTINs } from '../store/actions/authActions';

export interface IGSTINFormFields {
  gstin: string;
}

export default function GSTINForm(props: {
  onSubmitFn: (values: IGSTINFormFields) => any;
  justifyBtn?: RowProps['justify'];
  fetchRegisteredGSTINs: boolean;
}) {
  const { justifyBtn } = props;
  const storeDispatch = useDispatch();
  const authState = useSelector((state: IState) => state.authReducer);
  const [isGstValueSelected, setIsGstValueSelected] = useState(false);
  useEffect(() => {
    if (
      props.fetchRegisteredGSTINs === true &&
      authState.phoneNumber &&
      authState.registeredGSTINs === undefined
    ) {
      storeDispatch(
        requestRegisteredGSTINs({ phoneNumber: authState.phoneNumber })
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{ gstin: '' } as IGSTINFormFields}
      validate={(values) => {
        const validationFieldToFunctionMap = {
          gstin: ValidationService.isGstin,
        };
        return ValidationService.validateFields(
          values,
          validationFieldToFunctionMap
        );
      }}
      onSubmit={(values: IGSTINFormFields, actions) => {
        props.onSubmitFn(values);
        actions.setSubmitting(false);
      }}
      render={({ handleSubmit }) => (
        <Form>
          <Form.Item name="gstin" hasFeedback showValidateSuccess>
            {props.fetchRegisteredGSTINs === true ? (
              <Select
                name="gstin"
                placeholder="Select GSTIN Number"
                size="large"
                onChange={() => setIsGstValueSelected(true)}
              >
                {authState.registeredGSTINs &&
                  authState.registeredGSTINs.map((el) => {
                    return (
                      <Select.Option value={el.gstin} key={el.gstin}>
                        {el.gstin}
                      </Select.Option>
                    );
                  })}
              </Select>
            ) : (
              <Input
                name="gstin"
                placeholder="22AAAAA00xxxxxx"
                size="large"
                style={{borderRadius:'0.5rem',border:'solid 1px #5c5c5c'}}
              />
            )}
          </Form.Item>

          <Row justify={justifyBtn || 'center'}>
            <Col>
              <StylizedButton
                textInButton="Submit"
                onClickFn={handleSubmit}
                size="large"
                disabled={(props.fetchRegisteredGSTINs && !isGstValueSelected)}
                customStyles={{backgroundColor:'#ed6957',borderRadius:'0.4rem',color:'#fff',boxShadow:'none'}}
              />
            </Col>
          </Row>
        </Form>
      )}
    />
  );
}
