import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import sagas from './sagas/sagas';

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState: any) {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  store.subscribe(()=>{
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
    localStorage.reduxStateTime=new Date();
  });
  sagaMiddleware.run(sagas);
  return store;
}
