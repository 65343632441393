/* eslint-disable no-nested-ternary */
/* eslint no-nested-ternary: "error" */

import React, { useEffect } from 'react';
import { Row, Col, Tabs, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Title from 'antd/lib/typography/Title';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import DownloadGstR2A from '../components/DownloadGstR2A';
import UploadPR from '../components/UploadPr';
import MappingPR from '../components/MappingPr';
import StartRecon from '../components/StartRecon';
import GenerateOTP from '../components/GenerateOPT';
import GSTR2ADownloaded from '../components/GSTR2ADownloaded';
import { getPageState, navPrevious, navNext } from '../store/actions/ReconcilationActions';

const { TabPane } = Tabs;

const ReconcilationPage: React.FunctionComponent = () => {
  const activeStep = useSelector(
    (state: any) => state.reconReducer.activeState
  );
  const navStartingFrom = useSelector(
    (state: any) => state.reconReducer.navStartingFrom
  );
  // const pageState = useSelector((state: any) => state.reconReducer.pageState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPageState());
  }, [dispatch]);

  const ReconNavigators = () => {
    return(
      <div className="app__reconciliation-page--navigator">
        <div className="app__reconciliation-page--navigation__prev">
          <Button 
            disabled={activeStep === 0}
            onClick={
              () => {
                if(activeStep > 0){
                  dispatch(navPrevious());
                }
              }
            }
            type="primary"
            shape="round"
            icon={<ArrowLeftOutlined />}
            size="small"
          >
            Prev
          </Button>
        </div>
        <div className="app__reconciliation-page--navigation__next">
          <Button 
            disabled={activeStep >= navStartingFrom}
            onClick={
              () => {
                if(activeStep < navStartingFrom){
                  dispatch(navNext());
                }
              }
           } 
            type="primary" 
            shape="round" 
            icon={<ArrowRightOutlined />} 
            size="small"
          >
            Next
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="reconcilation-wrapper app__reconciliation-page">
      <Row>
        <Col sm={24}>
          <Title level={3}>Reconcilation</Title>
        </Col>
      </Row>
      <Row>
        <Col sm={24}>
          <Tabs
            defaultActiveKey="step1"
            activeKey={
              activeStep < 3 ? 'step1' : activeStep < 5 ? 'step2' : 'step3'
            }
            tabBarStyle={{ fontSize: '120px' }}
            tabBarExtraContent={ReconNavigators()}
          >
            {/* Reset activeStep to move to earlier screen */}
            <TabPane tab="Download GSTR2A" key="step1">
              {activeStep === 0 && <DownloadGstR2A />}
              {activeStep === 1 && <GenerateOTP />}
              {activeStep === 2 && <GSTR2ADownloaded />}
            </TabPane>
            <TabPane
              tab="Upload Purchase Register Data"
              key="step2"
              disabled={activeStep < 3}
            >
              {activeStep === 3 && <UploadPR />}
              {activeStep === 4 && <MappingPR />}
            </TabPane>
            <TabPane tab="Start Recon" key="step3" disabled={activeStep < 5}>
              {activeStep === 5 && <StartRecon />}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default ReconcilationPage;
