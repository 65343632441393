/* eslint-disable @typescript-eslint/camelcase */
import { IAction } from '../../models';

export enum ReconActions {
  GENERATE_OTP = 'GENERATE_OTP',
  GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS',
  TOKEN_EXISTS = 'TOKEN_EXISTS',
  VALIDATE_OTP = 'VALIDATE_OTP',
  VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS',
  REGENERATE_OTP = 'REGENERATE_OTP',
  NEXT = 'NEXT',
  PREV = 'PREV',
  UPLOADPR = 'UPLOADPR',
  UPLOADPR_SUCCESS = 'UPLOADPR_SUCCESS',
  UPLOADPR_FAILURE = 'UPLOADPR_FAILURE',
  GET_MAPPINGS = 'GET_MAPPINGS',
  GET_MAPPINGS_SUCCESS = 'GET_MAPPINGS_SUCCESS',
  GET_ITR_FILE_UPLOAD = 'GET_ITR_FILE_UPLOAD',
  GET_ITR_FILE_UPLOAD_SUCCESS = 'GET_ITR_FILE_UPLOAD_SUCCESS',
  PROCESS_ITR_FILE_UPLOAD = 'PROCESS_ITR_FILE_UPLOAD',
  PROCESS_ITR_FILE_UPLOAD_STARTED = 'PROCESS_ITR_FILE_UPLOAD_STARTED',
  ADD_MAPPINGS = 'ADD_MAPPINGS',
  ADD_MAPPINGS_SUCCESS = 'ADD_MAPPINGS_SUCCESS',
  GET_DROPDOWNS = 'GET_DROPDOWNS',
  GET_DROPDOWNS_SUCCESS = 'GET_DROPDOWNS_SUCCESS',
  GET_PAGE_STATE = 'GET_PAGE_STATE',
  GET_PAGE_STATE_SUCCESS = 'GET_PAGE_STATE_SUCCESS',
  SET_PAGE_STATE = 'SET_PAGE_STATE',
  GET_REPORT_LIST = 'GET_REPORT_LIST',
  GET_REPORT_LIST_SUCCESS = 'GET_REPORT_LIST_SUCCESS',
  GET_REPORT = 'GET_REPORT',
  GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS',
  GET_SUMMARY = 'GET_SUMMARY',
  GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS',
  START_RECON = 'START_RECON',
  TAKE_ACTION = 'TAKE_ACTION',
  TAKE_ACTION_SUCCESS = 'TAKE_ACTION_SUCCESS',
  GET_REPORT_HEADER = 'GET_REPORT_HEADER',
  GET_REPORT_HEADER_SUCCESS = 'GET_REPORT_HEADER_SUCCESS',
  UPDATE_MATCH_ENTRY = 'UPDATE_MATCH_ENTRY',
  EDIT_REPORT_SUCCESS = 'EDIT_REPORT_SUCCESS',
  GET_INVOICE_SEARCH = 'GET_INVOICE_SEARCH',
  GET_INVOICE_SEARCH_SUCCESS = 'GET_INVOICE_SEARCH_SUCCESS',
  ADD_INVOICE = 'ADD_INVOICE',
  ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS',
  CLEAR_INVOICE = ' CLEAR_INVOICE',
  GET_SUPPLIER = 'GET_SUPPLIER',
  GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS',
  GET_REPORT_DOWNLOAD = 'GET_REPORT_DOWNLOAD',
  GET_REPORT_DOWNLOAD_SUCCESS = 'GET_REPORT_DOWNLOAD_SUCCESS',
  SET_TRACKER_ID = 'SET_TRACKER_ID',
  RESET_RECON_STATE = 'RESET_RECON_STATE',
  NAV_ENABLE_PREV = 'NAV_ENABLE_PREV',
  NAV_DISABLE_PREV = 'NAV_DISABLE_PREV',
  NAV_ENABLE_NEXT = 'NAV_ENABLE_NEXT',
  NAV_DISABLE_NEXT = 'NAV_DISABLE_NEXT',
  NAV_NEXT = 'NAV_NEXT',
  NAV_PREV = 'NAV_PREV',
}

export function generateGstOtp(gstUsername: String): IAction {
  return {
    type: ReconActions.GENERATE_OTP,
    body: { gstUsername },
  };
}

export function generateOtpSuccess(otp: String): IAction {
  return {
    type: ReconActions.GENERATE_OTP_SUCCESS,
  };
}

export function validateOtpSuccess(): IAction {
  return {
    type: ReconActions.VALIDATE_OTP_SUCCESS,
  };
}

export function validateGstOtp(otp: String): IAction {
  return {
    type: ReconActions.VALIDATE_OTP,
    body: { otp },
  };
}

export function next(): IAction {
  return {
    type: ReconActions.NEXT,
  };
}

export function prev(): IAction {
  return {
    type: ReconActions.PREV,
  };
}

export function navNext(): IAction {
  return {
    type: ReconActions.NAV_NEXT,
  };
}

export function navPrevious(): IAction {
  return {
    type: ReconActions.NAV_PREV,
  };
}

export function uploadPr(body: any): IAction {
  return {
    type: ReconActions.UPLOADPR,
    body,
  };
}

export function getMappings(): IAction {
  return {
    type: ReconActions.GET_MAPPINGS,
  };
}

export function getMappingsSuccess(): IAction {
  return {
    type: ReconActions.GET_MAPPINGS_SUCCESS,
  };
}

export function getItrFileUpload(): IAction {
  return {
    type: ReconActions.GET_ITR_FILE_UPLOAD,
  };
}

export function getItrFileUploadSuccess(): IAction {
  return {
    type: ReconActions.GET_ITR_FILE_UPLOAD_SUCCESS,
  };
}

export function processItrFileUpload(): IAction {
  return {
    type: ReconActions.PROCESS_ITR_FILE_UPLOAD
  };
}

export function addMappings(data: any): IAction {
  return {
    type: ReconActions.ADD_MAPPINGS,
    body: data,
  };
}

export function getDropdowns(): IAction {
  return {
    type: ReconActions.GET_DROPDOWNS,
  };
}

export function getPageState(): IAction {
  return {
    type: ReconActions.GET_PAGE_STATE,
  };
}

export function setPageState(sub_page_id: number, tracker_id=null): IAction {
  return {
    type: ReconActions.SET_PAGE_STATE,
    body: { sub_page_id, tracker_id },
  };
}

export function getReportList(query: any): IAction {
  return {
    type: ReconActions.GET_REPORT_LIST,
    body: query,
  };
}

export function getReportById(id: any): IAction {
  return {
    type: ReconActions.GET_REPORT,
    body: id,
  };
}

export function getReporXlsx(id: any): IAction {
  return {
    type: ReconActions.GET_REPORT_DOWNLOAD,
    body: id,
  };
}

export function getSummary(id: any): IAction {
  return {
    type: ReconActions.GET_SUMMARY,
    body: id,
  };
}


export function startReconReport(data: any): IAction {
  return {
    type: ReconActions.START_RECON,
    body: data,
  };
}
export function takeAction(data: any): IAction {
  return {
    type: ReconActions.TAKE_ACTION,
    body: data,
  };
}

export function getHeaderData(data: any): IAction {
  return {
    type: ReconActions.GET_REPORT_HEADER,
    body: data,
  };
}

export function updateMatchEntry(data: any): IAction {
  return {
    type: ReconActions.UPDATE_MATCH_ENTRY,
    body: data,
  };
}

export function getInvoiceSearch(data: any): IAction {
  return {
    type: ReconActions.GET_INVOICE_SEARCH,
    body: data,
  };
}

export function clearInvoice(): IAction {
  return {
    type: ReconActions.CLEAR_INVOICE,
  };
}

export function addInvoice(data: any): IAction {
  return {
    type: ReconActions.ADD_INVOICE,
    body: data,
  };
}

export function getSupplier(id: any): IAction {
  return {
    type: ReconActions.GET_SUPPLIER,
    body: id,
  };
}

export function downloadXlsx(id: any): IAction {
  return {
    type: ReconActions.GET_REPORT_DOWNLOAD,
    body: id,
  };
}

export function resetReconState():IAction{
  return{
    type: ReconActions.RESET_RECON_STATE,
  };
}