
import React from 'react';

import { Col, Row, Typography,Modal,Spin } from 'antd';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { useHistory } from 'react-router-dom';

import JitFinLogo from '../components/logo';
import PhoneNumberForm from '../components/phoneNumberForm';
import { ROUTES } from '../constants/routePaths';
import SupportComponent from '../components/supportComponent';
import StylizedButton from '../components/stylizedButton';
import {loginImage} from '../utils/constants';
import { KYSSmobileLogo } from '../utils/constants';
import {KYSSwhiteLogo} from '../utils/constants';
import TermsAndConditionsPage from './termsAndConditionsPage';


const textColor = {
    color: '#ffffff',
  };
  
const JitfinLogoRow = () => (
    <Row style={{ height: '10vh' }} align="middle" justify="end">
      <Col style={{paddingTop:'-20px',paddingRight:'50px'}}>
        <JitFinLogo height="34.5px" width="140.5px" />
      </Col>
    </Row>
  );
const JitfinMobileLogo = ()=> (
  <Row className= 'mobile-logo' align="middle" justify="end">
  <Col className= 'image-style' style={{paddingRight:'20px'}}>
    <img className= "mobile_img" src={KYSSmobileLogo}></img>
  </Col>
</Row>
);

const RegisterCalloutRow = (props: { onRegisterClick: () => void }) => {
    const { onRegisterClick } = props;
    return (
      <Row justify="start" align="middle">
        <Col className='regcallout_reg'>
          <Typography.Text className='dont-have-acc'>
            Don't have an account?
          </Typography.Text>
          <a href='' style={{textDecorationLine:'underline',color:'#1a83ba'}} className='register-here-style' onClick={onRegisterClick}>Register here</a>
        </Col>
      </Row>
    );
};

export default function LoginPage() {
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [triggerForPrivacyHeadingScroll, setTriggerForPrivacyHeadingScroll] = React.useState(false);
    const closeModal = () => {
      setIsModalOpen(false)
      setTriggerForPrivacyHeadingScroll(false)

    };
    const captureClickForInModalView =
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, trigger_scroll: boolean) => {
      e.preventDefault();
      setIsModalOpen(true)
      if(trigger_scroll){
        setTriggerForPrivacyHeadingScroll(true)
      }
    };
    return(
        <div className='login_row'>
            <div className='login_column_1' id='login_img'>
                <JitfinMobileLogo />
                  <div id='text'>Welcome Back to KYSS
                      <div className='loginimg_text'>
                          <p style={{fontSize:'20px'}}>All you need to know about your Suppliers. All in one place.</p>
                      </div>
                </div>
            </div>
            <div className='login_column_2' style={{height:'100vh'}}>
                <div className='Jitfinlogo'><JitfinLogoRow /></div>
                <div className='Login_box'>
                    <Row align="middle" className='Login-box-row'>
                        <Col className='login-box-content' style={{ minHeight: '324px', maxWidth: '501px'}} xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
                        <div>
                            <h2 className='welcome-title'>Welcome Back!</h2>
                            <p className='tagline-kyss'>
                              Please enter your registered mobile number to login your account.
                            </p>
                        </div>
                        <PhoneNumberForm />
                        </Col>
                    </Row>
                    <div className='register_row'>
                        <Col xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
                            <RegisterCalloutRow onRegisterClick={() => history.push(ROUTES.SIGN_UP)} />
                        </Col>
                    </div>
                </div>
                <div className='product_info'>
                <Row className='' align="middle" >
                        <Col className='need_HELP_text--hide' xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
                            <SupportComponent isLogin />
                        </Col>
                        <Col className='need_HELP_text2 need_HELP_text2--mb' xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:3 }}>
                        <a 
                            onClick={(e) => captureClickForInModalView(e, false)}
                            href={ROUTES.TERMS_AND_CONDITIONS}
                          >
                            Terms & Conditions
                          </a>
                          <span className='text'> & </span>
                          <a 
                              onClick={(e) => captureClickForInModalView(e, true)}
                              href={ROUTES.TERMS_AND_CONDITIONS}
                          >
                            Privacy Policy
                          </a>
                        </Col>
                    </Row>
                    <Row className='need_help_link' align="middle" >
                        <Col className='need_HELP_text' xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
                            <SupportComponent isLogin />
                        </Col>
                        <Col className='need_HELP_text2' xs={{ span:22, offset:1 }} sm={{ span:22, offset:1 }} md={{ span:20, offset:2 }}>
                            A Product of JiT Finco
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
              className="terms_privacy_modal"
              title=""
              centered
              visible={isModalOpen}
              onOk={() => closeModal()}
              onCancel={() => closeModal()}
              footer={[
                <StylizedButton
                  key='ok'
                  textInButton="Ok"
                  size="large"
                  customStyles={{margin:"10px 0"}}
                  onClickFn={() => closeModal()}
                />]}
          
            >
            <section>
            <TermsAndConditionsPage scrollToPrivacyPolicySection={triggerForPrivacyHeadingScroll}/>
            </section>
          </Modal>
        </div>

    );
}
