import { IAction, IReportsState } from '../../models';
import { ReportsActions } from '../actions/reportsActions';
import {
  IResponseReportFiles,
  IResponseReportHistory,
} from '../../models/api/reportsModels';

const defaultState = {
  reportHistory: [],
  currentReportDownloadMetadata: null,
} as IReportsState;

export default function reportsReducer(
  reportsState = defaultState,
  action: IAction
): IReportsState {
  switch (action.type) {
    case ReportsActions.REQUEST_REPORT_HISTORY_SUCCESS: {
      const payload: IResponseReportHistory = action.body;
      const { reportHistory } = payload;
      return {
        ...reportsState,
        reportHistory,
      };
    }
    case ReportsActions.REQUEST_REPORT_FILES_SUCCESS: {
      const payload: IResponseReportFiles = action.body;
      return {
        ...reportsState,
        currentReportDownloadMetadata: payload,
      };
    }
    case ReportsActions.CLEAR_REPORT_FILE_METADATA: {
      return {
        ...reportsState,
        currentReportDownloadMetadata: null,
      };
    }
    default: {
      return reportsState;
    }
  }
}
