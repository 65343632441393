/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Select } from 'antd';
import { Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { downArr, upArrow, downArrow } from '../utils/constants';
// import ReportModal from './ReportModal';
import ReportModal2 from './ReportModal2';
import ReportModal3 from './ReportModal3';
import {
  getDropdowns,
  getReportById,
  takeAction,
  getHeaderData,
  getSupplier,
} from '../store/actions/ReconcilationActions';
import { downloadHandler } from '../utils/methods';
import config from '../config';

interface IReconcileReport {
  setActiveStep?: any;
}

interface ITableComponent {
  data?: any[];
}

interface IRowComponent {
  data?: any;
}

interface ICellComponent {
  data?: any;
  className?: string;
  is_claim?: any;
  is_supplier_data_empty?: any;
  is_my_data_empty?: any;
  otherClass?: any;
  myClass?: any;
}

enum EPaginationType {
  NEXT = 'next',
  PREV = 'prev'
}

enum ECellDatatype {
  STRING = 'string',
  CURRENCY = 'currency',
  DATE = 'date',
  NUMBER = 'number'
}

const CellComponent: React.FunctionComponent<ICellComponent> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dateFormat = 'DD-MM-YYYY';
  let { my, other } = props.data;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type } = props.data;
  const {
    is_claim,
    is_supplier_data_empty,
    is_my_data_empty,
    otherClass,
    myClass,
  } = props;
  // if(type === ECellDatatype.DATE){
  //   my = moment(my, dateFormat).format(dateFormat);
  //   other = moment(other, dateFormat).format(dateFormat);
  // }
  return (
    <div className={props.className}>
      <div className="h--18 flex-y pa--5 bb">
        <label
          className={
            myClass || (is_my_data_empty
              ? 'red-text mb--10'
              : my && other && my !== other
                ? 'red-text mb--10'
                : 'text-black mb--10')
          }
        >
          My Data
        </label>
        <label
          className={
            myClass || (is_my_data_empty
              ? 'red-text mb--10'
              : my && other && my !== other
                ? 'red-text mb--10'
                : 'text-black mb--10')
          }
        >
          {my === '' || (my !== 0.0 && !my) ? (
            <span className="fs--18">-</span>
          ) : is_claim ? (
            <del>{my}</del>
          ) : (
            my
          )}
        </label>
      </div>
      <div className="h--18 flex-y  pa--5">
        <label
          className={
            otherClass || (is_supplier_data_empty
              ? 'red-text mb--10'
              : my && other && my !== other
                ? 'red-text mb--10'
                : 'text-black mb--10')
          }
        >
          Supplier Data
        </label>
        <label
          className={
            otherClass || (is_supplier_data_empty
              ? 'red-text mb--10'
              : my && other && my !== other
                ? 'red-text mb--10'
                : 'text-black mb--10')
          }
        >
          {other === '' || (!other && other !== 0.0) ? (
            <span className="fs--18">-</span>
          ) : is_claim ? (
            <del>{other}</del>
          ) : (
            other
          )}
        </label>
      </div>
    </div>
  );
};

const RowComponent: React.FunctionComponent<IRowComponent> = (props) => {
  const [isExpand, setIsExpand] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const params: any = useParams();
  const dispatch = useDispatch();

  const takeActionOnclick = (data: any) => {
    // dispatch(getSupplier(params.id));
    dispatch(
      takeAction({ ...data, id: params.id, match_entry_id: props.data.id })
    );
  };
  const { data } = props;

  const updateHeader = () => {
    dispatch(getHeaderData({ id: params.id }));
  };

  // columns added to array in the order they should appear on the table
  const myColumns = ['gstin_itr', 'supplier_name_itr', 'invoice_number_itr', 'invoice_date_itr', 'taxable_value_itr', 'integrated_tax_itr', 'central_tax_itr', 'state_tax_itr', 'invoice_value_itr'];
  const supplierColumns = ['gstin_2a', 'supplier_name_2a', 'invoice_number_2a', 'invoice_date_2a', 'taxable_value_2a', 'integrated_tax_2a', 'central_tax_2a', 'state_tax_2a', 'invoice_value_2a'];
  // const columnTypes = [ECellDatatype.STRING, ECellDatatype.STRING, ECellDatatype.STRING, ECellDatatype.DATE, ECellDatatype.NUMBER, ECellDatatype.NUMBER, ECellDatatype.NUMBER, ECellDatatype.NUMBER, ECellDatatype.NUMBER];

  const is_my_data_empty = myColumns.reduce((prev, colKey) => data[colKey] === null && prev, true);
  const is_supplier_data_empty = supplierColumns.reduce((prev, colKey) => data[colKey] === null && prev, true);

  const getConfidenceScore = (score:number): string => {
    if(score === 100){
      return 'Complete Match';
    }
    if(score === 0){
      return 'Did not match';
    }
    return `${data.confidence_score}%`;
  };
  return (
    <>
      <tr className="trow">
        <td>
          <div className="h--36 bt bb bl brl">
            {' '}
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{ type: ECellDatatype.STRING, other: data.gstin_2a, my: data.gstin_itr }}
              otherClass={
                data.updated_fields.includes('gstin_2a') ? 'orange-text' : ''
              }
              myClass={
                data.updated_fields.includes('gstin_itr') ? 'orange-text' : ''
              }
            />
            {' '}
          </div>
        </td>
        <td>
          <div className="h--36 bt bb ">
            {' '}
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{
                type: ECellDatatype.STRING,
                other: data.supplier_name_2a,
                my: data.supplier_name_itr,
              }}
              otherClass={
                data.updated_fields.includes('supplier_name_2a')
                  ? 'orange-text'
                  : ''
              }
              myClass={
                data.updated_fields.includes('supplier_name_itr')
                  ? 'orange-text'
                  : ''
              }
            />
          </div>
        </td>
        <td>
          <div className="h--36 bt bb ">
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{
                type: ECellDatatype.STRING,
                other: data.invoice_number_2a,
                my: data.invoice_number_itr,
              }}
              otherClass={
                data.updated_fields.includes('invoice_number_2a')
                  ? 'orange-text'
                  : ''
              }
              myClass={
                data.updated_fields.includes('invoice_number_itr')
                  ? 'orange-text'
                  : ''
              }
            />
          </div>
        </td>
        <td>
          <div className="h--36 bt bb ">
            {' '}
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{ type: ECellDatatype.DATE, other: data.invoice_date_2a, my: data.invoice_date_itr }}
              otherClass={
                data.updated_fields.includes('invoice_date_2a')
                  ? 'orange-text'
                  : ''
              }
              myClass={
                data.updated_fields.includes('invoice_date_itr')
                  ? 'orange-text'
                  : ''
              }
            />
          </div>
        </td>
        <td>
          <div className="h--36 bt bb ">
            {' '}
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{ type: ECellDatatype.NUMBER, other: data.tax_rate_2a, my: data.tax_rate_itr }}
              otherClass={
                data.updated_fields.includes('tax_rate_2a') ? 'orange-text' : ''
              }
              myClass={
                data.updated_fields.includes('tax_rate_itr')
                  ? 'orange-text'
                  : ''
              }
            />
          </div>
        </td>
        <td>
          <div className="h--36 bt bb ">
            {' '}
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{
                type: ECellDatatype.NUMBER,
                other: data.taxable_value_2a,
                my: data.taxable_value_itr,
              }}
              otherClass={
                data.updated_fields.includes('taxable_value_2a')
                  ? 'orange-text'
                  : ''
              }
              myClass={
                data.updated_fields.includes('taxable_value_itr')
                  ? 'orange-text'
                  : ''
              }
            />
          </div>
        </td>
        <td>
          <div className="h--36 bt bb ">
            {' '}
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{
                type: ECellDatatype.NUMBER,
                other: data.integrated_tax_2a,
                my: data.integrated_tax_itr,
              }}
              otherClass={
                data.updated_fields.includes('integrated_tax_2a')
                  ? 'orange-text'
                  : ''
              }
              myClass={
                data.updated_fields.includes('integrated_tax_itr')
                  ? 'orange-text'
                  : ''
              }
            />
          </div>
        </td>
        <td>
          <div className="h--36 bt bb ">
            {' '}
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{ type: ECellDatatype.NUMBER, other: data.central_tax_2a, my: data.central_tax_itr }}
              otherClass={
                data.updated_fields.includes('central_tax_2a')
                  ? 'orange-text'
                  : ''
              }
              myClass={
                data.updated_fields.includes('central_tax_itr')
                  ? 'orange-text'
                  : ''
              }
            />
          </div>
        </td>
        <td>
          <div className="h--36 bt bb ">
            {' '}
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{ type: ECellDatatype.NUMBER, other: data.state_tax_2a, my: data.state_tax_itr }}
              otherClass={
                data.updated_fields.includes('state_tax_2a')
                  ? 'orange-text'
                  : ''
              }
              myClass={
                data.updated_fields.includes('state_tax_itr')
                  ? 'orange-text'
                  : ''
              }
            />
            {' '}
          </div>
        </td>
        <td>
          <div className="h--36 bt bb ">
            {' '}
            <CellComponent
              is_my_data_empty={is_my_data_empty}
              is_supplier_data_empty={is_supplier_data_empty}
              is_claim={data.flags.is_claim_ignored}
              data={{
                type: ECellDatatype.NUMBER,
                other: data.invoice_value_2a,
                my: data.invoice_value_itr,
              }}
              otherClass={
                data.updated_fields.includes('invoice_value_2a')
                  ? 'orange-text'
                  : ''
              }
              myClass={
                data.updated_fields.includes('invoice_value_itr')
                  ? 'orange-text'
                  : ''
              }
            />
          </div>
        </td>
        <td>
          <div
            className={
              !isExpand
                ? 'flex-y center pa--5 h--36 bt bb'
                : data.my && data.other
                  ? 'flex-y center pa--5 h--36 bt'
                  : 'flex-y center pa--5 h--36 bt bb'
            }
          >
            <label className="text-black center match-entry__confidence">
              {data.key === 3 ? (
                <div className="green-text">Manually Matched</div>
              ) : (
                getConfidenceScore(data.confidence_score)
              )}
            </label>
          </div>
        </td>
        <td>
          <div
            className={
              !isExpand
                ? 'flex-y center pa--5 h--36 bt bb  br brr'
                : data.my && data.other
                  ? 'flex-y center pa--5 h--36 bt br brr'
                  : 'flex-y center pa--5 h--36 bt br bb brr'
            }
            onClick={() => {
              setIsExpand(!isExpand);
            }}
          >
            <label className="text-black center">
              {isExpand ? (
                <img src={upArrow} alt="upArrow " className="cursor-pointer" />
              ) : (
                <img
                  src={downArr}
                  alt="down Arrow "
                  className="cursor-pointer"
                />
              )}
            </label>
          </div>
        </td>
      </tr>
      {!isExpand && (
        <tr className="trow">
          <td colSpan={12} className="ptb--25 bb bl br ">
            <div className="flex-x pull-right">
              {data.actions && data.actions.includes('add_invoice_data') && (
                <Button
                  className=" cursor-pointer red-btn  mr--40 button--add"
                  onClick={() => {
                    setIsModalVisible2(true);
                  }}
                >
                  Add Invoice data
                </Button>
              )}
              {data.actions && data.actions.includes('claim_ignored') && (
                <Button
                  className={`${' cursor-pointer red-btn  mr--65 button--ignore'}`}
                  onClick={() => {
                    takeActionOnclick({ action: 'claim_ignored' });
                  }}
                >
                  Ignore
                </Button>
              )}
              {data.actions && data.actions.includes('undo_ignore_claim') && (
                <Button
                  className=" cursor-pointer red-btn  mr--65 button--ignore-undo"
                  onClick={() => {
                    takeActionOnclick({ action: 'undo_ignore_claim' });
                  }}
                >
                  Undo Ignore
                </Button>
              )}
              {data.actions && data.actions.includes('not_match button--negation') && (
                <Button
                  className=" cursor-pointer red-btn  mr--40"
                  onClick={() => {
                    takeActionOnclick({
                      action: 'not_match',
                    });
                  }}
                >
                  Not a Match
                </Button>
              )}
              {data.actions && data.actions.includes('keep_supplier_data') && (
                <Button
                  className={`${' cursor-pointer red-btn  mr--40 button--affirmative'} `}
                  onClick={() => {
                    takeActionOnclick({
                      keep_supplier_data: true,
                      action: 'keep_supplier_data',
                    });
                  }}
                >
                  Supplier is correct
                </Button>
              )}
              {data.actions && data.actions.includes('review_kept_pending') && (
                <Button
                  disabled={data.flags && data.flags.is_review_kept_pending}
                  className={`${data.flags && !data.flags.is_review_kept_pending
                    ? ' cursor-pointer red-btn  mr--40 button--pending'
                    : 'green-btn  mr--40'
                  } `}
                  onClick={() => {
                    takeActionOnclick({
                      review_kept_pending: true,
                      action: 'review_kept_pending',
                    });
                  }}
                >
                  {data.flags && !data.flags.is_review_kept_pending
                    ? 'Pending for review'
                    : 'Marked pending for review'}
                </Button>
              )}
              {data.actions && data.actions.includes('keep_my_data') && (
                <Button
                  className={`${' cursor-pointer red-btn  mr--40 button--maintain'} `}
                  onClick={() => {
                    takeActionOnclick({
                      keep_my_data: true,
                      action: 'keep_my_data',
                    });
                  }}
                >
                  Keep my values
                </Button>
              )}
              {data.actions && data.actions.includes('data_modified') && (
                <Button
                  className="cursor-pointer red-btn mr--40 button--edit"
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                >
                  Edit
                </Button>
              )}
            </div>
          </td>
        </tr>
      )}
      {/* <ReportModal isModalVisible={isModalVisible} 
      setIsModalVisible={setIsModalVisible}  data={data}/> */}
      <ReportModal2
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        data={data}
        callback={() => updateHeader()}
      />
      <ReportModal3
        isModalVisible={isModalVisible2}
        setIsModalVisible={setIsModalVisible2}
        matchData={data}
        callback={() => updateHeader()}
      />
    </>
  );
};

const TableComponent: React.FunctionComponent<ITableComponent> = (props) => {
  return (
    <Table className="table">
      <thead className="thead">
        <tr>
          <th>GSTIN</th>
          <th>Supllier Name</th>
          <th>Invoice No</th>
          <th>Invoice Date</th>
          <th>Tax Rate</th>
          <th>Taxable Value</th>
          <th>IGST</th>
          <th>CGST</th>
          <th>SGST/UGST</th>
          <th>Total Invoice Val.</th>
          <th>Confidence Score</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody className="tbody">
        {props.data &&
          props.data.map((item: any) => (
            <>
              <div className="mtb--10" />
              <RowComponent data={item} key={item.id} />
            </>
          ))}
      </tbody>
    </Table>
  );
};

const ReconcileReport: React.FunctionComponent<IReconcileReport> = () => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const dropdowns = useSelector((state: any) => state.reconReducer.dropdowns);
  const report = useSelector((state: any) => state.reconReducer.report);
  const supplier = useSelector((state: any) => state.reconReducer.supplier);
  const authState = useSelector((state: any) => state.authReducer);
  const { token } = authState;

  const reportHeader = useSelector(
    (state: any) => state.reconReducer.reportHeader
  );
  const [query, setQuery] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const getPageNumberFromLink = (link: string) => {
    const tmp = link.split('?page=');
    return tmp.length > 1 ? parseInt(tmp[1], 10) : 0;
  };

  const setCurrentPage = (currentPage: number, link: string = '') => {
    if (currentPage === 0) {
      const numberFromLink = getPageNumberFromLink(link);
      // eslint-disable-next-line no-param-reassign
      currentPage = numberFromLink + 1;
    }
    setPageNumber(currentPage);
  };

  const gotoPage = (page: number) => {
    setPageNumber(page);
    dispatch(getReportById({ id: params.id, ...query, page }));
    return false;
  };

  if (pageNumber === 0 && report?.links) {
    if (!report.links.previous) {
      setCurrentPage(1);
    } else {
      setCurrentPage(0, report.links.previous);
    }
  }

  useEffect(() => {
    dispatch(getHeaderData({ id: params.id }));
    dispatch(getReportById({ id: params.id, ...query }));
    setPageNumber(1);
  }, [query, dispatch, params.id]);

  useEffect(() => {
    if(!dropdowns?.match_statuses){
      dispatch(getDropdowns());
    }
  }, [dispatch, dropdowns]);

  useEffect(() => {
    dispatch(getSupplier({ id: params.id }));
  }, [dispatch, params.id]);

  /**
   * 
   *@TODO hardcoded page size now, needs to be changed/
   */
  const pageSize = 100;
  return (
    <div className="flex-y reconcile-report reconcile__report">
      <div className="flex-x fill-width space-between mtb--35">
        <div className="flex-x">
          <div className="flex-y blue-card pa--15 mr--20">
            <span className="fs--14">
              Total Input Tax credit according to IT Register
            </span>
            <span className="fs--18 mt--10 light-bold">
              {reportHeader && reportHeader.itr_tax_amount}
            </span>
          </div>
          <div className="flex-y blue-card pa--15  mlr--20">
            <span className="fs--14">
              Total Input Tax credit according to GSTR2A
            </span>
            <span className="fs--18 mt--10 light-bold">
              {reportHeader && reportHeader.gstr2a_tax_amount}
            </span>
          </div>
        </div>
        <div className="flex-x center">
          <Select
            style={{background:'#462E90',borderRadius:'5px'}}
            className="red-select"
            placeholder="Download"
            suffixIcon={<img src={downArrow} alt="downArr" />}
            size="large"
            allowClear
            onChange={(e) => {
              // setDownloadQuery({ ...query, download_as: e });
              if (e === 'xlsx') {
                const url = `${config.apiURL}/recon/reports/${params.id}?download_as=xlsx`;
                downloadHandler(url, token, 'report', true);
              }
            }}
          >
            <Select.Option value="xlsx">xlsx</Select.Option>
          </Select>
        </div>
      </div>
      <div className="fit-content">
        <div
          className="flex-x center border-radius--5"
          style={{ border: '1px solid #D4D4D4' }}
        >
          <div
            className="ptb--12 plr--20 fs--16 bold"
            style={{ borderRight: '1px solid #D4D4D4' }}
          >
            Filter
          </div>
          <div
            className="flex row-wrap ptb--5 plr--5"
            style={{ borderRight: '1px solid #D4D4D4' }}
          >
            <span className="text-gray light-bold d-center">
              Filter based on Match type:
            </span>
            <Select
              style={{ width: '200px' }}
              placeholder="Select"
              suffixIcon={<img src={downArr} alt="down arrow" />}
              value={query && query.match_status}
              allowClear
              onChange={(e) => {
                setQuery({ ...query, match_status: e });
              }}
              size="large"
            >
              <Select.Option
                value={dropdowns && dropdowns.match_statuses.Match}
              >
                Match
              </Select.Option>
              <Select.Option
                value={dropdowns && dropdowns.match_statuses['Probable Match']}
              >
                Probable Match
              </Select.Option>
              <Select.Option
                value={dropdowns && dropdowns.match_statuses['Do Not Match']}
              >
                Do Not Match
              </Select.Option>
            </Select>
          </div>
          <div
            className="flex row-wrap ptb--5 plr--5"
            style={{ borderRight: '1px solid #D4D4D4' }}
          >
            <span className="text-gray light-bold d-center">
              Filter based on user action:
            </span>
            <Select
              style={{ width: '200px' }}
              placeholder="Select"
              suffixIcon={<img src={downArr} alt="down arrow" />}
              value={query && query.action}
              allowClear
              onChange={(e) => {
                setQuery({ ...query, action: e });
              }}
              size="large"
            >
              <Select.Option
                value={dropdowns && dropdowns.user_actions['Add invoice data']}
              >
                Add invoice data
              </Select.Option>
              <Select.Option
                value={dropdowns && dropdowns.user_actions['Data modified']}
              >
                Data modified
              </Select.Option>
              <Select.Option
                value={dropdowns && dropdowns.user_actions['Calim ignored']}
              >
                Ignored
              </Select.Option>
              <Select.Option
                value={dropdowns && dropdowns.user_actions['Keep my data']}
              >
                Keep My Data
              </Select.Option>
              <Select.Option
                value={
                  dropdowns && dropdowns.user_actions['Keep supplier data']
                }
              >
                Keep Supplier Data
              </Select.Option>
              {/* <Select.Option
                value={dropdowns && dropdowns.user_actions.Monitor}
              >
                Monitor
              </Select.Option> */}
              <Select.Option
                value={dropdowns && dropdowns.user_actions['Not a Match']}
              >
                Not a Match
              </Select.Option>
              <Select.Option
                value={
                  dropdowns && dropdowns.user_actions['Review kept pending']
                }
              >
                Review kept pending
              </Select.Option>
              <Select.Option
                value={dropdowns && dropdowns.user_actions['undo ignore claim']}
              >
                Undo Ignore Claim
              </Select.Option>
            </Select>
          </div>
          <div
            className="flex row-wrap ptb--5 plr--5"
            style={{ borderRight: '1px solid #D4D4D4' }}
          >
            <span className="text-gray light-bold d-center">
              Filter based on supplier:
              {' '}
            </span>
            {' '}
            <Select
              style={{ width: '200px' }}
              placeholder="Select"
              allowClear
              suffixIcon={<img src={downArr} alt="down arrow" />}
              onChange={(e) => {
                setQuery({ ...query, supplier_name: e });
              }}
              size="large"
            >
              {supplier &&
                supplier.data.suppliers &&
                supplier.data.suppliers.map((supplierValue: any, i: any) => (
                  <Select.Option value={supplierValue} key={i}>
                    {supplierValue}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>
        <Input
          className="search-input"
          onChange={(e) => {
            setQuery({ ...query, search_q: e.target.value });
          }}
          placeholder="Search"
        />
      </div>
      <div className="mt--30 overflow-x">
        <TableComponent data={report?.results} />
      </div>
      {report?.links && 
      (
      <div className='table__pagination flex-x flex-end fill-wdith mt--15'>
        <Pagination aria-label="Change page">
          <PaginationItem 
            disabled={report.links.previous == null}
          >
            <PaginationLink previous onClick={() => report.links.previous !== null && gotoPage(pageNumber - 1)} />
          </PaginationItem>
          {pageNumber - 1 > 0 && (
          <PaginationItem>
            <PaginationLink onClick={() => gotoPage(pageNumber - 1)}>
              {pageNumber - 1}
            </PaginationLink>
          </PaginationItem>
          )}
          <PaginationItem active>
            <PaginationLink>
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
          {
            pageSize * pageNumber < report?.count && (
              <PaginationItem>
                <PaginationLink onClick={() => gotoPage(pageNumber + 1)}>
                  {pageNumber + 1}
                </PaginationLink>
              </PaginationItem>
            )
          }
          <PaginationItem disabled={report.links.next == null}>
            <PaginationLink next onClick={() => report.links.next !== null && gotoPage(pageNumber + 1)} />
          </PaginationItem>
        </Pagination>
      </div>
)}
    </div>
  );
};

export default ReconcileReport;
