/* eslint-disable no-nested-ternary */
/* eslint no-nested-ternary: "error" */

import { IAction,  ISupplierState } from '../../models';
import {UploadStatus} from '../../typings';
import { SupplierReportActions } from '../actions/supplierReportActions';

const defaultState = {
  uploading: false,
  resetSupplier: false,
  uploadStatus: UploadStatus.NONE,
  pageStep: 0,
  file_upload_id: 0,

} as ISupplierState;

export default function supplierReducer(
  supplierState: any = defaultState,
  action: IAction
): any {
  switch (action.type) {
    case SupplierReportActions.SUPPLIRE_UPLOAD_RESET: {
      return { defaultState };
    }
    case SupplierReportActions.SUPPLIRE_UPLOAD: {
      return { ...supplierState, uploading: true, uploadStatus: UploadStatus.IN_PROGRESS };
    }
    case SupplierReportActions.SUPPLIRE_SUCCESS: {
      return { ...supplierState, uploading: false, uploadStatus: UploadStatus.SUCCESS, resetSupplier: false, file_upload_id: action.body.data.data.data.id };
    }
    case SupplierReportActions.SUPPLIRE_FAILURE: {
      return { ...supplierState, uploading: false, uploadStatus: UploadStatus.FAIL };
    }
    case SupplierReportActions.SUPPLIRE_NEXT_FILING_DATE: {
      return { ...supplierState, pageStep: 1 };
    }
    case SupplierReportActions.SUPPLIRE_NEXT_FILING_DATE_AFTER_UPLOAD: {
      return { ...supplierState, pageStep: 1, file_upload_id:action.body.file_upload_id };
    }
    case SupplierReportActions.SUPPLIRE_GST_GENERATE: {
      return { ...supplierState, pageStep: 3 };
    }

    case SupplierReportActions.SUPPLIRE_GST_GENERATE_FAIL: {
      return { ...supplierState };
    }
    default: {
      return supplierState;
    }
  }
}
