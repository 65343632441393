/* eslint-disable no-console */
import { put, takeEvery } from 'redux-saga/effects';
import { IAction } from '../../models';
import { globalAnalyticsService } from '../../services/analyticsService';
import { IZohoContactDetails } from '../../typings';
import { AnalyticsActions, ZohoAnalyticsSignupGstFailure, ZohoAnalyticsSignupGstSuccess } from '../actions/analytics';
import { exceptionLogger, spinForWorker } from './utils';

function* dispatchSignupGst(action: IAction): any {
  const payload: IZohoContactDetails = action.body;
  try {
    yield globalAnalyticsService.setAnalyticsContactZoho(payload);
    yield put(ZohoAnalyticsSignupGstSuccess({ payload }));
  } catch (error) {
    console.error('error dispatching zoho analytics');
    console.error(error);
    yield put(ZohoAnalyticsSignupGstFailure({ payload, error }));
  }
}

export default function* AnalyticsWatcher(): IterableIterator<any> {
  yield takeEvery(AnalyticsActions.ZOHO_CRM_CONTACT_BEGIN, (action) =>
    exceptionLogger(spinForWorker(dispatchSignupGst(action)))
  );
}
