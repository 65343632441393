import React, {useEffect, useRef, useState} from 'react';
import {
  Card,
  Typography,
  Spin,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  DatePicker,
  Modal,
  Upload,
  Dropdown,
  Menu,
  Select,
} from 'antd';
import { THEME } from '../constants/theme';
import {
  PlusOutlined,
  FilterOutlined,
  ApartmentOutlined,
  EditFilled,
  DeleteFilled,
  CheckCircleTwoTone,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useHistory,useLocation } from 'react-router';
import { ROUTES } from '../constants/routePaths';
import { FiEdit3 } from 'react-icons/fi';
import { IState } from '../models';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { globalApiService } from '../services/apiService';
import {useReactToPrint} from "react-to-print";
import OpenNotification from "../utils/notification";

const InvoicePreview = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  
  function convertNumberToWords(amount: any) {
    let words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';

    amount = amount.toString();
    let atemp: any = amount.split('.');
    let number: any = atemp[0].split(',').join('');
    let nLength: any = number.length;
    let wordsString = '';
    if (nLength <= 9) {
      let nArray: any = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      let receivedNArray = new Array();
      for (let i = 0; i < nLength; i++) {
        receivedNArray[i] = number.substr(i, 1);
      }
      for (let i = 9 - nLength, j = 0; i < 9; i++, j++) {
        nArray[i] = receivedNArray[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (nArray[i] == 1) {
            nArray[j] = 10 + parseInt(nArray[j]);
            nArray[i] = 0;
          }
        }
      }
      let value: any = '';
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = nArray[i] * 10;
        } else {
          value = nArray[i];
        }
        if (value != 0) {
          wordsString += words[value] + ' ';
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && nArray[i + 1] == 0)
        ) {
          wordsString += 'Crores ';
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && nArray[i + 1] == 0)
        ) {
          wordsString += 'Lakhs ';
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && nArray[i + 1] == 0)
        ) {
          wordsString += 'Thousand ';
        }
        if (
          i == 6 &&
          value != 0 &&
          nArray[i + 1] != 0 &&
          nArray[i + 2] != 0
        ) {
          wordsString += 'Hundred ';
        } else if (i == 6 && value != 0) {
          wordsString += 'Hundred ';
        }
      }
      wordsString = wordsString.split('  ').join(' ');
    }
    return wordsString;
  };
  //Initilization
  const history = useHistory();
  const location:any = useLocation();
  const [form] = Form.useForm();
  //Destructuring
  const { Title, Paragraph, Text } = Typography;
  const [loading, setLoading] = useState<boolean>(false);
  const [formData,setFormData] = useState<any>({})
  const [isGenerateIrnDisabled,setIsGenerateIrnDisabled] = useState(false)
  const [qrCode, setQrCode] = React.useState<any>('')
  const [isModalVisible, setModalVisible] = useState(false);
  const showErrorModal = () =>{
    setModalVisible(false);
  }
  const handleUploadOk = () => {
    updateInvoice(formData.eInvoice_id,{'status':'INVOICE_GENERATED', 'share_details':shareDetails, 'form_data':{...values, ...profileValues, ...bankDetails, 'item_data':tableData, 'total_amount':totalAmount, 'theme_color': values.invoice_type === 'B2B' ? themeState.b2b_theme : themeState.b2c_theme}})
    setModalVisible(false);
  };

  // const handleUploadCancel = () => {
  //   setModalVisible(false);
  // };

  const generateQrCode = (docNumber: string) => {
    globalApiService
      .generateQrCode(docNumber)
      .then((res) => {
        if (res.data.success) {
          setQrCode(res.data.data.qr_code);
        }
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
      });
  };
  
  //getSingleEinvoice
  React.useEffect(() => {
    if(location !== undefined && location.state !== undefined && location.state.formData){
      const {formData} = location?.state
      setFormData(formData)
      let tempAmt = 0
      setTableData(formData.items)
      setvalues(formData.formValues)
      if(formData.formValues.status == 'IRN_GENERATED'){
        generateQrCode(formData.formValues.invoice_number);
      }
      formData.items.map((ele:any) => {
        tempAmt += parseFloat(ele.totalAmt)
      })
      setBankDetails({...formData.bank_account})
      setTotalAmount(tempAmt)
    }
    if(location !== undefined && location.state !== undefined && location.state.step){
      const {step} = location?.state
      setInvoiceGenerateStep(step)
    }
    if(location !== undefined && location.state !== undefined && location.state.e_invoice_id){
      const {e_invoice_id,step} = location?.state
      globalApiService
      .getSingleEinvoice(e_invoice_id)
      .then((res) => {
        if(res.data.success){
          let tempTotalPayableAmount = 0
          let totalAmount = 0
          let highest_tax_bracket = 0
          let igstamt = 0
          let amtBeforeTax = 0
          let amtAfterTax = 0
          let taxableamt = 0
          let cgst = 0
          let sgst = 0
          let temp_total_tax_amt  = 0
          let taxamt = 0 
          let dummytotal = 0 
          let dummytaxamt =0 
          let dummyIndividualTaxAmt = 0

          let tempItems:any[] = []
          res.data.data.items.map((ele:any,idx:any) => {
            let tempTaxAmt = ele.quantity * ((ele.tax_percent / 100) * ele.price_per_unit)
            let total = 0
            
            // if (!ele.is_tax_included) {
            //   total = total + tempTaxAmt;
            // } 
            // if(ele.discount_percent > 0){
            //   total = total - (total * ele.discount_percent/100) ;
            // }
            if(ele.is_tax_included){
            taxableamt += ((ele.quantity*ele.price_per_unit)*(1-(ele.discount_percent/100)))/(1+(ele.tax_percent/100))
            dummytotal =  ((ele.quantity * ele.price_per_unit) - ((ele.quantity * ele.price_per_unit) *(ele.discount_percent/100)))/(1+ele.tax_percent/100)
            dummytaxamt += (ele.quantity * ele.price_per_unit)-((ele.quantity * ele.price_per_unit) *(ele.discount_percent/100)) - dummytotal
            dummyIndividualTaxAmt = (ele.quantity * ele.price_per_unit)-((ele.quantity * ele.price_per_unit) *(ele.discount_percent/100)) - dummytotal
            total += dummytotal
          }
            else{
            taxableamt += ((ele.quantity*ele.price_per_unit)*(1-(ele.discount_percent/100)))
            dummytotal =  ((ele.quantity * ele.price_per_unit) - ((ele.quantity * ele.price_per_unit) *(ele.discount_percent/100)))
            dummytaxamt += (dummytotal*ele.tax_percent/100)
            dummyIndividualTaxAmt = (dummytotal*ele.tax_percent/100)
            total += dummytotal


            }
            taxamt += taxableamt*(ele.tax_percent/100)
            if (ele.tax_percent > highest_tax_bracket) {
              highest_tax_bracket = ele.tax_percent;
            }
            temp_total_tax_amt = parseFloat(
              (
                dummytaxamt +
                (parseFloat(res.data.data.e_invoice.shipping_charges) * highest_tax_bracket) / 100
              ).toFixed(2)
            );
            let gst = res.data.data.e_invoice.customer.gstin
            if(gst.slice(0, 2) == authState.gstin.slice(0, 2)){
              cgst=res.data.data.e_invoice.round_off ? (Math.round(temp_total_tax_amt / 2) || 0) : (temp_total_tax_amt / 2 || 0)
              sgst= res.data.data.e_invoice.round_off ? (Math.round(temp_total_tax_amt / 2) || 0) : (temp_total_tax_amt / 2 || 0)
            }else{
              igstamt= res.data.data.e_invoice.round_off ? (Math.round(temp_total_tax_amt) || 0) : (temp_total_tax_amt || 0)
            }
           
           amtBeforeTax = taxableamt+res.data.data.e_invoice.shipping_charges

           amtAfterTax = (amtBeforeTax+cgst+sgst+igstamt+(amtBeforeTax*res.data.data.e_invoice.cess/100)-res.data.data.e_invoice.tds + res.data.data.e_invoice.reimbursements)
            totalAmount += total
            tempTotalPayableAmount += total
                tempItems.push({
                  'cess': ele.item.cess,
                  'cgst': ele.item.cgst,
                  'description':ele.item.description,
                  'discount_percent': ele.discount_percent,
                  'hsn_code': ele.item.hsn_code,
                  'id':ele.item.id,
                  'igst':ele.item.igst,
                  'index': idx,
                  'is_tax_included': ele.is_tax_included ? 'included' : 'excluded',
                  'item_code':ele.item.item_code,
                  'name':ele.item.name,
                  'price':ele.item.price,
                  'price_per_unit':ele.price_per_unit,
                  'quantity':ele.quantity,
                  'sgst': ele.item.sgst,
                  'taxAmt': parseFloat(dummyIndividualTaxAmt.toFixed(2)),
                  'tax_percent': ele.tax_percent,
                  'totalAmt': parseFloat(dummytotal.toFixed(2)),
                  'type': ele.item.type,
                  'unit': ele.item.unit,
                  'item_description' : ele.item_description
              })
              
          })
          setTableData([...tempItems])
          setGstStatus(res.data.data.e_invoice.customer.gstin_status == 'Active');
          tempTotalPayableAmount -= res.data.data.e_invoice.discount
          tempTotalPayableAmount -= res.data.data.e_invoice.tds
          tempTotalPayableAmount += res.data.data.e_invoice.shipping_charges
          tempTotalPayableAmount -= res.data.data.e_invoice.received
          let tempFormValue = {
            'bank_account_id': res.data.data.e_invoice.bank_account.id,
            'bill_address': res.data.data.e_invoice.bill_address,
            'bill_phone': res.data.data.e_invoice.bill_phone,
            'customer':{...res.data.data.e_invoice.customer},
            'customerName': res.data.data.e_invoice.customer.company_name,
            'customer_id': res.data.data.e_invoice.customer.id,
            'discount': res.data.data.e_invoice.discount,
            'due_date': res.data.data.e_invoice.due_date,
            'email': res.data.data.e_invoice.customer.email,
            'gst_reverse_charge': res.data.data.e_invoice.gst_reverse_charge,
            'gstin': res.data.data.e_invoice.customer.gstin,
            'invoice_date': res.data.data.e_invoice.invoice_date,
            'invoice_number': res.data.data.e_invoice.invoice_number,
            'invoice_type': res.data.data.e_invoice.invoice_type,
            'item_total': res.data.data.e_invoice.round_off ? Math.round(parseFloat(tempTotalPayableAmount.toFixed(2))) : parseFloat(tempTotalPayableAmount.toFixed(2)),
            'payment_type':res.data.data.e_invoice.payment_type,
            'pending': res.data.data.e_invoice.pending,
            'phone_number': res.data.data.e_invoice.customer.phone_number,
            'point_of_contact':res.data.data.e_invoice.customer.point_of_contact,
            'received': res.data.data.e_invoice.received,
            'reverse_chargable': res.data.data.e_invoice.reverse_chargable,
            'ship_address':res.data.data.e_invoice.ship_address,
            'ship_phone':res.data.data.e_invoice.ship_phone,
            'shipping_charges': res.data.data.e_invoice.shipping_charges,
            'status': 'IRN_GENERATED',
            'tax_invoice_total_amt':convertNumberToWords(res.data.data.e_invoice.round_off ? Math.round(parseFloat(amtAfterTax.toFixed(2))) : parseFloat(amtAfterTax.toFixed(2))),
            'tds':res.data.data.e_invoice.tds,
            'cess':res.data.data.e_invoice.cess,
            'cgst': parseFloat(cgst.toFixed(2)),
            'sgst': parseFloat(sgst.toFixed(2)),
            'igst':parseFloat(igstamt.toFixed(2)),
            'total_amount_before_tax' : res.data.data.e_invoice.round_off ? Math.round(parseFloat(amtBeforeTax.toFixed(2))) : parseFloat(amtBeforeTax.toFixed(2)),
            'total_amount_after_tax' : res.data.data.e_invoice.round_off ? Math.round(parseFloat(amtAfterTax.toFixed(2))) : parseFloat(amtAfterTax.toFixed(2)),
            'taxableamt' : parseFloat(dummytaxamt.toFixed(2)),
            'reimbursements':res.data.data.e_invoice.reimbursements
          }

          setvalues({...tempFormValue})
          generateQrCode(res.data.data.e_invoice.invoice_number)
          setFormData({
            'bank_account': {...res.data.data.e_invoice.bank_account},
            'eInvoice_id': e_invoice_id,
            'formValues': {...tempFormValue},
            'items':[...tempItems]
            
          })
          setBankDetails({...res.data.data.e_invoice.bank_account})
          setTotalAmount(parseFloat(totalAmount.toFixed(2)))
        }
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
    }
  },[location])
  // React.useEffect(() => {
  //   if(location.state.step!=0){
  //     if(location.state.step == 2)
  //     setInvoiceGenerateStep(2)
  //     if(location.state.step == 3)
  //     setInvoiceGenerateStep(3)

  //   }
  // },[Location])
  
  const authState = useSelector((state: IState) => state.authReducer);
  const [values,setvalues] = React.useState<any>({});
  const [gstStatus, setGstStatus] = React.useState<any>(true);
  const [shareDetails,setShareDetails] = React.useState<any>({
    email_check: false,
    email_copy: false,
    whatsapp_check: false,
    email_to:'',
    whatsapp_to:''
    
  });
  const [tableData,setTableData] = React.useState<any[]>([]);
  const [profileValues,setprofileValues] = React.useState<any>({})
  const [bankDetails,setBankDetails] = React.useState<any>({})
  const [themeState,setThemeState] = useState<any>({})
  const [logoUrl,setLogoUrl] = useState<any>('')

  const getLogoUrl = () => {
    globalApiService.getLogoUrl().then((response)=>{
      if(response.data.success){
        setLogoUrl(response.data.data.logo_url)
      }
    }).catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
      console.log(errorBody)
    });
  }

  React.useEffect(() => {
    getLogoUrl()
    globalApiService
      .fetchProfileDetails(authState.gstin)
      .then((res) => {
        if(res.data.success){
          console.log('res',res)
          setThemeState({...res.data.data.inv_settings})
          setprofileValues({
            'trade_name': res.data.data.general_info.trade_name,
            'address': res.data.data.general_info.address,
            'gstin': res.data.data.general_info.gstin
          });
        }
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        console.log(errorBody);
      });
},[])
  const [invoiceGenerateStep, setInvoiceGenerateStep] = React.useState(1);
  const [totalAmount, setTotalAmount] = React.useState(0);

  React.useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const updateInvoice = (id:number,data:any) => {
    setLoading(true)
    globalApiService
    .updateEinvoice(id,{...data})
    .then((res) => {
      if (res.data.success) {
        setInvoiceGenerateStep(2)
    }
      setLoading(false)
    })
    .catch((err: AxiosError) => {
      setLoading(false)
      const errorBody = err?.response?.data;
      console.log(errorBody);
    });
  }
  const generateIrn = (id:number,gstin:any) => {
    setIsGenerateIrnDisabled(true)
    if(!authState.isActive){
      OpenNotification('IRN cannot be generated because your GSTIN is InActive!', 'error');
      setIsGenerateIrnDisabled(false)
      return;
    }
    if(!gstStatus){
      OpenNotification('IRN cannot be generated because the customer GSTIN is InActive!', 'error');
      setIsGenerateIrnDisabled(false)
      return;
    }

    setLoading(true)
    globalApiService
    .generateIrn(id,gstin)
    .then((res) => {
      if (res.data.success) {
        setInvoiceGenerateStep(3)
        setIsGenerateIrnDisabled(false)
        generateQrCode(formData.formValues.invoice_number);
    }else{
      setIsGenerateIrnDisabled(false)
    }
      setLoading(false)
    })
    .catch((err: AxiosError) => {
      setLoading(false)
      setIsGenerateIrnDisabled(false)
      const errorBody = err?.response?.data;
      if(errorBody !== undefined){
         if (Array.isArray(errorBody.message) && errorBody.message.length > 0) {
           const errObj: any = errorBody.message[0];
           if ('error_msg' in errObj) {
             OpenNotification(errorBody.message[0]['error_msg'], 'error');
             return;
           } else {
             OpenNotification(errorBody.message[0], 'error');
             return;
           }
         }
      if(errorBody.message !== "Authentication API Error. Please try again" && errorBody.message.toLowerCase() !== "username or password not found".toLowerCase() && errorBody.message.toLowerCase() !== "cannot generate irn, your gstin status is inactive.".toLowerCase() && errorBody.message.toLowerCase() !== "cannot generate irn, customer's gstin status is inactive.".toLowerCase()){
        history.push(ROUTES.E_INVOICE,{tab:"error"})
        }
      }
     
    });
  }

  const handleGenerateClick = () => {
    setModalVisible(true);
    // updateInvoice(formData.eInvoice_id,{'status':'INVOICE_GENERATED', 'share_details':shareDetails, 'form_data':{...values, ...profileValues, ...bankDetails, 'item_data':tableData, 'total_amount':totalAmount, 'theme_color': values.invoice_type === 'B2B' ? themeState.b2b_theme : themeState.b2c_theme}})
    // globalApiService.getEinvoiceStatus(values.invoice_number)
    // .then((res) => {
    //   if(res.data.success){
    //     if(res.data.data.isInvoiceExist){
    //     OpenNotification(res.data.data.message,'error')
    //     history.push(ROUTES.E_INVOICE)
    //     }else {
    //     updateInvoice(formData.eInvoice_id,{'status':'INVOICE_GENERATED', 'share_details':shareDetails, 'form_data':{...values, ...profileValues, ...bankDetails, 'item_data':tableData, 'total_amount':totalAmount, 'theme_color': values.invoice_type === 'B2B' ? themeState.b2b_theme : themeState.b2c_theme}})
    //     }
    //   }
    // })
    // .catch((err: AxiosError) => {
    //   const errorBody = err?.response?.data;
    // });
    
  }

  React.useEffect(()=> {
    if(!shareDetails.email_check){
      form.resetFields(['email_to'])
    }
    if(!shareDetails.whatsapp_check){
      form.resetFields(['whatsapp_to'])
    }

  },[shareDetails.email_check,shareDetails.whatsapp_check])

  useEffect(() => {
    if(values.invoice_number && invoiceGenerateStep === 1){
    globalApiService.getEinvoiceStatus(values.invoice_number)
    .then((res) => {
      if(res.data.success){
        if(res.data.data.isInvoiceExist){
        OpenNotification(res.data.data.message,'error')
        history.push(ROUTES.E_INVOICE)
        }
      }
    })
    .catch((err: AxiosError) => {
      const errorBody = err?.response?.data;
    });
  }
  },[values.invoice_number,invoiceGenerateStep])

  // const onEditClick = () => {
  //   globalApiService.getEinvoiceStatus(values.invoice_number)
  //   .then((res) => {
  //     if(res.data.success){
  //       if(res.data.data.isInvoiceExist){
  //       OpenNotification(res.data.data.message,'error')
  //       history.push(ROUTES.E_INVOICE)
  //       }else {
  //       history.push(`/eInvoicing/editInvoice/${formData.eInvoice_id}`,{'data': {...formData}})
  //       }
  //     }
  //   })
  //   .catch((err: AxiosError) => {
  //     const errorBody = err?.response?.data;
  //   });
  // }

  return (
    <section className="Invoice-preview">
      <div className="invoice-preview-threecolgrid">
        {/*<Spin spinning={loading}>*/}
        <div className="invoice-preview-threecolgrid__firstele">
          <ArrowLeftOutlined className="invoice-preview-threecolgrid__firstele__icon"  onClick={invoiceGenerateStep === 3 || invoiceGenerateStep === 2 || invoiceGenerateStep === 4  ?() => history.push(ROUTES.E_INVOICE) :() =>  history.push(`/eInvoicing/editInvoice/${formData.eInvoice_id}`,{'data': {...formData}})}/>
        </div>
        <div className="invoice-preview-threecolgrid__secondele" ref={componentRef}>
          <Card className="invoice-card">
            <div>
              <h4 className="invoice-card__seller">
                Bill from
              </h4>
            </div>
            <div className="invoice-card__header" style={{borderColor:`${values.invoice_type === 'B2B' ? themeState.b2b_theme : themeState.b2c_theme}`}}>
              <div className="invoice-card__header__firstele">
                <h3 className="invoice-card__header__firstele--title">
                 {profileValues.trade_name}
                </h3>
                <p className="invoice-card__header__firstele--text">
                {profileValues.address}
                </p>
                <h4 className="invoice-card__header__firstele--gstin">
                 GSTIN: {profileValues.gstin}
                </h4>
                <h4 className="invoice-card__header__firstele--pan">
                 PAN: {profileValues.gstin?.slice(2,12)}
                </h4>
              </div>
              <div className="invoice-card__header__imgcontainer">
                {logoUrl && (
                  <img
                  src={logoUrl}
                  alt="Company logo"
                  className="invoice-card__header__imgcontainer--Logoimg"
                />
                )}
              </div>
              <div className="invoice-card__header__secondele">
                <h3 className="invoice-card__header__secondele--title">
                  Invoice no.: {values.invoice_number}
                </h3>
                <p className="invoice-card__header__secondele--text">
                  Date {values.invoice_date}
                </p>
                <div className="invoice-card__header__imgcontainer">
                {qrCode != '' && (
                  <img
                  src={`data:image/png;base64,${qrCode}`}
                  alt="QR Code"
                  className="invoice-card__header__imgcontainer--img"
                />
                )}
                </div>
              </div>
            </div>
            <div>
              <p className="invoice-card__buyer">
                Bill to
              </p>
            </div>
            {(invoiceGenerateStep === 1 || invoiceGenerateStep === 4) && <div className="invoice-card__threecolgrid">
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Invoiced to
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.customerName}
                </p>
              </div>
              {/* <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Contact no.
                </p>
                <p className="invoice-card__threecolgrid__ele__contactno">
                {values.phone_number}
                </p>
              </div> */}
              {/* <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__emailid">
                  Email id.
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.email}
                </p>
              </div> */}
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">GSTIN</p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.gstin}
                </p>
              </div>
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Billing address
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.bill_address}
                </p>
              </div>
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Shipping address
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.ship_address}
                </p>
              </div>
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Bill to phone number
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.bill_phone}
                </p>
              </div>
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Ship to phone number
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.ship_phone}
                </p>
              </div>
            </div>}
            {(invoiceGenerateStep === 2 || invoiceGenerateStep === 3) && <div className="invoice-card__threecolgrid">
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Invoiced to
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.customerName}
                </p>
              </div>
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">GSTIN</p>
                <p className="invoice-card__threecolgrid__ele__text">
                  {values.gstin}
                </p>
              </div>
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Billing address
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                  {values.bill_address}
                </p>
              </div>
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Shipping address
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                  {values.ship_address}
                </p>
              </div>
              {/* <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Contact no.
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.phone_number}
                </p>
              </div>
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Email id.
                </p>
                <p className="invoice-card__threecolgrid__ele__emailid">
                {values.email}
                </p>
              </div> */}
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Bill to phone number
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.bill_phone}
                </p>
              </div>
              <div className="invoice-card__threecolgrid__ele">
                <p className="invoice-card__threecolgrid__ele__label">
                  Ship to phone number
                </p>
                <p className="invoice-card__threecolgrid__ele__text">
                {values.ship_phone}
                </p>
              </div>
            </div>}
            <div className="invoice-card__tablecontainer" style={{borderColor:`${values.invoice_type === 'B2B' ? themeState.b2b_theme : themeState.b2c_theme}`}}>
              <div className="invoice-card__tablegrid">
                <p className="invoice-card__tablegrid__label">#</p>
                <p className="invoice-card__tablegrid__label">Item</p>
                <p className="invoice-card__tablegrid__label">HSN code</p>
                <p className="invoice-card__tablegrid__label">Qty</p>
                <p className="invoice-card__tablegrid__label">Price/Unit</p>
                <p className="invoice-card__tablegrid__label">Discount%</p>
                <p className="invoice-card__tablegrid__label">Taxable amt</p>
                <p className="invoice-card__tablegrid__label">Tax%</p>
                <p className="invoice-card__tablegrid__label">GST</p>
                <p className="invoice-card__tablegrid__label">Amount</p>
                {tableData.map((ele, index) => {
                  return (
                    <>
                      <p className="invoice-card__tablegrid__text">{index + 1}</p>
                      <p className="invoice-card__tablegrid__text">
                        {ele.name}
                        <p className="invoice-card__tablegrid__description">
                          { ele.item_description }
                        </p>
                      </p>
                      <p className="invoice-card__tablegrid__text">
                        {ele.hsn_code}
                      </p>
                      <p className="invoice-card__tablegrid__text">{ele.quantity}</p>
                      <p className="invoice-card__tablegrid__text">
                        {ele.price_per_unit}
                      </p>
                      <p className="invoice-card__tablegrid__text">
                        {ele.discount_percent}
                      </p>
                      <p className="invoice-card__tablegrid__text">{ele.totalAmt}</p>
                      <p className="invoice-card__tablegrid__text">
                        {ele.tax_percent}
                      </p>
                      <p className="invoice-card__tablegrid__text">
                        {ele.taxAmt}
                      </p>
                      <p className="invoice-card__tablegrid__text">
                        {(parseFloat(ele.taxAmt) + parseFloat(ele.totalAmt)).toFixed(2)}
                      </p>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="invoice-card__footerthreecolgrid">
              <div className="invoice-card__footerthreecolgrid__firstele">
                <p className="invoice-card__footerthreecolgrid__label">
                  Tax invoice total amount in words
                </p>
                <p className="invoice-card__footerthreecolgrid__text invoice-card__footerthreecolgrid__text--mb">
                  {values.tax_invoice_total_amt}
                </p>
                <p className="invoice-card__footerthreecolgrid__label">
                  Type of invoice payment
                </p>
                <p className="invoice-card__footerthreecolgrid__text invoice-card__footerthreecolgrid__text--mb">
                {values.payment_type}
                </p>
                <p className="invoice-card__footerthreecolgrid__label">
                  Bank account for payment
                </p>
                <p className="invoice-card__footerthreecolgrid__text">
                  {bankDetails.bank_name}, <br></br>IFSC {bankDetails.ifsc}<br></br>Acc no.:
                  {bankDetails.account_no}
                </p>
              </div>
              <div className="invoice-card__footerthreecolgrid__secondele">
                <p className="invoice-card__footerthreecolgrid__label">
                  Due Date
                </p>
                <p className="invoice-card__footerthreecolgrid__text">
                {values.due_date}
                </p>
              </div>
              <div className="invoice-card__footerthreecolgrid__thirdele">
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    Total
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                    {values.item_total}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  {/* <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    Discount
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.discount}
                  </p> */}
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    Shipping charges
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.shipping_charges}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                  Reimbursements
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.reimbursements}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    Total Amount before tax
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.total_amount_before_tax}
                  </p>
                </div>
              </div>
              <div className="invoice-card__footerthreecolgrid__thirdele">
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    CGST
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                    {values.cgst}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    SGST/UGST
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.sgst}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    IGST
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.igst}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    CESS
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.cess}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    TDS/other deductions
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.tds}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    Total amount after tax 
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.total_amount_after_tax}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    Received
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.received}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    Pending
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.pending}
                  </p>
                </div>
                <div className="invoice-card__footerthreecolgrid__thirdele--container">
                  <p className="invoice-card__footerthreecolgrid__label invoice-card__footerthreecolgrid__label--alignright">
                    GST payable on reverse charge
                  </p>
                  <p className="invoice-card__footerthreecolgrid__text">
                  {values.gst_reverse_charge}
                  </p>
                </div>
                <div className="invoice-summary-container" style={{borderColor:`${values.invoice_type === 'B2B' ? themeState.b2b_theme : themeState.b2c_theme}`}}>
                  <p className="invoice-summary-container__text">
                    {' '}
                    Total payable
                  </p>
                  <h3 className="invoice-summary-container__amtlabel">
                  &#8377; {parseFloat((values.pending + 0).toFixed(2))}
                  </h3>
                </div>
              </div>
            </div>
            <p className="footnote">This is a computer generated invoice and is valid without the signature and seal.{' '}<br />Created using KYSS, a JitFinco product. Visit <a href="https://app.kyss.ai/" className="footnote__anchor">app.kyss.ai</a></p>
          </Card>
        </div>
        <div className="invoice-preview-threecolgrid__thirdele">
          {invoiceGenerateStep === 1 && (
            <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={() => handleGenerateClick()}
            onFinishFailed={() => {}}
            autoComplete="on"
            className="einvoice-form"
            form={form}
          >
            <div className="invoice-generate">
              <div className="invoice-generate__ele">
                <Checkbox
                  onChange={(e) => setShareDetails({...shareDetails, 'email_check': e.target.checked})}
                  className="invoice-generate__ele__checkbox invoice-generate__ele__checkbox--margin-bottom"
                />

              <Form.Item label="" name="email_to" rules={[{ required:shareDetails.email_check, message: 'email is a manadatory field'},{pattern: new RegExp(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/),message: 'Enter a valid email'},]}>
                <Input
                  disabled={!shareDetails.email_check}
                  name="email_to"
                  value={shareDetails?.email_to || ''}
                  onChange={(e) => setShareDetails({...shareDetails, 'email_to': e.target.value})}
                  placeholder={'Email to..'}
                  className="invoice-generate__ele__input"
                />
              </Form.Item>
              </div>
              <div className="invoice-generate__ele invoice-generate__ele--margin-bottom">
                <Checkbox
                  onChange={(e) => setShareDetails({...shareDetails, 'email_copy': e.target.checked})}
                  className="invoice-generate__ele__checkbox"
                />
                <Input
                  readOnly
                  name="documentNumber"
                  value={authState.email}
                  // onChange={onEWayBillChange}
                  //value="Send me a copy"
                  className="invoice-generate__ele__input invoice-generate__ele__input--margin-bottom"
                />
              </div>
              <div className="invoice-generate__ele">
                <Checkbox
                  onChange={(e) => setShareDetails({...shareDetails, 'whatsapp_check': e.target.checked})}
                  className="invoice-generate__ele__checkbox invoice-generate__ele__checkbox--margin-bottom"
                />
                <Form.Item label="" name="whatsapp_to" rules={[{ required:shareDetails.whatsapp_check, message: 'whatsapp_to is a manadatory field'},{pattern: new RegExp(/^([+]\d{2})?\d{10}$/),message: 'Enter a valid phone no'}]}>
                <Input
                  disabled={!shareDetails.whatsapp_check}
                  name="whatsapp_to"
                  value={shareDetails?.whatsapp_to || ''}
                  onChange={(e) => setShareDetails({...shareDetails, 'whatsapp_to': e.target.value})}
                  placeholder={`WhatsApp to..`}
                  className="invoice-generate__ele__input"
                />
                </Form.Item>
                {/* <FiEdit3 className="invoice-generate__ele__icon" /> */}
              </div>
              <div className="invoice-generate__btnele">
                <Button className="invoice-generate__btnele__btn invoice-generate__btnele__btn--bg" onClick={() =>  history.push(`/eInvoicing/editInvoice/${formData.eInvoice_id}`,{'data': {...formData}})}>
                  Edit
                </Button>
                <Button
                  className="invoice-generate__btnele__btn"
                  htmlType="submit"
                  //onClick={() => updateInvoice(formData.eInvoice_id,{'status':'INVOICE_GENERATED', 'share_details':shareDetails, 'form_data':{...values, ...profileValues, ...bankDetails, 'item_data':tableData, 'total_amount':totalAmount, 'theme_color': values.invoice_type === 'B2B' ? themeState.b2b_theme : themeState.b2c_theme}})}
                >
                  Generate
                </Button>
                <div className="invoice-generate__btnele__textele">
                  {/* <p className="invoice-generate__btnele__textele--text"
                    onClick={() =>  history.push(ROUTES.E_INVOICE)}
                  >
                    Cancel
                  </p> */}
                </div>
              </div>
            </div>
            </Form>
          )}
          {invoiceGenerateStep === 2 && (
            <div className="invoice-generate-success">
              <h3 className="invoice-generate-success__text">
                Invoice no.: {values.invoice_number ? values.invoice_number : ''} successfully generated!
              </h3>
              <div className="invoice-generate-success__btnele">
                {values.invoice_type == 'B2B' && (
                <Button
                  className={`invoice-generate-success__btn ${isGenerateIrnDisabled && 'pointer-events-none'}`}
                  onClick={() => generateIrn(formData.eInvoice_id, authState.gstin)}
                >
                  Generate IRN
                </Button>
                )}
              </div>
              <div className="invoice-generate-success__printele">
                <p
                  className="invoice-generate-success__printele__text"
                  onClick={handlePrint}
                >
                  Print
                </p>
                {/* <p className="invoice-generate-success__printele__text"
                  onClick={() =>  history.push(ROUTES.E_INVOICE)}
                >
                  Cancel
                </p> */}
              </div>
            </div>
          )}
          {invoiceGenerateStep === 3 && (
            <div className="invoice-generate-success">
              <h3 className="invoice-generate-success__text">
                IRN successfully <br></br>generated!
              </h3>
              <div className="invoice-generate-success__btnele">
                <Button
                  className="invoice-generate-success__btn"
                  onClick={() => history.push(ROUTES.E_WAY_BILL , {'e_invoice_id': formData.eInvoice_id})}
                >
                  Generate E way bill
                </Button>
              </div>
              <div className="invoice-generate-success__printele">
                <p
                  className="invoice-generate-success__printele__text"
                  onClick={handlePrint}
                >
                  Print
                </p>
                {/* <p className="invoice-generate-success__printele__text"
                  onClick={() =>  history.push(ROUTES.E_INVOICE)}
                >
                  Cancel
                </p> */}
              </div>
              <div className="invoice-generate-success__printele">
                {/*<p className="invoice-generate-success__printele__text">*/}
                {/*  Print*/}
                {/*</p>*/}
                {/* <p className="invoice-generate-success__printele__text"
                  onClick={() =>  history.push(ROUTES.E_INVOICE)}
                >
                  Cancel
                </p> */}
              </div>
            </div>
          )}
          {invoiceGenerateStep === 4 &&(
             <div className="invoice-print__printele">
             <p
               className="invoice-print__printele__text"
               onClick={handlePrint}
             >
               Print
             </p>
             {/* <p className="invoice-generate-success__printele__text"
               onClick={() =>  history.push(ROUTES.E_INVOICE)}
             >
               Cancel
             </p> */}
           </div>
          )
          }
        </div>
        {/*</Spin>*/}
      </div>
      {/* code for pipeline */}
      <Modal 
        className="IRNgenerateModal"
        width={1000}
        title="WARNING !" 
        centered
        onCancel={showErrorModal}
        visible={isModalVisible}
        footer={[
          <Button className='buttonProceed' onClick={handleUploadOk}>Proceed</Button>,
          <Button className='buttonCancel' onClick={showErrorModal}>Cancel</Button>
        ]}
      >
        <p>
          <b>
          {'Are you sure you want to proceed?'}
          </b>
        </p>
        <b>Note: </b>{'No edits possible once invoice is generated.'}
      </Modal>
    </section>
  );
};
export default InvoicePreview;
