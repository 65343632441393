import React from 'react';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';


const TermsAndConditionsPage = (props:any) => {
  const {scrollToPrivacyPolicySection} = props
  const refForPrivacyPolicy:any = React.useRef(null)
  const scrollToRef = (ref:any) => {
    ref.current.scrollIntoView({behavior: 'smooth'})
  }
  React.useEffect(() => {
    if(scrollToPrivacyPolicySection){
      scrollToRef(refForPrivacyPolicy)
    }
    
  },[scrollToPrivacyPolicySection])
  return (
    <div>

      
          <div >
            <Title level={2} style={{ color: '#4533bf' }}>
              Terms and Conditions
            </Title>
            <Paragraph>
              These terms of service and Our Privacy Policy (together, these
              &quot;Terms&quot;) form a contract between You and JiT Techlabs
              Pvt Ltd. (referred to as &quot;Us&quot;, &quot;We&quot;,
              &quot;Our&quot;) and govern Your right to access the Websites and
              the use of and access to the Services by You, Your Affiliates,
              Users and End-Customers as part of a Subscription during the Term.
              By accessing or using the Services or Websites or authorizing or
              permitting any User or End-Customer to access or use the Services
              or Websites, You are agreeing to be bound by these Terms. In the
              event of a conflict between these terms of service and Our Privacy
              Notice, these terms of service shall prevail. If You are using Our
              Services for an organization, You are agreeing to these Terms on
              behalf of that organization and in these Terms, &quot;You&quot; or
              &quot;Your&quot; refers to that organization. If You do not have
              such authority, or if You do not agree with these Terms, You must
              not accept these Terms and should not access or use the Services
              and/or Websites.
            </Paragraph>

            <Paragraph>
              In these Terms, You and We are individually referred to as a
              &quot;Party&quot; and collectively as &quot;Parties&quot;. You, as
              an individual, must be eighteen (18) years or older to access the
              Websites and register for and use the Services.
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              1. Definitions
            </Title>

            <Paragraph>
              When used in these Terms with the initial letters capitalized, in
              addition to the terms defined elsewhere in these Terms, the
              following terms have the following meanings:
            </Paragraph>

            <Paragraph>
              <b>Account: </b>
              means any accounts or instances created by or on behalf of You for
              access and use of the Services. You may have multiple
              Subscriptions which may be subject to separate and distinct Plans.
              For the avoidance of doubt, reference to an Account herein refers
              to (i) Your trial account where a live account has not been
              activated; or (ii) Your trial account and live account where the
              live account has been activated.
            </Paragraph>

            <Paragraph>
              <b>Affiliate: </b>
              means, with respect to a Party, any entity that directly or
              indirectly controls, is controlled by, or is under common control
              with such Party, whereby &quot;control&quot; (including, with
              correlative meaning, the terms &quot;controlled by&quot; and
              &quot;under common control&quot;) means the possession, directly
              or indirectly, of the power to direct, or cause the direction of
              the management and policies of such person, whether through the
              ownership of voting securities, by contract, or otherwise.
            </Paragraph>

            <Paragraph>
              <b>Applicable Data Protection Law: </b>
              means all laws and regulations, including laws and regulations of
              the United States, European Union, the European Economic Area and
              their member states, Switzerland and the United Kingdom,
              applicable to the Processing of Personal Data under these Terms.
            </Paragraph>

            <Paragraph>
              <b>Data Processing Addendum: </b>
              means the data processing addendum available here as updated
              periodically and which is incorporated into these Terms by
              reference; or a separate addendum that the Parties have executed
              in this regard, as the case may be, pursuant to which We shall
              Process Personal Data forming part of Your Service Data.
            </Paragraph>

            <Paragraph>
              <b>Documentation: </b>
              means any written or electronic documentation, images, video, text
              or sounds specifying the functionalities of the Services provided
              or made available by Us to You or Users through the Services or
              otherwise.
            </Paragraph>

            <Paragraph>
              <b>End-Customer: </b>
              means Your customers and/or any person or entity other than You or
              Users with whom You interact using the Services.
            </Paragraph>

            <Paragraph>
              <b>Group Companies: </b>
              means JiT Techlabs Pvt Ltd and its Affiliates.
            </Paragraph>

            <Paragraph>
              <b>Personal Data: </b>
              means data forming part of the Service Data and relating to a
              living individual who is or can be identified either from the data
              or from the data in conjunction with other information that is in,
              or is likely to come into the possession of the data controller or
              such other similar term as defined under Applicable Data
              Protection Law.
            </Paragraph>

            <Paragraph>
              <b>Pricing Agreement: </b>
              means a pricing agreement or an order form referencing these Terms
              and executed or approved by You and Us with respect to Your
              Subscription, which agreement may detail, among other things, the
              Plan applicable to a Subscription, the associated Subscription
              Fees and other details of the Subscription.
            </Paragraph>

            <Paragraph>
              <b>Process/Processing: </b>
              means any operation or set of operations which is performed upon
              Personal Data, whether or not by automatic means, such as
              collection, recording, organization, storage, adaptation or
              alteration, retrieval, consultation, use, disclosure by
              transmission, dissemination or otherwise making available,
              alignment or combination, blocking, erasure or destruction.
            </Paragraph>

            <Paragraph>
              <b>Services: </b>
              means Our cloud-based supplier analysis and scoring platform and
              related products and services that You subscribe to, and/or any
              new services or features that We may introduce as a Service to
              which You may subscribe to, and any Updates to the Services,
              including individually and collectively, any software, APIs and
              Documentation.
            </Paragraph>

            <Paragraph>
              <b>Service Data: </b>
              means all electronic data, text, messages or other materials,
              including Registration Data, Business Data and and Personal Data
              of Users and Suppliers, submitted to the Services through an
              Account in connection with Your use of the Services.
            </Paragraph>

            <Paragraph>
              <b>Subscription: </b>
              means Your subscription to the Services through a Plan of Your
              choice for Your access and use of an Account.
            </Paragraph>

            <Paragraph>
              <b>User: </b>
              means those individuals You authorize as users within an Account
              to use the Services by way of designated login credentials.
            </Paragraph>

            <Paragraph>
              <b>Websites: </b>
              means the websites that We operate.
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              2. Rights to Use the Services
            </Title>
            <Paragraph>
              <b>2.1 </b>
              Subject to Your (including Users&apos; and End-Customers&apos;)
              compliance with these Terms and solely during the Term, We grant
              You a limited, non-exclusive, non-transferable, non-assignable
              (except to the extent expressly permitted by Section 18.4
              (Assignment)), worldwide (subject to applicable law) and revocable
              right to access and use the Services that You have subscribed to
              for Your own internal business purposes. You may use the
              Documentation solely in connection with Your use of the Services.
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              3. Your Responsibilities
            </Title>
            <Paragraph>
              <Paragraph>
                <b>3.1 Registration Data. </b>
                You agree to provide Us with current, complete and accurate
                registration information as prompted by the Services&apos;
                registration process (&quot;Registration Data&quot;). You agree
                not to omit or misrepresent any Registration Data, and further
                agree to update the Registration Data periodically to ensure
                that it is current, complete and accurate. You further authorize
                Us to verify the Registration Data as required for Your use of
                the Services.
              </Paragraph>
              <Paragraph>
                <b>3.2 Account Activities. </b>
                You are solely responsible for
                <Paragraph style={{ marginTop: '6px', paddingLeft: '20px' }}>
                  <b>i. </b>
                  Your (including Users&apos; and End-Customers&apos;) access
                  and use of the Services in compliance with these Terms;
                  <br />
                  <b>ii. </b>
                  Without prejudice to Our obligations under Section 10
                  (Confidentiality) and Section 11 (Data Security and Privacy),
                  maintaining the confidentiality of unique login information,
                  credentials and passwords associated with Your Account, and
                  the privacy and security of Your Account;
                  <br />
                  <b>iii. </b>
                  Ensuring that Your use of the Services to store, process and
                  transmit Service Data is compliant with applicable laws and
                  regulations;
                  <br />
                  <b>iv. </b>
                  All activities that occur regarding Your Account regardless of
                  whether the activities are undertaken by You, Users,
                  End-Customers or a third party (including Your contractors or
                  agents;
                  <br />
                  <b>v. </b>
                  Immediately ceasing use of the Services for a prohibited
                  activity or purpose if We inform You that a specified activity
                  or purpose is prohibited with respect to the Services; and
                  <br />
                  <b>vi. </b>
                  Determining whether the Services or the information generated
                  thereby is accurate or sufficient for Your purposes.
                </Paragraph>
              </Paragraph>
              <Paragraph>
                <b>3.3 Your Conduct.</b>
                You agree to use the Services in accordance with these Terms
                (including any addendum, schedules and exhibits), Documentation
                and any API policies We implement in this regard. Further, You
                agree, on behalf of Yourself and Users, not to
                <b>(a)</b>
                use the Services or permit the Services to be used to provide
                supplier analysis services or related services for any third
                party, or otherwise license, sublicense, sell, resell, rent,
                lease, transfer, assign, distribute, time share or otherwise
                commercially exploit or make the Services available to any third
                party, other than Users and End-Customers in furtherance of Your
                internal business purposes as expressly permitted under these
                Terms;
                <b>(b)</b>
                use the Services to Process data on behalf of any third party
                other than Users and End-Customers;
                <b>(c)</b>
                modify, adapt, or hack the Services or otherwise attempt to gain
                or gain unauthorized access to the Services or related systems
                or networks or tamper with or breach the security of the
                Services;
                <b>(d)</b>
                use the Services in any manner that interferes with or disrupts
                the integrity or performance of the Services and its components;
                <b>(e)</b>
                not use or permit the use of any software, hardware, application
                or process that interferes with the Services, interferes with or
                disrupts servers, systems or networks connected to the Services,
                or violates the regulations, policies or procedures of such
                servers, systems or networks, accesses or attempts to access Our
                other customers&apos; accounts, servers, systems or networks
                without authorization, or harasses or interferes with Our other
                customers&apos; use and enjoyment of the Services;
                <b>(f)</b>
                attempt to decipher, decompile, reverse engineer, disassemble,
                reproduce, or copy or otherwise access or discover or attempt to
                derive the source code or underlying program of any software
                making up the Services;
                <b>(g)</b>
                falsely imply any sponsorship or association with Us;
                <b>(h)</b>
                use the Services in any unlawful manner, including but not
                limited to violation of any person&apos;s privacy rights;
                <b>(i)</b>
                use the Services to send unsolicited communications junk mail,
                spam, pyramid schemes or other forms of duplicative or
                unsolicited messages;
                <b>(j)</b>
                use the Services to store or transmit any content that infringes
                upon any person&apos;s intellectual property rights;
                <b>(k)</b>
                remove or modify any trademarks, trade names, service marks,
                service names, logos or brands, or copyright or other
                proprietary notices on the Services or the Documentation, or add
                any other markings or notices to the Services or the
                Documentation;
                <b>(l)</b>
                use the Services to knowingly post, transmit, upload, link to,
                send or store any content that is unlawful, racist, hateful,
                abusive, libelous, obscene, or discriminatory or promotes any
                financial exploitation or a criminal activity;
                <b>(m)</b>
                modify, port, adapt, translate or create any derivative work
                based upon the Services or the Documentation;
                <b>(n)</b>
                use the Services to store or transmit any &quot;protected health
                information&quot; as that term is defined in 45 C.F.R. 160.103
                unless expressly agreed to otherwise in writing by Us;
                <b>(o)</b>
                use the Services to knowingly post, transmit, upload, link to,
                send or store any viruses, malware, Trojan horses, time bombs,
                worms or cancelbots or any other similar harmful software
                (&quot;Malicious Software&quot;);
                <b>(p)</b>
                establish a link to the Websites in such a way as to suggest any
                form of association, approval or endorsement on Our part where
                none exists;
                <b>(q)</b>
                use the Services for the purposes of cookie tracking, ad
                exchanges, ad networks, data brokerages, or sending electronic
                communications (including e-mail) in violation of applicable
                law;
                <b>(r)</b>
                use of the Services for any purpose prohibited by applicable
                export laws and regulations, including without limitation,
                nuclear, chemical, or biological weapons proliferation, or
                development of missile technology or related to the sale of
                controlled substances such as illegal drugs, tobacco products,
                prescription drugs or medications, steroids such as human growth
                hormone; or
                <b>(s)</b>
                try to use, or use the Services in violation of these Terms
                and/or applicable law. Neither You nor any of Your Affiliates
                shall access or use the Services or Websites if such individual
                or entity is a competitor of the Group Companies. You and Your
                Affiliates shall not access or use the Services or Website to
                evaluate its functionality or performance for competitive
                purposes, including for developing any similar application,
                product or service. Further, at Our sole discretion, any
                suspected fraudulent, abusive, or illegal activity by You may be
                referred to law enforcement authorities.
              </Paragraph>
              <Paragraph>
                <b>3.4 </b>
                Except to the extent caused by Our willful breach of these
                Terms, the Group Companies will not be responsible for any
                alteration, compromise, corruption, damage or loss that may
                result from Your failure to protect Your login information,
                including any passwords. You agree to notify Us immediately of
                any unauthorized access or use of Your Account, log-in
                information, credentials or passwords, or any unauthorized
                activity in Your Account. We have the right to suspend or
                terminate the Services without notice to You, if We reasonably
                suspect any unauthorized activity and You agree to cooperate
                with Us in any investigation into suspected or actual
                unauthorized activity. Where Your Account is so suspended and
                the breach remains uncured post any cure period We may grant You
                at Our sole discretion, We reserve the right to terminate Your
                Subscription with immediate effect.
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              4. Changes to the Websites and Services
            </Title>
            <Paragraph>
              <Paragraph>
                <b>4.1 Websites. </b>
                We have the right but not the obligation to change content on
                Our Websites at any time. We may discontinue or change any part
                of Our Websites, that does not affect the Services, without
                notifying You.
              </Paragraph>
              <Paragraph>
                <b>4.2 Services. </b>
                We may upgrade, update, or modify the provision of all or any
                part of the Services from time to time, including to improve the
                Services or to address any legal or regulatory requirements or
                to address a change in Our policies (&quot;Updates&quot;) and
                You may receive notifications where the Update is material. What
                constitutes a &quot;material Update&quot; will be determined by
                Us in good faith using Our reasonable judgment but will only
                include an Update that materially affects Your use of the
                Services. Unless otherwise stated, the Updates are also subject
                to these Terms and We reserve the right to deploy Updates at any
                time. Where You do not agree to an Update, Your sole remedy
                shall be to terminate Your use of the Services and to comply
                with the termination obligations set forth in these Terms. Any
                use of the Services after the Update will constitute Your
                acceptance of the Update.
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              5. Intellectual Property Rights
            </Title>
            <Paragraph>
              <Paragraph>
                <b>5.1 Ownership. </b>
                All right, title and interest in and to all of Our patents,
                inventions, copyrights, trademarks, domain names, trade secrets,
                know-how and other intellectual property and/or proprietary
                rights in or related to the Services, including the Websites,
                and any part of it (collectively, the &quot;JiT Techlabs
                Intellectual Property Rights&quot;), are owned or licensed by
                and shall remain exclusively with Us and/or the Sub-processors.
                We are the owner or the licensee of all JiT Techlabs
                Intellectual Property Rights in the Websites, and the content or
                material published on it. Those works are protected by copyright
                laws and treaties around the world. You must not use any part of
                the content on the Websites for commercial purposes without
                obtaining appropriate licenses. Subject to the limited rights
                expressly granted by Us to You in Section 2 (Rights to use the
                Services) of these Terms, You do not have any right, title to or
                interest in the JiT Techlabs Intellectual Property Rights. By
                using the Services, You grant Us a limited license to access,
                disclose, process, transmit and use Your Service Data for
                providing the Services to You and for complying with these
                Terms. We claim no intellectual property rights over the Service
                Data. All rights not expressly granted to You in these Terms are
                reserved by Us and/or the Sub-processors.
              </Paragraph>
              <Paragraph>
                <b>5.2 Grant of License to Us. </b>
                You may choose or We may invite You to submit suggestions,
                recommendations, enhancement requests or other feedback about
                the Services (&quot;Feedback&quot;). By submitting any Feedback,
                You agree that such Feedback is gratuitous, unsolicited and
                without restriction, and You grant Us a fully paid up,
                royalty-free, worldwide, transferable, sub-licensable,
                irrevocable and perpetual license to incorporate Feedback, in
                whole or in part, into the Services or Websites. Further, unless
                You notify Us otherwise via e-mail to support@jitfin.co , You
                grant Us a fully paid up, royalty-free, worldwide, transferable
                license to use Your name and logo to identify You as Our
                customer on the Websites or in other sales or marketing
                materials, provided however, We will not issue any press release
                without Your prior consent.
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              6. Sub-processors, Other Services and Third-party Content
            </Title>
            <Paragraph>
              <Paragraph>
                <b>6.1 Sub-processors. </b>
                Certain third parties (each, a &quot;Sub-processor&quot;) may
                assist Us in providing the Services by delivering their products
                and/or services as part of the Services. We contract directly
                with the Sub-processors for the provision of their products
                and/or services, which are integrated with or are an integral
                part of the Services. The products and services provided by the
                Sub-processors do not include any of the Other Services.
              </Paragraph>

              <Paragraph>
                <b>6.2 Other Services. </b>
                Certain other services (&quot;Other Services&quot;) such as
                integrations and apps may be made available to You through
                forums for their integration with the Services. These Other
                Services are governed by their own terms and privacy policies
                and You agree that We are not responsible for Your use of these
                Other Services where You choose to enable these Other Services
                and integrate them into the Services. By enabling the Other
                Services, You understand and agree that We do not provide any
                warranties for Other Services and We are not liable for any
                damage or loss caused or alleged to be caused by or in
                connection with Your enablement, access or use of any such Other
                Services, or Your reliance on the privacy practices, data
                security processes or other policies of such Other Services. You
                understand that We are not responsible for providing technical
                support for Other Services or for the data hosting and data
                transfer practices followed by providers of such Other Services.
                To this extent, You agree to address any comments, queries,
                complaints or feedback about such Other Services with the
                respective developers or publishers as specified in those
                forums.
              </Paragraph>

              <Paragraph>
                <b>6.3 Third-Party Content. </b>
                The Services and Websites may also contain links to websites,
                resources and/or other content provided by third parties (the
                &quot;Third-Party Content&quot;). We do not endorse any
                Third-Party Content. You agree that We are not responsible for
                the quality, performance, reliability or security of any
                Third-Party Content, and that We are not responsible for any
                damages in connection with Your and/or Users&apos; and/or
                End-Customers&apos; access or use of any Third-Party Content, or
                Your reliance on the privacy practices, data security measures
                or other policies of any Third-Party Content. You acknowledge
                and agree that each Third-Party Content is governed by that
                third party provider&apos;s terms and privacy policy and that We
                do not have any control over such services or the Third-Party
                Content. To this extent, You agree to address any comments,
                queries, complaints or feedback regarding the Third-Party
                Content with the third party providing them.
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              7. Billing and Payments
            </Title>
            <Paragraph>
              <Paragraph>
                <b>7.1 Subscription Fees. </b>
                When You are on a paying Plan, You shall be charged a fee based
                on the Plan You choose for the Services (&quot;Subscription
                Fees&quot;). Unless specified otherwise herein or in a Pricing
                Agreement, the Subscription Fees are payable in full and in
                advance until Your Subscription is terminated in accordance with
                Section 8 (Term, Termination and Suspension).
              </Paragraph>
              <Paragraph>
                <b>7.2 Payment. </b>
                You agree to pay the Subscription Fees through a payment method
                acceptable to Us. You hereby authorize Us and/or Our authorized
                agents, as applicable, to bill You in accordance with Your Plan
                and/or Pricing Agreement for Your Subscription (and any renewal
                thereof). Unless otherwise stated in a Pricing Agreement, Your
                payment is due in full immediately upon each due date with
                respect to Your Subscription. You must notify Us of any change
                in Your payment account information, either by updating Your
                Account or via e-mail to support@jitfin.co
              </Paragraph>
              <Paragraph>
                <b>7.3 Renewal. </b>
                For any Renewal Term (as defined below), unless otherwise agreed
                to in a Pricing Agreement, We reserve the right to change the
                subscription fees and billing methods upon a thirty (30) day
                notice. For existing Subscriptions for which the Subscription
                Fees has been already received by Us, We will implement the
                price changes during the next Renewal Term.
              </Paragraph>
              <Paragraph>
                <b>7.4 Refunds. </b>
                Unless otherwise specified in these Terms or Pricing Agreement,
                all Subscription Fees are non-refundable. We do not provide
                refunds or credits for any partial use or non-use of the
                Services.
              </Paragraph>
              <Paragraph>
                <b>7.5 Taxes. </b>
                Unless otherwise specified in a Pricing Agreement, the
                Subscription Fees do not include any taxes, duties, fees or
                other amounts assessed or imposed by any government authority,
                for which You are responsible except for taxes imposed on Our
                income. You agree to pay or reimburse Us for all such amounts
                upon demand or provide evidence of payment or exemption.
              </Paragraph>
              <Paragraph>
                <b>7.6 Delayed Payments/Non-payment of Subscription Fees. </b>
                Where We do not receive payment towards the Subscription Fees
                within the due date for a Subscription, You shall be notified of
                such non-payment. We must receive payments within a maximum of
                thirty (30) days from the date of Our notice. If We do not
                receive payment within the abovementioned time period, in
                addition to Our right to other remedies available under law, We
                may (i) charge an interest for late payment (a) @ 1.5% per month
                on the outstanding balance where the Subscription Fees is
                payable monthly; or (b) @ 1.5% per month on the outstanding
                balance where the Subscription Fees is payable annually; and/or
                (ii) suspend Your access to and use of the Services until We
                receive Your payment towards the outstanding Subscription Fees;
                and/or (iii) terminate the Subscription in accordance with
                Section 8 (Term, Termination and Suspension).
              </Paragraph>
              <Paragraph>
                <b>7.7 Upgrades and Downgrades. </b>
                You may upgrade or downgrade between the Plans. You understand
                that downgrading may cause loss of features or capacity of the
                Services as available to You before downgrading Your
                Subscription. We will not be liable for such loss. When You
                upgrade or downgrade, the new Subscription Fees become
                immediately applicable. Upon upgrade, the new Subscription Fees
                for the subsisting month would be charged on a pro-rated basis
                and shall be payable in accordance with Section 7.2 (Payment).
                Subsequent months will be charged in full according to the new
                Subscription Fees and any credits will be adjusted
                appropriately.
              </Paragraph>
              <Paragraph>
                <b>7.8 Benefits. </b>
                We may, at Our sole discretion, offer You certain
                non-transferrable benefits (such as discounts on Subscription
                Fees or a free extension in Term) specific to a Subscription.
                The benefits may have an expiry date. If they do not have an
                expiry date, they will expire upon completion of twelve (12)
                months from their date of offer.
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              8 Term, Termination and Suspension
            </Title>
            <Paragraph>
              <Paragraph>
                <b> 8.1Term. </b>
                Unless otherwise specified in a Pricing Agreement, a
                Subscription is valid for a period of twelve (12) months from
                the date of Your Subscription (&quot;Initial Term&quot;) and
                shall renew automatically for a term equivalent in length to the
                then expiring term (&quot;Renewal Term&quot;) unless terminated
                in accordance with these Terms. For the purposes of these Terms,
                &quot;Term&quot; shall mean the Initial Term and any Renewal
                Terms.
              </Paragraph>

              <Paragraph>
                <b>8.2 Termination by You. </b>
                You may terminate a Subscription at any time by raising a
                request for cancellation of Your Subscription or by e-mailing Us
                at support@jitfin.co and ceasing all use of the Services.
              </Paragraph>

              <Paragraph>
                <b>8.3 Suspension and Termination by Us. </b>
                In the event of any actual or threatened breach of these Terms
                by You (including non-payment of Subscription Fees), We may
                immediately suspend all or part of Your Subscription(s). We may
                terminate these Terms, including Your Subscription(s), if You
                have breached any of these Terms and if that breach can be
                cured, You fail to cure that breach within fifteen (15) days
                after receiving written notice of that breach from Us
                (&quot;Your Cure Period&quot;).
              </Paragraph>

              <Paragraph>
                <b>8.4 Consequences of termination of a Subscription.</b>
                <Paragraph style={{ marginTop: '6px', paddingLeft: '20px' }}>
                  <Paragraph>
                    <b>8.4.1 Consequences. </b>
                    We shall not be liable to You or any other third party for
                    suspension or termination of Your Subscription or Your
                    access to and use of the Services, including any destruction
                    of Service Data, if such suspension or termination is in
                    accordance with these Terms. Upon any termination of a
                    Subscription, all rights, including the right to access and
                    use the Services granted to You will immediately cease. You
                    shall immediately destroy (or, at Our request, return) Our
                    Confidential Information in Your possession or control.
                  </Paragraph>

                  <Paragraph>
                    <b>8.4.2 Data Export. </b>
                    Following termination of a Subscription, We will retain that
                    Account&apos;s Service Data for one hundred and twenty (120)
                    days from such date of termination (&quot;Data Retention
                    Period&quot;) beyond which, We will have no obligation to
                    maintain or provide You, Users and End-Customers access to
                    Service Data. Thereafter, unless legally prohibited, We
                    reserve the right to destroy all Service Data in Our
                    possession in the normal course of Our operations in
                    accordance with the Privacy Notice. You agree that You are
                    solely responsible for exporting Service Data prior to
                    termination of a Subscription, and if You require assistance
                    for exporting Service Data during the Data Retention Period,
                    You may be required to pay a separate fee to Us.
                  </Paragraph>

                  <Paragraph>
                    <b>8.4.3 Subscription Fees. </b>
                    Unless waived by Us in writing or where the total
                    Subscription Fees has already been prepaid, if a
                    Subscription is terminated in accordance with Section 8.2
                    (Termination by You) or 8.3 (Suspension and Termination by
                    Us) of these Terms, in addition to other amounts You may owe
                    Us, You must immediately pay all unpaid Subscription Fees
                    for the Term accrued through and including Your Cure Period.
                    The sole exclusion to this payment obligation is when You
                    terminate a Subscription as a result of a material breach of
                    these Terms by Us (provided that You provide notice of such
                    breach to Us and afford Us not less than fifteen (15) days
                    (&quot;Our Cure Period&quot;) to reasonably cure such
                    breach), in which case, You are eligible to receive a refund
                    of any unused Subscription Fees prepaid by You for Services
                    not rendered from the date of such termination.
                  </Paragraph>
                </Paragraph>
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              9. Confidentiality
            </Title>
            <Paragraph>
              <Paragraph>
                <b>9.1 </b>
                The access, disclosure and use of Confidential Information and
                any other rights and obligations regarding such information
                shall be solely and exclusively governed by these Terms. The
                provisions of this Section 9 (Confidentiality) shall supersede
                any non-disclosure agreement between the Parties entered prior
                to these Terms that would purport to address the confidentiality
                of Confidential Information and such agreement shall have no
                further force or effect with respect to Confidential
                Information. All confidentiality obligations shall remain in
                force and effect for the Term plus one (1) year.
              </Paragraph>
              <Paragraph>
                <b>9.2 </b>
                You or JiT Techlabs Pvt Ltd (the &quot;Disclosing Party&quot;)
                may from time to time, while Your Subscriptions are valid,
                disclose to the other (the &quot;Receiving Party&quot;) the
                Disclosing Party&apos;s confidential, proprietary and/or
                non-public information, materials or knowledge that is
                designated as confidential or that reasonably should be
                understood to be confidential given the nature of the
                information, materials or knowledge and/or the circumstances of
                disclosure (the &quot;Confidential Information&quot;). For
                purposes of these Terms, Your Confidential Information includes
                Service Data, and Our Confidential Information includes the
                Services, Documentation and the contents of any Pricing
                Agreement. The Receiving Party agrees to protect the Disclosing
                Party&apos;s Confidential Information from unauthorized access,
                disclosure or use in the same manner that the Receiving Party
                protects its own confidential or proprietary information of a
                similar nature but with no less than reasonable care. The
                Receiving Party will only access and use the Disclosing
                Party&apos;s Confidential Information in connection with
                performing these Terms (or as expressly authorized by the
                Disclosing Party in writing), and will disclose the Disclosing
                Party&apos;s Confidential Information only to the employees and
                contractors of the Receiving Party who have a need to know the
                Confidential Information for purposes of performing these Terms
                (or for other purposes as expressly authorized by the Disclosing
                Party in writing) and who are under a duty of confidentiality no
                less restrictive than the Receiving Party&apos;s duty hereunder.
              </Paragraph>
              <Paragraph>
                <b>9.3 </b>
                The Receiving Party&apos;s obligations with respect to
                Confidential Information of the Disclosing Party will terminate
                if the Receiving Party can document that such information (i)
                was already lawfully known to the Receiving Party at the time of
                disclosure by the Disclosing Party free from any obligation of
                confidence, (ii) was disclosed to the Receiving Party by a third
                party who had the right to make such disclosure without any
                confidentiality restrictions, (iii) is, or through no fault of
                the Receiving Party has become, generally available to the
                public or (iv) was independently developed by the Receiving
                Party without access to, or use of, the Disclosing Party&apos;s
                Confidential Information. In addition, the Receiving Party may
                disclose Confidential Information of the Disclosing Party to the
                extent compelled by law or a court or other judicial or
                administrative body, provided that the Receiving Party notifies
                the Disclosing Party of such compelled disclosure promptly and
                in writing (to the extent legally permitted) and cooperates with
                the Disclosing Party, at the Disclosing Party&apos;s reasonable
                request and expense, in any lawful action to contest or limit
                the scope of such required disclosure.
              </Paragraph>
              <Paragraph>
                <b>9.4 </b>
                The Receiving Party will return to the Disclosing Party all
                Confidential Information of the Disclosing Party in the
                Receiving Party&apos;s possession or control and permanently
                erase all electronic copies of such Confidential Information
                promptly upon the written request of the Disclosing Party. At
                the Disclosing Party&apos;s request, the Receiving Party will
                certify in writing that it has fully complied with its
                obligations under this Section 9 (Confidentiality).
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              10. Data Security and Privacy
            </Title>
            <Paragraph>
              <Paragraph>
                <b>10.1 Security of Service Data. </b>
                We use appropriate technical and organizational measures to
                protect the Service Data. The measures used are designed to
                provide a level of security appropriate to the risk of
                Processing the Service Data.
              </Paragraph>
              <Paragraph>
                <b>10.2 Data Privacy.</b>
                <Paragraph style={{ marginTop: '6px', paddingLeft: '20px' }}>
                  <Paragraph>
                    <b>10.2.1 </b>
                    You understand that the Group Companies and Sub-processors
                    shall Process Service Data in accordance with Applicable
                    Data Protection Laws, the Privacy Notice (to the extent
                    applicable) and the Data Processing Addendum. You
                    acknowledge and agree that the Our Group Companies and
                    Sub-processors may also access or disclose information about
                    You, Your Subscription and Account, Users, including Service
                    Data, to (a) provide the Services and perform obligations
                    under these Terms, including, without limitation, to respond
                    to Your support requests; (b) comply with the law or respond
                    to lawful requests or legal process; (c) professionals and
                    advisors in order to protect the Group Companies&apos; or
                    Our other customers&apos; or partners&apos; rights or
                    property; (d) act on a good faith belief that such
                    disclosure is necessary to protect the personal safety or
                    avoid violation of applicable law or regulation.
                  </Paragraph>
                  <Paragraph>
                    <b>10.2.2 </b>
                    We Process Service Data only as per Your instructions to the
                    extent they are consistent with the functionality of the
                    Services.
                  </Paragraph>
                  <Paragraph>
                    <b>10.2.3 </b>
                    In Your role as a controller of Data, (i) You hereby
                    authorize, on behalf of Yourself, Users and Suppliers, and
                    represent that You have the authority to provide such
                    authorization to the Processing and transfer of Data; (ii)
                    You understand that it shall be Your responsibility to
                    inform the Users and Supplier about the Processing, and,
                    where required, obtain necessary consent or authorization
                    for any Data that is collected as part of the Service Data
                    through Your use of the Services. We do not own, control or
                    direct the use of Service Data and only access such
                    information as reasonably necessary to provide the Services
                    (including to respond to support requests), as otherwise
                    authorized by You or as required by law. Unless We
                    explicitly agree otherwise in writing, You shall not provide
                    Sensitive Data at any time to the Services and We will have
                    no liability whatsoever for and in connection with the
                    Sensitive Data. For purposes of these Terms, &quot;Sensitive
                    Data&quot; means (i) Aadhaar number, passport number,
                    driver&apos;s license number, or similar identifier (or any
                    portion thereof), (ii) employment, financial, genetic,
                    biometric or health information, (iii) racial, ethnic,
                    political or religious affiliation, trade union membership,
                    or information about sexual life or sexual orientation, (iv)
                    account passwords, (v) date of birth, (vi) criminal history,
                    (vii) mother&apos;s maiden name or (viii) any other
                    information or combinations of information that is deemed
                    sensitive under the legal framework of any applicable
                    jurisdiction.
                  </Paragraph>
                </Paragraph>
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              11. Other Data Services
            </Title>
            <Paragraph>
              <Paragraph>
                <b>11.1 Data Migration. </b>
                You may, at any time during the Term, request Us to import data
                into Your Account (&quot;Data Migration&quot;). You hereby
                understand and acknowledge that the Group Companies may access
                and Process Your data in connection with providing support
                during such Data Migration.
              </Paragraph>
              <Paragraph>
                <b>11.2 Aggregated Analytical Data. </b>
                The Group Companies and the service providers they use may use
                (but not disclose) data collected regarding Your use of the
                Services for Our internal business purposes, including for
                creating anonymized, aggregated and otherwise non-personal data.
                We may freely use and make available such anonymized, aggregated
                and otherwise non-personal data regarding the use of the
                Services for Our business purposes. We will ensure that this
                anonymized, aggregated and otherwise non-personal data cannot
                identify You or your Suppliers and can in no way be specifically
                associated to You or Suppliers.
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              12. Disclaimer of Warranties
            </Title>
            <Paragraph>
              OUR SERVICES ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS
              AVAILABLE&quot; BASIS. WE DISCLAIM ANY AND ALL WARRANTIES, WHETHER
              EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WITHOUT
              LIMITATION THE IMPLIED WARRANTIES OF NON-INFRINGEMENT,
              MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT
              WARRANT THAT OUR SERVICES OR ANY PART THEREOF, OR USE THEREOF WILL
              BE ERROR-FREE, UNINTERRUPTED, SECURE OR VIRUS FREE, WILL MEET YOUR
              QUALITY AND PERFORMANCE REQUIREMENTS, OR WILL BE CORRECTED FOR ANY
              DEFECTS WITHIN A STIPULATED PERIOD PURSUANT TO ANY SERVICE LEVEL
              AGREEMENT. YOU UNDERSTAND THAT IN USING OUR SERVICES, SENSITIVE
              INFORMATION WILL TRAVEL THROUGH THIRD PARTY INFRASTRUCTURES THAT
              ARE NOT UNDER OUR CONTROL. WE MAKE NO WARRANTY WITH RESPECT TO THE
              SECURITY OF SUCH THIRD PARTY INFRASTRUCTURES. YOU ASSUME THE
              ENTIRE RISK OF AND SHALL NOT HOLD US RESPONSIBLE FOR ANY
              ALTERATION, COMPROMISE, CORRUPTION OR LOSS OF SERVICE DATA
              NOTWITHSTANDING ANY SECURITY OR OTHER MEASURE THAT MAY BE PROVIDED
              BY US.
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              13. Limitation of Liability
            </Title>
            <Paragraph>
              EXCEPT FOR YOUR MISAPPROPRIATION OR VIOLATION OF JIT TECHLABS
              INTELLECTUAL PROPERTY RIGHTS AND BREACH OF YOUR CONFIDENTIALITY
              OBLIGATIONS, IN NO EVENT SHALL EITHER PARTY BE LIABLE FOR ANY
              INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL OR
              CONSEQUENTIAL DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF
              BUSINESS, GOODWILL, USE OR OTHER ECONOMIC ADVANTAGE, BUSINESS
              INTERRUPTION, OR ANY ALTERATION, COMPROMISE, CORRUPTION OR LOSS OF
              CUSTOMER DATA) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH OUR
              SERVICES, THE DOCUMENTATION OR USE THEREOF OR THESE TERMS, WHETHER
              BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, EVEN IF ADVISED
              OF THE POSSIBILITY OF SUCH DAMAGES. IF, NOTWITHSTANDING THIS
              LIABILITY LIMITATION, SHOULD WE HAVE ANY LIABILITY TO YOU OR ANY
              THIRD PARTY FOR ANY DAMAGE, AND EXCEPT FOR YOUR MISAPPROPRIATION
              OF INTELLECTUAL PROPERTY AND BREACH OF YOUR CONFIDENTIALITY
              OBLIGATIONS, EACH PARTY&apos;S AGGREGATE LIABILITY UNDER THESE
              TERMS SHALL BE LIMITED TO THE FEES PAID AND PAYABLE BY YOU FOR OUR
              SERVICES FOR THE SIX (6) MONTHS IMMEDIATELY PRIOR TO THE EVENT
              GIVING RISE TO THE CLAIM FOR SUCH DAMAGE. FOR CLARITY, THE ABOVE
              LIMITATIONS SHALL NOT LIMIT YOUR PAYMENT OBLIGATIONS TO US FOR THE
              SERVICES. NO CLAIM MAY BE PURSUED BY YOU MORE THAN ONE (1) YEAR
              AFTER THE FACTS GIVING RISE TO SUCH CLAIM HAVE ARISEN. THIS
              LIABILITY LIMITATION FORMS AN ESSENTIAL BASIS OF THE BARGAIN
              BETWEEN THE PARTIES AND SHALL SURVIVE AND APPLY EVEN IF ANY REMEDY
              SPECIFIED IN THESE TERMS DOES NOT FULLY COMPENSATE YOU FOR ANY
              LOSSES OR IS FOUND TO HAVE FAILED ITS ESSENTIAL PURPOSE.
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              14. Indemnification
            </Title>
            <Paragraph>
              <Paragraph>
                <b>14.1 </b>
                If We reasonably believe that Your use of the Services is likely
                to be enjoined, or if the Services are held to infringe a valid
                United States patent, copyright or trademark and all use of such
                Services by You is thereby enjoined, We shall, at Our expense
                and sole option, (i) procure for You the right to continue using
                the Services as set forth hereunder, (ii) replace the Services
                with other non-infringing services or software of substantially
                equivalent functionality or (iii) modify the Services so that
                there is no infringement, provided that such modified services
                or software provides substantially equivalent functionality. If,
                in Our reasonable opinion, the remedies above are infeasible or
                commercially impracticable, We may, at Our sole discretion,
                terminate Your Subscription(s).
              </Paragraph>
              <Paragraph>
                <b>14.2 </b>
                Subject to Your compliance with these Terms, We, if notified
                promptly in writing and given authority, control, information
                and assistance at Our expense for defense and settlement of
                same, shall defend You against any third party action, suit or
                proceeding brought against You so far as it is based on a claim
                that the use of the Services infringes a United States patent,
                copyright or trademark, and shall indemnify You and hold You
                harmless for (i) all attorneys&apos; fees, and court or tribunal
                costs incurred by Us with respect to defense and settlement of
                such third party claim, (ii) any judgments, fines, costs and
                penalties awarded by any court or tribunal against Us and/or Our
                customers, including You, for such third party claim and (iii)
                any amounts paid in settlement of such third party claim as
                mutually agreed by Us and the third party in such claim. We will
                have no liability or obligation with respect to any third party
                claim if such claim is caused in whole or in part by (i)
                modification of the Services by anyone other than Us; (ii) the
                combination, operation or use of the Services with any other
                software, hardware, application or process where the Services
                would not by themselves be infringing; (iii) compliance with
                designs, data, materials, instructions or specifications
                provided by You; (iv) use of the Services by You and/or Users in
                violation of these Terms; or (v) any ‘Other Services&apos;
                and/or ‘Third-Party Content&apos;.
              </Paragraph>
              <Paragraph>
                <b>14.3 </b>
                SECTIONS 14.1 AND 14.2 (Indemnification) SET FORTH YOUR SOLE AND
                EXCLUSIVE REMEDY AND OUR SOLE, EXCLUSIVE AND ENTIRE OBLIGATION
                AND LIABILITY WITH RESPECT TO ANY CLAIM OF INFRINGEMENT,
                MISAPPROPRIATION OR VIOLATION OF INTELLECTUAL PROPERTY RIGHTS.
                You agree that You shall, in no event, settle any matter without
                Our prior written approval.
              </Paragraph>
              <Paragraph>
                <b>14.4 </b>
                Indemnification by You. You will indemnify and hold Us and/or
                the other Group Companies harmless against any claim brought by
                a third party against Us and/or the other Group Companies, and
                Our respective employees, officers, directors and agents arising
                from or related to use of the Services by You in breach of these
                Terms or matters which You have expressly agreed to be
                responsible pursuant to these Terms; provided that (a) We notify
                You of the threat or notice of such claim; (b) You have or will
                have the sole and exclusive control and authority to select
                defense attorneys, defend and/or settle any such claim; and (c)
                We reasonably cooperate with You at Your expense in connection
                therewith. Notwithstanding the foregoing, We may participate in
                the defense of any matter with counsel of Our own choosing at
                Our cost and expense, and You will not settle any matter without
                Our prior written consent, unless the settlement fully and
                unconditionally releases Us and/or the other Group Companies and
                does not require Us and/or the other Group Companies to pay any
                amount, take any action or admit any liability.
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              15. Survival
            </Title>
            <Paragraph>
              <b>15.1 </b>
              Sections 3 (Your Responsibilities), 5 (Intellectual Property
              Rights), 7 (Billing and Payments), 8 (Term, Termination and
              Suspension), 9 (Confidentiality), 10 (Data Security and Privacy),
              12 (Disclaimer of Warranties), 13 (Limitation of Liability), 14
              (Indemnification), 15 (Survival), 17 (General) of these Terms and
              such other provisions that by their nature are intended to survive
              termination, shall survive any expiration or termination of these
              Terms. Termination of these Terms shall not limit either
              Party&apos;s liability for obligations accrued as of or prior to
              such termination or for any breach of these Terms.
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              16. Communication From Us
            </Title>

            <Paragraph>
              We may communicate with You directly via e-mail at the e-mail
              address associated with Your Account, or through the Services
              (such as, through pop-ups), or Our blog or Our Website or over
              phone, including,
            </Paragraph>

            <Paragraph style={{ paddingLeft: '20px' }}>
              <b>i. </b>
              regarding Your Subscription, system updates or other matters
              related to Your Account;
              <br />
              <b>ii. </b>
              if You and/or Users are in violation of these Terms;
              <br />
              <b>iii. </b>
              if a specific activity or purpose is prohibited with respect to
              the Services, so that You immediately cease use of the Services
              for such prohibited activity or purpose; or
              <br />
              <b>iv. </b>
              if Your or Users&apos; and/or End-Customers&apos; activities on
              the Service causes excessive stress on the Services and associated
              infrastructure.
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }}>
            <Title level={2} style={{ color: '#4533bf' }}>
              17. General
            </Title>
            <Paragraph>
              <Paragraph>
                <b>17.1 Parties. </b>
                The Parties are independent contractors. Nothing in these Terms
                shall be deemed to constitute a partnership, joint venture,
                employment or franchise between the Parties, nor constitute any
                Party as the agent of the other Party for any purpose or entitle
                any Party to commit or bind the other Party in any manner.
              </Paragraph>
              <Paragraph>
                <b>17.2 Entire Agreement and Revisions. </b>
                These Terms including any exhibits and Pricing Agreements,
                constitute the entire agreement, and supersede all prior or
                contemporaneous, written or oral understandings, communications
                or agreements between the Parties regarding such subject matter,
                including any click-wrap terms. Any use of Your pre-printed
                forms, such as purchase orders, are for convenience only, and
                any pre-printed terms and conditions set forth in Your printed
                forms that are in addition to, inconsistent or in conflict with,
                or different than, these Terms shall be null and void. Neither
                commencement of performance, nor failure to object to any
                additional or different terms and conditions from You, nor
                provision of Services, by, in each case, Us or Our other Group
                Companies shall constitute an acceptance of any terms and
                conditions proposed by You that are in addition to, inconsistent
                or in conflict with, or different than, these Terms. In the
                event of a conflict between any Pricing Agreement and these
                Terms, the Pricing Agreement shall prevail to the extent it is
                intended to. We may amend these Terms from time to time by
                posting the most current version on Our Website, in which case
                the new Terms will supersede prior versions. Please check these
                Terms periodically to take notice of changes as they will be
                binding on You. If an amendment materially affects Your rights,
                We will notify You (by, for example, sending a message to the
                e-mail address associated with Your Account, or posting on Our
                blog or on the Website or as a notification inside the
                Services). Your continued use of the Services following the
                effective date of any such amendment may be relied upon by Us as
                Your acceptance of any such amendment. If You do not agree to an
                amendment, You may terminate Your use of the Services or request
                Us to terminate the provision of Our Services to You.
              </Paragraph>
              <Paragraph>
                <b>17.3 Severability and Waiver. </b>
                If any provision of these Terms is held to be unenforceable by a
                court of competent jurisdiction, the unenforceable provision
                shall be replaced by the court with an enforceable provision
                that comes closest to the Parties&apos; intent underlying the
                unenforceable provision, and the remaining provisions of these
                Terms shall remain in full force and effect. The
                unenforceability of any provision in any jurisdiction shall not
                affect the enforceability of such provision in any other
                jurisdiction. Our non-exercise of any right herein does not
                constitute a waiver of that right or provision of these Terms.
                Any term of these Terms may be waived only by a separate written
                agreement signed by Our authorized representative.
              </Paragraph>
              <Paragraph>
                <b>17.4 Assignment. </b>
                We may assign or transfer all or any part of these Terms or Our
                rights under these Terms or delegate the performance of Our
                duties under these Terms in whole or in part to any of the other
                Group Companies, or in connection with any acquisition,
                consolidation, merger, reorganization, transfer all or
                substantially all of Our assets or other business combination,
                or by operation of law without Your consent and without
                providing notice. We may subcontract Our obligations hereunder,
                provided that We shall at all times remain responsible for the
                performance of the subcontractor. You may not assign or transfer
                any part of these Terms, or delegate Your duties or
                responsibilities under these Terms, by business combination,
                operation of law or otherwise without Our prior written consent.
                Subject to the foregoing, these Terms will bind and benefit the
                Parties and their respective successors and permitted assigns.
              </Paragraph>
              <Paragraph>
                <b>17.5 Third Party Beneficiaries. </b>
                Except as expressly provided in these Terms, nothing in these
                Terms, express or implied, is intended to confer upon any party
                (other than the Parties hereto, their respective successors and
                permitted assigns and the Sub-processors) any rights or
                obligations, to enforce these Terms.
              </Paragraph>
              <Paragraph>
                <b>17.6 Governing Law. </b>
                These Terms shall be governed by Indian laws. These Terms shall
                not be governed by the conflict of law rules of any jurisdiction
                or the United Nations Convention on Contracts for the
                International Sale of Goods, the application of which is
                expressly excluded. The respective courts of India shall have
                exclusive jurisdiction for any dispute between the Parties, and
                the Parties consent to venue and personal jurisdiction there.
                THE PARTIES HEREBY WAIVE ANY RIGHTS THEY MAY HAVE TO TRIAL BY
                JURY. YOU AGREE THAT ANY PROCEEDINGS, INCLUDING ANY ARBITRATION,
                WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A
                CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION. Each Party shall
                have the right, at its election, to seek injunctive or other
                equitable relief in any court of competent jurisdiction to
                enforce these Terms, which remedy will be cumulative and not
                exclusive. If any action is pursued to enforce or obtain
                compliance with these Terms, the prevailing Party shall be
                entitled to reasonable attorneys&apos; fees and costs, in
                addition to any other relief to which such Party may be
                entitled.
              </Paragraph>
              <Paragraph>
                <b>17.7 Force Majeure and Other Events. </b>
                We will make commercially reasonable efforts to keep the
                Services operational twenty-four (24) hours a day and seven (7)
                days a week, however, We shall not be liable for inadequacy of
                or irregularity in the Services caused by (i) any circumstance
                beyond Our reasonable control, including acts of God, acts of
                government, flood, fire, earthquakes, civil unrest, acts of
                terror, strikes or other labor problems, technical failures
                (including failures in telecommunications, internet, internet
                service provider or hosting facilities, power shortages) or acts
                undertaken by third parties, including without limitation,
                denial of service attacks; (ii) sub optimal functionality,
                availability of GST Network and/or ‘Other Services&apos;; (iii)
                Your, Users&apos; or Suppliers use of the Services in an
                unauthorized, improper or unlawful manner; or for any misuse or
                modification or damage of the Services caused by You, Users or
                Suppliers; or any breach of these Terms by You or Users; or (iv)
                planned downtime for maintenance, upgrades and updates for which
                We will use commercially reasonable efforts to provide at least
                forty-eight (48) hours prior notice (collectively, the
                &quot;Force Majeure and Other Events&quot;). Despite Our
                efforts, the Services or any functionality may from time to time
                encounter technical or other problems and may not continue to be
                uninterrupted. We are not responsible for any such problems,
                interruption, any ongoing obligation to offer Our Services or
                any functionality, or any damages resulting therefrom.
              </Paragraph>
              <Paragraph>
                <b>17.9 Notices. </b>
                All notices to be provided by Us to You under these Terms may be
                delivered in writing by an internationally recognized overnight
                courier or by registered post of India Post to the mailing
                address provided by You while subscribing to the Services, or by
                electronic mail to the e-mail address provided by You in
                connection with Your Subscription. You may opt out of receiving
                e-mails from Us by clicking on the &quot;unsubscribe&quot; or
                &quot;opt-out&quot; link in the e-mails, and in the absence of
                such links, by reaching out to Us at support@jitfin.co or by
                terminating Your Subscription. The contact information for a
                notice to Us by courier or by Registered Post is JiT Techlabs
                Pvt. Ltd., 3266, 11th Main, Appareddypalya, Near ESI Hospital,
                Indiranagar, Bangalore – 560038 with a CC to support@jitfin.co
                and praveen@jitfin.co. All notices shall be deemed delivered
                upon receipt.
              </Paragraph>
            </Paragraph>
          </div>

          <div style={{ margin: '30px auto' }} ref={refForPrivacyPolicy}>
            <Title level={2} style={{ color: '#4533bf' }}>
              18. Privacy Policy
            </Title>
            <Paragraph>
              <Paragraph>
                <b>Information we collect:</b>
                <Paragraph style={{ marginTop: '6px' }}>
                  For those users who provide requisite authorization during the
                  account setup and login process, we collect information from
                  enquiry forms, contacts forms, Google GSuite or Microsoft
                  Office 365, and others, as applicable to provide better
                  service to all users. This includes first name, last name,
                  email address, phone numbers, business name and GST Numbers.
                  Our legitimate interest is to optimize our product, increase
                  user experience, enhance our customer support, and improve our
                  internal process efficiency.
                </Paragraph>
              </Paragraph>

              <Paragraph>
                <b>Information we collect as customers use our service.</b>
                <Paragraph style={{ marginTop: '6px' }}>
                  KYSS may collect specific information about how customers use
                  our product. This include situations such as how a customer
                  uses a specific feature and when a customer uses the product.
                  The information is collected as data logs, images, or a
                  logical sequence of images/videos to reply an issue
                  encountered by a user. Our logging system automatically
                  collects information such as the internet protocol address,
                  browser type, browser language, referring URL, features
                  accessed, errors generated, time zone, geo-location data,
                  operating system information, and other such information that
                  is transmitted in the header of the user’s HTTP request. This
                  information is stored in log files. JiT Finco uses these log
                  files to analyze trends, administer, and improve the
                  application. Our legitimate interest is to optimize our
                  product, increase user experience, enhance our customer
                  support, and improve our internal process efficiency.
                </Paragraph>
              </Paragraph>

              <Paragraph>
                <b>Cookies</b>
                <Paragraph style={{ marginTop: '6px' }}>
                  We use cookies on all our websites and software applications.
                  Cookies are small text files that your browser saves on your
                  computer and mobile device, which allow an analysis of your
                  usage. Cookies contain information about your device and your
                  connection to the Internet, such as: IP address, operating
                  system and browser used. We use our access to the information
                  in the cookies to optimize our offering, to present
                  appropriate content to customers easily, and to improve our
                  advertising and marketing. This is done as follows: Most of
                  the cookies we use are so-called session cookies, which are
                  automatically deleted by your browser after the end of your
                  visit to our website. Session cookies provide us with
                  information about visitor behavior on our website, which we
                  analyze. With the knowledge gained, we will make our website
                  more user-friendly, place important content on the most
                  popular pages and develop our marketing strategies according
                  to the interests of our target audience. Other cookies are
                  stored longer on your device and allow us to recognize you as
                  a user during a subsequent visit to our website. We do not
                  pass on information that we receive through our cookies to
                  third parties. By using the “Incognito” or “Private” mode of
                  your browser, or by means of adjusting the settings in your
                  browser, you can prevent the installation of cookies. As a
                  result, some of the facilitation of operating our website will
                  no longer work. Nevertheless, you can use the website, login
                  and avail all offers of KYSS.ai even without cookies.
                </Paragraph>
              </Paragraph>

              <Paragraph>
                <b>How we use the Information we collect</b>
                <Paragraph style={{ marginTop: '6px' }}>
                  <Paragraph>
                    JiT Finco uses the above information for the following
                    general purposes: service provisioning, billing,
                    identification and authorization, service improvement, and
                    research. When a customer contacts JiT Finco, we may keep a
                    record of customer communication to help solve any issues
                    the customer might be facing.
                  </Paragraph>

                  <Paragraph>
                    If you contact us by email or contact form, the information
                    you provide will be stored for the purpose of processing the
                    request and for possible follow-up questions. Please email
                    us at support@jitfin.co to assert your data protection
                    rights. Please read the section entitled “Your rights to
                    access, rectification, cancellation, limitation of
                    processing, data portability and to withdraw consent”.
                  </Paragraph>

                  <Paragraph style={{ paddingLeft: '20px' }}>
                    <Paragraph>
                      <b>1. </b>
                      Service Providers. We may provide access to or share your
                      information with select third parties who perform services
                      on our behalf. These third parties provide a variety of
                      services to us, including without limitation billing,
                      sales, marketing, provision of content and features,
                      advertising, analytics, research, customer service,
                      shipping and fulfillment, data storage, security, fraud
                      prevention, payment processing, and legal services.
                    </Paragraph>
                    <Paragraph>
                      <b>2. </b>
                      Complying with our obligations: we may process your
                      personal data to comply with our legal or regulatory
                      requirements, where this is required by law.
                    </Paragraph>
                    <Paragraph>
                      <b>3. </b>
                      To continue using the Services after the free trial
                      period, you must begin a paid subscription which requires
                      a valid credit card. Enterprise customers may request a
                      paper contract that includes alternative billing
                      arrangements including purchase orders.
                    </Paragraph>
                  </Paragraph>
                </Paragraph>
              </Paragraph>

              <Paragraph>
                <b>How Secure is your personal data and information with us?</b>
                <Paragraph style={{ marginTop: '6px' }}>
                  <Paragraph>
                    We work hard to protect KYSS.ai and our users from
                    unauthorized access or unauthorized alteration, disclosure,
                    or destruction of information we hold. In particular:
                  </Paragraph>
                  <Paragraph style={{ paddingLeft: '20px' }}>
                    <b>1. </b>
                    We encrypt our services using SSL.
                    <br />
                    <b>2. </b>
                    We review our information collection, storage and processing
                    practices, including physical security measures, to guard
                    against unauthorized access to systems.
                    <br />
                    <b>3. </b>
                    We restrict access to personal information to JiT Finco
                    employees, contractors and agents who need to know that
                    information in order to process it for us, and who are
                    subject to strict contractual confidentiality obligations
                    and may be disciplined or terminated if they fail to meet
                    these obligations.
                  </Paragraph>
                  <Paragraph>
                    We have housed KYSS.ai at a ISO 27001 Certified data centre.
                    When we receive formal written complaints, we will contact
                    the person who made the complaint to follow up. We work with
                    the appropriate regulatory authorities, including local data
                    protection authorities, to resolve any complaints regarding
                    the transfer of personal data that we cannot resolve with
                    our users directly.
                  </Paragraph>
                </Paragraph>
              </Paragraph>

              <Paragraph>
                <b>Retention, deletion or blocking of data</b>
                <Paragraph style={{ marginTop: '6px' }}>
                  We adhere to the principles of purpose limitation and data
                  minimisation. Therefore, we only store your personal data for
                  as long as it is necessary to achieve the purposes mentioned
                  here or as long as the many storage periods required by law.
                  After discontinuation of the respective purpose or expiry of
                  these deadlines, the corresponding data will be blocked or
                  deleted in accordance with the statutory provisions.
                </Paragraph>
              </Paragraph>

              <Paragraph>
                <b>
                  Your rights of access, rectification, cancellation, limitation
                  of processing, data portability and to withdraw consent
                </b>
                <Paragraph style={{ marginTop: '6px' }}>
                  We will provide access to your personal data stored by us at
                  any time. If you wish, you will receive it in a structured,
                  commonly-used and machine-readable format. We will be happy to
                  correct, edit, limit, or delete your personal data at your
                  request, provided that no statutory storage requirements are
                  in conflict, or forward them to another responsible office. If
                  your personal data stored with us has been processed
                  incorrectly, out-of-date, or unlawfully, you can restrict its
                  processing by us. Finally, you may change or revoke your
                  consent to the processing of your personal information at any
                  time with future effect. To contact us for any of these
                  concerns, please email us at support@jitfin.co. Our legal
                  basis for the disclosure, correction, deletion, limitation of
                  processing and data transmission is the respective request of
                  the data subject.
                </Paragraph>
                <Paragraph>
                  You have the right to complain about the improper processing
                  of your personal data (such as collection, storage,
                  modification, transfer, deletion, etc.) by emailing us at
                  support@jitfin.co
                </Paragraph>
              </Paragraph>

              <Paragraph>
                <b>Modification of this Privacy Policy</b>
                <Paragraph style={{ marginTop: '6px' }}>
                  We reserve the right to change this Privacy Policy from time
                  to time to ensure that it complies with current legal
                  requirements or to implement changes to our services in the
                  Privacy Policy, such as when introducing new services. Your
                  new visit will be subject to the new privacy policy.
                </Paragraph>
              </Paragraph>
            </Paragraph>
          </div>
   

    </div>
  );
};

export default TermsAndConditionsPage;
