import { IZohoContactDetails } from '../../typings/index';
import { AnalyticsActions } from '../actions/analytics';
import { IAction } from '../../models';
import * as Typings from '../../typings';

const defaultState = {
  tokenSet:{
    [Typings.EAnalyticsSources.ZOHO]: false
  },
  tokenReset:{
    [Typings.EAnalyticsSources.ZOHO]: false
  },
  loading: {
    [Typings.EAnalyticsSources.ZOHO]: false
  },
  data: {
    [Typings.EAnalyticsSources.ZOHO]: {} as IZohoContactDetails
  },
  utmSources: null
} as Typings.IAnalyticsState;

export default function AnalyticsReducer(
  analyticsState: Typings.IAnalyticsState = defaultState,
  action: IAction
): Typings.IAnalyticsState {
  switch (action.type) {
    case AnalyticsActions.ZOHO_PAGESENSE_SET_TOKEN_BEGIN: {
      return {
        ...analyticsState,
        loading: {
          ...analyticsState.loading, 
          [Typings.EAnalyticsSources.ZOHO]: true
        },
      };
    }
    case AnalyticsActions.ZOHO_PAGESENSE_SET_TOKEN_SUCCESS: {
      return {
        ...analyticsState,
        loading: {
          ...analyticsState.loading, 
          [Typings.EAnalyticsSources.ZOHO]: false
        },
        tokenSet: {
          ...analyticsState.tokenSet, 
          [Typings.EAnalyticsSources.ZOHO]: true
        },
      };
    }
    case AnalyticsActions.ZOHO_PAGESENSE_SET_TOKEN_FAILURE: {
      return {
        ...analyticsState,
        loading: {
          ...analyticsState.loading, 
          [Typings.EAnalyticsSources.ZOHO]: false
        },
        tokenSet: {
          ...analyticsState.tokenSet, 
          [Typings.EAnalyticsSources.ZOHO]: false
        },
      };
    }
    case AnalyticsActions.ZOHO_CRM_CONTACT_BEGIN: {
      return {
        ...analyticsState,
        loading: {
          ...analyticsState.loading, 
          [Typings.EAnalyticsSources.ZOHO]: true
        },
      };
    }
    case AnalyticsActions.ZOHO_CRM_CONTACT_SUCCESS: {
      return {
        ...analyticsState,
        loading: {
          ...analyticsState.loading, 
          [Typings.EAnalyticsSources.ZOHO]: false
        },
        tokenSet: {
          ...analyticsState.tokenSet, 
          [Typings.EAnalyticsSources.ZOHO]: true
        },
      };
    }
    case AnalyticsActions.ZOHO_CRM_CONTACT_FAILURE: {
      return {
        ...analyticsState,
        loading: {
          ...analyticsState.loading, 
          [Typings.EAnalyticsSources.ZOHO]: false
        },
        tokenSet: {
          ...analyticsState.tokenSet, 
          [Typings.EAnalyticsSources.ZOHO]: false
        },
      };
    }
    case AnalyticsActions.ZOHO_CRM_SAVE_UTM_BEGIN:{
      return{
        ...analyticsState,

      };
    }
    default: {
      return analyticsState;
    }
  }
}
