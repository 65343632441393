import { IAction, IDataSourceState, IProspect } from '../../models';
import { DataSourceActions } from '../actions/dataSourceActions';
import {
  IResponseDocumentUploadData,
  IResponseFieldsForTemplate,
  IResponseProspectLatestFilingInfo,
  IResponseProspectsOfDataSource,
  IResponseItemsOfUser,
  IResponseUpdateProspect,
  IWhatsappResponse,
  IWatchlistEntries,
} from '../../models/api/dataSourceModels';

const defaultState = {} as IDataSourceState;

export default function dataSourceReducer(
  dataSourceState = { ...defaultState, whatsappMessageQueue: [{}], deleteWatchlistEntriesFlag:false, deleteSupplierFlag:false, ItemFlag:false, deleteInvalidListFlag:false,addTransporterFlag:false },
  action: IAction
) {
  switch (action.type) {
    case DataSourceActions.DATA_SOURCE_RESET_DATA: {
      return {
      };
    }
    case DataSourceActions.REQUEST_PROSPECTS_OF_DATA_SOURCE_SUCCESS: {
      const payload: IResponseProspectsOfDataSource = action.body;
      return {
        ...dataSourceState,
        prospectsData: {
          ...dataSourceState.prospectsData,
          count: payload?.count,
          prospects: payload?.prospects,
        },
      };
    }
    case DataSourceActions.GET_ITEM_SUCCESS: {
      const payload: IResponseItemsOfUser = action.body;
      return {
        ...dataSourceState,
        itemsData: {
          ...dataSourceState.itemsData,
          count: payload?.count,
          items: payload?.items,
        },
      };
    }
    case DataSourceActions.UPDATE_PROSPECT_SUCCESS: {
      const payload: IResponseUpdateProspect = action.body;
      const prospectsList: IProspect[] =
        dataSourceState.prospectsData.prospects;
      const prospectToReplaceIndex: number = prospectsList.findIndex(
        (r) => r.id === payload.id
      );
      prospectsList.splice(prospectToReplaceIndex, 1, payload);
      return {
        ...dataSourceState,
        prospectsData: {
          ...dataSourceState.prospectsData,
          prospects: [...prospectsList],
        },
      };
    }
    case DataSourceActions.DELETE_PROSPECT: {
      const payload = action.body;
      // eslint-disable-next-line no-console

      return { ...dataSourceState };
    }
    case DataSourceActions.DELETE_PROSPECT_SUCCESS: {
      const payload = action.body;
      return { ...dataSourceState };
    }

    case DataSourceActions.REQUEST_GST_FILING_STATS_FOR_FILE_SUCCESS: {
      const payload: IResponseProspectLatestFilingInfo = action.body;
      return {
        ...dataSourceState,
        latestFileGstFilingInfo: {
          prospectCount: payload.prospectCount,
          validProspectCount: payload.validProspectCount,
          prospectCountFiledGST1: payload.prospectCountFiledGST1,
          prospectCountFiledGSTR3B: payload.prospectCountFiledGSTR3B,
          filedGSTR3BAsOf: payload.filedGSTR3BAsOf,
          filedGSTR1AsOf: payload.filedGSTR1AsOf,
        },
      };
    }
    case DataSourceActions.REQUEST_FIELDS_FOR_DATA_SOURCE_SUCCESS: {
      const payload: IResponseFieldsForTemplate = action.body;
      return {
        ...dataSourceState,
        fieldsOfDataSource: payload.fields,
      };
    }
    case DataSourceActions.REQUEST_LATEST_FILE_UPLOAD_DATA_SUCCESS: {
      const payload: IResponseDocumentUploadData = action.body;
      return {
        ...dataSourceState,
        latestFileUploadStatus: payload.latestFileUploadInfo,
      };
    }
    case DataSourceActions.INITIATE_WHATSAPP_MESSAGE_RESULT: {
      const payload: IWhatsappResponse = action.body;
      return {
        ...dataSourceState,
        whatsappMessageQueue: {
          ...dataSourceState.whatsappMessageQueue,
          [payload.tradeName]: payload.isMessageSent,
        },
      };
    }
    case DataSourceActions.GSTIN_FILTER_STATUS_SUCCESS: {
      return {
        ...dataSourceState,
        uniqueStatusArray: action.body,
        // whatsappMessageQueue: {
        //   ...dataSourceState.whatsappMessageQueue,
        //   [payload.tradeName]: payload.isMessageSent
        // }
      };
    }
    case DataSourceActions.GET_WATCHLIST_ENTRIES_SUCCESS: {
      const payload: IWatchlistEntries = action.body;

      return {
        ...dataSourceState,
        watchlistEntries: payload.results,
      };
    }
    // case DataSourceActions.GET_WATCHLIST_ENTRIES_SUCCESS: {
    //   const payload: IWatchlistEntries = action.body;

    //   return {
    //     ...dataSourceState,
    //     watchlistEntries: payload.results,
    //   };
    // }
    case DataSourceActions.DELETE_WATCHLIST_ENTRIES_SUCCESS: {
      const payload = action.body;
      // eslint-disable-next-line no-console
      return { ...dataSourceState,
        deleteWatchlistEntriesFlag: !dataSourceState.deleteWatchlistEntriesFlag};
    }
    case DataSourceActions.GSTIN_WATCHLIST_FILTER_STATUS_SUCCESS: {
      const payload = action.body
      return {
        ...dataSourceState,
        watchlistUniqueStatusArray: payload,
      };
    }
    case DataSourceActions.DELETE_SUPPLIER: {
      const payload = action.body;
      // eslint-disable-next-line no-console
      return { ...dataSourceState };
    }
    case DataSourceActions.DELETE_SUPPLIER_SUCCESS: {
      const payload = action.body;
      // eslint-disable-next-line no-console
      return { ...dataSourceState,
        deleteSupplierFlag: !dataSourceState.deleteSupplierFlag};
    }
    case DataSourceActions.DELETE_ITEM_SUCCESS: {
      const payload = action.body;
      return { ...dataSourceState,
        ItemFlag: !dataSourceState.ItemFlag};
    }
    case DataSourceActions.ADD_ITEM_SUCCESS: {
      const payload = action.body;
      return { ...dataSourceState,
        ItemFlag: !dataSourceState.ItemFlag};
    }
    case DataSourceActions.UPDATE_ITEM_SUCCESS: {
      const payload = action.body;
      return { ...dataSourceState,
        ItemFlag: !dataSourceState.ItemFlag};
    }

    case DataSourceActions.GET_INVALID_LIST_SUCCESS: {
      const payload = action.body
      return {
        ...dataSourceState,
        invalidlistEntries: [...payload.results],
      };

      
    }
    case DataSourceActions.DELETE_INVALID_LIST_SUCCESS: {
      const payload = action.body;
      return { ...dataSourceState,
        deleteInvalidListFlag: !dataSourceState.deleteInvalidListFlag};
    }
    case DataSourceActions.GET_TRANSPORTER: {
      const payload = action.body;
      return { ...dataSourceState};
    }
    case DataSourceActions.GET_TRANSPORTER_SUCCESS: {
      const payload = action.body;
      return { ...dataSourceState,
        transporterList:payload.results,
      totalTransporterCount: payload.count};
    }
    // case DataSourceActions.EXPORT_INVALIDLIST_CSV: {
    //   const payload = action.body;
    //   return { ...dataSourceState };
    // }
    // case DataSourceActions.EXPORT_INVALIDLIST_CSV_SUCCESS: {
    //   const payload = action.body;
    //   return { ...dataSourceState,
    //     exportInvalidListCsv:payload };
    // }
    case DataSourceActions.ADD_TRANSPORTER: {
      const payload = action.body;
      return { ...dataSourceState};
    }
    case DataSourceActions.ADD_TRANSPORTER_SUCCESS: {
      const payload = action.body;
      return { ...dataSourceState,
        addTransporterFlag:!dataSourceState.addTransporterFlag};
    }
    case DataSourceActions.DELETE_TRANSPORTER: {
      const payload = action.body;
      return { ...dataSourceState};
    }
    case DataSourceActions.DELETE_TRANSPORTER_SUCCESS: {
      const payload = action.body;
      return { ...dataSourceState,
        addTransporterFlag:!dataSourceState.addTransporterFlag};
    }
    case DataSourceActions.UPDATE_TRANSPORTER: {
      const payload = action.body;
      return { ...dataSourceState};
    }
    case DataSourceActions.UPDATE_TRANSPORTER_SUCCESS: {
      const payload = action.body;
      return { ...dataSourceState,
        addTransporterFlag:!dataSourceState.addTransporterFlag};
    }
    default: {
      return {
        ...dataSourceState,
      };
    }
  }
}
