/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { Col, Row } from 'antd/lib/grid';
import { useDispatch, useSelector } from 'react-redux';
import { Spin,Modal} from 'antd';
import Layout from 'antd/es/layout';
// import { RiArrowDropLeftLine, RiArrowDropRightLine } from 'react-icons/ri';
import LoginPage from './pages/loginPage';
import VerifyOtpPage from './pages/verifyOtpPage';
import GSTINPage from './pages/GSTINPage';
import { ROUTES } from './constants/routePaths';
import Dashboard from './pages/dashboard';
import ManageSuppliersPage from './pages/manageSuppliersPage';
import CompanyProfilePage from './pages/companyProfilePage';
import ManageSuppliers from './pages/manageSuppliersNew';
import { IState } from './models';
import { globalApiService } from './services/apiService';
import ManageColleaguesPage from './pages/manageColleaguesPage';
import TopBar from './components/topBar';
import SignUpPage from './pages/signUpPage';
import GetAnchorDetailsPage from './pages/getAnchorDetailsPage';
import ProfilePage from './pages/profilePage';
import { requestAnchorInfo, setDefault } from './store/actions/authActions';
import { IRequestAnchorInfo } from './models/api/authModels';
import TermsAndConditionsPage from './pages/termsAndConditionsPage';
import UploadSuppliersToDataSourcePage from './pages/uploadSuppliersToDataSourcePage';
import DownloadCenterPage from './pages/downloadCenterPage';
import SideBar from './components/navBar';
import './styles/base.css';
import ReconcilationPage from './pages/reconcilationPage';
import ReconSummary from './pages/reconSummary';
import ReconcileReportPage from './pages/ReconcileReportPage';
import ReportCenter from './pages/reportsV2/ReportCenter';
import GenerateSupplierReports from './pages/reportsV2/GenerateSupplierReports';
import SupplierReports from './pages/reportsV2/SupplierReports';
import DownloadCenterPageV2ReportView from './pages/reportsV2/SupplierReportView';
import SignupSuccessPage from './pages/signupSuccessPage';
import ZohoEndPoint from './pages/ZohoEndPoint';
import WhatsappChatbot from './components/WhatsappChatbot';
import GSTINSearch from './pages/GSTINSearch';
import GSTINDetails from './pages/GSTINDetails';
import EInvoicing from './pages/eInvoicing';
import NewInvoice from './pages/newInvoice';
import EWayBill from './pages/eWayBill';
import InvoicePreview from './pages/invoicePreview';
import EWayBillPreview from './pages/eWayBillPreview';
// import AppConfig from './config';
// import { EAnalyticsSources, IAnalyticsZohoAccesToken } from './typings';
// import { ZohoAnalyticsSetUserTokenBegin } from './store/actions/analytics';

// const timeout = AppConfig.zohoTimeout;
// const zohoToken = AppConfig.selfClient;
import ManageCustomers from './pages/manageCustomers';
import AddCustomer from './pages/addCustomer';
import CustomerDetails from './pages/customerDetails';
import Items from './pages/items';
import AddItem from './pages/addItem';
import Transporters from './pages/transporters';
import StylizedButton from './components/stylizedButton';
const { Sider, Content, Footer } = Layout;


function GetNonAuthBundle() {
  // const storeDispatch = useDispatch();
  // const { tokenSet, tokenReset } = useSelector((state: IState) => state.analyticsReducer);
  // useEffect(() => {
  //   if (!tokenSet[EAnalyticsSources.ZOHO] || tokenReset[EAnalyticsSources.ZOHO]) {
  //     storeDispatch(ZohoAnalyticsSetUserTokenBegin({ zohoToken } as IAnalyticsZohoAccesToken));
  //   }
  // }, [storeDispatch, tokenSet, tokenReset]);
  

  return (
    <Router>
      <Row>
        <Col flex="auto">
          <Switch>
            <Route path={ROUTES.LOGIN} component={LoginPage} />
            {/* <Route path={ROUTES.LOGIN} component={LoginPage} /> */}
            <Route
              path={ROUTES.SIGN_UP_SUCCESS}
              component={SignupSuccessPage}
            />
            {/* <Route path={ROUTES.SIGN_UP} component={SignUpPage} /> */}
            <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route path={ROUTES.VERIFY_OTP} component={VerifyOtpPage} />
            {/* <Route path={ROUTES.TRANSPORTERS} component={Transporters} /> */}
            {/* <Route path={ROUTES.ADD_CUSTOMER} component={AddCustomer} /> */}
            <Route path={ROUTES.GET_USER_GSTIN} component={GSTINPage} />
            {/* <Route path={ROUTES.MANAGE_CUSTOMERS} component={ManageCustomers} /> */}
            {/* <Route exact path={ROUTES.CUSTOMER_DETAILS} component={CustomerDetails} /> */}
            {/* <Route path={ROUTES.MANAGE_SUPPLIERS_NEW} component={ManageSuppliers} /> */}
            {/* <Route path={ROUTES.GSTIN_DETAILS} component={GSTINDetails} /> */}
            <Route path={ROUTES.ZOHO_CRM} component={ZohoEndPoint} />
            {/* <Route path={ROUTES.COMPANY_PROFILE} component={CompanyProfilePage} /> */}
            {/* <Route path={ROUTES.GSTIN_SEARCH} exact component={GSTINSearch} /> */}
            <Route
              path={ROUTES.TERMS_AND_CONDITIONS}
              component={TermsAndConditionsPage}
            />
            <Route
              path={ROUTES.GET_ANCHOR_DETAILS}
              component={GetAnchorDetailsPage}
            />
            {/*<Route*/}
            {/*  path={ROUTES.INVOICE_PREVIEW}*/}
            {/*  component={InvoicePreview}*/}
            {/*/>*/}
            {/* <Route*/}
            {/*  path={ROUTES.EWAYBILL_PREVIEW}*/}
            {/*  component={EWayBillPreview}*/}
            {/*/>*/}
            {/*<Route path={ROUTES.NEW_INVOICE} component={NewInvoice} />*/}
            {/*/!*<Route path={ROUTES.E_WAY_BILL} component={EWayBill} />*!/*/}
            {/*<Route path={ROUTES.E_INVOICE} component={EInvoicing} />*/}

            <Route path="/" component={GetAuthBundle} />
            <Redirect from="/" to={ROUTES.DASHBOARD} />
          </Switch>
        </Col>
      </Row>
      
    </Router>
  );
}

function GetAuthBundle() {
  const authState = useSelector((state: IState) => state.authReducer);

  const storeDispatch = useDispatch();
  const { gstin, anchorId, token } = authState;
  const location = useLocation();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [triggerForPrivacyHeadingScroll, setTriggerForPrivacyHeadingScroll] = React.useState(false);
  useEffect(() => {
    storeDispatch(setDefault());
  }, []);

  if (typeof token === 'string') {
    globalApiService.setUserToken(token || '');
  }

  useEffect(() => {
    if (gstin !== undefined && !anchorId) {
      storeDispatch(
        requestAnchorInfo({
          gstin,
        } as IRequestAnchorInfo)
      );
    }
  }, [gstin, anchorId, storeDispatch]);

  if (anchorId === null) {
    // Redirect to anchor creation page if anchor is not yet
    // created for that particular GSTIN
    return (
      <div>
        <Route path={ROUTES.PROFILE} exact component={ProfilePage} />
        <Redirect to={ROUTES.PROFILE} />
      </div>
    );
  }

  const toggleSidebarState = (isCollapsed: boolean) => {
    setCollapsed(!isCollapsed);
  };

  const expandSider = () => {
    setCollapsed(false);
  };

  const collapseSider = () => {
    setCollapsed(true);
  };


  const closeModal = () => {
    setIsModalOpen(false)
    setTriggerForPrivacyHeadingScroll(false)

  };
  const captureClickForInModalView =
  (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, trigger_scroll: boolean) => {
    e.preventDefault();
    setIsModalOpen(true)
    if(trigger_scroll){
      setTriggerForPrivacyHeadingScroll(true)
    }
  };
  return (
    <Layout>
      <Sider
        className="hide-scroll"
        breakpoint="lg"
        collapsedWidth="65px"
        width="270px"
        theme="light"
        collapsible
        collapsed={collapsed}
        trigger={null}
        role="presentation"
        onMouseOver={() => expandSider()}
        onMouseOut={() => collapseSider()}
        style={{
          height: '100vh',
          position: 'fixed',
          left: 0,
          zIndex: 2000,
          overflow:'hidden !important',
        }}
      >
        <SideBar pathName={location.pathname} propCollapse={collapsed} />
        <div
          className="sidebar--toggle-control hidden"
          onClick={() => toggleSidebarState(collapsed)}
          role="presentation"
        >
          <span>{collapsed ? '>' : '<'}</span>
        </div>
      </Sider>
      <Layout style={{ marginLeft: 60, paddingBottom: "4.5rem"}}>
        <TopBar />
        <Content
          className='mainbg'
          style={{
            padding: ' 20px 24px',
            overflowY: 'auto',
            minHeight: '655px',
          }}
        >
          <Switch>
            {
              typeof token === 'string' ? (
                <>
                  {/* <Route
                    path={ROUTES.MANAGE_SUPPLIERS}
                    exact
                    component={ManageSuppliersPage}
                  /> */}
                  <Route
                    path={ROUTES.MANAGE_COLLEAGUES}
                    exact
                    component={ManageColleaguesPage}
                  />
                  <Route
                    path={ROUTES.UPLOAD_SUPPLIERS_TO_DATA_SOURCE}
                    exact
                    component={UploadSuppliersToDataSourcePage}
                  />
                  <Route
                    path={ROUTES.DOWNLOAD_CENTER}
                    exact
                    component={DownloadCenterPage}
                  />
                  <Route
                    path={ROUTES.RECONCILATION}
                    exact
                    component={ReconcilationPage}
                  />
                  <Route
                    path={ROUTES.RECONCIL_SUMMARY}
                    exact
                    component={ReconSummary}
                  />
                  <Route
                    path={ROUTES.RECONCIL_REPORT}
                    exact
                    component={ReconcileReportPage}
                  />

                  <Route
                    path={ROUTES.DOWNLOAD_CENTER_V2}
                    exact
                    component={ReportCenter}
                  />


                  <Route exact path={ROUTES.COMPANY_PROFILE} component={CompanyProfilePage} />
                  <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
                  <Route exact path={ROUTES.ADD_CUSTOMER} component={AddCustomer} />
                  <Route exact path={ROUTES.EDIT_CUSTOMER} component={AddCustomer} />
                  <Route exact path={ROUTES.MANAGE_CUSTOMERS} component={ManageCustomers} />
                  <Route exact path={ROUTES.CUSTOMER_DETAILS} component={CustomerDetails} />
                  <Route exact path={ROUTES.MANAGE_SUPPLIERS_NEW} component={ManageSuppliers} />
                  <Route exact path={ROUTES.ITEMS} component={Items} />
                  <Route exact path={ROUTES.ADD_ITEM} component={AddItem} />
                  <Route exact path={ROUTES.EDIT_ITEM} component={AddItem} />
                  <Route path={ROUTES.TRANSPORTERS} component={Transporters} />
                  <Route
                    path={ROUTES.GSTIN_SEARCH}
                    exact
                    component={GSTINSearch}
                  />
                  <Route path={ROUTES.GSTIN_DETAILS} component={GSTINDetails} />
                  <Route
                    exact
                    path={ROUTES.INVOICE_PREVIEW}
                    component={InvoicePreview}
                  />
                  <Route
                    exact
                    path={ROUTES.EWAYBILL_PREVIEW}
                    component={EWayBillPreview}
                  />
                  <Route exact path={ROUTES.NEW_INVOICE} component={NewInvoice} />
                  <Route exact path={ROUTES.EDIT_INVOICE} component={NewInvoice} />
                  <Route exact path={ROUTES.EDIT_EWB} component={EWayBill} />
                  <Route exact path={ROUTES.E_WAY_BILL} component={EWayBill} />
                  <Route exact path={ROUTES.E_INVOICE} component={EInvoicing} />
                  {/* <Route exact path={ROUTES.DASHBOARD} component={Dashboard} /> */}
                </>
              ) : (
                <>
                  <Redirect to={ROUTES.SIGN_UP} />
                </>
              )
            }
          </Switch>
        </Content>
        <WhatsappChatbot />
        <Footer style={{ textAlign: 'center' }} className="footer">
          <span className="footer-text">A Product of </span>
          <a
            href="https://jitfin.co"
            title="Jit Finco"
            rel="noopener noreferrer"
            target="_blank"
          >
            JiT Finco
          </a>
          <p className='remove-margin'>
            <a 
              onClick={(e) =>captureClickForInModalView(e, false)}
              href={ROUTES.TERMS_AND_CONDITIONS}
            >
              Terms & Conditions
            </a>
            <span className='text'> & </span>
            <a 
                onClick={(e) => captureClickForInModalView(e, true)}
                href={ROUTES.TERMS_AND_CONDITIONS}
            >
              Privacy Policy
            </a></p>
        </Footer>
        <Modal
          className="terms_privacy_modal"
          title=""
          centered
          visible={isModalOpen}
          onOk={() => closeModal()}
          onCancel={() => closeModal()}
          footer={[
            <StylizedButton
                key='ok'
                textInButton="Ok"
                size="large"
                customStyles={{margin:"10px 0"}}
                onClickFn={() => closeModal()}
            />]}
          
            >
            <section>
            <TermsAndConditionsPage scrollToPrivacyPolicySection={triggerForPrivacyHeadingScroll}/>
            </section>
          </Modal>
      </Layout>

    </Layout>
  );
}

function LoadWithSpinner(props: {
  loadingIndicator: boolean;
  component: React.ReactNode;
}) {
  const { loadingIndicator, component } = props;
  return loadingIndicator ? <Spin>{component}</Spin> : <div>{component}</div>;
}

export default function App() {
  const authState = useSelector((state: IState) => state.authReducer);
 
  return (
    <Router>

      <LoadWithSpinner
        loadingIndicator={authState.loadingIndicator}
        component={<GetNonAuthBundle />}/>
      
    </Router>
  );
}