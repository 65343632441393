/* eslint-disable jsx-a11y/iframe-has-title */
import { Col, Row } from 'antd';
import React from 'react';
import Title from 'antd/es/typography/Title';
// import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { THEME } from '../constants/theme';
import AnchorDetailsForm from '../components/anchorDetailsForm';
import { requestGstAccountCreation } from '../store/actions/authActions';
import { ROUTES } from '../constants/routePaths';
import { IState } from '../models';
import BackToLoginBtn from '../components/backToLogin';
import AppConfig from '../config';
import { ZohoAnalyticsSignupGstBegin } from '../store/actions/analytics';
import { IZohoContactDetails } from '../typings';
import { IRequestGstAccountCreation } from '../models/api/authModels';

const { enableAnalytics } = AppConfig;

export default function GetAnchorDetailsPage() {
  const storeDispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector((state: IState) => state.authReducer);
  const { search } = useLocation();

  const zohoIdentity = (phoneNumber: string) => {
    (window as any).pagesense = (window as any).pagesense || [];
    (window as any).pagesense.push(['identifyUser', phoneNumber]);
  };

  const getQueryParams = (paramString: string) => {
    try{
      const params = paramString.split('?')
        .filter(a => a.length).map(a => a.split('&'))
        .reduce((a, i) => [...a, ...i], [])
        .map(a => a.split('='))
        .reduce((a, i) => ({ ...a, [i[0]]: i[1] }), {});
      return params;
    } catch(err) {
      return {};
    }
  };

  const addAnalyticsZohoCRM = (data: IRequestGstAccountCreation, company = 'N.A') => {
    if(!enableAnalytics){
      return;
    }
    try{
      zohoIdentity(data.phoneNumber);
      const nameArray = data.name.split(' ');
      let queryParams = {};
      // eslint-disable-next-line @typescript-eslint/camelcase
      const url_params = `Query Params: ${search} `;
      queryParams = getQueryParams(search);
      const firstName = nameArray.splice(0, 1).join();
      const lastName = nameArray.join(' ');
      const params = {
        'First_Name': firstName,
        'Last_Name': lastName.length ? lastName : 'N.A',
        'Email': data.email,
        'gst_number': data.gstin,
        'Mobile': data.phoneNumber,
        'Salutation': data.gender,
        'Account_Name': company,
        'from': 'App',
        'State_Name': authState.stateName,
        'Address': authState.Address,
        ...queryParams,
        url_params
      } as IZohoContactDetails;
      // console.log(params);
      storeDispatch(ZohoAnalyticsSignupGstBegin(params));
    } catch(err){
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const gaFunctions = () => {
    if (!enableAnalytics) {
      return;
    }
    if (typeof ((window as any)?.gtag) === 'function') {
      (window as any)?.gtag('event', 'conversion', {
        'send_to': 'AW-411682450/Fm0fCN_4howCEJKNp8QB',
      });
    }
  };

  return (
    <div>
      <Row
        style={{ height: '5vh', paddingRight: '10px' }}
        justify="end"
        align="middle"
      >
        <BackToLoginBtn />
      </Row>
      <Row style={{ height: '95vh' }} justify="center" align="middle">
        <Col md={8} xs={20}>
          <Title style={{ color: THEME.PRIMARY_COLOR }}>Fill the Details</Title>

          <AnchorDetailsForm
            onSubmitFn={(values) => {
              const params = {
                gstin: authState.gstin,
                name: values.name,
                email: values.emailId,
                gender: values.gender,
                phoneNumber: values.primaryContactNumber,
                isWhatsappOptIn: values.whatsappOptin,
              } as IRequestGstAccountCreation;
              storeDispatch(
                requestGstAccountCreation(params,
                  () => {
                    addAnalyticsZohoCRM(params, values.tradeName);
                    history.push(ROUTES.SIGN_UP_SUCCESS);
                  }
                )
              );
              gaFunctions();
              
            }}
            justifyBtn="start"
          />
        </Col>
      </Row>
    </div>
  );
}
