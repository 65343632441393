import { IAction, IColleague } from '../../models';
import {
  IRequestAddColleague,
  IRequestColleagueUpdate,
  IResponseAddColleague,
  IResponseColleagueDelete,
  IResponseColleagueList,
  IResponseColleagueUpdate,
} from '../../models/api/colleagueModels';

export enum ColleagueActions {
  REQUEST_COLLEAGUE_LIST = 'REQUEST_COLLEAGUE_LIST',
  REQUEST_COLLEAGUE_LIST_SUCCESS = 'REQUEST_COLLEAGUE_LIST_SUCCESS',

  REQUEST_COLLEAGUE_UPDATE = 'REQUEST_COLLEAGUE_UPDATE',
  REQUEST_COLLEAGUE_UPDATE_SUCCESS = 'REQUEST_COLLEAGUE_UPDATE_SUCCESS',

  REQUEST_COLLEAGUE_DELETE = 'REQUEST_COLLEAGUE_DELETE',
  REQUEST_COLLEAGUE_DELETE_SUCCESS = 'REQUEST_COLLEAGUE_DELETE_SUCCESS',

  ADD_COLLEAGUE = 'ADD_COLLEAGUE',
  ADD_COLLEAGUE_SUCCESS = 'ADD_COLLEAGUE_SUCCESS',
  RESET_DATA= 'RESET_DATA',
}

export function resetColleagueData(): IAction {
  return {
    type: ColleagueActions.RESET_DATA,
  };
}

export function requestColleagueList(): IAction {
  return {
    type: ColleagueActions.REQUEST_COLLEAGUE_LIST,
  };
}

export function requestColleagureListSuccess(
  body: IResponseColleagueList
): IAction {
  return {
    type: ColleagueActions.REQUEST_COLLEAGUE_LIST_SUCCESS,
    body,
  };
}

export function requestColleagueUpdate(body: IRequestColleagueUpdate): IAction {
  return {
    type: ColleagueActions.REQUEST_COLLEAGUE_UPDATE,
    body,
  };
}

export function requestColleagueUpdateSuccess(
  body: IResponseColleagueUpdate
): IAction {
  return {
    type: ColleagueActions.REQUEST_COLLEAGUE_UPDATE_SUCCESS,
    body,
  };
}

export function requestColleagueDelete(body: IColleague): IAction {
  return {
    type: ColleagueActions.REQUEST_COLLEAGUE_DELETE,
    body,
  };
}

export function requestColleagueDeleteSuccess(
  body: IResponseColleagueDelete
): IAction {
  return {
    type: ColleagueActions.REQUEST_COLLEAGUE_DELETE_SUCCESS,
    body,
  };
}

export function addColleague(body: IRequestAddColleague): IAction {
  return {
    type: ColleagueActions.ADD_COLLEAGUE,
    body,
  };
}

export function addColleagueSuccess(body: IResponseAddColleague): IAction {
  return {
    type: ColleagueActions.ADD_COLLEAGUE_SUCCESS,
    body,
  };
}
