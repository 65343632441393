import React, { useState } from 'react';
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  DatePicker,
  Modal,
  Upload,
  Dropdown,
  Menu,
  Select,
  Progress,
} from 'antd';
import { THEME } from '../constants/theme';
import {
  PlusOutlined,
  FilterOutlined,
  ApartmentOutlined,
  EditFilled,
  DeleteFilled,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { FiMoreHorizontal } from 'react-icons/fi';
import { ROUTES } from '../constants/routePaths';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../models';
import {
  addSupplier,
  getGstinStatus,
  getWatchlistGstinStatus,
  deleteSupplier,
  // exportInvalidlistCsv
} from '../store/actions/dataSourceActions';
import ProspectTable from '../components/newProspectTable';
import { requestFieldsOfDataSource } from '../store/actions/dataSourceActions';
import OpenNotification from '../utils/notification';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { globalApiService } from '../services/apiService';
import { AxiosError } from 'axios';
import WatchListTable from '../components/watchListTable';
import { FiXCircle } from 'react-icons/fi';
import { HiSortDescending, HiSortAscending } from 'react-icons/hi';
import { IUniqueStatus, IWatchlistUniqueStatus } from '../models';
import InvalidListTable from '../components/invalidListTable';
const ManageSuppliers = () => {
  const storeDispatch = useDispatch();
  const authState = useSelector((state: IState) => state.authReducer);
  const { dataSourceName, dataSourceId } = authState;
  //Initialization
  const history = useHistory();
  //Destructuring
  const { Title, Paragraph, Text } = Typography;
  const { Search } = Input;
  const { TabPane } = Tabs;
  const { Option } = Select;
  const { Dragger } = Upload;
  //Base 64  Function
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  //Prospect table Search
  const [searchQuery, setSearchQuery] = useState('');
  const onSearch = (key: string) => {
    setSearchQuery(key);
  };
  //WatchlistSearch
  const [watchlistSearchQuery, setWatchlistSearchQuery] = useState('');
  const onWatchlistSearch = (key: string) => {
    setWatchlistSearchQuery(key);
  };
  const [fetchWatchlistTrigger,setFetchWatchlistTrigger] = useState(false)

  const handleFetchWatchlistTrigger = () => {
    setFetchWatchlistTrigger(false)
  }
  //WatchlistSearch
  const [invalidSearchQuery, setInvalidSearchQuery] = useState('');
  const onInvalidSearch = (key: string) => {
    setInvalidSearchQuery(key);
  };
  const [tabState, setTabState] = useState({ activeTab: '1' });
  //Tab Change
  const onTabChange = (key: any) => {
    if(key==='3'){
      setFetchWatchlistTrigger(true)
    }
    
    setTabState({ activeTab: key });
  };
  //Handle Filter Value Change
  const handleFilterOptionChange = (value: string) => {
    setStatus(value);
  };

  //Handle Menu Click
  function handleMenuClick(e: any) {
    console.log('Click on menu item.');
    // console.log('click', uniqueStatus[e.key]);
    // setStatus(uniqueStatus[e.key]);
  }
  function handleSortMenuClick(e: any) {
    console.log('Click on menu item.');
    console.log('click', e);
  }

  const [status, setStatus] = useState<string | undefined>(undefined);
  const [uniqueStatus, setUniqueStatus] = useState<IUniqueStatus[]>([]);
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={''} className="clear-icon-alignment">
        Clear <FiXCircle />
      </Menu.Item>
    </Menu>
  );

  //Add Supplier
  const [addSupplierValue, setAddSupplierValue] = useState({
    supplierGstin: '',
    companyName: '',
    email: '',
    contactNo: '',
  });
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const handleAddOk = () => {
    setIsAddModalVisible(false);
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const onAddSupplierFinish = (values: any) => {

    storeDispatch(
      addSupplier({
        ...values,
        dataSourceId: authState.dataSourceId,
        dataSourceName: authState.dataSourceName,
      })
    );
    handleAddOk();
  };

  const onAddSupplierFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const onAddSupplierChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    setAddSupplierValue((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  //Bulk Upload Modal
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isFirstStageVisible, setIsFirstStageVisible] = useState(true);
  const [isSuccessScreenVisible, setISSuccessScreenVisible] = useState(false);
  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const handleUploadOk = () => {
    setIsUploadModalVisible(false);
  };

  const handleUploadCancel = () => {
    setIsUploadModalVisible(false);
    setIsFirstStageVisible(true); //To Hide Second stage for bulk upload
  };

  //Init and fetching all the status for Prospect table
  const uniqueStatusState = useSelector(
    (state: IState) => state.dataSourceReducer
  );
  const { uniqueStatusArray } = uniqueStatusState;
  React.useEffect(() => {
    if (Array.isArray(uniqueStatusArray)) {
      setUniqueStatus(uniqueStatusArray);
    }
  }, [uniqueStatusArray]);

  const setUniqueStatusArray = (data:any) => {
    if (Array.isArray(data)) {
      setUniqueStatus(data);
    }
  } 

  // React.useEffect(() => {
  //   storeDispatch(getGstinStatus());
  //   storeDispatch(getWatchlistGstinStatus());
  // }, [storeDispatch]);

  //Init and fetching all the status for Watchlist table
  const [watchlistStatus, setWatchlistStatus] = useState<string | undefined>(
    undefined
  );
  const [watchlistUniqueStatus, setWatchUniqueStatus] = useState<
    IUniqueStatus[]
  >([]);
  const watchlistStatusState = useSelector(
    (state: IState) => state.dataSourceReducer
  );
  const { watchlistUniqueStatusArray } = watchlistStatusState;
  React.useEffect(() => {
    if (Array.isArray(watchlistUniqueStatusArray)) {
      setWatchUniqueStatus(watchlistUniqueStatusArray);
    }
  }, [watchlistUniqueStatusArray]);

  const handleWatchlistFilterOptionChange = (value: string) => {
    setWatchlistStatus(value);
  };
  //Bulk Upload Drag and Drop
  const [totalUploadedRecord,setTotalUploadedRecord] = useState<number>(0)
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isValidationError, setIsValidationError] = useState<boolean>(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [progress, setProgress] = useState(0);
  const [
    shouldGenerateSupplierReport,
    setShouldGenerateSupplierReport,
  ] = useState<boolean>(true);
  const [
    shouldSendNotificationsGSTR1,
    setShouldSendNotificationsGSTR1,
  ] = useState<boolean>(true);
  const [
    shouldSendNotificationsGSTR3B,
    setShouldSendNotificationsGSTR3B,
  ] = useState<boolean>(true);
  const onUpload = (file_list:any) => {
    setIsUploading(true);
    globalApiService
      .uploadSuppliersFile({
        fileObject: file_list[0],
        dataSourceName,
        description: `Uploaded file at ${new Date().toLocaleDateString()}`,
        shouldGenerateSupplierReport,
        shouldSendNotificationsGSTR1,
        shouldSendNotificationsGSTR3B,
      })
      .then((res) => {
        // isFirstStageVisible(false)
        // isSuccessScreenVisible(true)
        setTotalUploadedRecord(res?.data.data.data.total_records)
        setIsFirstStageVisible(false);
        setISSuccessScreenVisible(true);
        OpenNotification(
          'File uploaded successfully. The system is capturing the data. The report will be shared on your email ID!',
          'success'
        )
      })
      .catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        if (errorBody) {
          const { success, message: errorMessage } = errorBody;
          if (
            success === false &&
            errorMessage &&
            errorMessage === "Empty file can't be uploaded"
          ) {
            setIsValidationError(true);
          } else if (
            success === false &&
            errorMessage &&
            (errorMessage as string)?.includes('Extra Columns present in file')
          ) {
            setIsValidationError(true);
          }
        }
      });
    setIsUploading(false);
  };
  const uploadProps = {
    accept: '.csv',
    multiple: false,
    disabled: isUploading,
    fileList,
    beforeUpload:beforeUpload,
    onChange(info: any) {
    // console.log(info);
    const {status} = info.file;
    // console.log(status);
    },
    onRemove: (file: UploadFile) => {
      const updatedFileList: RcFile[] = fileList;
      const deletedFileIndex: number = updatedFileList.findIndex(
        (el) => el.name === file.name
      );
      if (deletedFileIndex > -1) {
        updatedFileList.splice(deletedFileIndex, 1);
      }
      setFileList([...updatedFileList]);
      setIsValidationError(false);
    },
  };
  //Before Upload
  function beforeUpload(file: any) {
    if (
      ![
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ].includes(file.type)
    ) {
      OpenNotification('Only csv files are allowed', 'error');
      return false;
    }
    if (fileList.length < 1) {
      // setFileList([...fileList, file]);
      onUpload([...fileList, file]);
      handleUploadOk();
    } else {
      OpenNotification(
        'Only one file can be uploaded. ' +
          'Please remove file previously uploaded from this screen.',
        'error'
      );
      return false;
    }
    return false;
  }

  const dataSourceState = useSelector(
    (state: IState) => state.dataSourceReducer
  );

  const { fieldsOfDataSource } = dataSourceState;
  const getFileContentsForTemplate = () => {
    const quotedFieldsOfDataSource = fieldsOfDataSource.map((el) => `"${el}"`);
    return quotedFieldsOfDataSource.join(',');
  };
  React.useEffect(() => {
    if (!fieldsOfDataSource) {
      storeDispatch(requestFieldsOfDataSource({ dataSourceId }));
    }
  }, [storeDispatch, fieldsOfDataSource, dataSourceId]);

  //Add to Watchlist Trigger in Propect Table
  const [watchlistTrigger, setWatchlistTrigger] = useState(false);

  const TogglewatchlistTrigger = () => {
    //Lifting the state up from Propect table
    setWatchlistTrigger(false);
    // setRemoveWatclistTrigger(false);
  };
  //Remove from watclist
  const [removeWatchlistTrigger, setRemoveWatclistTrigger] = useState(false);

  const changeRemoveWatchlistRigger = () =>{
    setRemoveWatclistTrigger(false)
  }
 
  //delete trigger
  const [bulkDeleteTrigger, setBulkDeleteTrigger] = useState<boolean>(false);
  const ToggleBulkDeleteTrigger = () => {
    //Lifting the state up from newProspectTable(Child)
    setBulkDeleteTrigger(false);
  
  };


  //Invalid list clear
  const [clearState,setClearState] = useState<boolean>(false);

  const changeClearState = () => {
    //Lifting the state up from invalidListTable(Child)
    setClearState(false)
  }
 
  return (
    <main className="">
      {/* Add Supplier Modal */}
      <Modal
        className="addSupplierModal"
        title=""
        centered
        visible={isAddModalVisible}
        onOk={handleAddOk}
        onCancel={handleAddCancel}
        footer={[]}
      >
        <section className="addSupplierform">
          <Title level={3}>Add supplier details</Title>
          <div className="addSupplierform__inputbox">
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onAddSupplierFinish}
              onFinishFailed={onAddSupplierFailed}
              autoComplete="off"
            >
              <Row gutter={48}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Supplier GSTIN"
                    name="gstin"
                    //   rules={[{message: '07AABCU9603R1ZP' }]}
                    rules={[
                      {
                        required: true,
                        message: 'gstin is a manadatory field',
                      },
                      {
                        pattern: new RegExp(
                          /([0-9]{2})([A-Z]{5}[0-9]{4}[A-Z])[1-9A-Z][A-Z][0-9A-Z]/
                        ),
                        message: 'Enter a valid gstin',
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      placeholder="Enter supplier GSTIN"
                      name="gstin"
                      // value={addSupplierValue.supplierGstin}
                      onChange={onAddSupplierChange}
                      className='input-class'

                      //   style={{ width: '60%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Supplier Email"
                    name="email"
                    //   rules={[{message: '07AABCU9603R1ZP' }]}
                    rules={[
                      {
                        pattern: new RegExp(
                          /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
                        ),
                        message: 'Enter a valid email',
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      name="email"
                      placeholder="Enter supplier email"
                      // value={addSupplierValue.email}
                      onChange={onAddSupplierChange}
                      className='input-class'

                      //   style={{ width: '60%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  {/* <Form.Item
                    label="Company Name"
                    name="companyName"
                    //   rules={[{message: '07AABCU9603R1ZP' }]}
                    rules={[
                      {
                        pattern: new RegExp(/^[a-zA-Z]+$/),
                        message: 'Enter a valid company name',
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      name="companyName"
                      placeholder="Enter company name"
                      // value={addSupplierValue.companyName}
                      onChange={onAddSupplierChange}

                      //   style={{ width: '60%' }}
                    />
                  </Form.Item> */}
                  <Form.Item
                    label="Contact no"
                    name="phoneNumber"
                    //   rules={[{message: '07AABCU9603R1ZP' }]}
                    rules={[
                      {
                        pattern: new RegExp(
                          /^(?:(?:\+|0{0,2})91(\s*[ -]\s*)?|[0]?)?[56789]\d{9}|(\d[ -]?){10}\d$/
                        ),
                        message: 'Enter a valid contact number',
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      name="phoneNumber"
                      placeholder="Enter contact number"
                      // value={addSupplierValue.contactNo}
                      onChange={onAddSupplierChange}
                      className='input-class'

                      //   style={{ width: '60%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="addSupplierform__btnbox">
                <Form.Item>
                  <p
                    className="addSupplierform__btnbox__btn addSupplierform__btnbox__btn--paragraph"
                    style={{
                      color: THEME.PRIMARY_COLOR,
                    }}
                    onClick={handleAddCancel}
                  >
                    Cancel
                  </p>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="addSupplierform__btnbox__btn"
                    style={{
                      backgroundColor: THEME.PRIMARY_COLOR,
                    }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </section>
      </Modal>

      {/* endModal */}
      {/* Bulk Upload Modal */}
      <Modal
        className="bulkUploadModal"
        title=""
        centered
        visible={isUploadModalVisible}
        onOk={handleUploadOk}
        onCancel={handleUploadCancel}
        footer={[]}
      >
        {isFirstStageVisible && (
          <section className="uploadSection">
            <h3 className="uploadSection__title">
              You need to download template, add your customers to it and upload
              the file
            </h3>
            <div className="uploadSection__done">
              <p className="uploadSection__done__text">Already done ?</p>
            </div>
            <div className="uploadSection__download">
              {fieldsOfDataSource && (
                <a
                  href={`data:text/csv;charset=utf-8,${getFileContentsForTemplate()}`}
                  download="jitfinTemplate.csv"
                  className="uploadSection__download__text"
                >
                  Download Template
                </a>
              )}
              <Button
                className="uploadSection__download__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                }}
                onClick={() => setIsFirstStageVisible(false)}
              >
                Upload file
              </Button>
            </div>
          </section>
        )}
        {!isFirstStageVisible && !isSuccessScreenVisible && (
          <section className="fileupload-section">
            <Title level={3} className="fileupload-section__title">
              Bulk upload suppliers
            </Title>
            <div className="fileupload-section__dragger">
              <Dragger
                {...uploadProps}
                id="supplier-bulk-dragger"
              >
                <p className="fileupload-section__dragger__text">
                  Drag & drop <br></br>file
                </p>
              </Dragger>
            </div>
            <div className="fileupload-section__browse">
              {/* <Upload beforeUpload={beforeUpload} {...uploadProps}> */}
              {/* <Button
                className="fileupload-section__browse__btn"
                onClick={() =>
                  // document.getElementById("supplier-bulk-dragger")?.click()
                  onUpload()
                }
              >
                Upload
              </Button> */}
              <Upload
              {...uploadProps}>
              <Button className="fileupload-section__browse__btn">
                Browse
              </Button>
              </Upload>
              {/* </Upload> */}
            </div>
          </section>
        )}
        {!isFirstStageVisible && isSuccessScreenVisible && (
          <section className="success-section">
            <div className="success-section__icon">
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: '24px' }}
              />
            </div>
            <h2 className="success-section__text">{totalUploadedRecord} Suppliers added</h2>
            <div className="success-section__btnbox">
              <Button
                className="success-section__btnbox__btn"
                onClick={handleUploadCancel}
              >
                Ok
              </Button>
            </div>
          </section>
        )}
      </Modal>

      {/* endModal */}
      <Row>
        <Title level={3}>Manage Suppliers</Title>
      </Row>
      <Tabs activeKey={tabState.activeTab} className='custom_tabs' onChange={onTabChange}>
        <TabPane tab="Suppliers" key="1">
          <section className="customers">
            <div className="customers__add">
              <Button className="customers__add__btn" onClick={showAddModal}>
                <PlusOutlined className='btn-icon-padding' />
                Add Suppliers
              </Button>
              <Button
                className="customers__add__btn customers__add__btn--bg"
                onClick={showUploadModal}
              >
                Bulk upload suppliers
              </Button>
            </div>
            <div className="customers__search">
              <div className="customers__search__bar">
                <Search
                  className='date-picker-border-radius'
                  placeholder="Search by company name/GSTIN"
                  onSearch={onSearch}
                />
                <Select
                  className="gstin-select select-border-radius"
                  placeholder={`GSTR Filing Filters`}
                  onChange={handleFilterOptionChange}
                  suffixIcon={<FilterOutlined color="#ccc" />}
                  // size="large"
                  allowClear={true}
                >
                  <Select.Option value="On time">On time</Select.Option>
                  <Select.Option value="Delayed">Delayed</Select.Option>
                  <Select.Option value="Not Filed">Not Filed</Select.Option>
                  <Select.Option value="Technical Failure">Technical Failure</Select.Option>
                </Select>

                {/* <div>
                  <HiSortDescending className="sort-icon" />
                </div> */}


              </div>
              <div className="customers__search__delete">
                <Button
                  className="customers__search__delete--btn"
                  onClick={() => setWatchlistTrigger(true)}
                >
                  Add to Watchlist
                </Button>
                <Button
                  className="customers__search__delete--btn"
                  onClick={() => setBulkDeleteTrigger(true)}
                >
                  Delete Supplier/s
                </Button>
              </div>
            </div>
          </section>
          <div className="customers__table custom_table">
            {/* <Table
              columns={col}
              dataSource={data}
              scroll={{ x: true }}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              onChange={onChange}
            /> */}
            <ProspectTable
              pageSize={10}
              dataSourceName={authState.dataSourceName}
              searchProspectsQuery={searchQuery ? searchQuery : ''}
              status={status !== undefined ? status : ''}
              watchlistTrigger={watchlistTrigger}
              TogglewatchlistTrigger={TogglewatchlistTrigger}
              bulkDeleteTrigger={bulkDeleteTrigger}
              // setBulkDeleteTrigger={setBulkDeleteTrigger}
              ToggleBulkDeleteTrigger={() => ToggleBulkDeleteTrigger()}
              setUniqueStatusArray={setUniqueStatusArray}
            />
          </div>
        </TabPane>
        <TabPane tab="Watchlist" key="3">
          <section className="customers">
            <div className="customers__search">
              <div className="customers__search__bar customers__search__bar--widthModifier">
                <Search
                  className='date-picker-border-radius'
                  placeholder="Search by company name/GSTIN"
                  onSearch={onWatchlistSearch}
                />
                {/* <Select
                  className="gstin-select"
                  placeholder={`GSTIN Filters`}
                  onChange={handleWatchlistFilterOptionChange}
                  suffixIcon={<FilterOutlined color="#ccc" />}
                  // size="large"
                  allowClear={true}
                >
                  {watchlistUniqueStatus.length > 0 &&
                    watchlistUniqueStatus.map((ele, idx) => (
                      <Option value={`${ele}`}>{ele}</Option>
                    ))}
                </Select> */}
                {/* <div>
                  <HiSortDescending className="sort-icon" />
                </div> */}
 
              </div>
              <div className="customers__search__delete">
                <span
                  onClick={() => onTabChange('1')}
                  style={{
                    textDecoration: 'underline',
                    marginTop: '5px',
                    borderColor: '#4532bf'
                  }}
                >
                  <a style={{ color: '#4532bf' }}>Add suppliers to watchlist</a>
                </span>
                <Button
                  className="customers__search__delete--btn"
                  onClick={() =>
                    setRemoveWatclistTrigger(true)
                  }
                >
                  Remove from watchlist
                </Button>
              </div>
            </div>
          </section>
          <div className="customers__table custom_table">
            <WatchListTable
              pageSize={10}
              dataSourceName={authState.dataSourceName}
              searchProspectsQuery={
                watchlistSearchQuery ? watchlistSearchQuery : ''
              }
              status={watchlistStatus !== undefined ? watchlistStatus : ''}
              removeWatchlistTrigger={removeWatchlistTrigger}
              changeRemoveWatchlistRigger={changeRemoveWatchlistRigger}
              fetchWatchlistTrigger={fetchWatchlistTrigger}
              handleFetchWatchlistTrigger={handleFetchWatchlistTrigger}
            ></WatchListTable>
          </div>
        </TabPane>
        <TabPane tab="Invalid List" key="2">
          <section className="invalidcustomers">
            <div className="invalidcustomers__search">
              <div className="invalidcustomers__search__bar invalidcustomers__search__bar--invalidsupplier">
                <Search
                  className='date-picker-border-radius'
                  placeholder="Search by GSTIN"
                  onSearch={onInvalidSearch}
                />

              </div>
              <div className="invalidcustomers__search__clear">
                <a className="invalidcustomers__search__clear--text"
                href={`${process.env.REACT_APP_API_URL}/kyss/failed-entires/export?data_source_id=${authState.dataSourceId}`}
                download="invalidlist.csv"
                // onClick={handleOnclickDownload}
                >
                  Download list
                </a>
                <Button className="invalidcustomers__search__clear--btn" onClick={() => setClearState(true)}>
                  Clear
                </Button>
              </div>
            </div>
          </section>
          <div className="invalidcustomers__table custom_table">
            <InvalidListTable
              pageSize={10}
              dataSourceName={authState.dataSourceName}
              dataSourceId={authState.dataSourceId}
              invalidSearchQuery={
                invalidSearchQuery ? invalidSearchQuery : ''
              }
              status={''}
              clearState={clearState}
              changeClearState={changeClearState}

            ></InvalidListTable>
          </div>
        </TabPane>
      </Tabs>
    </main>
  );
};
export default ManageSuppliers;
