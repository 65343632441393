export const downloadHandler = (
  url: string | null | undefined,
  token: any,
  fileName: string,
  isXls: boolean = false
) => {
  if (url) {
    fetch(url, {
      credentials: 'include',
      headers: {
        authorization: `Token ${token}`
      }
    }).then((t) => {
      return t.blob().then(b => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        if (isXls) {
          a.setAttribute('download', `${fileName}.xlsx`);
        } else {
          a.setAttribute('download', `${fileName}.pdf`);
        }
        a.click();
      });
    });
  }
};

export default '';