import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Button, Table, Tag,  Input, Select, DatePicker, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import  { ArrowLeftOutlined, DownloadOutlined, FileExcelOutlined, FilePdfOutlined} from '@ant-design/icons';
import { Bar, Pie } from 'react-chartjs-2';
import { TabPane } from 'reactstrap';


import { globalApiService } from '../../services/apiService';

const queryString = require('query-string');

const { Option } = Select;

const columns = [
  {
    title: 'GSTIN',
    dataIndex: 'cell',
    width: '20%',
  },
  {
    title: 'Name of the Company',
    dataIndex: 'name',
    render: (name: any) => `${name.title} ${name.first} ${name.last} `,
  },
  {
    title: 'GSTIN Status',
    dataIndex: 'nat',
    render: (nat: any) => { console.log(nat); return `${nat}`; },
  },
  {
    title: 'GSTRI filed for Current Month ?',
    dataIndex: 'gender'
  },
  {
    title: 'Filing Date',
    dataIndex: 'registered',
    render: (registered: any) => { console.log(registered); return `${registered}`; },
  },
  {
    title: 'Action',
    dataIndex: '',
    render: () => (
      <> 
        {' '}
        <span>
          <Tag>
            Send Reminder
          </Tag>
        </span>
        {' '}
 
      </>
    ),
  },
];


const ReconCenterReport: React.FunctionComponent<any> = (props) => {
  console.log(props.reportid);

  const [tabname, setTabname] = useState(1);
  const [summaryData, setSummaryData] = useState({});
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [date, setDate] = useState(new Date());
  const [dateString, setDateString] = useState(new Date().toISOString().split('T')[0]);


  function updateLastTaxFilingDate(dateLo: any, dateStringLo: any) {
    setDate(dateLo);
    setDateString(dateStringLo);
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function onSelectChange(selectedRowKeysLo: any) {
    setSelectedRowKeys(selectedRowKeysLo);
  }
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
    reportId: props.reportid
  });
  useEffect(() => {
    // do stuff here...
    sumarry();
    componentDidMount();
  }, []); // <-- empty dependency array


  function onClickBack() {
    // history.push(ROUTES.DOWNLOAD_CENTER_V2)

    // props.data();
  }
  const urlParams = queryString.parse(history.location.search) || {};

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });


  function componentDidMount() {
    fetch(pagination);
  }

  function handleTableChange() {
    fetch(pagination);

  }
  
  async function fetch(params: any) {
    setLoading(true);
    const data=  await globalApiService.supplierReportList(params);
    const dataRes:any = [];
    for (let i = 0; i < data.data.data.results.length; i++) {
      const re=data.data.data.results[i];
      if(re.gst_data_downloaded_at ==null){
        re.gst_data_downloaded_at='';
      }
      if(re.is_gst_data_download_requested ==null){
        re.is_gst_data_download_requested='';
      }
      dataRes.push({
        key: re.id,
        name: re.tracker_id,
        phone: re.tracker_id,
        dob: re.tracker_id,
        gender: re.is_gst_data_download_requested,
        view:false,
        isNew:false
        
      });
    }
    setPagination({ ...pagination, current:params.current, total:data.data.data.count });
    setTableData(dataRes);
    setLoading(false);
    
  }

  
  async function sumarry() {
    const data = await globalApiService.supplierReportSummary(props.reportId);
    setSummaryData(data.data);
    console.info(data);
  }

  function DelayAnalysis() {
    const columnsAnalysis = [
      {
        title: 'Name of the Company',
        dataIndex: 'company'
      },
      {
        title: 'GSTIN',
        dataIndex: 'gstin'
      },
      {
        title: 'Not filed for',
        dataIndex: 'notFiled'
      },
      {
        title: 'Delayed for',
        dataIndex: 'delayed'
      },
      {
        title: 'Current Month',
        dataIndex: 'month'
      },
    ];
    const [tableAnalysis, setTableAnalysis] = useState([
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },

    ]);

    return (
      <>
        <Col sm={24} style={{ padding: '5px' }} className="bxsh">
          <Row style={{ margin: '25px 0px 0px' }}>
            <Col sm={4}>Recon Workspace </Col>
            <Col sm={4}>
              <Select
                placeholder="Filter by Match Type"
                allowClear
              >
                <Option value="male">male</Option>
                <Option value="female">female</Option>
                <Option value="other">other</Option>
              </Select>
            </Col>
          
            <Col sm={4}>
              <Select
                placeholder="Filter by Highest tax"
                allowClear
              >
                <Option value="male">male</Option>
                <Option value="female">female</Option>
                <Option value="other">other</Option>
              </Select>
            </Col>
          
            <Col sm={5}>
              <Select
                placeholder="Change Tolerance Level"
                allowClear
              >
                <Option value="male">male</Option>
                <Option value="female">female</Option>
                <Option value="other">other</Option>
              </Select>
            </Col>
          
            <Col sm={4}>
              <Select
                placeholder="Filter by on supplier"
                allowClear
              >
                <Option value="male">male</Option>
                <Option value="female">female</Option>
                <Option value="other">other</Option>
              </Select>
            </Col>
          
          
            <Col sm={3}>
              <Input readOnly value="Save As" style={{ width:'100px' }} />
              <FilePdfOutlined style={{ color:'red', fontSize:'22px' }} /> 
              {' '}
              <FileExcelOutlined style={{ color:'green', fontSize:'22px' }} />
            </Col>
          </Row>
          <Row style={{ margin: '25px 0px 0px' }}>
            <Col sm={8}>
              <Card className="recon-wordspace">
                Companies identified based on Delay criteria
              </Card>
            </Col>
            <Col sm={8}>
              <Card className="recon-wordspace">
                Companies identified based on Delay criteria
              </Card>
            </Col>
            <Col sm={8}>
              <Card className="recon-wordspace">
                Companies identified based on Delay criteria
              </Card>
            </Col>
          
          </Row>
        
        
          <Row>
            <Col sm={24} className="bxsh" style={{ marginTop:'10px' }}>
              <Table

                columns={columnsAnalysis}
                dataSource={tableAnalysis}
                size="small"
              />
            </Col>
          </Row>
        </Col>
      </>
    );
  }

  function Watchlist() {
    const columnsWatchlist = [
      {
        title: 'Name of the Company',
        dataIndex: 'company'
      },
      {
        title: 'GSTIN',
        dataIndex: 'gstin'
      },
      {
        title: 'Not filed for',
        dataIndex: 'notFiled'
      },
      {
        title: 'Delayed for',
        dataIndex: 'delayed'
      },
      {
        title: 'Current Month',
        dataIndex: 'month'
      },
    ];
    const [tableDataWatchlist, setTableWatchlist] = useState([
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },
      { 'company': 'ABCK', 'gstin': '78DFDF768780Z', 'notFiled': '2', 'delayed': '3', 'month': '-' },

    ]);

    const columnsRisk = [
      {
        title: 'Heigh Risk',
        dataIndex: 'company'
      }
    ];
    const [dataRisk, setDataRisk] = useState([
      { 'company': 'ABCK' },
      { 'company': 'ABCK' },
      { 'company': 'ABCK' }

    ]);


    const columnsMedium = [
      {
        title: 'Medium Risk',
        dataIndex: 'company'
      }
    ];
    const [dataMedium, setDataMedium] = useState([
      { 'company': 'ABCK' },
      { 'company': 'ABCK' },
      { 'company': 'ABCK' }

    ]);


    const columnsAll = [
      {
        title: 'All Stars',
        dataIndex: 'company'
      }
    ];
    const [dataAll, setDataAll] = useState([
      { 'company': 'ABCK' },
      { 'company': 'ABCK' },
      { 'company': 'ABCK' }

    ]);




    return (
      <>
        <Col sm={24} style={{ padding: '5px' }} className="bxsh">
          <Tabs type="card" tabPosition="left">
            <TabPane
              tab={(
                <>
                  <div className="spr_card_hr">
                    <h3>High Risk</h3>
                    <div>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                    </div>
                  </div>
                </>
          )}
              key="1"
            >
              <Table

                columns={columnsWatchlist}
                dataSource={tableDataWatchlist}
                pagination={false}
                size="small"
              />
            </TabPane>






            <TabPane
              tab={(
                <>
                  <div className="spr_card_mr">
                    <h3>High Risk</h3>
                    <div>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                    </div>
                  </div>
                </>
           )}
              key="2"
            >
              <Table

                columns={columnsWatchlist}
                dataSource={tableDataWatchlist}
                pagination={false}
                size="small"
              />
            </TabPane>




            <TabPane
              tab={(
                <>
                  <div className="spr_card_all">
                    <h3>High Risk</h3>
                    <div>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                      <p>Content of Tab Pane 1</p>
                    </div>
                  </div>
                </>
           )}
              key="3"
            >
              <Table

                columns={columnsWatchlist}
                dataSource={tableDataWatchlist}
                pagination={false}
                size="small"
              />
            </TabPane>


          </Tabs>
        </Col>
      
      
      </>
    );
  }

  function Overview() {
    const labelsBar = [
      'Invoice Date Mismatch',
      'Taxable Value Mismatch',
      'Tax type mismatch',
      'Tax amount mismatch',
      'GSTIN not found in 2A',
      'Invoice not found in 2A',
      'GSTIN not found in PR',
      'Invoice not found in PR'];

    const dataBar = {
      labels: labelsBar,
      datasets: [{
        label: 'Type of mismatch        ',
        barThickness: 10,
        data: [26, 23, 3, 5, 10, 10, 2, 21],
        fill: false,
        backgroundColor: [
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',

        ],
        borderColor: [
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',
          '#5359A6',
        ],
        borderWidth: 1
      }]
    };

    const data = {
      labels: ['Perfect Match ', 'Partial Match', 'Did not Match'],
      datasets: [
        {
          label: 'Current Month GSTR 1',
          data: [8, 3, 1],
          backgroundColor: ['#FF7272', '#FFB23E', '#3DD6AB'],
        }
      ]
    };
    const columnsTaxable = [
      {
        title: 'Type',
        dataIndex: 'type'
      },
      {
        title: 'Count',
        dataIndex: 'count'
      },
    ];
    const [tableDataTaxable, setTableDataType] = useState([{ 'type': 'ABCK', 'count': 20 }, { 'type': 'ABCK', 'count': 20 }]);


    const columnsSuppliers = [
      {
        title: 'Type',
        dataIndex: 'type'
      },
      {
        title: 'Count',
        dataIndex: 'count'
      },
    ];
    const [tableDataSuppliers, setTableDataStatus] = useState([{ 'type': 'ABCK', 'count': 20 }, { 'type': 'ABCK', 'count': 20 }]);

    return (
      <>
        <Col sm={24} style={{ padding: '5px' }} className="bxsh">
          <Row>
            <Col sm={8}>

              <Pie
                data={data}
                width={100}
                options={{ maintainAspectRatio: false }}
                style={{ margin: '10px' }}
              />
            </Col>
            <Col sm={16} className="bxsh">
              <Row>
                <Col sm={24} style={{ margin: '10px' }}>
                  <div style={{ width: '98%', height: '70px', backgroundColor: '#FF7272', border: '1px solid #EE3636', borderBottom: '10px  solid #EE3636', color: '#fff', fontSize: '30px' }}>
                    <span style={{ float: 'left' }}>Potential ITR Loss</span> 
                    {' '}
                    <span style={{ float: 'right' }}>1204530</span>
                    {' '}
                  </div>
                </Col>
                <Col sm={24} style={{ margin: '10px' }}><Title level={4}>Top Highlights</Title></Col>
                <Col sm={8}><div style={{ width: '95%', height: '140px', backgroundColor: '#F5F4FC', margin: 'auto', border: '1px solid #B3A7FF', borderBottom: '10px  solid #B3A7FF', textAlign: 'center', paddingTop: '20%', color: '#4532BF', fontSize: '30px' }}>02</div></Col>
                <Col sm={8}><div style={{ width: '95%', height: '140px', backgroundColor: '#FFF3F2', margin: 'auto', border: '1px solid #FB6363', borderBottom: '10px solid #FB6363', textAlign: 'center', paddingTop: '20%', color: '#6A6A6A', fontSize: '30px' }}>16</div></Col>
                <Col sm={8}><div style={{ width: '95%', height: '140px', backgroundColor: '#FFF3F2', margin: 'auto', border: '1px solid #FB6363', borderBottom: '10px solid #FB6363', textAlign: 'center', paddingTop: '20%', color: '#6A6A6A', fontSize: '30px' }}>102</div></Col>
              </Row>
            </Col>

          </Row>
        </Col>
        <Col sm={24}>
          <Row>
            <Col sm={24} className="bxsh">

              <Bar
                data={dataBar}
                width={700}
                options={{
                  indexAxis: 'y',
                  scales: {
                    y: {
                      beginAtZero: true
                    }
                  }
                }}
                style={{ margin: '10px' }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Title level={4}>TOP 10 Invoices by Taxable Value – Status Mismatch/No Match</Title>
            </Col>
            <Col sm={24} className="bxsh">
              <Table

                columns={columnsTaxable}
                dataSource={tableDataTaxable}
                pagination={false}
                size="small"
              />

            </Col>
            <Col sm={24}>
              <Title level={4}>Suppliers who have not filed </Title>
            </Col>
            <Col sm={24} className="bxsh">
              <Table

                columns={columnsSuppliers}
                dataSource={tableDataSuppliers}
                pagination={false}
                onChange={handleTableChange}
                size="small"
              />
            </Col>

          </Row>
        </Col>
      </>
    );
  }
  return (
    <>
      <Row>
        <Col sm={24}>
          <Row>
            <Col sm={20}>
              <Title level={3}>Report Central</Title>
            </Col>
            <Col sm={4}>
              <Title level={4} style={{ color: '#ff4d4f' }}>
                <DownloadOutlined />
                Download
              </Title>
            </Col>
          </Row>
        </Col>
        <Col sm={24} style={{ padding: '5px 0px 0px' }} className="bxsh">
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={1}>
              <Button type="primary" icon={<ArrowLeftOutlined />} danger onClick={onClickBack} />
            </Col>
            <Col sm={3}>
              <Title level={3} style={{ fontSize: '15px', marginLeft: '10px' }} className="sp_right">Recon Reports</Title>
            </Col>

            <Col sm={3}>
              <Title level={3} style={{ fontSize: '15px', marginLeft: '10px' }} className="sp_right">Reports View</Title>
            </Col>

            <Col sm={3}>
              <Title level={3} style={{ fontSize: '15px', marginLeft: '10px' }}>
                Reports ID
                <span style={{ color: '#ff4d4f' }}> 
                  {' '}
                  {props.reportid}
                </span>
              </Title>
            </Col>

            <Col sm={5} />

            <Col sm={3}>
              <Input placeholder="Filter by Date" readOnly />
            </Col>
            <Col sm={2}>
              <Input value="GSTIN" readOnly />
            </Col>
            <Col sm={2}>
              <DatePicker placeholder="GSTIN" defaultValue={moment(dateString, 'YYYY-MM-DD')} onChange={updateLastTaxFilingDate} />
            </Col>
            <Col sm={2}>
              <Button type="primary" style={{ float: 'right' }} danger onClick={onClickBack}>
                SUBMIT
              </Button>
            </Col>
          </Row>
        </Col>



        <Col sm={24} style={{ padding: '5px' }} className="bxsh">
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={8}>
              <Button
                style={{
                  color: tabname === 1 ? '#ffff' : '',
                  backgroundColor: tabname == 1 ? '#4532BF' : '',
                  borderColor: tabname == 1 ? '#4532BF' : '',
                  width: '100%'
                }}
                onClick={() => { setTabname(1); }}
              >
                {' '}
                Overview
                {' '}
              </Button>
            </Col>
            <Col sm={8}>
              <Button
                style={{
                  color: tabname === 2 ? '#ffff' : '',
                  backgroundColor: tabname == 2 ? '#4532BF' : '',
                  borderColor: tabname == 2 ? '#4532BF' : '',
                  width: '100%'
                }}
                onClick={() => { setTabname(2); }}
              >
                {' '}
                SuppWatchlist lier Analysis
                {' '}
              </Button>
            </Col>
            <Col sm={8}>
              <Button
                style={{
                  color: tabname == 3 ? '#ffff' : '',
                  backgroundColor: tabname == 3 ? '#4532BF' : '',
                  borderColor: tabname == 3 ? '#4532BF' : '',
                  width: '100%'
                }}
                onClick={() => { setTabname(3); }}
              >
                {' '}
                Recon Workspace
                {' '}
              </Button>
            </Col>
          </Row>
        </Col>

        {tabname === 1 ? <Overview /> : ''}
        {tabname === 2 ? <Watchlist /> : ''}
        {tabname === 3 ? <DelayAnalysis /> : ''}






      </Row>




    </>
  );
};

export default ReconCenterReport;
