import { put } from 'redux-saga/effects';
import { spinEnd, spinStart } from '../actions/authActions';

export function* spinForWorker(workerFn: any) {
  yield put(spinStart());
  try {
    yield workerFn;
  } finally {
    yield put(spinEnd());
  }
}

export function* exceptionLogger(workerFn: any) {
  try {
    yield workerFn;
  } catch (e) {
    // TODO: Add alerts to error reporting platform like rollbar
  }
}
