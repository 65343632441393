/* eslint-disable no-nested-ternary */
/* eslint no-nested-ternary: "error" */

import { IAction } from '../../models';
import { UploadStatus } from '../../typings';
import { ReconActions } from '../actions/ReconcilationActions';

interface IReconReducer {
  activeState: number;
  gstUsername: string | null;
  uploading: boolean;
  uploadStatus: UploadStatus;
  loading: boolean;
  mappings: null;
  mappingsFixed: boolean;
  itrFileUpload: any;
  dropdowns: any;
  pageState: any;
  reports: any;
  report: any;
  summary: any;
  reportHeader: any;
  supplier: any;
  invoice_data: any;
  download: any;
  trackerId: string | null;
  resetRecon: boolean;
  navInProgress: boolean;
  navStartingFrom: number;
}

const defaultState:IReconReducer = {
  activeState: 0,
  gstUsername: null,
  uploading: false,
  uploadStatus: UploadStatus.NONE,
  loading: false,
  mappings: null,
  mappingsFixed: false,
  itrFileUpload: null,
  dropdowns: null,
  pageState: null,
  reports: null,
  report: null,
  summary: null,
  reportHeader: null,
  supplier: null,
  invoice_data: null,
  download: null,
  trackerId: null,
  resetRecon:false,
  navInProgress: false,
  navStartingFrom: 0
};

export default function reportsReducer(
  reconState: IReconReducer = defaultState,
  action: IAction
): IReconReducer {
  switch (action.type) {
    case ReconActions.GENERATE_OTP: {
      return { ...reconState, gstUsername: action.body.gstUsername };
    }
    case ReconActions.GENERATE_OTP_SUCCESS: {
      return { ...reconState, activeState: 1, navStartingFrom: 1 };
    }
    case ReconActions.TOKEN_EXISTS: {
      return { ...reconState, activeState: 2, navStartingFrom: 1 };
    }
    case ReconActions.VALIDATE_OTP_SUCCESS: {
      return { ...reconState, activeState: 2, navStartingFrom: 1 };
    }
    case ReconActions.NEXT: {
      return { 
        ...reconState, 
        navStartingFrom: reconState.activeState,
        activeState: reconState.activeState + 1,
      };
    }
    case ReconActions.UPLOADPR: {
      return { ...reconState, uploading: true, uploadStatus:UploadStatus.IN_PROGRESS };
    }
    case ReconActions.UPLOADPR_SUCCESS: {
      // eslint-disable-next-line max-len
      return { ...reconState, uploading: false, uploadStatus:UploadStatus.SUCCESS, resetRecon:false  };
    }
    case ReconActions.UPLOADPR_FAILURE: {
      return { ...reconState, uploading: false, uploadStatus:UploadStatus.FAIL  };
    }
    case ReconActions.PREV: {
      // eslint-disable-next-line max-len
      return { ...reconState, activeState: reconState.activeState - 1 };
    }
    case ReconActions.NAV_NEXT: {
      return { 
        ...reconState,

        activeState: reconState.navStartingFrom === 5
          ? reconState.activeState < 3 ? 3 : 5
          : reconState.navStartingFrom >= 3 
            ? reconState.activeState < 3 ? 3 : reconState.activeState + 1
            : reconState.activeState + 1
      };
    }
    case ReconActions.NAV_PREV: {
      return { 
        ...reconState, 
        activeState: reconState.activeState === 5
          ? 3 : reconState.activeState === 4 
            ? 3 : reconState.activeState === 3 
              ? 0 : reconState.activeState - 1
      };
    }
    case ReconActions.GET_MAPPINGS: {
      return { ...reconState, mappings: null, loading: true, mappingsFixed: false };
    }
    case ReconActions.GET_MAPPINGS_SUCCESS: {
      return { ...reconState, mappings: action.body, loading: false };
    }
    case ReconActions.ADD_MAPPINGS_SUCCESS: {
      return { ...reconState, mappingsFixed: true, loading: false };
    }
    case ReconActions.GET_ITR_FILE_UPLOAD: {
      return { ...reconState, mappings: null, loading: true };
    }
    case ReconActions.GET_ITR_FILE_UPLOAD_SUCCESS: {
      return { ...reconState, itrFileUpload: action.body, loading: false };
    }
    case ReconActions.GET_DROPDOWNS_SUCCESS: {
      return { ...reconState, dropdowns: action.body };
    }
    case ReconActions.GET_PAGE_STATE_SUCCESS: {
      const subPageState = action.body ? action.body.sub_page_id : 1;
      const activeState =
        subPageState === 1
          ? 0
          : subPageState === 2
            ? 3
            : subPageState === 3
              ? 4
              : 5;
      return { ...reconState, navStartingFrom: activeState, pageState: action.body, activeState };
    }
    case ReconActions.GET_REPORT_LIST: {
      return { ...reconState, reports: null };
    }
    case ReconActions.GET_REPORT_LIST_SUCCESS: {
      return { ...reconState, reports: action.body };
    }
    case ReconActions.GET_REPORT: {
      return { ...reconState, report: null };
    }
    case ReconActions.GET_REPORT_SUCCESS: {
      return { ...reconState, report: action.body };
    }
    case ReconActions.GET_REPORT_HEADER: {
      return { ...reconState, reportHeader: null };
    }
    case ReconActions.GET_REPORT_HEADER_SUCCESS: {
      return { ...reconState, reportHeader: action.body };
    }
    case ReconActions.GET_SUMMARY: {
      return { ...reconState, report: null };
    }
    case ReconActions.GET_SUMMARY_SUCCESS: {
      return { ...reconState, summary: action.body };
    }
    case ReconActions.GET_INVOICE_SEARCH: {
      return { ...reconState, invoice_data: action.body };
    }
    case ReconActions.GET_INVOICE_SEARCH_SUCCESS: {
      return { ...reconState, invoice_data: action.body };
    }
    case ReconActions.CLEAR_INVOICE: {
      return { ...reconState, invoice_data: null };
    }
    case ReconActions.GET_SUPPLIER: {
      return { ...reconState, supplier: null };
    }
    case ReconActions.GET_SUPPLIER_SUCCESS: {
      return { ...reconState, supplier: action.body };
    }
    case ReconActions.GET_REPORT_DOWNLOAD: {
      return { ...reconState, download: null };
    }
    case ReconActions.GET_REPORT_DOWNLOAD_SUCCESS: {
      return { ...reconState, download: action.body };
    }
    case ReconActions.TAKE_ACTION_SUCCESS: {
      if(reconState.report && reconState?.report?.results?.length) {
        return {
          ...reconState,
          report: {
            ...reconState.report,
            results: reconState &&
            reconState?.report?.results ?
              reconState.report.results.map((r: any) => {
                if(r.id === action.body.id) {
                  return {
                    ...r,
                    ...action.body
                  };
                }
                return {
                  ...r
                };
              }) : null
          }
        };
      }
      return {
        ...reconState
      };
    }
    case ReconActions.SET_TRACKER_ID: {
      return {
        ...reconState, trackerId: action.body.tracker_id
      };
    }
    case ReconActions.RESET_RECON_STATE: {
      return { ...reconState, activeState: 2, resetRecon:true };
    }
    // case ReconActions.NAV_ENABLE_PREV: {
    //   return { ...reconState, navPrev: true };
    // }
    // case ReconActions.NAV_DISABLE_PREV: {
    //   return { ...reconState, navPrev: false };
    // }
    // case ReconActions.NAV_ENABLE_NEXT: {
    //   return { ...reconState, navPrev: true };
    // }
    // case ReconActions.NAV_DISABLE_NEXT: {
    //   return { ...reconState, navPrev: false };
    // }
    default: {
      return reconState;
    }
  }
}
