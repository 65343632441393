import { combineReducers } from 'redux';
import authReducer from './authReducer';
import dataSourceReducer from './dataSourceReducer';
import colleagueReducer from './colleagueReducer';
import reportsReducer from './reportsReducer';
import reconReducer from './reconcileReducers';
import supplierReducer from './supplierReportReducer';
import analyticsReducer from './analyticsReducer';

export default combineReducers({
  authReducer,
  dataSourceReducer,
  colleagueReducer,
  reportsReducer,
  reconReducer,
  supplierReducer,
  analyticsReducer
});
