import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { successIcon } from '../utils/constants';
import { next } from '../store/actions/ReconcilationActions';

interface IGSTR2ADownloaded {
  setActiveStep?: any;
}
const GSTR2ADownloaded: React.FunctionComponent<IGSTR2ADownloaded> = () => {
  // const trackerId = useSelector((state: any) => state.reconReducer?.trackerId);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(setPageState(2, trackerId));
  // }, [dispatch, trackerId]);

  return (
    <div className="flex-y align-center gst-downloaded">
      <span className="fs--20 lh-30 mt--100 title">
        Your GSTR2A is sucessfully downloaded (Copy TBD)
        {' '}
      </span>
      <img src={successIcon} alt="success" className="mtb--70" />
      <Button
        className="orange-btn"
        onClick={() => {
          dispatch(next());
        }}
      >
        PROCEED
      </Button>
    </div>
  );
};

export default GSTR2ADownloaded;
