import {
  IRequestReportFiles,
  IRequestReportForDataSource,
  IRequestReportHistory,
  IResponseReportFiles,
  IResponseReportHistory,
} from '../../models/api/reportsModels';
import { IAction } from '../../models';

export enum ReportsActions {
  REQUEST_REPORT_HISTORY = 'REQUEST_REPORT_HISTORY',
  REQUEST_REPORT_HISTORY_SUCCESS = 'REQUEST_REPORT_HISTORY_SUCCESS',
  REQUEST_REPORT_FILES = 'REQUEST_REPORT_FILES',
  REQUEST_REPORT_FILES_SUCCESS = 'REQUEST_REPORT_FILES_SUCCESS',
  REQUEST_REPORT_FOR_DATA_SOURCE = 'REQUEST_REPORT_FOR_DATA_SOURCE',
  CLEAR_REPORT_FILE_METADATA = 'CLEAR_REPORT_FILE_METADATA',
}

export function requestReportHistory(body: IRequestReportHistory): IAction {
  return {
    type: ReportsActions.REQUEST_REPORT_HISTORY,
    body,
  };
}

export function requestReportHistorySuccess(
  body: IResponseReportHistory
): IAction {
  return {
    type: ReportsActions.REQUEST_REPORT_HISTORY_SUCCESS,
    body,
  };
}


export function requestReportFiles(body: IRequestReportFiles): IAction {
  return {
    type: ReportsActions.REQUEST_REPORT_FILES,
    body,
  };
}

export function requestReportFilesSuccess(body: IResponseReportFiles): IAction {
  return {
    type: ReportsActions.REQUEST_REPORT_FILES_SUCCESS,
    body,
  };
}

export function requestClearReportFileMetadata(): IAction {
  return {
    type: ReportsActions.CLEAR_REPORT_FILE_METADATA,
  };
}

export function requestReportForDataSource(
  body: IRequestReportForDataSource
): IAction {
  return {
    type: ReportsActions.REQUEST_REPORT_FOR_DATA_SOURCE,
    body,
  };
}
