import React, {useEffect, useState} from 'react';
import { Col, Row } from 'antd/es/grid';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IState } from '../models';
import {
  requestLatestFileUploadData,
  requestGstFilingStatsForFile,
  requestProspectsOfDataSource,
} from '../store/actions/dataSourceActions';
import LatestFileUploadSummaryCards from '../components/dataSourceSummaryCards';
import RequestReportCards from '../components/requestReportCards';
import { requestReportForDataSource } from '../store/actions/reportsActions';
import { ROUTES } from '../constants/routePaths';
import { IRequestProspectsOfDataSource } from '../models/api/dataSourceModels';
import {
  getDropdowns
} from '../store/actions/ReconcilationActions';

export default function Dashboard() {
  const dataSourceState = useSelector(
    (state: IState) => state.dataSourceReducer
  );

  const authState = useSelector((state: IState) => state.authReducer);
  const { dataSourceId, dataSourceName } = authState;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loginStateClassName = authState.token ? 'logged-in' : 'logged-out';
    document.body.className = `${document.body.className} ${loginStateClassName}`.trim();
  }, [isLoggedIn]);

  const storeDispatch = useDispatch();
  const history = useHistory();

  const latestFileUploadStatusForDataSource =
    dataSourceState.latestFileUploadStatus;

  const prospectLatestFilingInfo = dataSourceState.latestFileGstFilingInfo;

  const [prospectCount, setProspectCount] = useState(0);

  const latestFileUploadId = latestFileUploadStatusForDataSource ?.id;

  useEffect(() => {
    storeDispatch(getDropdowns());
    if (latestFileUploadId) {
      const now = new Date();
      storeDispatch(
        requestGstFilingStatsForFile({
          fileId: latestFileUploadId,
          asOfMonth: now.getMonth() + 1,
          asOfYear: now.getFullYear(),
          asOfDate: now.getDate(),
        })
      );
    }
    // eslint-disable-next-line
  }, [latestFileUploadId]);

  useEffect(() => {
    if (dataSourceName !== undefined) {
      storeDispatch(
        requestProspectsOfDataSource({
          dataSourceName,
          pageSize: 1,
          pageNumber: 1,
        } as IRequestProspectsOfDataSource)
      );
    }
    // eslint-disable-next-line
  }, [dataSourceName]);

  useEffect(() => {
    if (dataSourceState.prospectsData !== undefined) {
      setProspectCount(dataSourceState.prospectsData ?.count);
    }
    // eslint-disable-next-line
  }, [dataSourceState]);

  // useEffect(() => {
  //   if (dataSourceName !== undefined && prospectCount === undefined) {
  //     storeDispatch(
  //       requestProspectsOfDataSource({
  //         dataSourceName,
  //         pageSize: 1,
  //         pageNumber: 1,
  //       } as IRequestProspectsOfDataSource)
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [dataSourceName]);

  // useEffect(() => {
  //   if (dataSourceName !== undefined && prospectCount === undefined) {
  //     storeDispatch(
  //       requestProspectsOfDataSource({
  //         dataSourceName,
  //         pageSize: 1,
  //         pageNumber: 1,
  //       } as IRequestProspectsOfDataSource)
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [dataSourceName]);

  useEffect(() => {
    if (dataSourceName) {
      storeDispatch(
        requestLatestFileUploadData({
          dataSourceName,
        })
      );
    }
    // eslint-disable-next-line
  }, [dataSourceName]);

  return (
    <div className="app__dashboard">
      {prospectCount !== undefined && (
        <Row justify="center" align="middle" style={{ paddingBottom: '30px' }}>
          <Col flex="auto">
            <Title level={3}>
              {`Total Number of Your Suppliers on KYSS :${prospectCount}`}
            </Title>
          </Col>
        </Row>
      )}

      <Row>
        <Col
          md={24}
        >
          <Title level={3} className="font-regular">
            Your Supplier GST Snapshot
          </Title>
          {latestFileUploadStatusForDataSource &&
            latestFileUploadStatusForDataSource.completedAt && (
              <Text>
                {latestFileUploadStatusForDataSource.completedAt.toLocaleString()}
              </Text>
          )}
        </Col>
      </Row>

      <LatestFileUploadSummaryCards
        latestFileUploadStatusForDataSource={
          latestFileUploadStatusForDataSource
        }
        latestFilingInfo={prospectLatestFilingInfo}
      />
      <Row>
        <Col md={24}>
          <Title level={3}>Get Latest GST Filing Status</Title>
        </Col>
      </Row>
      <Row justify="space-between" style={{ paddingBottom: '30px' }}>
        <Col
          md={24}
          lg={12}
          style={{
            paddingRight: '8px',
            minHeight: '256px'
          }}
        >
          <RequestReportCards
            buttonText="Generate Report For All"
            onClickFn={() => {
              storeDispatch(requestReportForDataSource({ dataSourceId }));
            }}
            title="GST Filing Status for all Suppliers"
          />
        </Col>
        <Col
          md={24}
          lg={12}
          style={{
            paddingLeft: '8px',
            minHeight: '256px'
          }}
        >
          <RequestReportCards
            buttonText="Generate Report for Specific Suppliers"
            onClickFn={() => {
              history.push(ROUTES.UPLOAD_SUPPLIERS_TO_DATA_SOURCE);
            }}
            title="GST Filing Status for Specific Suppliers"
          />
        </Col>
      </Row>
    </div>
  );
}
