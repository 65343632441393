/* eslint-disable no-console */
import { Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory} from 'react-router-dom';
import { AnyAction } from 'redux';
import { ROUTES } from '../constants/routePaths';
import { IState } from '../models';
import { clearAccountCreationFlag } from '../store/actions/authActions';
import JitFinLogo from '../components/logo';
import AppConfig from '../config';

const { enableAnalytics } = AppConfig;

const SignupSuccessPage = () => {
  const signupSuccessFlag = useSelector((state: IState) => state.authReducer.signupSuccessFlag);
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const history = useHistory();
  const countdownSeconds = 10000;
  const [timer, setTimer] = useState(countdownSeconds);
  const countdown = (): number => {
    setTimeout(() => setTimer(timer -1000), 1000);
    return timer/1000;
  };
  useEffect(() => {
    if (!signupSuccessFlag) {
      history.push(ROUTES.LOGIN);
    }
    setTimeout(() => history.push(ROUTES.LOGIN), countdownSeconds);
  }, [signupSuccessFlag, history]);

  if (enableAnalytics && typeof ((window as any)?.gtag) === 'function') {
    (window as any)?.gtag('event', 'conversion', {
      'send_to': 'AW-411682450/T1AiCJalmPsCEJKNp8QB',
    });
  }
  return(
    <Row style={{ minHeight: '100vh' }}>
      <Col xs={24}>
        <main className='signup-success__page'>
          <Row align="middle" justify="center" style={{ marginBottom: '50px' }}>
            <Col>
              <JitFinLogo customStyles={{ height: '165px', width: '250px', maxHeight: '100%' }} />
            </Col>
          </Row>
          <Row style={{ minHeight: '30vh' }} align='middle' justify="center">
            <Col xs={22} md={12}>
              <Row justify='center'>
                <Col>
                  <Title level={2}>
                    Thank You for signing up for KYSS!
                  </Title>
                </Col>
              </Row>
              <Row justify='center'>
                <Col>
                  <Paragraph>
                    <span>
                      A customer success team member will be in touch with you soon.
                      Meanwhile, if you need any help, please WhatsApp us on
                    </span>
                    <span style={{ color: 'rgb(69, 50, 191)', fontWeight: 'bold' }}> +91 966-345-8899.</span>
                  </Paragraph>
                  <Paragraph>
                    <span className='text'>You will be redirected to login page in </span>
                    <span className='text text--timer'>{countdown()}</span>
                    <span className='text'>seconds. Or click </span>
                    <span className='text'>
                      <a
                        className='text--highlight text--link'
                        onClick={() => dispatch(clearAccountCreationFlag())}
                        title='Login' 
                        href={ROUTES.LOGIN}
                        style={{ color: 'rgb(255, 95, 79)', fontWeight: 'bold' }}
                      >
                        here
                      </a>
                    </span>
                    <span className='text'> to login rightaway</span>
                  </Paragraph>
                </Col>
              </Row>
              <Row style={{ visibility: 'hidden' }}>
                <Col>
                  {
                    enableAnalytics && (
                      <>
                        <iframe 
                          title='analytics' 
                          src='https://aqugen.go2cloud.org/aff_goal?a=l&goal_name=final&adv_id=1078&offer_id=1868' 
                          scrolling='no' 
                          frameBorder="0" 
                          width="1" 
                          height="1" 
                        />
                      </>
                    )
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </main>
      </Col>
      <Col className='hidden' xs={0} md={12} style={{ backgroundColor: 'rgb(69, 50, 191)', color: '#ffffff' }}>
        <Row justify='center' align='middle' style={{ height: '100vh' }}>
          <Col span={22} offset={1}>
            <Title level={2} style={{ color: '#ffffff' }} className='heading--lined'>
              <span className="line">Congratulations</span>
              <span className="line">&amp;</span>
              <span className="line">Thank You for signing up for KYSS!</span>
            </Title>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default SignupSuccessPage;