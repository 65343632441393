import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '../models';

const WhatsappChatbot: React.FunctionComponent = () => {
  const authState = useSelector((state: IState) => state.authReducer);
  useEffect(() => {
    const waBtn = document.getElementsByClassName('floating-wpp-button');
    if(!authState.phoneNumber){
      waBtn[0]?.remove();
      return;
    }
    if(!waBtn || waBtn?.length === 0){
      ($('#WAButton') as any).floatingWhatsApp({
        phone: '919663458899',
        headerTitle: 'Chat with us on WhatsApp!',
        popupMessage: 'Hello, how can we help you?',
        showPopup: true,
        buttonImage: '<img src="https://app.tellephant.com/assets/images/whatsapp.svg" />',
        position: 'right'
      });
    }
  }, [authState]);
  
  return(
    <>
    </>
  );
};

export default WhatsappChatbot;