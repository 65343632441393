/* eslint-disable global-require */
export const reconcilationIcon = require('../assets/images/reconcilation.svg');
export const reconcilationIconb = require('../assets/images/reconcilation_b.svg');
export const gstr2aIcon = require('../assets/images/gstr2a.svg');
export const infoIcon = require('../assets/images/info.svg');
export const successIcon = require('../assets/images/success.svg');
export const desk = require('../assets/images/desk.svg');
export const file = require('../assets/images/file.svg');
export const recon = require('../assets/images/recon.svg');
export const correct = require('../assets/images/correct.svg');
export const correctfile = require('../assets/images/gen_sup_rep_success_file.png');
export const correctfolder = require('../assets/images/gen_sup_rep_success_folder.svg');

export const loader = require('../assets/images/loader.svg');
export const downArrow = require('../assets/images/down_arrow.svg');
export const upArrow = require('../assets/images/up_arr.svg');
export const downArr = require('../assets/images/down_arr.svg');
export const dashboard = require('../assets/images/dashboard.svg');
export const dashboardB = require('../assets/images/dashboard_b.svg');
export const downloadCenter = require('../assets/images/download_center.svg');
export const downloadCenterB = require('../assets/images/download_center_b.svg');
export const manageSupplier = require('../assets/images/manage_supplier.svg');
export const manageSupplierB = require('../assets/images/manage_supplier_b.svg');
export const notification = require('../assets/images/notification.svg');
export const notificationB = require('../assets/images/notification_b.svg');
export const reportSetting = require('../assets/images/report_setting.svg');
export const reportSettingB = require('../assets/images/report_setting_b.svg');
export const inviteCol = require('../assets/images/invite_colleage.svg');
export const inviteColNew = require('../assets/images/invite_colleage_d.svg');
export const bulkUpload = require('../assets/images/bulk_upload.svg');
export const bulkUploadNew = require('../assets/images/bulk_upload_b.svg');
export const whatsappIcon = require('../assets/images/whatsapp.svg');
export const genSupRepIcon1 = require('../assets/images/gen_sup_rep_icon_1.svg');
export const genSupRepIcon2 = require('../assets/images/gen_sup_rep_icon_2.svg');
export const genUploadFolderV2 = require('../assets/images/gen_upload_folder_report_v2.svg');
export const genUploadMswordIcon = require('../assets/images/gen_upload_msword_icon_v2.svg');
export const genSupCheckIcon = require('../assets/images/gen_sup_check_icon.svg');
export const genSupGstDateFiling = require('../assets/images/gen_sup_gst_date_filing.svg');
export const genSupStatus = require('../assets/images/gen_sup_status.svg');
export const genSupStatusLoding = require('../assets/images/gen_sup_status_loding.svg');

export const QRCode = require('../assets/images/QRCode.png');
export const withLogoInvoice = require('../assets/images/with_logo_invoice.png');
export const withoutLogoInvoice = require('../assets/images/without_logo_invoice.png');
export const userPlusLogo = require('../assets/images/user_plus.svg');
export const loginImage = require('../assets/images/login_image.jpg');
export const carouselImg1 = require('../assets/images/track_supplier_GST_filings.png');
export const carouselImg2 = require('../assets/images/claim_full_input_mb.png');
export const carouselImg3 = require('../assets/images/claim_input_new.png');
export const carouselImg4 = require('../assets/images/carousal_img.png');
export const KYSSmobileLogo = require('../assets/images/Kyss_logo_bw.svg');
export const KYSSwhiteLogo = require('../assets/images/Kyss_logo_white.svg');