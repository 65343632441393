import React from 'react';
import { Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import ReconeSummary from '../components/ReconeSummary';

const ReconSummary: React.FunctionComponent = () => {
  return (
    <div className='reconcilation-wrapper app__reconcilation'>
      <Row>
        <Col sm={24}>
          <Title level={3}> Reconcilation </Title>
        </Col>
      </Row>
      <Row>
        <Col sm={24}>
          <div className="page-subtitle pa--10 mb--10"> Summary </div>
          <ReconeSummary />
        </Col>
      </Row>
    </div>
  );
};

export default ReconSummary;
