import React, { useState } from 'react';
import { Form, Input } from 'antd';
import 'antd/dist/antd.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestOtpForLoginAction } from '../store/actions/authActions';
import StylizedButton from './stylizedButton';
import { ROUTES } from '../constants/routePaths';
import { IRequestLoginOtp } from '../models/api/authModels';

export default function PhoneNumberForm() {
  const storeDispatch = useDispatch();
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const loginOnSubmit = (phNumber: string) => {
    storeDispatch(
      requestOtpForLoginAction(
        { phoneNumber: phNumber, register: false } as IRequestLoginOtp,
        () => history.push(ROUTES.VERIFY_OTP)
      )
    );
  };

  // TODO: Migrate this form to formik
  return (
    <Form name="basic" initialValues={{ remember: true }}>
      <Form.Item
        name="phoneNumber"
        help={errorMessage}
        rules={[
          {
            required: true,
            type: 'regexp',
            message: 'Please input your phone number!',
            pattern: new RegExp(/\d{10}/g),
          },
        ]}
      >
        <Input
          onChange={(e) => {
            const formPhoneNumber = e.target.value;
            if (formPhoneNumber) {
              setPhoneNumber(formPhoneNumber);
            } else {
              setErrorMessage('Please enter phone number');
            }
          }}
          placeholder="82864 xxxxx"
          size="large"
          style={{borderRadius:'0.5rem',border:'solid 1px #5c5c5c'}}
        />
      </Form.Item>

      <Form.Item>
        <StylizedButton
          textInButton="Login"
          onClickFn={() => {
            phoneNumber
              ? loginOnSubmit(phoneNumber)
              : setErrorMessage('Please enter phone number');
          }}
          size="large"
          customStyles={{backgroundColor:'#ed6957',borderRadius:'0.4rem',color:'#fff',boxShadow:'none'}}
        />
      </Form.Item>
    </Form>
  );
}
