import { IAction } from '../../models';
import {
  IRequestAddSupplier,
  IRequestCreateDataSource,
  IRequestDocumentUploadData,
  IRequestFieldsForTemplate,
  IRequestProspectLatestFilingInfo,
  IRequestProspectsOfDataSource,
  IRequestItemsOfUser,
  IRequestUpdateItem,
  IRequestAddItem,
  IRequestUpdateProspect,
  IResponseDocumentUploadData,
  IResponseFieldsForTemplate,
  IResponseProspectLatestFilingInfo,
  IResponseProspectsOfDataSource,
  IResponseUpdateProspect,
  IStandardResponse,
  IWhatsappRequestBody,
  IWhatsappResponse,
} from '../../models/api/dataSourceModels';
import { useDispatch, useSelector } from 'react-redux';

export enum DataSourceActions {
  REQUEST_LATEST_FILE_UPLOAD_INFO = 'REQUEST_LATEST_FILE_UPLOAD_INFO',

  REQUEST_GST_FILING_STATS_FOR_FILE = 'REQUEST_GST_FILING_STATS_FOR_FILE',
  REQUEST_GST_FILING_STATS_FOR_FILE_SUCCESS = 'REQUEST_GST_FILING_STATS_FOR_FILE_SUCCESS',

  REQUEST_PROSPECTS_OF_DATA_SOURCE = 'REQUEST_PROSPECTS_OF_DATA_SOURCE',
  REQUEST_PROSPECTS_OF_DATA_SOURCE_SUCCESS = 'REQUEST_PROSPECTS_OF_DATA_SOURCE_SUCCESS',

  REQUEST_FIELDS_FOR_DATA_SOURCE = 'REQUEST_FIELDS_FOR_DATA_SOURCE',
  REQUEST_FIELDS_FOR_DATA_SOURCE_SUCCESS = 'REQUEST_FIELDS_FOR_DATA_SOURCE_SUCCESS',

  REQUEST_LATEST_FILE_UPLOAD_DATA = 'REQUEST_LATEST_FILE_UPLOAD_DATA',
  REQUEST_LATEST_FILE_UPLOAD_DATA_SUCCESS = 'REQUEST_LATEST_FILE_UPLOAD_DATA',

  REQUEST_CREATE_DATA_SOURCE = 'REQUEST_CREATE_DATA_SOURCE',

  ADD_PROSPECT = 'ADD_PROSPECT',
  UPDATE_PROSPECT = 'UPDATE_PROSPECT',
  UPDATE_PROSPECT_SUCCESS = 'UPDATE_PROSPECT_SUCCESS',
  DELETE_PROSPECT = 'DELETE_PROSPECT',
  DELETE_PROSPECT_SUCCESS = 'DELETE_PROSPECT_SUCCESS',

  INITIATE_WHATSAPP_MESSAGE = 'INITIATE_WHATSAPP_MESSAGE',
  INITIATE_WHATSAPP_MESSAGE_RESULT = 'INITIATE_WHATSAPP_MESSAGE_RESULT',
  DATA_SOURCE_RESET_DATA = 'DATA_SOURCE_RESET_DATA',
  GSTIN_FILTER_STATUS = 'GSTIN_FILTER_STATUS',
  GSTIN_FILTER_STATUS_SUCCESS = 'GSTIN_FILTER_STATUS_SUCCESS',
  GET_WATCHLIST_ENTRIES = 'GET_WATCHLIST_ENTRIES',
  GET_WATCHLIST_ENTRIES_SUCCESS = 'GET_WATCHLIST_ENTRIES_SUCCESS',
  DELETE_WATCHLIST_ENTRIES = 'DELETE_WATCHLIST_ENTRIES',
  DELETE_WATCHLIST_ENTRIES_SUCCESS = 'DELETE_WATCHLIST_ENTRIES_SUCCESS',
  GSTIN_WATCHLIST_FILTER_STATUS = 'GSTIN_WATCHLIST_FILTER_STATUS',
  GSTIN_WATCHLIST_FILTER_STATUS_SUCCESS = 'GSTIN_WATCHLIST_FILTER_STATUS_SUCCESS',
  DELETE_SUPPLIER = 'DELETE_SUPPLIER',
  DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS',
  GET_INVALID_LIST='GET_INVALID_LIST',
  GET_INVALID_LIST_SUCCESS='GET_INVALID_LIST_SUCCESS',
  DELETE_INVALID_LIST='DELETE_INVALID_LIST',
  DELETE_INVALID_LIST_SUCCESS='DELETE_INVALID_LIST_SUCCESS',
  EXPORT_INVALIDLIST_CSV = 'EXPORT_INVALIDLIST_CSV',
  EXPORT_INVALIDLIST_CSV_SUCCESS = 'EXPORT_INVALIDLIST_CSV_SUCCESS',
  GET_TRANSPORTER = 'GET_TRANSPORTER',
  GET_TRANSPORTER_SUCCESS = 'GET_TRANSPORTER_SUCCESS',
  ADD_TRANSPORTER = 'ADD_TRANSPORTER',
  ADD_TRANSPORTER_SUCCESS= 'ADD_TRANSPORTER_SUCCESS',
  DELETE_TRANSPORTER='DELETE_TRANSPORTER',
  DELETE_TRANSPORTER_SUCCESS='DELETE_TRANSPORTER_SUCCESS',
  UPDATE_TRANSPORTER='UPDATE_TRANSPORTER',
  UPDATE_TRANSPORTER_SUCCESS='UPDATE_TRANSPORTER_SUCCESS',
  GET_ITEM = 'GET_ITEM',
  GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS',
  ADD_ITEM = 'ADD_ITEM',
  ADD_ITEM_SUCCESS= 'ADD_ITEM_SUCCESS',
  DELETE_ITEM='DELETE_ITEM',
  DELETE_ITEM_SUCCESS='DELETE_ITEM_SUCCESS',
  UPDATE_ITEM='UPDATE_ITEM',
  UPDATE_ITEM_SUCCESS='UPDATE_ITEM_SUCCESS'

}


export function dataSourceResetData(): IAction {
  return {
    type: DataSourceActions.DATA_SOURCE_RESET_DATA
  };
}
export function requestProspectsOfDataSource(
  body: IRequestProspectsOfDataSource
): IAction {
  return {
    type: DataSourceActions.REQUEST_PROSPECTS_OF_DATA_SOURCE,
    body,
  };
}

export function requestProspectsOfDataSourceSuccess(
  body: IResponseProspectsOfDataSource
): IAction {
  return {
    type: DataSourceActions.REQUEST_PROSPECTS_OF_DATA_SOURCE_SUCCESS,
    body,
  };
}

export function addSupplier(body: IRequestAddSupplier): IAction {
  return {
    type: DataSourceActions.ADD_PROSPECT,
    body,
  };
}

export function updateProspect(body: IRequestUpdateProspect): IAction {
  return {
    type: DataSourceActions.UPDATE_PROSPECT,
    body,
  };
}

export function updateProspectSuccess(body: IResponseUpdateProspect): IAction {
  return {
    type: DataSourceActions.UPDATE_PROSPECT_SUCCESS,
    body,
  };
}

export function deleteProspect(body: { prospectId: number }): IAction {
  return {
    type: DataSourceActions.DELETE_PROSPECT,
    body,
  };
}

export function deleteProspectSuccess(body: { prospectId: number }): IAction {
  return {
    type: DataSourceActions.DELETE_PROSPECT_SUCCESS,
    body,
  };
}

export function createDataSource(body: IRequestCreateDataSource): IAction {
  return {
    type: DataSourceActions.REQUEST_CREATE_DATA_SOURCE,
    body,
  };
}

export function requestGstFilingStatsForFile(
  body: IRequestProspectLatestFilingInfo
): IAction {
  return {
    type: DataSourceActions.REQUEST_GST_FILING_STATS_FOR_FILE,
    body,
  };
}

export function requestGstFilingStatsForFileSuccess(
  body: IResponseProspectLatestFilingInfo
): IAction {
  return {
    type: DataSourceActions.REQUEST_GST_FILING_STATS_FOR_FILE_SUCCESS,
    body,
  };
}

export function requestFieldsOfDataSource(
  body: IRequestFieldsForTemplate
): IAction {
  return {
    type: DataSourceActions.REQUEST_FIELDS_FOR_DATA_SOURCE,
    body,
  };
}

export function requestFieldsOfDataSourceSuccess(
  body: IResponseFieldsForTemplate
): IAction {
  return {
    type: DataSourceActions.REQUEST_FIELDS_FOR_DATA_SOURCE_SUCCESS,
    body,
  };
}

export function requestLatestFileUploadData(
  body: IRequestDocumentUploadData
): IAction {
  return {
    type: DataSourceActions.REQUEST_LATEST_FILE_UPLOAD_DATA,
    body,
  };
}

export function responseLatestFileUploadData(
  body: IResponseDocumentUploadData
): IAction {
  return {
    type: DataSourceActions.REQUEST_LATEST_FILE_UPLOAD_DATA_SUCCESS,
    body,
  };
}

export function intitiateWhatsappMessage(body: IWhatsappRequestBody): IAction {
  return {
    type: DataSourceActions.INITIATE_WHATSAPP_MESSAGE,
    body,
  };
}

export function intitiateWhatsappMessageResult(
  body: IWhatsappResponse
): IAction {
  return {
    type: DataSourceActions.INITIATE_WHATSAPP_MESSAGE_RESULT,
    body,
  };
}

export function getGstinStatus() {
  return {
    type: DataSourceActions.GSTIN_FILTER_STATUS,
  };
}

export function getGstinStatusSuccess(body: any): IAction {
  return {
    type: DataSourceActions.GSTIN_FILTER_STATUS_SUCCESS,
    body,
  };
}

export function getWatchlistEntries(body: any) {
  return {
    type: DataSourceActions.GET_WATCHLIST_ENTRIES,
    body,
  };
}

export function getWatchlistEntriesSuccess(body: any): IAction {
  return {
    type: DataSourceActions.GET_WATCHLIST_ENTRIES_SUCCESS,
    body,
  };
}

export function deleteWatchlistEntries(body: {
  ids: number[] | React.Key[];
}): IAction {
  return {
    type: DataSourceActions.DELETE_WATCHLIST_ENTRIES,
    body,
  };
}

export function deleteWatchlistEntriesSuccess(body: any): IAction {
  return {
    type: DataSourceActions.DELETE_WATCHLIST_ENTRIES_SUCCESS,
    body,
  };
}

export function getWatchlistGstinStatus() {
  return {
    type: DataSourceActions.GSTIN_WATCHLIST_FILTER_STATUS,
  };
}

export function getWatchlistGstinStatusSuccess(body: any): IAction {
  return {
    type: DataSourceActions.GSTIN_WATCHLIST_FILTER_STATUS_SUCCESS,
    body,
  };
}
export function deleteSupplier(body: { ids: number[] | React.Key[] }): IAction {
  return {
    type: DataSourceActions.DELETE_SUPPLIER,
    body,
  };
}

export function deleteSupplierSuccess(body: any): IAction {
  return {
    type: DataSourceActions.DELETE_SUPPLIER_SUCCESS,
    body,
  };
}


export function getInvalidlistEntries(body:any) {
  return {
    type: DataSourceActions.GET_INVALID_LIST,
    body
  };
}

export function getInvalidlistEntriesSuccess(body: any): IAction {
  return {
    type: DataSourceActions.GET_INVALID_LIST_SUCCESS,
    body,
  };
}

export function deleteInvalidlistEntries(body: {
  ids: number[] | React.Key[];
  datasourceID : number | string;
}) {
  return {
    type: DataSourceActions.DELETE_INVALID_LIST,
    body
  };
}

export function deleteInvalidlistEntriesSuccess(body: any): IAction {
  return {
    type: DataSourceActions.DELETE_INVALID_LIST_SUCCESS,
    body,
  };
}

// export function exportInvalidlistCsv(body: { dataSourceID: number | string}): IAction {
//   return {
//     type: DataSourceActions.EXPORT_INVALIDLIST_CSV,
//     body,
//   };
// }

// export function exportInvalidlistCsvSuccess(body: any): IAction {
//   return {
//     type: DataSourceActions.EXPORT_INVALIDLIST_CSV_SUCCESS,
//     body,
//   };
// }

export function getTransporter(
  body: IRequestProspectsOfDataSource
): IAction {
  return {
    type: DataSourceActions.GET_TRANSPORTER,
    body,
  };
}

export function getTransporterSuccess(
  body: any
): IAction {
  return {
    type: DataSourceActions.GET_TRANSPORTER_SUCCESS,
    body,
  };
}

export function addTransporter(
  body: {
    name: string,
    contact_no:string
  }
): IAction {
  return {
    type: DataSourceActions.ADD_TRANSPORTER,
    body,
  };
}

export function addTransporterSuccess(
  body: any
): IAction {
  return {
    type: DataSourceActions.ADD_TRANSPORTER_SUCCESS,
    body,
  };
}

export function deleteTransporter(body: {
  ids: number[] | React.Key[];
}): IAction {
  return {
    type: DataSourceActions.DELETE_TRANSPORTER,
    body,
  };
}

export function deleteTransporterSuccess(body: any): IAction {
  return {
    type: DataSourceActions.DELETE_TRANSPORTER_SUCCESS,
    body,
  };
}

export function updateTransporter(body: {
  id: number | string,
  name: string,
  transporter_id: string,
  contact_no:string,
  vehicle_no:string
}): IAction {
  return {
    type: DataSourceActions.UPDATE_TRANSPORTER,
    body,
  };
}

export function updateTransporterSuccess(body: any): IAction {
  return {
    type: DataSourceActions.UPDATE_TRANSPORTER_SUCCESS,
    body,
  };
}

export function getItems(
  body: IRequestItemsOfUser
): IAction {
  return {
    type: DataSourceActions.GET_ITEM,
    body,
  };
}

export function getItemsSuccess(
  body: any
): IAction {
  return {
    type: DataSourceActions.GET_ITEM_SUCCESS,
    body,
  };
}

export function addItem(
  body: IRequestAddItem
): IAction {
  return {
    type: DataSourceActions.ADD_ITEM,
    body,
  };
}

export function addItemSuccess(
  body: any
): IAction {
  return {
    type: DataSourceActions.ADD_ITEM_SUCCESS,
    body,
  };
}

export function deleteItem(body: {
  ids: number[] | React.Key[];
}): IAction {
  return {
    type: DataSourceActions.DELETE_ITEM,
    body,
  };
}

export function deleteItemSuccess(body: any): IAction {
  return {
    type: DataSourceActions.DELETE_ITEM_SUCCESS,
    body,
  };
}

export function updateItem(body: IRequestUpdateItem): IAction {
  return {
    type: DataSourceActions.UPDATE_ITEM,
    body,
  };
}

export function updateItemSuccess(body: any): IAction {
  return {
    type: DataSourceActions.UPDATE_ITEM_SUCCESS,
    body,
  };
}

