// eslint-disable-next-line import/prefer-default-export
export const THEME = {
  PRIMARY_COLOR: '#462e90',
  SHADOW: '3px 3px 10px 0 rgba(69, 50, 191, 0.3)',
};

export const THEME_ORANGE = {
  PRIMARY_COLOR: '#FF5F4F',
  COLOR_SECONDARY: '#1890ff',
  COLOR_CTA: '#FF5F4F',
  HEADINGS :'#4532bf',
  SHADOW: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  COLOR_ERROR: '#D62C2C',
  COLOR_SUCCESS: '#69C57C',
};