import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'antd/es';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons/lib';
import Modal from 'antd/es/modal';
import { IColleague, IState } from '../models';
import {
  requestColleagueDelete,
  requestColleagueList,
  requestColleagueUpdate,
} from '../store/actions/colleagueActions';
import ColleagueForm from './colleagueForm';

const ColleagueTable = () => {
  const [tableData, setTableData] = useState<IColleague[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [
    colleagueBeingEdited,
    setColleagueBeingEdited,
  ] = useState<IColleague | null>(null);

  const handleCancel = () => {
    setColleagueBeingEdited(null);
  };

  const EditColleagueModal = () => {
    return (
      colleagueBeingEdited && (
        <Modal
          title="Edit Colleague details"
          visible={colleagueBeingEdited !== null}
          onCancel={handleCancel}
          footer={null}
        >
          <ColleagueForm
            onSubmitFn={(values) => {
              storeDispatch(
                requestColleagueUpdate({
                  ...values,
                  id: colleagueBeingEdited.id,
                })
              );
              handleCancel();
            }}
            justifyBtn="end"
            existingColleague={colleagueBeingEdited}
          />
        </Modal>
      )
    );
  };

  const storeDispatch = useDispatch();

  const colleagueState = useSelector((state: IState) => state.colleagueReducer);
  const { colleagues } = colleagueState;

  useEffect(() => {
    setLoading(true);
    storeDispatch(requestColleagueList());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTableData(colleagues);
    setLoading(false);
  }, [colleagues]);

  const deleteColleagueFromTable = (record: IColleague) => {
    storeDispatch(requestColleagueDelete(record));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      width: '40%',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      sorter: true,
      width: '20%',
    },
    {
      title: 'Edit/Delete',
      width: '20%',
      render: (text: string, record: IColleague, _index: number) => {
        return (
          <div>
            <Button
              size="small"
              onClick={() => {
                setColleagueBeingEdited(record);
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              size="small"
              style={{ marginLeft: '3px' }}
              type="default"
              onClick={() => {
                deleteColleagueFromTable(record);
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <EditColleagueModal />
      <Table
        rowKey={(row: IColleague, _) => row.id}
        size="small"
        pagination={false}
        columns={columns}
        dataSource={tableData}
        loading={loading}
      />
    </div>
  );
};

export default ColleagueTable;
