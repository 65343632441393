/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  setPageState,
  getItrFileUpload,
  startReconReport,
  resetReconState
} from '../store/actions/ReconcilationActions';
import { recon } from '../utils/constants';
// import { ROUTES } from '../constants/routePaths';


interface IStartRecon {
  setActiveStep?: any;
}


const StartRecon: React.FunctionComponent<IStartRecon> = ({
  setActiveStep,
}) => {
  const uploadFileid = useSelector((state: any) => state.reconReducer?.itrFileUpload?.id);
  const fileName = useSelector((state:any) => state.reconReducer?.itrFileUpload?.file_name);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getItrFileUpload());
  }, [dispatch]);
  const history = useHistory();
  const startReconOnclick = (data: any) => {
    dispatch(
      startReconReport({ ...data, history })
    );
  };

  const changeRecon = () => {
    dispatch(resetReconState());
    dispatch(setPageState(2));
  };


  return (
    <div className="flex-y align-center download-gst-r2a-wrapper">
      <div
        className="mt--80 flex-y align-center"
        style={{ position: 'relative' }}
      >
        <span className="fs--14 lh--21 img-text" style={{ top: '15px', maxWidth: '385px' }}>
          <div className="flex-x center title"> GSTR2A Download</div>
          <div className="subtitle">
            Your 2A for the selected period is being downloaded - please click on START RECON button to proceed further.
          </div>
        </span>
        <span className="fs--14 lh--21 img-text" style={{ top: '195px' }}>
          <div className="flex-x center title">
            Purchase Register Data uploaded
          </div>
          <div className="subtitle">
            {`Filename is ${fileName}`}
          </div>
          <div className="recon-change__wrapper">
            <a href='#' onClick={changeRecon} className="recon-change__btn">
              Change
            </a>
          </div>
        </span>
        <img src={recon} alt="img" />
      </div>
      <div
        style={{ marginTop: '-26px' }}
        className="start-recon-btn flex-x align-center center fs--16 lh--20"
        onClick={() => {
          startReconOnclick({
            uploadFileid
          });
        }}
        role="button"
        tabIndex={0}
        onKeyDown={() => {

        }}
      >
        START RECON
      </div>
    </div>
  );
};

export default StartRecon;
