import React, { useState } from 'react';
import {
  Card,
  Badge,
  Popconfirm,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Tabs,
  Table,
  DatePicker,
  Modal,
  Upload,
} from 'antd';
import { THEME } from '../constants/theme';
import {FiEdit3, RiDeleteBinLine} from "react-icons/all";
import {
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { IProspect, IState } from '../models';
import {
  getTransporter,
  addTransporter,
  deleteTransporter,
  updateTransporter
} from '../store/actions/dataSourceActions';
import OpenNotification from '../utils/notification';
import moment from 'moment';

const Transporters = () => {
  const { Title, Paragraph, Text } = Typography;
  const { Search } = Input;
  const { Dragger } = Upload;
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = useState<boolean>(false);
  //Store Init
 
  const storeDispatch = useDispatch();
  //Fetching AuthState
  const authState = useSelector((state: IState) => state.authReducer);
  const { dataSourceName, dataSourceId } = authState;
  let user = authState.userId
  //Search
  const onSearch = (value: string) => {
    setSearch(value);
  };
  //Form Declaration
  const [transporterForm] = Form.useForm();
  const [editTransporterForm] = Form.useForm();
  // Add Transporter Modal
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const showModal = () => {
    transporterForm.resetFields();
    setIsAddModalVisible(true);
  };

  const handleOk = () => {
    setIsAddModalVisible(false);
  };

  const handleCancel = () => {
    setIsAddModalVisible(false);
  };
  const onAddTransporterFinish = (values: any) => {
    if(values.vehicle_no == '' || values.vehicle_no == undefined){
      OpenNotification('Vehicle no. field should not be empty',"error")
      return;
    }
    storeDispatch(
      addTransporter({...values})
    );
    
    transporterForm.resetFields();
    handleOk();
  };

  const onAddTransporterFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  //Bulk Upload Modal
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isFirstStageVisible, setIsFirstStageVisible] = useState(true);
  const [isSuccessScreenVisible, setISSuccessScreenVisible] = useState(false);
  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const handleUploadOk = () => {
    setIsUploadModalVisible(false);
  };

  const handleUploadCancel = () => {
    setIsUploadModalVisible(false);
    setIsFirstStageVisible(true); //To Hide Second stage for bulk upload
  };
  //Bulk Upload Drag and Drop
  const props = {
    name: 'file',
    multiple: false,
    headers: {
      authorization: `Token ${authState.token}`,
    },
    accept: '.csv, text/csv',
    action: `${process.env.REACT_APP_API_URL}/kyss/files/transporters`,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        // console.log(`${info.file.name} file uploaded successfully.`);
        //OpenNotification(`${info.file.name} File Uploaded Successfully.`, 'success');
        if(info.file.response.data.created_transporter_count == 0 ){
          OpenNotification('There is an issue with the file. Please check the format', 'error');
        }else{
        OpenNotification(`${info.file.response.data.created_transporter_count} transporters added`, 'success');
        }
        handleUploadCancel();
        fetchTransporter(
          authState.userId,
          1,
          pagination.pageSize,
          search
        );
      } else if (status === 'error') {
        // console.log(`${info.file.name} file upload failed.`);
        // if(!info.file.response.sucess){
        handleUploadCancel();
        OpenNotification('File Upload Failed.', 'error');
        // }
      }
    },
    onDrop(e: any) {
      console.log(e);
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  //Before Upload
  function beforeUpload(file: any, fileList: any) {
    console.log(file);
    console.log(fileList);

    return true;
  }

  //For table
  const onDelete = (id: number) => {
    storeDispatch(deleteTransporter({ ids: [id] }));
  };
  const onEdit = (record: any) => {
    console.log(record);
  };
  const [rowKeys, setRowKeys] = useState<React.Key[]>();
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(selectedRows)
      let tempRowKeys:any = []
      selectedRows.map((ele:any)=>{
        if(ele!=undefined){
          tempRowKeys.push(ele.id)
        }
      })

      setRowKeys(tempRowKeys);
    },
  };

  const handleBulkDeleteClick = () => {
    if (Array.isArray(rowKeys) && rowKeys?.length > 0) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete these transporters?')) {
        storeDispatch(deleteTransporter({ ids: Array.from(rowKeys)}));
        setIsRowSelected(false);
        pagination.current = 1 
        setRowKeys([]);
      }
    }
    if (!isRowSelected || rowKeys?.length === 0) {
      OpenNotification('Please select transporter to delete', 'error');
    }
  }
  function onChange(pager:any) {
    pagination.current = pager.current;
    pagination.pageSize = pager.pageSize;
    setPagination(pagination);
    // storeDispatch(getWatchlistEntries());
    fetchTransporter(dataSourceName, pagination.current, pagination.pageSize);
  }

    //No pointer events if row is selected
    const [isRowSelected,setIsRowSelected] = useState<boolean>(false)
    React.useEffect(() => {
      if(Array.isArray(rowKeys) && rowKeys?.length > 0){
        setIsRowSelected(true)
      }else {
        setIsRowSelected(false)
      }
    },[rowKeys])
  const col = [
    {
      title: 'Transporter',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (text:any,record:any) => {
        return(
          <div>
          {(moment().subtract(3, 'days') < moment(record.created_on, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')) ? (
            <Badge count="New" offset={[22, 0]}>
              <span>{text}</span>
            </Badge>
          ) : <span>{text}</span>}
        </div>
        )
      }
    },
    {
      title: 'Contact Number',
      dataIndex: 'contact_no',
      key: 'contact_no',
      width: '20%',
    },
    {
      title: 'Transporter Id',
      dataIndex: 'transporter_id',
      key: 'transporter_id',
      width: '25%',
    },
    {
      title: 'Vehicle No',
      dataIndex: 'vehicle_no',
      key: 'vehicle_no',
      width: '25%',
    },
    {
      title: 'Actions',
      dataIndex: 'actions2',
      key: 'actions2',
      width: '10%',
      render: (text: any, record: any) => {
        return (
          <div className="iconContainer">
            <FiEdit3
              className="iconContainer__item"
              onClick={() => handleEditClick(record)}
            ></FiEdit3>
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this transporter?"
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <RiDeleteBinLine
                className='iconContainer__item'
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  //State Initilization
  const [table,setTable] = useState([])
  const [pagination, setPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize:10});


const transpoterState = useSelector(
    (state: IState) => state.dataSourceReducer
  );

const { transporterList,addTransporterFlag, totalTransporterCount } = transpoterState
React.useEffect(() => {
  if(search !== undefined){
  fetchTransporter(
    authState.dataSourceName,
    1,
    pagination.pageSize,
    search,
  ) 
  }else {
    setTable([])
}
}, [storeDispatch,search,addTransporterFlag])


React.useEffect(() => {
  if(transporterList != undefined){
  setTable([...transporterList])
  setLoading(false);
  }
  pagination.total = totalTransporterCount
  setPagination(pagination)
},[transporterList])


//Edit modal
const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
const handleEditOk = () => {
  setIsEditModalVisible(false);
};

const handleEditCancel = () => {
  setIsEditModalVisible(false);
  editTransporterForm.resetFields();
};

const onEditTransporterFinish = (values: any) => {
  storeDispatch(
    updateTransporter({
        id: editRecord.id,
        name: values.name ? values.name : editRecord.name,
        contact_no: values.contact_no ? values.contact_no : editRecord.contact_no,
        transporter_id: values.transporter_id ? values.transporter_id : editRecord.transporter_id,
        vehicle_no: values.vehicle_no ? values.vehicle_no : editRecord.vehicle_no
    })
  );
  editTransporterForm.resetFields();
  handleEditOk()
}

const onEditTransporterFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const handleEditClick = (record: any) => {
  setIsEditModalVisible(true);
  // setEditRecord(record)
  dispatchEditRecord({ type: 'NEW', record: record });
};


const EditRecordFunction = (state: any, action: any) => {
  if (action.type === 'NEW') {
    return action.record;
  }
  return defaultRecord;
};
const defaultRecord = {
  name:'',
  transporter_id:'',
  contact_no:'',
  id: '',
  created_on:'',
  vehicle_no:''
};
const [editRecord, dispatchEditRecord] = React.useReducer(
  EditRecordFunction,
  defaultRecord
);
//GET call for Transporter entries
  function fetchTransporter(
    nameOfDataSource: string | number,
    pageNumber: number,
    sizeOfPage: number,
    search?: string,
    status?: string,
  ) {
    // setLoading(true);
    storeDispatch(
      getTransporter({
        dataSourceName: nameOfDataSource,
        pageSize: sizeOfPage,
        pageNumber,
        search,
        status,
        user_id:authState.userId
      } as any)
    );
  }

  const getFileContentsForTemplate = () => {
    const quotedFieldsOfTransporters= ['name','contact_no','transporter_id','vehicle_no'].map((el) => `"${el}"`);
    return quotedFieldsOfTransporters.join(',');
  };

  return (
    <section className="transporters">
      {/* Add Transporter Modal */}
      <Modal
        className="addTransporterModal"
        title=""
        centered
        visible={isAddModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <section className="addform">
          <Title level={3}>Add transporter details</Title>
          <div className="addform__inputbox">
            <Form
              form={transporterForm}
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onAddTransporterFinish}
              onFinishFailed={onAddTransporterFailed}
              autoComplete="off"
            >
              <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap:'1.5rem'}}>
              <Form.Item
                label="Transporter Name"
                name="name"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z0-9\s]+$/),
                    message: 'Enter a valid name',
                  },
                ]}
              >
                <Input
                  name="name"
                  placeholder="Enter Name"
                  className='input-class'
                />
              </Form.Item>
              <Form.Item
                  label="Transporter Id"
                  name="transporter_id"
                  rules={[
                    {
                      pattern: new RegExp(
                        '^[0-9]{2}[0-9A-Z]{13}$'
                      ),
                      message: 'Enter a valid transporter id',
                    },
                  ]}
                >
                  <Input
                    name="transporter_id"
                    placeholder="Enter Transporter Id"
                    className='input-class'
                  />
                </Form.Item>
              <Form.Item
                label="Contact No(optional)"
                name="contact_no"
                rules={[
                  {
                    message: 'Please enter your Contact No!',
                  },
                  {
                    pattern: new RegExp(/^[6-9]{1}[0-9]{9}$/),
                    message: 'Please Enter Valid Mobile Number',
                  },
                ]}
              >
                <Input
                  name="contact_no"
                  placeholder="Enter Contact Number"
                  className='input-class'
                />
              </Form.Item>
              <Form.Item
                label="Vehicle no"
                name="vehicle_no"
                rules={[
                  {
                    pattern: new RegExp(/^([A-Z|a-z|0-9]{4,20})$/),
                    message: 'Please Enter Valid Vehicle Number',
                  },
                ]}
              >
                <Input
                  name="vehicle_no"
                  placeholder="Enter Vehicle Number"
                  className='input-class'
                />
              </Form.Item>
              </div>
              <div className="addform__btnbox">
                <Form.Item>
                  <p
                    className="addform__btnbox__btn addform__btnbox__btn--paragraph"
                    style={{
                      color: THEME.PRIMARY_COLOR,
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </p>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="addform__btnbox__btn"
                    style={{
                      backgroundColor: THEME.PRIMARY_COLOR,
                    }}
                  >
                    Add
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </section>
      </Modal>

      {/* endModal */}
      {/* Edit Transporter Modal */}
        <Modal
        className="addTransporterModal"
        title=""
        centered
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        footer={[]}
      >
        <section className="addform">
          <Title level={3}>Edit transporter details</Title>
          <div className="addform__inputbox">
            <Form
              form={editTransporterForm}
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onEditTransporterFinish}
              onFinishFailed={onEditTransporterFailed}
              autoComplete="off"
            >
              <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap:'1.5rem'}}>
              <Form.Item
                label="Transporter Name"
                name="name"
                rules={[
                  {
                    pattern: new RegExp(/^[a-zA-Z\s]+$/),
                    message: 'Enter a valid name',
                  },
                ]}
              >
                <Input
                  name="name"
                  value={editRecord.name}
                  placeholder={editRecord.name}
                  className='input-class'
                />
              </Form.Item>
                <Form.Item
                  label="Transporter Id"
                  name="transporter_id"
                  rules={[
                    {
                      pattern: new RegExp(
                        '^[0-9]{2}[0-9A-Z]{13}$'
                      ),
                      message: 'Enter a valid transporter id',
                    },
                  ]}
                >
                  <Input
                    name="transporter_id"
                    value={editRecord.transporter_id}
                    placeholder={editRecord.transporter_id}
                    className='input-class'
                  />
                </Form.Item>
              <Form.Item
                label="Contact No"
                name="contact_no"
                rules={[
                  {
                    pattern: new RegExp(
                      /[6-9]{1}[0-9]{9}/
                    ),
                    message: 'Enter a valid contact number',
                  },
                ]}
              >
                <Input
                  name="contact_no"
                  value={editRecord.contact_no}
                  placeholder={editRecord.contact_no}
                  className='input-class'
                />
              </Form.Item>
              <Form.Item
                label="Vehicle no(optional)"
                name="vehicle_no"
                rules={[
                  {
                    pattern: new RegExp(/^([A-Z|a-z|0-9]{4,20})$/),
                    message: 'Please Enter Valid Vehicle Number',
                  },
                ]}
              >
                <Input
                  name="vehicle_no"
                  value={editRecord.vehicle_no}
                  placeholder={editRecord.vehicle_no}
                  className='input-class'
                />
              </Form.Item>
              </div>
              <div className="addform__btnbox">
                <Form.Item>
                  <p
                    className="addform__btnbox__btn addform__btnbox__btn--paragraph"
                    style={{
                      color: THEME.PRIMARY_COLOR,
                    }}
                    onClick={handleEditCancel}
                  >
                    Cancel
                  </p>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="addform__btnbox__btn"
                    style={{
                      backgroundColor: THEME.PRIMARY_COLOR,
                    }}
                  >
                    Update
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </section>
      </Modal>

      {/* endModal */}
      {/* Bulk Upload Modal */}
      <Modal
        className="bulkUploadModal"
        title=""
        centered
        visible={isUploadModalVisible}
        onOk={handleUploadOk}
        onCancel={handleUploadCancel}
        footer={[]}
      >
        {isFirstStageVisible && (
          <section className="uploadSection">
            <h3 className="uploadSection__title">
            To upload multiple transporters at once, please download the template and upload with transporter details according to the format
            </h3>
            <div className="uploadSection__done">
              <p className="uploadSection__done__text">Already done ?</p>
            </div>
            <div className="uploadSection__download">
              <a 
                href={`data:text/csv;charset=utf-8,${getFileContentsForTemplate()}`}
                download="jitfinTemplate.csv"
               className="uploadSection__download__text">
                Download Template
              </a>
              <Button
                className="uploadSection__download__btn"
                style={{
                  backgroundColor: THEME.PRIMARY_COLOR,
                }}
                onClick={() => setIsFirstStageVisible(false)}
              >
                Upload file
              </Button>
            </div>
          </section>
        )}
        {!isFirstStageVisible && !isSuccessScreenVisible && (
          <section className="fileupload-section">
            <Title level={3} className="fileupload-section__title">
              Bulk upload transporters
            </Title>
            <div className="fileupload-section__dragger">
              <Dragger {...props}>
                <p className="fileupload-section__dragger__text">
                  Drag & drop <br></br>file
                </p>
              </Dragger>
            </div>
            <div className="fileupload-section__browse">
              <Upload {...props}>
                <Button className="fileupload-section__browse__btn">
                  Browse
                </Button>
              </Upload>
            </div>
          </section>
        )}
        {!isFirstStageVisible && isSuccessScreenVisible && (
          <section className="success-section">
            <div className="success-section__icon">
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: '24px' }}
              />
            </div>
            <h2 className="success-section__text">30 transporters added</h2>
            <div className="success-section__btnbox">
              <Button
                className="success-section__btnbox__btn"
                onClick={handleUploadCancel}
              >
                Ok
              </Button>
            </div>
          </section>
        )}
      </Modal>

      {/* endModal */}

      {/* Section Markup Start from Here  */}
      <Row>
        <Title level={3}>Transporter List</Title>
      </Row>
      <div className="transporters__add">
        <Button className="transporters__add__btn" onClick={showModal}>
          <PlusOutlined className='btn-icon-padding' />
          Add transporter
        </Button>
        <Button
          className="transporters__add__btn transporters__add__btn--bg"
          onClick={showUploadModal}
        >
        Bulk upload transporters
        </Button>
      </div>
      <div className="transporters__search">
        <div className="transporters__search__bar">
          <Search
            className='date-picker-border-radius'
            placeholder="Search by transporter name"
            onSearch={onSearch}
          />
        </div>
        <div className="transporters__search__delete">
          <Button className="transporters__search__delete--btn" onClick={() => handleBulkDeleteClick()}>
            Delete Transporter/s
          </Button>
        </div>
      </div>
      <div className="transporters__table custom_table">
        <Table
          columns={col}
          rowKey={(record) => record.id}
          dataSource={table}
          loading={loading}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          onChange={onChange}
          pagination={pagination}
        />
      </div>
    </section>
  );
};

export default Transporters;