import { notification, message } from 'antd';
import { IconType } from 'antd/es/notification';
import { ArgsProps } from 'antd/lib/message';

const DEFAULT_NOTIFICATION_TIMEOUT = 8;

export enum ENotificationType {
  NOTIFICATION = 'notification',
  MESSAGE = 'message'
}

export enum ENoticeType {
  INFO = 'info',
  SUCCESS= 'success',
  ERROR = 'error',
  WARNING = 'warning',
  LOADING ='loading'
}

const OpenNotification = (
  messageText: string,
  type: IconType,
  description?: string,
  duration?: number,
  notificationType: ENotificationType = ENotificationType.MESSAGE
) => {
  const durationOfNotification = duration || DEFAULT_NOTIFICATION_TIMEOUT;
  if (notificationType === ENotificationType.NOTIFICATION) {
    const args = {
      message: messageText,
      description,
      duration: durationOfNotification
    };
    notification[type](args);
  } else {
    const messageType = type as any;
    const messageArgs = {
      content: messageText,
      type: messageType,
      description,
      duration: durationOfNotification,
    } as ArgsProps;
    message.open(messageArgs);
  }
};

export default OpenNotification;