import { put, PutEffect, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import {IAction, IDocumentUploadStatus, IItem, IProspect} from '../../models';
import {
  DataSourceActions,
  deleteProspectSuccess,
  intitiateWhatsappMessageResult,
  requestFieldsOfDataSourceSuccess,
  requestGstFilingStatsForFileSuccess,
  requestProspectsOfDataSource,
  requestProspectsOfDataSourceSuccess,
  responseLatestFileUploadData,
  updateProspectSuccess,
  getGstinStatusSuccess,
  getWatchlistEntriesSuccess,
  deleteWatchlistEntriesSuccess,
  getWatchlistGstinStatusSuccess,
  deleteSupplierSuccess,
  getInvalidlistEntriesSuccess,
  deleteInvalidlistEntriesSuccess,
  getTransporterSuccess,
  getItemsSuccess,
  addTransporterSuccess,
  addItemSuccess,
  getItems,
  deleteTransporterSuccess,
  deleteItemSuccess,
  updateTransporterSuccess,
  updateItemSuccess,
  // exportInvalidlistCsvSuccess
} from '../actions/dataSourceActions';
import {
  IRequestAddSupplier,
  IRequestCreateDataSource,
  IRequestDocumentUploadData,
  IRequestFieldsForTemplate,
  IRequestItemsOfUser,
  IRequestProspectLatestFilingInfo,
  IRequestProspectsOfDataSource,
  IRequestUpdateProspect,
  IWhatsappResponse,
} from '../../models/api/dataSourceModels';
import { exceptionLogger, spinForWorker } from './utils';
import { globalApiService } from '../../services/apiService';
import { requestAnchorInfo } from '../actions/authActions';
import OpenNotification from '../../utils/notification';
import { useDispatch, useSelector } from 'react-redux';

function* requestProspectsOfDataSourceWorker(action: IAction) {
  const payload: IRequestProspectsOfDataSource = action.body;
  const response = yield globalApiService.requestProspectsOfDataSource(payload);
  const dataInResp = response.data;
  const prospectList: IProspect[] = dataInResp?.data.results?.map(
    (el: any) =>
      ({
        id: el.id,
        phoneNumber: el['phone number'],
        email: el.email_id,
        gstin: el.gstin,
        company: el.company,
        tradeName: el['Trade Name'],
        status: el.status,
        gst_status: el['GSTIN/UIN Status'],
        created_at: el.created_at,
      } as IProspect)
  );
  if (response && dataInResp) {
    yield put(
      requestProspectsOfDataSourceSuccess({
        count: dataInResp.data.count,
        prospects: prospectList,
      })
    );
  }
}

function* requestAddSupplier(action: IAction) {
  const payload: IRequestAddSupplier = action.body;
  yield globalApiService.addSupplier(payload);
  yield put(
    requestProspectsOfDataSource({
      dataSourceName: payload.dataSourceName,
      pageSize: 10,
      pageNumber: 1,
    } as IRequestProspectsOfDataSource)
  );
  OpenNotification('Supplier added successfully!', 'success');
}

function* requestGstFilingStatsForFile(action: IAction) {
  const payload: IRequestProspectLatestFilingInfo = action.body;
  const response: any = yield globalApiService.getGstFilingStatsForFile(
    payload
  );
  const dataInResp = response?.data;
  if (dataInResp) {
    yield put(
      requestGstFilingStatsForFileSuccess({
        prospectCount: dataInResp.prospect_count,
        validProspectCount: dataInResp.valid_prospect_count,
        prospectCountFiledGST1: dataInResp.count_filed_gstr_1,
        prospectCountFiledGSTR3B: dataInResp.count_filed_gstr_3b,
        filedGSTR1AsOf: dataInResp.filed_gstr1_as_of,
        filedGSTR3BAsOf: dataInResp.filed_gstr3b_as_of,
      })
    );
  }
}

function* requestCreateDataSourceWorker(action: IAction) {
  const payload: IRequestCreateDataSource = action.body;
  yield globalApiService.createDataSource(payload);
  yield put(requestAnchorInfo({ gstin: payload.gstin }));
}

function* requestUpdateProspect(action: IAction) {
  const payload: IRequestUpdateProspect = action.body;
  const response: any = yield globalApiService.updateProspect(payload);
  const dataInResp = response?.data?.data;
  if (dataInResp) {
    yield put(
      updateProspectSuccess({
        id: dataInResp.id,
        gstin: dataInResp.gstin,
        email: dataInResp.email_id,
        company: dataInResp.company,
        phoneNumber: dataInResp['phone number'],
        tradeName: dataInResp['Trade Name'],
        status: dataInResp.status,
        gst_status: dataInResp['GSTIN/UIN Status'],
        created_at: dataInResp.created_at
      })
    );
  }
}

function* requestDeleteProspect(action: IAction) {
  const payload: { prospectId: number } = action.body;
  // const response = yield globalApiService.deleteProspect(payload?.prospectId);
  const response = { data: { data: payload.prospectId } };
  const dataInResp = response?.data?.data;
  if (dataInResp) {
    yield put(deleteProspectSuccess({ prospectId: dataInResp }));
  }
}

function* requestFieldsOfDataSource(action: IAction) {
  const payload: IRequestFieldsForTemplate = action.body;
  const response = yield globalApiService.getFieldsForTemplateForDataSource(
    payload
  );
  const dataInResp = response?.data;
  if (dataInResp) {
    yield put(requestFieldsOfDataSourceSuccess(dataInResp));
  }
}

function* requestLatestFileUploadDataWatcher(action: IAction) {
  const payload: IRequestDocumentUploadData = action.body;
  const response = yield globalApiService.getDocumentUploadData({
    dataSourceName: payload.dataSourceName,
  });
  const dataInResp = response?.data;
  if (dataInResp) {
    const documentsInfo = dataInResp.results;
    if (documentsInfo.length > 0) {
      const latestDocumentInfoRaw = documentsInfo[0];
      const completedAtRaw: string = latestDocumentInfoRaw.completed_at;
      const completedAt: Date | '--' = completedAtRaw
        ? moment(completedAtRaw, moment.ISO_8601).toDate()
        : '--';
      const latestDocumentInfo = {
        id: latestDocumentInfoRaw.id,
        fileName: latestDocumentInfoRaw.file_name,
        passedRecords: latestDocumentInfoRaw.passed_records,
        failedRecords: latestDocumentInfoRaw.failed_records,
        totalRecords: latestDocumentInfoRaw.total_records,
        completedAt,
      } as IDocumentUploadStatus;
      yield put(
        responseLatestFileUploadData({
          latestFileUploadInfo: latestDocumentInfo,
        })
      );
    }
  }
}

function* intitiateWhatsappMessage(action: IAction) {
  try {
    const payload = action.body;
    const response = yield globalApiService.initiateWhatsappMessage(payload);
    const isMessageSent = response.data.data?.message
      .toLowerCase()
      .includes('success');
    const message = response.data?.message || 'N.A';
    yield put(
      intitiateWhatsappMessageResult({
        isMessageSent,
        message,
        tradeName: payload.tradeName,
      } as IWhatsappResponse)
    );
    isMessageSent
      ? OpenNotification('Whatsapp message sent sucessfully', 'success')
      : OpenNotification(
          response.data.data?.message || 'Whatsapp message not sent',
          'error'
        );
    // eslint-disable-next-line no-empty
  } catch (err) {
    OpenNotification('Whatsapp message not sent', 'error');
  }
}
function* getGstinStatus(_: IAction) {
  const response = yield globalApiService.getGstinStatus();
  const responseBody = response.data.data
if(response && responseBody){
  yield put(getGstinStatusSuccess(responseBody))
}
}

function* getWatchlistEntries(action: IAction) {
  const payload = action.body
  const response = yield globalApiService.getWatchlistEntries(payload);
  const responseBody = response.data.data
if(response && responseBody){
  yield put(getWatchlistEntriesSuccess(responseBody))
}
}
function* deleteWatchlistEntries(action: IAction) {
  const payload: { ids: number[] } = action.body;
  const response = yield globalApiService.deleteFromWatchlist(payload?.ids);
  // const response = { data: { data: payload.ids } };
  // const dataInResp = response?.data?.data;
  if (response.data?.success) {
    OpenNotification(response.data?.data?.message, 'success');
    // yield globalApiService.getWatchlistEntries();
    yield put(deleteWatchlistEntriesSuccess(response));
  }
}

//getWatchlistGstinStatus
function* getWatchlistGstinStatus(_: IAction) {
  const response = yield globalApiService.getWatchlistGstinStatus();
  const responseBody = response.data.data
if(response && responseBody){
  yield put(getWatchlistGstinStatusSuccess(responseBody))
}
}

function* deleteSupplier(action: IAction) {
  const payload: { ids: number[] } = action.body;
  const response = yield globalApiService.deleteProspect(payload?.ids);
  // const response = { data: { data: payload.ids } };
  // const dataInResp = response?.data?.data;
  if (response.data?.success) {
    OpenNotification(response.data?.data?.message, 'success');
    // yield globalApiService.getWatchlistEntries();
    yield put(deleteSupplierSuccess(response));
  }
}

function* getInvalidlistEntries(action: IAction) {
  const payload = action.body
  const response = yield globalApiService.getInvalidlistEntries(payload);
  const responseBody = response.data.data
if(response){
  yield put(getInvalidlistEntriesSuccess(responseBody))
}
}
function* deleteInvalidlistEntries(action: IAction) {
  const payload = action.body;
  const response = yield globalApiService.deleteInvalidlistEntries(payload);
  // const response = { data: { data: payload.ids } };
  // const dataInResp = response?.data?.data;
  if (response) {
    // yield globalApiService.getWatchlistEntries();
    if(response.data.success){
      OpenNotification(response.data.data.message,'success')
    }else {
      OpenNotification(response.data.data.message,'error')
    }
    yield put(deleteInvalidlistEntriesSuccess(response));
  }
}

// function* exportInvalidlistCsv(action: IAction) {
//   const payload: { dataSourceID: number | string } = action.body;
//   const response = yield globalApiService.exportInvalidlistCsv(payload?.dataSourceID);
//   if (response) {
//     yield put(exportInvalidlistCsvSuccess(response));
//   }
// }

function* getTransporter(action: IAction) {
  const payload = action.body

  const response = yield globalApiService.getTransporter(payload);

  const responseBody = response.data.data
if(response && responseBody){
  yield put(getTransporterSuccess(responseBody))
}
}

function* getItemsOfUserWorker(action: IAction) {
  const payload: IRequestItemsOfUser = action.body;
  const response = yield globalApiService.getItems(payload);
  const dataInResp = response.data;
  const itemList: IItem[] = dataInResp?.data.results;
  if (response && dataInResp) {
    yield put(
      getItemsSuccess({
        count: dataInResp.data.count,
        items: itemList,
      })
    );
  }
}

function* addItem(action: IAction) {
  const payload: IRequestItemsOfUser = action.body;
  const response = yield globalApiService.addItem(payload);
  if(response){
    if(response.data.success){
      OpenNotification(response.data.data.message, 'success');
    }else {
      OpenNotification(response.data.data.message, 'error');
    }
    yield put(addItemSuccess(response));
  }
  // yield put(getItems({
  //   userId:payload.userId,
  //   pageSize:10,
  //   pageNumber:1
  // } as IRequestItemsOfUser))

  // OpenNotification('Item Added Successfully!', 'success');

}

function* addTransporter(action: IAction) {
  const payload = action.body

  const response = yield globalApiService.addTransporter(payload);

  const responseBody = response.data.data
  if(response && responseBody){
    if(response.data.success){
      OpenNotification(response.data.data.message,'success')
    }else{
      OpenNotification(response.data.data.message,'error')
    }
    yield put(addTransporterSuccess(responseBody))
  }
}
function* deleteTransporter(action: IAction) {
  const payload: { ids: number[] } = action.body;
  const response = yield globalApiService.deleteTransporter(payload?.ids);
  if (response) {
    yield put(deleteTransporterSuccess(response));
    OpenNotification('Transporters deleted successfully!', 'success');
  }
}
function* deleteItem(action: IAction) {
  const payload: { ids: number[] } = action.body;
  const response = yield globalApiService.deleteItem(payload?.ids);
  if (response) {
    yield put(deleteItemSuccess(response));
    OpenNotification('Items deleted successfully!', 'success');
  }
}

function* updateTransporter(action: IAction) {
  const payload = action.body;
  const response = yield globalApiService.updateTransporter(payload);
  if (response) {
    if(response.data.success){
      OpenNotification(response.data.data.message, 'success');
    }else {
      OpenNotification(response.data.data.message, 'error');
    }
    yield put(updateTransporterSuccess(response));
  }
}
function* updateItem(action: IAction) {
  const payload = action.body;
  const response = yield globalApiService.updateItem(payload);
  
  if (response.data.success) {
    OpenNotification(response.data.data.message,'success')
    yield put(updateItemSuccess(response));
  }else {
    OpenNotification(response.data.data.message,'error')
  }
}

export default function* DataSourceWatcher(): IterableIterator<any> {
  yield takeEvery(
    DataSourceActions.REQUEST_PROSPECTS_OF_DATA_SOURCE,
    (action) => exceptionLogger(requestProspectsOfDataSourceWorker(action))
  );
  yield takeEvery(DataSourceActions.ADD_PROSPECT, (action) =>
    exceptionLogger(spinForWorker(requestAddSupplier(action)))
  );
  yield takeEvery(DataSourceActions.REQUEST_CREATE_DATA_SOURCE, (action) =>
    exceptionLogger(requestCreateDataSourceWorker(action))
  );
  yield takeEvery(
    DataSourceActions.REQUEST_GST_FILING_STATS_FOR_FILE,
    (action) => exceptionLogger(requestGstFilingStatsForFile(action))
  );
  yield takeEvery(DataSourceActions.UPDATE_PROSPECT, (action) =>
    exceptionLogger(requestUpdateProspect(action))
  );
  yield takeEvery(DataSourceActions.DELETE_PROSPECT, (action) =>
    exceptionLogger(requestDeleteProspect(action))
  );
  yield takeEvery(DataSourceActions.REQUEST_FIELDS_FOR_DATA_SOURCE, (action) =>
    exceptionLogger(requestFieldsOfDataSource(action))
  );
  yield takeEvery(DataSourceActions.REQUEST_LATEST_FILE_UPLOAD_DATA, (action) =>
    exceptionLogger(requestLatestFileUploadDataWatcher(action))
  );
  yield takeEvery(DataSourceActions.INITIATE_WHATSAPP_MESSAGE, (action) =>
    exceptionLogger(intitiateWhatsappMessage(action))
  );

  yield takeEvery(DataSourceActions.GSTIN_FILTER_STATUS, (action) =>
    exceptionLogger(getGstinStatus(action))
  );
  yield takeEvery(DataSourceActions.GET_WATCHLIST_ENTRIES, (action) =>
  exceptionLogger(getWatchlistEntries(action))
);
  yield takeEvery(DataSourceActions.DELETE_WATCHLIST_ENTRIES, (action) =>
  exceptionLogger(deleteWatchlistEntries(action))
);
  yield takeEvery(DataSourceActions.GSTIN_WATCHLIST_FILTER_STATUS, (action) =>
  exceptionLogger(getWatchlistGstinStatus(action))
);
yield takeEvery(DataSourceActions.DELETE_SUPPLIER, (action) =>
exceptionLogger(deleteSupplier(action))
);
yield takeEvery(DataSourceActions.GET_INVALID_LIST, (action) =>
exceptionLogger(getInvalidlistEntries(action))
);
yield takeEvery(DataSourceActions.DELETE_INVALID_LIST, (action) =>
exceptionLogger(deleteInvalidlistEntries(action))
);
yield takeEvery(DataSourceActions.GET_TRANSPORTER, (action) =>
exceptionLogger(getTransporter(action))
);
yield takeEvery(DataSourceActions.ADD_TRANSPORTER, (action) =>
exceptionLogger(addTransporter(action))
);
yield takeEvery(DataSourceActions.DELETE_TRANSPORTER, (action) =>
exceptionLogger(deleteTransporter(action))
);
yield takeEvery(DataSourceActions.UPDATE_TRANSPORTER, (action) =>
exceptionLogger(updateTransporter(action))
);
// yield takeEvery(DataSourceActions.EXPORT_INVALIDLIST_CSV, (action) =>
// exceptionLogger(exportInvalidlistCsv(action))
// );
  yield takeEvery(
    DataSourceActions.GET_ITEM,
    (action) => exceptionLogger(getItemsOfUserWorker(action))
  );
  yield takeEvery(DataSourceActions.ADD_ITEM, (action) =>
    exceptionLogger(addItem(action))
  );
  yield takeEvery(DataSourceActions.UPDATE_ITEM, (action) =>
    exceptionLogger(updateItem(action))
  );
  yield takeEvery(DataSourceActions.DELETE_ITEM, (action) =>
    exceptionLogger(deleteItem(action))
  );
}
