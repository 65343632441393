import React from 'react';

import { Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { useDispatch, useSelector } from 'react-redux';
import Paragraph from 'antd/es/typography/Paragraph';
import { useHistory } from 'react-router-dom';
import { IState } from '../models';
import GSTINForm from '../components/GSTINForm';
import { requestGstAccountLogin } from '../store/actions/authActions';

import { ROUTES } from '../constants/routePaths';
import BackToLoginBtn from '../components/backToLogin';

export default function GSTINPage() {
  const authState = useSelector((state: IState) => state.authReducer);
  const storeDispatch = useDispatch();
  const history = useHistory();

  return (
    <div>
      <Row
        style={{ height: '5vh', paddingRight: '10px' }}
        justify="end"
        align="middle"
      >
        <BackToLoginBtn />
      </Row>
      <Row justify="center" style={{ height: '40vh', width: '100vw' }}>
        <Col style={{ paddingTop: '10%' }} sm={24}>
          <Title level={1} style={{ textAlign: 'center' }}>
            Almost there
          </Title>
          <Paragraph style={{ textAlign: 'center' }}>
            {`Please Confirm Your GSTIN associated with ${authState.phoneNumber} and KYSS`}
          </Paragraph>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col style={{ width: '300px' }}>
          <GSTINForm
            onSubmitFn={(values) => {
              storeDispatch(
                requestGstAccountLogin(
                  {
                    gstin: values.gstin,
                    phoneNumber: authState.phoneNumber,
                  },
                  () => {
                    history.push(ROUTES.DASHBOARD);
                  }
                )
              );
            }}
            fetchRegisteredGSTINs
          />
        </Col>
      </Row>
    </div>
  );
}
