/* eslint-disable max-len */
import { Card, Button, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import { Col } from 'antd/es/grid';
import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { getReportList } from '../store/actions/ReconcilationActions';
import { THEME } from '../constants/theme';

interface IRecornReport {
  setActiveStep?: any;
}

enum ReportTrackerStatuses {
  SUCCESS = 3,
  FAILED = 2,
  PENDING = 1,
  CREATED = 0,
}

const RecornReport: React.FunctionComponent<IRecornReport> = ({
  setActiveStep,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const reports = useSelector((state: any) => state.reconReducer.reports);
  useEffect(() => {
    dispatch(getReportList(null));
  }, [dispatch]);
  
  return (
    <div className="flex-y align-center  download-gst-r2a-wrapper">
      {reports &&
        reports.results &&
        reports.results.map((report: any) => (
          <>
            <Col
              key={1}
              style={{ padding: '1vw', width: '100%', borderRadius: '10px' }}
            >
              <Card
                className="flex-x"
                style={{
                  boxShadow: THEME.SHADOW,
                  width: '100%',
                  borderRadius: '10px',
                }}
              >
                <div style={{ width: '100%' }} className="recorn-card">
                  <div className="">
                    <div className="title"> Recon Report</div>
                    <div className="subtitle">
                      {moment(report.created_on).format('DD MMM YYYY hh:mm a')}
                    </div>
                  </div>
                  <div className="ml-auto flex-x align-center">
                    {report.status === ReportTrackerStatuses.FAILED && (
                      <Text>The report generation has failed.</Text>
                    )}
                    {(report.status === ReportTrackerStatuses.PENDING || report.status === ReportTrackerStatuses.CREATED) && (
                      <div>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color:'#462E90' }} spin />} />
                        <Text style={{ paddingLeft: '10px' }}>In Progress</Text>
                      </div>
                    )}
                    {report.status === ReportTrackerStatuses.SUCCESS && (
                      <>
                        <Button
                          className="white-btn mr--15"
                          onClick={() => history.push(`/reconcil-summary/${report.id}`)}
                        >
                          SUMMARY
                        </Button>
                        <Button
                          className="orange-btn"
                          onClick={() => history.push(`/reconcil-report/${report.id}`)}
                        >
                          VIEW REPORT
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </Card>
            </Col>
          </>
        ))}
      {/* {   <>
                <Col key={1} style={{ padding: '1vw', width: '100%', borderRadius: '10px' }}>
                    <Card className="flex-x" style={{ boxShadow: THEME.SHADOW, width: '100%',borderRadius: '10px' }}>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div className="flex-y">
                                <div className="title"> Recon Report (Latest)</div>
                                <div className="subtitle">
                                    20 october 2020, 11:18 AM
                            </div>
                            <div className="subtitle" style={{color:'#0078EF'}}>
                                Report is getting generated. Please wait.
                            </div>
                            </div>
                        </div>
                    </Card>
                </Col>
            </>} */}
      {/* <>
                <Col key={1} style={{ padding: '1vw', width: '100%', borderRadius: '10px' }}>
                    <Card className="flex-x" style={{ boxShadow: THEME.SHADOW, width: '100%', borderRadius: '10px' }}>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div className="flex-y">
                                <div className="title"> Recon Report</div>
                                <div className="subtitle">
                                    20 october 2020, 11:18 AM
                            </div>
                            </div>
                            <div className="ml-auto flex-x align-center">
                                <Button className="white-btn mr--15" onClick={() => { history.push('/reconcil-summary') }}>
                                    SUMMARY
                                </Button>
                                <Button className="orange-btn" onClick={() => { history.push('/reconcil-report') }}>
                                    VIEW REPORT
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Col>
            </> */}
    </div>
  );
};

export default RecornReport;
