import { Card, Button } from 'antd';
import React, { useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { THEME } from '../constants/theme';
import { getSummary } from '../store/actions/ReconcilationActions';

interface IReconeSummary {
  setActiveStep?: any;
}

const ReconeSummary: React.FunctionComponent<IReconeSummary> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const summary = useSelector((state: any) => state.reconReducer.summary);

  useEffect(() => {
    dispatch(getSummary({ id: params.id }));
  }, [dispatch, params.id]);

  return (
    <div className="flex-y align-center record-summary-wrapper">
      <div className="flex row-wrap">
        <div
          key={1}
          className="mtb--32 mlr--50 border-radius--10"
          style={{ borderRadius: '10px' }}
        >
          <Card
            className="flex-x border-radius--10 border-top-green"
            style={{ width: '400px', height: '140px', boxShadow: THEME.SHADOW }}
          >
            <div className="subtitle fs--32">
              {summary && summary.perfect_match_percentage}
              %
            </div>
            <div className="subtitle fs--16 mt--25">Perfect Match</div>
          </Card>
        </div>

        <div key={1} className="mtb--32  mlr--50 border-radius--10">
          <Card
            className="flex-x border-radius--10 border-top-orange"
            style={{ width: '400px', height: '140px', boxShadow: THEME.SHADOW }}
          >
            <div className="subtitle fs--32">
              {summary && summary.probable_match_percentage}
              %
            </div>
            <div className="subtitle fs--16  mt--25">Probable Match</div>
          </Card>
        </div>

        <div key={1} className="mtb--32  mlr--50 border-radius--10">
          <Card
            className="flex-x border-radius--10 border-top-red"
            style={{ width: '400px', height: '140px', boxShadow: THEME.SHADOW }}
          >
            <div className="subtitle fs--32">
              {(summary && summary.no_match_percentage) || 0}
              %
            </div>
            <div className="subtitle fs--16  mt--25">Did Not Match</div>
          </Card>
        </div>
      </div>

      <div className="flex row-wrap">
        <div key={1} className="mtb--32  mlr--50 border-radius--10">
          <Card
            className="flex-x border-radius--10 border-top-red"
            style={{ width: '400px', height: '140px', boxShadow: THEME.SHADOW }}
          >
            <div className="title fill-width  text-center mb--15 light-bold fs--16">
              Invoice Number match not found
            </div>
            <div className="flex-x subtitle center">
              <div className="flex-y center">
                <span className="fs--30 center">
                  {summary && summary.inv_number_not_match_count}
                </span>
              </div>
            </div>
          </Card>
        </div>

        <div key={1} className="mtb--32  mlr--50 border-radius--10">
          <Card
            className="flex-x border-radius--10 border-top-red"
            style={{ width: '400px', height: '140px', boxShadow: THEME.SHADOW }}
          >
            <div className="title fill-width  text-center mb--15 light-bold fs--16">
              GSTIN match not found
            </div>
            <div className="flex-x subtitle center">
              <div className="flex-y center">
                <span className="fs--30 center">
                  {summary && summary.gstin_not_match_count}
                </span>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <Button
        className="next-btn flex-x align-center center fs--16 lh--20"
        onClick={() => {
          history.push(`/reconcil-report/${params.id}`);
        }}
        style={{marginBottom:'60px',backgroundColor:'#462E90'}}
      >
        View Report
      </Button>
    </div>
  );
};

export default ReconeSummary;
