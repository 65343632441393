import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import { ConfigureSentry } from './utils/sentry';
import './assets/scss/app.scss';

ConfigureSentry();

const data=localStorage.getItem('reduxState');

let persistedState = data  ? JSON.parse(data)  : {};
const reduxStateTime = Date.parse(localStorage.reduxStateTime);
const date:any=new Date();

const tt = Date.parse(date);
const d=tt-reduxStateTime;

if(!reduxStateTime || (d/1000) >43200){
  persistedState={};
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store(persistedState)}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

//
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// 
serviceWorker.unregister();
