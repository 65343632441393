import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Tag, Input, DatePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { ArrowLeftOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';

import { globalApiService } from '../../services/apiService';
import { setTimeout } from 'timers';

const queryString = require('query-string');




const SupplierReports: React.FunctionComponent<any> = (props) => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [name, setName] = useState('');
  const [refreshData, setRefreshData] = useState(0);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function onSelectChange(selectedRowKeysLo: any) {
    setSelectedRowKeys(selectedRowKeysLo);
  }

  const [dateString, setDateString] = useState();



  function updateLastTaxFilingDate(dateLo: any, dateStringLo: any) {
    console.log('TEST DATE ', dateLo);
    setDate(dateLo);
    setDateString(dateString);
    setPagination({ ...pagination, date: dateStringLo });

  }

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100,
    search_q: '',
    date: ''
  });
  useEffect(() => {
    componentDidMount();
    onReloadDate();
  }, []);
  useEffect(() => {
    componentDidMount();
  }, [name, dateString]);
  useEffect(() => {
    onReloadOutlined();
  }, [refreshData,dateString,date]);

  
  function onClickBack() {
    props.data();
  }

  function onAdvanceFilter() {
    // props.data();
    alert(`AdvanceFilter ${dateString}`);
  }

  function onReloadOutlined() {
    fetch2(pagination);
  }

  function onReloadDate() {
    setTimeout(function(){
      setRefreshData(new Date().getSeconds());

      onReloadDate();

    },20000);
  }

  const urlParams = queryString.parse(history.location.search) || {};

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });


  function componentDidMount() {
    fetch(pagination);
  }

  function handleTableChange(pagin: any, filters: any, sorter: any) {
    fetch(pagin);
  }
  async function fetch2(params: any){
    const data = await globalApiService.supplierReportList(params);
    const dataRes: any = [];
    for (let i = 0; i < data.data.data.results.length; i++) {
      const re = data.data.data.results[i];
      if (re.gst_data_downloaded_at == null) {
        re.gst_data_downloaded_at = '';
      }
      if (re.is_gst_data_download_requested == null) {
        re.is_gst_data_download_requested = '';
      }
      dataRes.push({
        key: re.tracker_id,
        id: re.id,
        document_upload_obj: re.document_upload_obj,
        tracker_id: re.tracker_id,
        is_gst_data_download_requested: re.is_gst_data_download_requested,
        gst_data_downloaded_at: re.gst_data_downloaded_at,
        is_gst_report_file_requested: re.is_gst_report_file_requested,
        report_file_generated_at: re.report_file_generated_at,
        created_on: re.created_on,
        view: false,
        isNew: false,
        completion_percentage: re.completion_percentage

      });
    }
    setPagination({ ...pagination, current: params.current, total: data.data.data.count });
    setTableData(dataRes);
    
  }


  async function fetch(params: any) {
    setLoading(true);
    await fetch2(params);
    setLoading(false);

  }

  const columns = [
    {
      title: 'Supplier Report',
      dataIndex: 'tracker_id',
      width: '20%',
    },
    {
      title: 'Supplier Generation Date',
      dataIndex: 'created_on',
      render: (createdOn: any) => {
        const dateLo = new Date(createdOn);
        return moment(dateLo).format('yyyy-MM-DD LT');
      },
      width: '50%',
    },
    {
      title: '',
      dataIndex: '',
      render(record: any) {
        let dateTemp = new Date();
        dateTemp.setDate(dateTemp.getDate() - 1);

        const progerss = parseInt(record.completion_percentage.split('%')[0], 10);



        return (
          <>
            {
              progerss &&
                progerss < 100 ?
                <span style={{ color: '#ff4d4f',float:'right' }}>{record.completion_percentage}</span>
                :
                new Date(record.created_on.split('T')[0]).getTime()
                  > dateTemp.getTime()
                  ? (
                    <span style={{float:'right'}} >
                      <Tag style={{ backgroundColor: '#3dd6ab', borderRadius: '2px', color: '#fff' }}>
                        new
                      </Tag>
                    </span>
                  ) : ''
            }

          </>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      width: '15%',
      render(record: any) {
        const progerss = parseInt(record.completion_percentage.split('%')[0], 10);

        return (
          <>
            {
              progerss &&
                progerss < 100 ? <span style={{ color: '#462E90',float:'right' }}>Work is in progress</span> :
                <span onClick={() => { viewReport(record.key); }} style={{ color:'#462E90',cursor: 'pointer',textAlign:'center', textDecoration:'underline' }}>View</span>
            }

          </>
        );



      },
    },
  ];

  function viewReport(key: any) {
    props.viewReport(key);
    // history.push(ROUTES.DOWNLOAD_CENTER_V2_REPORT_VIEW+"?key="+key);
  }
  function searchByName(e: any) {
    setPagination({ ...pagination, search_q: e.currentTarget.value });
    console.log('pagination',pagination);
    setName(e.currentTarget.value);
  }
  return (
    <>
      <Row>
        <Col sm={24}>
          <Row>
            <Col sm={24}>
              <Title level={3}>Report Central</Title>
            </Col>
          </Row>
        </Col>

        <Col sm={24} className="bxsh" style={{padding: '2px 0px'}}>
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={1}>
              <Button type="primary" style={{backgroundColor:'#462E90',borderColor:'#462E90'}} icon={<ArrowLeftOutlined />} danger onClick={onClickBack} />
            </Col>
            <Col sm={23}>
            <Title level={3} style={{ fontSize: '15px', margin: '5px', float: 'left', color: '#4532BF',borderBottom: '2px solid #4532BF',cursor:'pointer'}}><span onClick={() => { props.tab(2); }}>Supplier Reports</span></Title>
            <Title level={3} style={{ fontSize: '15px', margin: '5px', float: 'left', color: '#979797',cursor:'pointer'}}><span onClick={() => { props.tab(6); }}>Older Supplier Reports</span></Title>
              <Title level={3} style={{ fontSize: '15px', margin: '5px', float: 'left', color: '#979797',cursor:'pointer'}}><span onClick={() => { props.tab(4); }}>Recon Report</span></Title>
            </Col>
          </Row>
        </Col>



        <Col sm={24} style={{ padding: '5px 0px 0px' }} className="bxsh">
          <Row style={{ backgroundColor: '#FFFFFF' }}>

            <Col sm={12}>
              <Title level={3} style={{ fontSize: '15px', margin: '5px' }}>All Supplier Reports</Title>
            </Col>
            <Col sm={6} />
            <Col sm={6}>
              <Button type="primary" danger style={{ width: '100%',backgroundColor:'#462E90',borderColor:'#462E90'}} onClick={props.onClickGenerate}>Generate Supplier Report</Button>
            </Col>

          </Row>
        </Col>



        <Col sm={24} style={{ padding: '5px 0px 0px' }} className="bxsh">
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={6}>
              <Input placeholder="Search by Report Id" onChange={(e) => { searchByName(e); }} value={name} />
              <SearchOutlined style={{ color: '#462E90', position: 'absolute', right: '5px', top: '10px' }} />
            </Col>
            <Col sm={14} />
            <Col sm={3}>
              {date ?
                <DatePicker placeholder="Filter by Date" defaultValue={moment(date, 'YYYY-MM-DD')} onChange={updateLastTaxFilingDate} />
                :
                <DatePicker placeholder="Filter by Date" defaultValue={dateString} onChange={updateLastTaxFilingDate} />}

            </Col>
            <Col sm={1}>
              <Button style={{ float: 'right', color: '#462E90' }} icon={<ReloadOutlined />} onClick={onReloadOutlined} />
            </Col>

          </Row>
        </Col>


        <Col sm={24} style={{ padding: '5px' }} className="bxsh">

          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={24}>
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tableData}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                size="small"
              />
            </Col>
          </Row>
        </Col>




      </Row>




    </>
  );
};

export default SupplierReports;
