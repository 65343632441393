import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, Button, Steps, Upload, message, Table, Tag, Input, DatePicker, Modal, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import Icon, { ArrowLeftOutlined, CheckCircleOutlined, CheckOutlined, CloudUploadOutlined, ControlOutlined, DownloadOutlined, ReloadOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import RecornReport from '../../components/RecornReport';
import ReportHistory from '../../components/reportList';
import RequestReportCards from '../../components/requestReportCards';
import {
  genSupRepIcon1,
  genSupRepIcon2,
} from '../../utils/constants';
import UploadPR from '../../components/UploadPr';
import UploadPRV2 from '../../components/reportsV2/UploadPrV2';
import { ROUTES } from '../../constants/routePaths';
import { ISupplierReport } from '../../models';

import { globalApiService } from '../../services/apiService';

const queryString = require('query-string');




const ReconCenterReportView: React.FunctionComponent<any> = (props) => {
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportId, setReportId] = useState(0);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  function onSelectChange(selectedRowKeys: any) {
    setSelectedRowKeys(selectedRowKeys);
  }

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 100
  });
  useEffect(() => {
    componentDidMount();
  }, []);


  function onClickBack() {
    props.data();
  }
  const urlParams = queryString.parse(history.location.search) || {};

  const getRandomuserParams = (params: any) => ({
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  });


  function componentDidMount() {
    fetch(pagination);
  }

  function handleTableChange(pagination: any, filters: any, sorter: any) {
    fetch(pagination);

  }
  
  async function fetch(params: any) {
    setLoading(true);
    const data=  await globalApiService.supplierReportList(params);
    const dataRes:any = [];
    for (let i = 0; i < data.data.data.results.length; i++) {
      const re=data.data.data.results[i];
      if(re.gst_data_downloaded_at ==null){
        re.gst_data_downloaded_at='';
      }
      if(re.is_gst_data_download_requested ==null){
        re.is_gst_data_download_requested='';
      }
      dataRes.push({
        key: re.id,
        name: re.tracker_id,
        phone: re.tracker_id,
        dob: re.tracker_id,
        gender: re.is_gst_data_download_requested,
        view:false,
        isNew:false
        
      });
    }
    setPagination({ ...pagination, current:params.current, total:data.data.data.count });
    setTableData(dataRes);
    setLoading(false);
    
  }

  const columns = [
    {
      title: 'Recon Report',
      dataIndex: 'phone',
      width: '20%',
    },
    {
      title: 'Report Name',
      dataIndex: 'name',
      render: (name: any) => `${name.title} ${name.first} ${name.last} `,
    },
    {
      title: 'Report Generation Date',
      dataIndex: 'dob',
      render: (dob: any) => `${dob.date}`,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
    },
    {
      title: 'Action',
      dataIndex: 'key',
      render: (record: any) => (
        <>
          <span>
            <Tag onClick={() => { viewSummary(record); }}>
              Summary
            </Tag>
          </span>
          <Tag onClick={() => { viewReport(record); }}>View</Tag>
        </>
      ),
    },
  ];

  function viewSummary(key: any) {
    setIsModalVisible(true);
    setReportId(key);
    // props.viewReport(key);
    // history.push(ROUTES.DOWNLOAD_CENTER_V2_REPORT_VIEW+"?key="+key);
  }

  function viewReport(key: any) {
    props.viewReport(key);
    // history.push(ROUTES.DOWNLOAD_CENTER_V2_REPORT_VIEW+"?key="+key);
  }

  function handleOk() {
    setIsModalVisible(false);
  }
  function handleCancel() {
    setIsModalVisible(false);
  }

  return (
    <>
      <Row>
        <Col sm={24}>
          <Row>
            <Col sm={24}>
              <Title level={3}>Report Central</Title>
            </Col>
          </Row>
        </Col>

        <Col sm={24} style={{ padding: '2px 0px' }}  className='bxsh'>
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={1}>
              <Button type="primary" style={{backgroundColor:'#462E90',borderColor:'#462E90'}} icon={<ArrowLeftOutlined />} danger onClick={onClickBack} />
            </Col>
            <Col sm={23}>
              <Title level={3} style={{ fontSize: '15px', margin: '5px', float: 'left', color: '#979797',cursor:'pointer'}}><span onClick={() => { props.tab(2); }}>Supplier Reports</span></Title>
              <Title level={3} style={{ fontSize: '15px', margin: '5px', float: 'left', color: '#979797',cursor:'pointer'}}><span onClick={() => { props.tab(6); }}>Older Supplier Reports</span></Title>
              <Title level={3} style={{ fontSize: '15px', margin: '5px', float: 'left', color: '#4532BF', borderBottom: '2px solid #4532BF',cursor:'pointer'}}><span onClick={() => { props.tab(4); }}>Recon Report</span></Title>
            </Col>
          </Row>
        </Col>



        {/* <Col sm={24} style={{ padding: '5px 0px 0px' }} className="bxsh">
          <Row style={{ backgroundColor: '#FFFFFF' }}>

            <Col sm={12}>
              <Title level={3} style={{ fontSize: '15px', margin: '5px' }}>All Recon Reports</Title>
            </Col>
            <Col sm={6} />
            <Col sm={6}>
              <Button type="primary" danger style={{ width: '100%' }}>Generate Recon Report</Button>
            </Col>

          </Row>
        </Col> */}



        {/* <Col sm={24} style={{ padding: '5px 0px 0px' }} className="bxsh">
          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={4}>
              <Input placeholder="Search by GSTIN/Name" />
              <SearchOutlined style={{ color: '#ff4d4f', position: 'absolute', right: '5px', top: '10px' }} />
            </Col>
            <Col sm={12} />
            <Col sm={4}>
              <DatePicker placeholder="Filter by Date" />
            </Col>
            <Col sm={3}>
              <Button type="primary" style={{ float: 'right' }} icon={<ControlOutlined />} danger onClick={onClickBack}>
                Advanced Filters

              </Button>
            </Col>
            <Col sm={1}>
              <Button style={{ float: 'right', color: '#ff4d4f' }} icon={<ReloadOutlined />} onClick={onClickBack} />
            </Col>

          </Row>
        </Col> */}


        {/* <Col sm={24} style={{ padding: '5px' }} className="bxsh">

          <Row style={{ backgroundColor: '#FFFFFF' }}>
            <Col sm={24}>
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tableData}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                size="small"
              />
            </Col>
          </Row>
        </Col> */}
        <div className='recon-report-container'>
        <RecornReport/>
        </div>




      </Row>
      <Modal
        title={(
          <>
            <Row>
              <Col sm={24}> 
                {' '}
                <span> 
                  {' '}
                  {reportId}
                  {' '}
                </span> 
                {' '}
                <span> summary </span>
                {' '}
              </Col>

            </Row>

          </>
)}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            style={{ backgroundColor: '#ff4d4f', color: '#fff', borderRadius: '5px' }}
            onClick={handleOk}
          >
            VIEW REPORT
          </Button>,
        ]}
      >
        <Row>
          <Col sm={11}>
            <Card className="recon-success">
              Card content
            </Card>
          </Col>
          <Col sm={2} />
          <Col sm={11}>
            <Card className="recon">
              Card content
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop:'10px' }}>
          <Col sm={11}>
            <Card className="recon">
              Card content
            </Card>
          </Col>
          <Col sm={2} />
          <Col sm={11}>
            <Card className="recon">
              Card content
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop:'10px' }}>
          <Col sm={11}>
            <Card className="recon">
              Card content
            </Card>
          </Col>
          <Col sm={2} />
          <Col sm={11}>
            <Card className="recon">
              Card content
            </Card>
          </Col>
        </Row>
      </Modal>



    </>
  );
};

export default ReconCenterReportView;
