import React, { useEffect, useState, useReducer } from 'react';
import Table from 'antd/es/table/Table';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'antd/es/modal/Modal';
import { Button } from 'antd/es';
import {FiEdit3, RiDeleteBinLine} from 'react-icons/all';
import moment from 'moment';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons/lib';
import { IProspect, IState } from '../models';
import {
  deleteProspect,
  intitiateWhatsappMessage,
  requestProspectsOfDataSource,
  updateProspect,
  deleteSupplier
} from '../store/actions/dataSourceActions';
import {
  IRequestProspectsOfDataSource,
  IWhatsappRequestBody,
} from '../models/api/dataSourceModels';
import ProspectForm from './prospectForm';
import { whatsappIcon } from '../utils/constants';
import {
  PlusOutlined,
  FilterOutlined,
  ApartmentOutlined,
  EditFilled,
  DeleteFilled,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import {
  Badge,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Popconfirm,
  Radio,
  Tabs,
  DatePicker,
  Upload,
  Dropdown,
  Menu,
  Select,
} from 'antd';
import { THEME } from '../constants/theme';
import { globalApiService } from '../services/apiService';
import OpenNotification from '../utils/notification';
import {AxiosError} from "axios";
import {deleteWatchlistEntries,} from '../store/actions/dataSourceActions';

export default function ProspectTable(props: {
  pageSize: number;
  dataSourceName: string;
  searchProspectsQuery: string;
  status:string;
  watchlistTrigger:boolean,
  TogglewatchlistTrigger:() => void,
  bulkDeleteTrigger:boolean,
  ToggleBulkDeleteTrigger:() => void,
  setUniqueStatusArray:(data:any) => void
  
}) {
  const { pageSize, dataSourceName, searchProspectsQuery,status,watchlistTrigger,bulkDeleteTrigger,TogglewatchlistTrigger,ToggleBulkDeleteTrigger,setUniqueStatusArray } = props;
  const [tableData, setTableData] = useState<IProspect[]>([]);
  const [pagination, setPagination] = useState<{
    total?: number;
    current: number;
    defaultCurrent: number;
    pageSize: number;
  }>({ current: 1, defaultCurrent: 1, pageSize});
  const [loading, setLoading] = useState<boolean>(false);
  const [
    prospectBeingEdited,
    setProspectBeingEdited,
  ] = useState<IProspect | null>(null);
  const storeDispatch = useDispatch();

  const dataSourceState = useSelector(
    (state: IState) => state.dataSourceReducer
  );
  const { prospectsData } = dataSourceState;
  const prospects = prospectsData?.prospects;
  const prospectCount = prospectsData?.count;

  const { deleteSupplierFlag } = dataSourceState

  useEffect(() => {
    fetchProspects(
      dataSourceName,
      1,
      pagination.pageSize,
      searchProspectsQuery,
      status
    );
 
  }, [deleteSupplierFlag])

  //Destructuring
  const { Title, Paragraph, Text } = Typography;

  useEffect(() => {
    if (searchProspectsQuery !== undefined && dataSourceName !== undefined && status !== undefined) {
      fetchProspects(
        dataSourceName,
        1,
        pagination.pageSize,
        searchProspectsQuery,
        status
      );
    }
    // eslint-disable-next-line
  }, [searchProspectsQuery,status]);

  let statusArray:any = []
  useEffect(() => {
    if (prospects !== undefined && prospectCount !== undefined) {
      pagination.total = prospectCount;
      setPagination(pagination);
      setTableData(prospectsData.prospects);
      prospectsData.prospects.map((ele) => {
        let status = ele.status
        if (!statusArray.includes(status)){
          statusArray.push(status)
        }
      })
      //Lifting the state UP
      setUniqueStatusArray(statusArray)
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [prospects]);

  function handleTableChange(pager: any) {
    pagination.current = pager.current;
    pagination.pageSize = pager.pageSize;
    setPagination(pagination);
    fetchProspects(dataSourceName, pagination.current, pagination.pageSize,searchProspectsQuery, status);
  }

  function fetchProspects(
    nameOfDataSource: string,
    pageNumber: number,
    sizeOfPage: number,
    search?: string,
    status?: string,
  ) {
    setLoading(true);
    storeDispatch(
      requestProspectsOfDataSource({
        dataSourceName: nameOfDataSource,
        pageSize: sizeOfPage,
        pageNumber,
        search,
        status,
      } as IRequestProspectsOfDataSource)
    );
  }

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleCancel = () => {
    setProspectBeingEdited(null);
  };
  const onEditSupplierFinish = (values: any) => {
    storeDispatch(
      updateProspect({
        prospectId: editRecord.id,
        email: values.email,
        dataSourceName,
        phoneNumber: values.phoneNumber,
      })
    );
    // setEditRecord({
    //   gstin: '',
    //   email: '',
    //   companyName: '',
    //   phoneNumber: '',
    //   id: 0,
    // });
    form.resetFields();

    handleEditOk();
  };
  const handleEditOk = () => {
    setIsEditModalVisible(false);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };
  const onEditSupplierFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const deleteSelectedProspect = (prospectId: number) => {
    // eslint-disable-next-line no-console
    storeDispatch(deleteSupplier({ ids: [prospectId] }));
    // globalApiService.deleteProspect(prospectId)
    // .then((res) => {
    //   if(res.data.success){
    //     fetchProspects(
    //       dataSourceName,
    //       1,
    //       pagination.pageSize,
    //       searchProspectsQuery,
    //       status
    //     );
    //   }
    // })
  };





  const initiateWhatsappMsg = (record: { [key: string]: string }) => {
    storeDispatch(
      intitiateWhatsappMessage({
        dsName: dataSourceName,
        tradeName: record.tradeName,
      } as IWhatsappRequestBody)
    );
  };

  // const [editRecord,setEditRecord] = useState({
  //   gstin:"",
  //   email:"",
  //   companyName:"",
  //   phoneNumber:"",
  //   id:0
  // })
  const [form] = Form.useForm();
  const EditRecordFunction = (state: any, action: any) => {
    if (action.type === 'NEW') {
      return action.record;
    }
    return defaultRecord;
  };
  const defaultRecord = {
    gstin: '',
    email: '',
    // companyName: '',
    phoneNumber: '',
    id: 0,
  };

  const [editRecord, dispatchEditRecord] = useReducer(
    EditRecordFunction,
    defaultRecord
  );
  const handleEditClick = (record: any) => {
    setIsEditModalVisible(true);
    // setEditRecord(record)
    dispatchEditRecord({ type: 'NEW', record: record });
  };
  
  const columns = [
    {
      title: 'GSTIN',
      dataIndex: 'gstin',
      key: 'gstin',
      // sorter: (a:any, b:any) => a.gstin.localeCompare(b.gstin),
      // sorter: (a:any, b:any) => a.gstin - b.gstin,
      // sortDirections: ['descend'],
    },
    {
      title: 'Company Name',
      dataIndex: 'tradeName',
      key: 'tradeName',
      render: (text: any, record: any) => {
        return (
          <div>
            {(moment().subtract(3, 'days') < moment(record.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')) ? (
              <Badge count="New" offset={[22, 0]}>
                <span>{text}</span>
              </Badge>
            ) : <span>{text}</span>}
          </div>
        );
      }
      // sorter: (a:any, b:any) => a.tradeName.localeCompare(b.tradeName.length),
      // sorter: (a:any, b:any) => a.tradeName.length - b.tradeName.length,
      // sortDirections: ['descend'],
    },
    {
      title: 'GSTR Filing Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Email id',
      dataIndex: 'email',
      key: 'email',
      // sorter: (a:any, b:any) => {
      //   if(a.email && b.email){
      //     return a.email.localeCompare(b.email)
      //   }
      // },
      // sorter: (a:any, b:any) => a.email - b.email,
      // sortDirections: ['descend'],
    },
    {
      title: 'Phone no',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      // sorter: (a:any, b:any) => {
      //   if(a.phoneNumber && b.phoneNumber){
      //     return a.phoneNumber.localeCompare(b.phoneNumber)
      //   }
      // },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (text: any, record: any) => {
        return (
          <div className="iconContainer">
            <FiEdit3
              className="iconContainer__item"
              onClick={() => handleEditClick(record)}
            ></FiEdit3>
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete this prospect?"
              onConfirm={() => deleteSelectedProspect(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <RiDeleteBinLine
                className="iconContainer__item"
              >
              </RiDeleteBinLine>
            </Popconfirm>
          </div>
        );
      },
    },
  ] as any[];
  const [rowkeys,setRowKeys] = useState<React.Key[]>([])
  useEffect(() => {
    if(watchlistTrigger){
      if(rowkeys.length){
        addToWatchList();
      }else {
        OpenNotification('Please select atleast one supplier','error')
      }
    }
    
    TogglewatchlistTrigger()
  }, [watchlistTrigger])
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setRowKeys(selectedRowKeys)
    },
  };

  const addToWatchList = () => {
    if(rowkeys){
      setLoading(true);
      globalApiService.AddToWatchlist(rowkeys)
        .then((res) => {
          // TogglewatchlistTrigger()
          if(res.data.success){
            TogglewatchlistTrigger()
            OpenNotification(
              `${res.data.data.message}`,'success'
            )
            // setRowKeys([])
          }else{
            TogglewatchlistTrigger()
            OpenNotification(
              `${res.data.data.message}`,'error'
            )
          }
          setLoading(false);
        }).catch((err: AxiosError) => {
        TogglewatchlistTrigger()
        const errorBody = err?.response?.data;
        setLoading(false);
      });
    }
  }
  
  useEffect(() => {
    
    if(bulkDeleteTrigger){
      let tempWatchlist:any = []
      let watclistDeletedKeys:any = []
      globalApiService.getWatchlistEntries({
        dataSourceName: dataSourceName,
        pageSize:10,
        pageNumber:1,
        search:'',
        status:''}).then((res) => {
        if(res.data.success){
          tempWatchlist = [...res.data.data.results]
          tempWatchlist.map((ele:any,idx:any) => {
          Array.from(rowkeys).map((Keyele:any) => {
            if(ele.prospect_id == Keyele){
              watclistDeletedKeys.push(ele.id)
            }
          })
        })
        if(rowkeys.length > 0){
          console.log('watclistDeletedKeys',watclistDeletedKeys)
          //eslint-disable-next-line no-restricted-globals
          if (confirm('Are you sure you want to delete these suppliers?')) {
            storeDispatch(deleteSupplier({ids: [...rowkeys]}));
            if(watclistDeletedKeys.length){
              storeDispatch(deleteWatchlistEntries({ids: [...watclistDeletedKeys]}));
            }
  
            setRowKeys([])
          }
          ToggleBulkDeleteTrigger()
        }else {
          OpenNotification('Please select atleast one supplier to delete','error')
        }
        } else {
          OpenNotification(res?.data?.message || 'Error Occured', 'error');
        }
        setLoading(false);
      }).catch((err: AxiosError) => {
        const errorBody = err?.response?.data;
        setLoading(false);
      });
      
      ToggleBulkDeleteTrigger()
      
    }
    
  }, [bulkDeleteTrigger])

  return (
    <div>
      <Modal
        className="addSupplierModal"
        title=""
        centered
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        footer={null}
      >
        <section className="addSupplierform">
          <Title level={3}>Edit supplier details</Title>
          <div className="addSupplierform__inputbox">
            <Form
              form={form}
              name="basic"
              layout="vertical"
              // initialValues={{ remember: true }}
              onFinish={onEditSupplierFinish}
              onFinishFailed={onEditSupplierFailed}
              autoComplete="off"
            >
              <Row gutter={48}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Supplier GSTIN"
                    name="gstin"
                    //   rules={[{message: '07AABCU9603R1ZP' }]}
                    rules={
                      [
                        // {
                        //   required: true,
                        //   message: 'gstin is a manadatory field',
                        // },
                        // {
                        //   pattern: new RegExp(
                        //     /([0-9]{2})([A-Z]{5}[0-9]{4}[A-Z])[1-9A-Z][A-Z][0-9A-Z]/
                        //   ),
                        //   message: 'Enter a valid gstin',
                        // },
                      ]
                    }
                  >
                    <Input
                      style={{ width: '100%' }}
                      // placeholder="Enter supplier GSTIN"
                      name="gstin"
                      disabled
                      // defaultValue={editRecord.gstin}
                      // onChange={onAddSupplierChange}
                      placeholder={editRecord.gstin}

                      //   style={{ width: '60%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Supplier Email"
                    name="email"
                    //   rules={[{message: '07AABCU9603R1ZP' }]}
                    rules={[
                      {
                        pattern: new RegExp(
                          /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
                        ),
                        message: 'Enter a valid email',
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      name="email"
                      placeholder={editRecord.email}
                      // value={addSupplierValue.email}
                      // onChange={onAddSupplierChange}

                      //   style={{ width: '60%' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  {/* <Form.Item
                    label="Company Name"
                    name="companyName"
                    //   rules={[{message: '07AABCU9603R1ZP' }]}
                    rules={[
                      {
                        pattern: new RegExp(/^[a-zA-Z]+$/),
                        message: 'Enter a valid company name',
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      name="companyName"
                      placeholder="Enter company name"
                      // value={addSupplierValue.companyName}
                      // onChange={onAddSupplierChange}

                      //   style={{ width: '60%' }}
                    />
                  </Form.Item> */}
                  <Form.Item
                    label="Contact no"
                    name="phoneNumber"
                    //   rules={[{message: '07AABCU9603R1ZP' }]}
                    rules={[
                      {
                        pattern: new RegExp(
                          /^(?:(?:\+|0{0,2})91(\s*[ -]\s*)?|[0]?)?[56789]\d{9}|(\d[ -]?){10}\d$/
                        ),
                        message: 'Enter a valid contact number',
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      name="phoneNumber"
                      placeholder={editRecord.phoneNumber}
                      // value={addSupplierValue.contactNo}
                      // onChange={onAddSupplierChange}

                      //   style={{ width: '60%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="addSupplierform__btnbox">
                <Form.Item>
                  <p
                    className="addSupplierform__btnbox__btn addSupplierform__btnbox__btn--paragraph"
                    style={{
                      color: THEME.PRIMARY_COLOR,
                    }}
                    onClick={handleEditCancel}
                  >
                    Cancel
                  </p>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="addSupplierform__btnbox__btn"
                    style={{
                      backgroundColor: THEME.PRIMARY_COLOR,
                    }}
                  >
                    Update
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </section>
      </Modal>

      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={tableData}
        loading={loading}
        onChange={handleTableChange}
        pagination={pagination}
        scroll={{ x: true }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </div>
  );
}

ProspectTable.defaultProps = {
  searchProspectsQuery: '',
};
