import { put, takeEvery } from 'redux-saga/effects';

import {
  ReportsActions,
  requestReportFilesSuccess,
  requestReportHistorySuccess,
} from '../actions/reportsActions';
import { exceptionLogger } from './utils';
import { IAction, IReportTracker } from '../../models';
import {
  IRequestReportFiles,
  IRequestReportForDataSource,
  IRequestReportHistory,
} from '../../models/api/reportsModels';
import { globalApiService } from '../../services/apiService';
import OpenNotification from '../../utils/notification';

function* requestReportHistoryWatcher(action: IAction) {
  const payload: IRequestReportHistory = action.body;
  const response = yield globalApiService.getReportHistory(payload);
  const dataInResp = response?.data;
  if (dataInResp) {
    const reportHistoryList: IReportTracker[] = dataInResp.map((el: any) => {
      return {
        id: el.id,
        reportName: el.report_name,
        initiatedAt: el.initiated_at,
        completedAt: el.completed_at,
        status: el.status,
      };
    });
    yield put(
      requestReportHistorySuccess({ reportHistory: reportHistoryList }),
    );
  }
}

function* requestReportFilesWatcher(action: IAction) {
  const payload: IRequestReportFiles = action.body;
  const response = yield globalApiService.getReportFiles(payload);
  const dataInResponse = response?.data;
  if (dataInResponse) {
    const reportFiles = dataInResponse.report_files.map((el: any) => {
      return { fileName: el.file_name, fileUrl: el.file_url };
    });
    yield put(
      requestReportFilesSuccess({
        reportTrackerId: dataInResponse.id,
        files: reportFiles,
      }),
    );
  }
}

function* requestReportForDataSourceWatcher(action: IAction) {
  const payload: IRequestReportForDataSource = action.body;
  yield globalApiService.generateReportForDataSource({
    dataSourceId: payload.dataSourceId,
  });
  OpenNotification(
    'KYSS is generating the report you requested. Please check your ‘Downloads Center’ 10 mins for the latest report.',
    'success',
  );
}

export default function* reportsWatcher(): IterableIterator<any> {
  yield takeEvery(ReportsActions.REQUEST_REPORT_HISTORY, (action) =>
    exceptionLogger(requestReportHistoryWatcher(action)),
  );
  yield takeEvery(ReportsActions.REQUEST_REPORT_FILES, (action) =>
    exceptionLogger(requestReportFilesWatcher(action)),
  );
  yield takeEvery(ReportsActions.REQUEST_REPORT_FOR_DATA_SOURCE, (action) =>
    exceptionLogger(requestReportForDataSourceWatcher(action)),
  );
}
