import { all } from 'redux-saga/effects';
import AuthWatcher from './authSaga';
import DataSourceWatcher from './dataSourceSaga';
import ColleagueWatcher from './colleagueSaga';
import reportsWatcher from './reportsSaga';
import reconeWatcher from './ReconcilationSaga';
import supplierWatcher from './supplierReportSaga';
import AnalyticsWatcher from './analyticsSaga';

export default function* sagas() {
  yield all([
    AnalyticsWatcher(),
    AuthWatcher(),
    DataSourceWatcher(),
    ColleagueWatcher(),
    reportsWatcher(),
    reconeWatcher(),
    supplierWatcher()
  ]);
}
