import React from 'react';
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Radio,
  Badge
} from 'antd';
import {useHistory} from 'react-router';
import {THEME} from '../constants/theme';

const GSTINSearch = () => {
  const history = useHistory();
  const {Title, Paragraph, Text} = Typography;

  const onFinish = (values: any) => {
    // console.log('Success:', values);
    history.push(`/gstin/${value.inputValue.trim()}`);
  };
  // Form
  const [gstForm] = Form.useForm();
  const [value, setValue] = React.useState(
    {
      radioValue: 'gstin',
      inputValue: ''

    }
  );

  const onChange = (e: any) => {
    let name = e.target.name;
    let val = e.target.value;
    setValue((prevState) => {
      return { ...prevState, [name]: val };
    });
    console.log(value);
  };

  const onInputChange = (e: any) => {
    let name = e.target.name;
    let target_value = e.target.value;
    setValue((prevState) => {
      return { ...prevState, [name]: target_value };
    });
  }
  return (
    <Card className="card">
      <Row>
        <Title level={3}>Search by</Title>
      </Row>

      <Form
        form={gstForm}
        name="basic"
        initialValues={{radioValue: 'gstin'}}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item>
          <Radio.Group onChange={onChange} value={value.radioValue}>
            <Radio value='gstin'>GSTIN</Radio>
            <Radio value='pan' disabled>
              Business PAN
              <Badge className='badge'>
                <span className='badge-span'>Coming soon</span>
              </Badge>
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="inputValue"
          rules={[
            {
              pattern: new RegExp(
                '(\s*[0-9]{2})([A-Za-z]{5}[0-9]{4}[A-Za-z]{1})[1-9A-Za-z]{1}[Zz]{1}[0-9A-Za-z]{1}\s*'
              ),
              message: 'Please enter valid GST number',
            },
          ]}
        >
          <Input name="inputValue" placeholder="07AABCU9603R1ZP" onChange={onInputChange} className='input-class'/>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: THEME.PRIMARY_COLOR,
              borderRadius: '5px',
              padding: '0 1.2rem'
            }}
            disabled={!value.inputValue}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
export default GSTINSearch;
